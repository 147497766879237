import {
  FETCH_DESTINATION_MONTHS_BEGIN,
  FETCH_DESTINATION_MONTHS_SUCCESS,
  FETCH_DESTINATION_MONTHS_FAILURE
} from '../actions/fetchDestinationMonths'

const initialState = {
  loading: false,
  error: null
}

export default function fetchDestinationMonthsReducers(
  state = initialState,
  action
) {
  switch (action.type) {
    case FETCH_DESTINATION_MONTHS_BEGIN:
      // Request begin: set loading true
      return {
        ...state,
        loading: true,
        error: null
      }

    case FETCH_DESTINATION_MONTHS_SUCCESS:
      // Request success: set loading false, and display request data
      return {
        ...state,
        loading: false,
        destinations: action.payload.destinations,
        months: action.payload.months,
      }

    case FETCH_DESTINATION_MONTHS_FAILURE:
      // Request failed: set loading false, save & display error
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        destinations: []
      }

    default:
      return state
  }
}
