import {
  SUBMIT_EDIT_AGENT_BEGIN,
  SUBMIT_EDIT_AGENT_SUCCESS,
  SUBMIT_EDIT_AGENT_FAILURE
} from '../actions/submitEditAgentActions'

const initialState = {
  loading: false,
  error: null
}

export default function submitEditAgentReducers(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_EDIT_AGENT_BEGIN:
      // Request begin: set loading true
      return {
        ...state,
        loading: true,
        error: null
      }

    case SUBMIT_EDIT_AGENT_SUCCESS:
      // Request success: set loading false, and display request data
      return {
        ...state,
        loading: false,
        agency: action.payload.agency,
        agent: action.payload.agent,
        image: action.payload.image
      }

    case SUBMIT_EDIT_AGENT_FAILURE:
      // Request failed: set loading false, save & display error
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        ...[]
      }

    default:
      return state
  }
}
