import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import { get } from 'lodash'
import brandConfiguration from '../../configuration/brands'

import fetchItinerary from '../../actions/fetchItinerary'

import EQuotePackageDetailsHeader from '../../components/packageDetails/EQuotePackageDetailsHeader'

import tourIconDark from '../../assets/icons/360-tour-dark.svg'

import '../../styles/scss/components/equote-package-details-modal.scss'
import '../../styles/scss/components/equote-package-details.scss'
import '../../styles/scss/components/equote-land-program-flag.scss'
import '../../styles/scss/base/sliders.scss'

import EQuotePackageDetailsOutline from '../../components/packageDetails/EQuotePackageDetailsOutline'
import EQuotePackageDetailsHeroImage from '../../components/packageDetails/EQuotePackageDetailsHeroImage'
import EQuotePackageDetailsHighlights from '../../components/packageDetails/EQuotePackageDetailsHighlights'
import EQuotePackageDetailsItinerary from '../../components/packageDetails/EQuotePackageDetailsItinerary'
import EQuotePackageDetailsNavigation from '../../components/packageDetails/EQuotePackageDetailsNavigation'
import EQuotePackageDetailsShipInformation from '../../components/packageDetails/EQuotePackageDetailsShipInformation'
import EQuotePackageDetailsRelatedSailings from '../../components/packageDetails/EQuotePackageDetailsRelatedSailings'
import EQuotePackageDetailsAgentAccolades from '../../components/packageDetails/EQuotePackageDetailsAgentAccolades'
import EQuotePackageDetailsAgentFooter from '../../components/packageDetails/EQuotePackageDetailsAgentFooter'
import EQuotePackageDetailsFooter from '../../components/packageDetails/EQuotePackageDetailsFooter'
import EQuotePackageDetailsStateRooms from '../../components/packageDetails/EQuotePackageDetailsStateRooms'

import { filterImagesByFileName } from '../../utilities/filterImagesByFileName'
import { getAllActivePackagesDetails } from '../../reducers/setActivePackageReducers'
import { getAllActiveCabinData } from '../../reducers/setActiveCabinReducers'
import { getPackagesDetails } from '../../reducers/fetchPackagesReducers'
import { getBrandsData } from '../../sessionStorage/getBrandsData'
import {
  getDestination,
  getDestinationsData
} from '../../sessionStorage/getDestinationsData'
import { getShipsData } from '../../sessionStorage/getShipsData'
import EQuotePackageDetailsLandProgram from '../../components/packageDetails/EQuotePackageDetailsLandProgram'
import { getLandPackageByIdentifier } from '../../reducers/fetchLandPackagesReducer'
import { getTermsByCurrency } from '../../reducers/fetchConfigurationReducers'

// import EQuoteVirtualTourBanner from '../../components/packageDetails/EQuoteVirtualTourBanner'

class EQuotePackageDetailsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      virtualTourModalIsOpen: false,
      virtualTourLink: null,
      virtualTourTitle: null
    }
  }

  componentDidMount() {
    const {
      handleFetchItinerary,
      packageDetails,
      packageItinerary
    } = this.props

    if (packageDetails && !packageItinerary) {
      // Request itinerary for brand package
      const { brand, id, sailDate, identifier, shipCode } = packageDetails
      handleFetchItinerary(brand, id, sailDate, identifier, shipCode)
    }

    window.onpopstate = () => {
      this.props.handleClosePackageDetailsModal()
      window.history.go(1)
    }
  }

  openVirtualTourModal = ({ link, title }) => {
    this.setState({
      virtualTourModalIsOpen: true,
      virtualTourLink: link,
      virtualTourTitle: title
    })
  }

  closeVirtualTourModal = () => {
    this.setState({
      virtualTourModalIsOpen: false,
      virtualTourLink: null,
      virtualTourTitle: null
    })
  }

  renderPackageDetails = () => {
    const {
      packageDetails,
      brands,
      ssoData,
      shipsData,
      destinationsData,
      modifiedSsoData,
      activeCabinData,
      stateRooms,
      activeSearchFilterData,
      landProgramData,
      guestCount,
      terms
    } = this.props
    if (packageDetails) {
      const relatedSailings =
        packageDetails &&
        packageDetails.relatedSailings &&
        packageDetails.relatedSailings.length
          ? packageDetails.relatedSailings
          : []

      const brandDetails =
        brands.find(brand => brand.brand_code === packageDetails.brand) || {}

      const brandClassName = get(
        brandConfiguration,
        [packageDetails.brand, 'className'],
        ''
      )
      return (
        <div
          className={`equote__package-details ${brandClassName}`}
          key={packageDetails.id}
        >
          <div className="u-modal__body">
            <EQuotePackageDetailsHeader
              brandDetails={brandDetails}
              brandClassName={brandClassName}
              modifiedSsoData={modifiedSsoData}
              ssoData={ssoData}
            />

            <EQuotePackageDetailsOutline
              packageData={packageDetails}
              brandDetails={brandDetails}
              brandClassName={brandClassName}
              activeCabinData={activeCabinData}
              fees={0}
              selectedStateRoomData={stateRooms}
              landProgramData={landProgramData}
              guestCount={guestCount}
            />

            {/*<EQuoteVirtualTourBanner brandClassName={brandClassName} />*/}

            <EQuotePackageDetailsHeroImage
              shipsData={shipsData}
              brandDetails={brandDetails}
              packageData={packageDetails}
              destinationsData={destinationsData}
              brandClassName={brandClassName}
              itinerary={this.props.packageItinerary}
            />
            <EQuotePackageDetailsNavigation />
            <EQuotePackageDetailsHighlights
              brandDetails={brandDetails}
              packageData={packageDetails}
              brandClassName={brandClassName}
            />
            {landProgramData && Object.keys(landProgramData).length ? (
              <EQuotePackageDetailsLandProgram
                landProgramData={landProgramData}
              />
            ) : null}
            {this.props.packageItinerary && (
              <>
                <EQuotePackageDetailsItinerary
                  itinerary={this.props.packageItinerary}
                  brandClassName={brandClassName}
                  startDate={packageDetails.startDate}
                  packageData={packageDetails}
                  destinationImage={this.props.destinationImage}
                />
              </>
            )}
            <EQuotePackageDetailsShipInformation
              shipDetails={
                this.props.shipsData &&
                this.props.shipsData.find(
                  ship => ship.ship_code === packageDetails.shipCode
                )
              }
              brandClassName={brandClassName}
              openVirtualTour={this.openVirtualTourModal}
              closeVirtualTour={this.closeVirtualTourModal}
            />
            <EQuotePackageDetailsStateRooms
              brandClassName={brandClassName}
              currentPackageData={packageDetails}
              currency={packageDetails.currency}
              selectedStateRoomData={stateRooms}
              activeSearchFilterData={activeSearchFilterData}
              openVirtualTour={this.openVirtualTourModal}
              closeVirtualTour={this.closeVirtualTourModal}
              terms={terms}
            />
            <EQuotePackageDetailsRelatedSailings
              brandClassName={brandClassName}
              packageData={packageDetails}
              currency={packageDetails.currency}
              relatedSailings={relatedSailings}
              destinationImage={this.props.destinationImage}
            />
            <EQuotePackageDetailsAgentAccolades
              brandClassName={brandClassName}
              brandCode={packageDetails.brand}
            />
            <EQuotePackageDetailsAgentFooter
              agent={this.props.ssoData.agent}
              agency={this.props.ssoData.agency}
              brandClassName={brandClassName}
              modifiedSsoData={modifiedSsoData}
              ssoData={ssoData}
            />
            <EQuotePackageDetailsFooter />
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <p>Unable to find details for this sailing</p>
        </div>
      )
    }
  }

  render() {
    const { detailsModalVisible, handleClosePackageDetailsModal } = this.props
    return (
      <Modal
        className={{
          base: 'u-modal--preview-package',
          afterOpen: 'u-modal--visible',
          beforeClose: 'u-modal--close',
          ariaHideApp: false
        }}
        overlayClassName={{
          base: 'u-fade',
          afterOpen: 'u-fade--visible u-add-padding',
          beforeClose: 'u-fade--visible'
        }}
        isOpen={detailsModalVisible}
        onRequestClose={handleClosePackageDetailsModal}
        contentLabel="eQuote Package Details Modal"
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        htmlOpenClassName="ReactModal__Html--open"
      >
        <div className="equote__package-detail-modal">
          <div
            onClick={this.props.handleClosePackageDetailsModal}
            className="u-modal__header--sticky"
          >
            <div className="u-modal__close" tabIndex={0}>
              Dismiss{' '}
              <svg
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.21348 12.6543C2.8541 12.9837 2.83913 13.3281 3.16855 13.6875C3.52793 14.0169 3.87233 14.002 4.20176 13.6426L8.1998 9.68945L12.1979 13.6426C12.5273 14.002 12.8567 14.0169 13.1861 13.6875C13.5455 13.3281 13.5455 12.9837 13.1861 12.6543L9.23301 8.65625L13.1861 4.6582C13.5455 4.32878 13.5455 3.99935 13.1861 3.66992C12.8567 3.31055 12.5273 3.31055 12.1979 3.66992L8.1998 7.62305L4.20176 3.66992C3.87233 3.31055 3.52793 3.31055 3.16855 3.66992C2.83913 3.99935 2.8541 4.32878 3.21348 4.6582L7.1666 8.65625L3.21348 12.6543Z"
                  fill="#2A467B"
                />
              </svg>
            </div>
          </div>
          <div>{this.renderPackageDetails()}</div>
        </div>
        <Modal
          className={{
            base: 'u-modal virtual-tour-modal',
            afterOpen: 'u-modal--visible',
            beforeClose: 'u-modal--close',
            ariaHideApp: false
          }}
          overlayClassName={{
            base: 'u-fade',
            afterOpen: 'u-fade--visible u-add-padding',
            beforeClose: 'u-fade--visible'
          }}
          contentLabel="eQuote Virtual Tour"
          shouldCloseOnOverlayClick={true}
          ariaHideApp={false}
          htmlOpenClassName="ReactModal__Html--open"
          isOpen={detailsModalVisible && this.state.virtualTourModalIsOpen}
          onRequestClose={this.closeVirtualTourModal}
        >
          <div className="virtual-tour-modal-content">
            <div className="virtual-tour-modal-header">
              <div className="virtual-tour-modal-title-container">
                <img
                  src={tourIconDark}
                  alt={this.state.virtualTourTitle || 'Virtual Tour'}
                />
                <h1 className="virtual-tour-modal-title">
                  {this.state.virtualTourTitle}
                </h1>
              </div>
              <button className="btn " onClick={this.closeVirtualTourModal}>
                Close X
              </button>
            </div>
            <div className="virtual-tour-frame-container">
              <iframe
                title={this.state.virtualTourTitle}
                src={this.state.virtualTourLink}
                frameBorder="0"
                allow="vr"
                allowFullScreen="allowfullscreen"
              />
            </div>
          </div>
        </Modal>
      </Modal>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const brandCode =
    ownProps &&
    ownProps.activeDetailsData &&
    ownProps.activeDetailsData.brand &&
    ownProps.activeDetailsData.brand.toLowerCase()

  const destinationCode =
    ownProps &&
    ownProps.activeDetailsData &&
    ownProps.activeDetailsData.destinationCode

  const destinationDetails = getDestination(destinationCode)

  const destinationImageSource =
    destinationDetails && destinationDetails[`image_${brandCode}`]
      ? destinationDetails[`image_${brandCode}`]
      : destinationDetails && destinationDetails.image

  const destinationImage =
    destinationImageSource &&
    filterImagesByFileName(destinationImageSource, 'details-hero')

  const packageDetails = getPackagesDetails(
    state,
    ownProps.isCruiseTour,
    `${ownProps.activeDetailsData.id}-${ownProps.activeDetailsData.sailDate}`,
    Boolean(ownProps.activeDetailsData.landPackageIdentifier),
    Boolean(ownProps.activeDetailsData.landPackageIdentifier)
      ? ownProps.activeDetailsData.landPackageIdentifier
      : null
  )

  let landProgramData =
    ownProps.activeDetailsData &&
    ownProps.activeDetailsData.allSelectedLandPrograms
      ? ownProps.activeDetailsData.allSelectedLandPrograms
      : null

  if (!landProgramData && ownProps.activeDetailsData.landPackageIdentifier) {
    const program = getLandPackageByIdentifier(
      state,
      ownProps.activeDetailsData.landPackageIdentifier
    )

    const programKey = program.prePostTagName

    landProgramData = {
      [programKey]: program
    }
  }

  const itineraryKey = packageDetails
    ? `${packageDetails.identifier}-${packageDetails.brand}`
    : null

  const packageItinerary =
    state.itineraryData.itineraries &&
    itineraryKey &&
    state.itineraryData.itineraries[itineraryKey]
      ? state.itineraryData.itineraries[itineraryKey]
      : null
  const terms = getTermsByCurrency(state)
  return {
    guestCount: state.activeSearchFilterData.guestCount,
    availablePackages: ownProps.isCruiseTour
      ? state.cruiseToursData.tours
      : state.packagesData.availablePackages,
    packageDetails,
    activePackages: getAllActivePackagesDetails(state),
    brands: getBrandsData(),
    destinationsData: getDestinationsData(),
    isCruiseTour: ownProps.isCruiseTour,
    packageItinerary,
    shipsData: getShipsData(),
    ssoData: state.ssoData,
    activeCabinData: getAllActiveCabinData(state),
    stateRooms: packageDetails.selectedStateRooms,
    modifiedSsoData: state.modifiedSsoData,
    unsupportedQualifierPricesData: state.unsupportedQualifierPricesData,
    destinationImage,
    activeSearchFilterData: state.activeSearchFilterData,
    landProgramData,
    terms
  }
}

const mapDispatchToProps = dispatch => ({
  handleFetchItinerary(brand, packageId, sailDate, identifier, shipCode) {
    return dispatch(
      fetchItinerary(brand, packageId, sailDate, identifier, shipCode)
    )
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuotePackageDetailsContainer)
