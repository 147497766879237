import React from 'react'

import BackArrow from '../../assets/icons/back-button-arrow.svg'

const PageHeader = props => {
  return (
    <div className="u-page-header">
      {props.buttonText && (
        <button className="btn btn-transparent" onClick={props.clickHandler}>
          <span className="u-page-header__back-button-inner">
            <span className="u-page-header__back-button-icon-container">
              <img src={BackArrow} alt="Begin a New Search" />
            </span>
            <span>{props.buttonText}</span>
          </span>
        </button>
      )}
      <div className="u-page-header__title">
        <h2>{props.title}</h2>
        {props.subTitle && <p className="u-remove-margin">{props.subTitle}</p>}
      </div>
    </div>
  )
}

export default PageHeader
