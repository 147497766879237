export default function updateSearchParams(activeSearchFilterData) {
  const queryParameters = new URLSearchParams(window.location.search)

  if (activeSearchFilterData.brand) {
    queryParameters.set('brand', activeSearchFilterData.brand)
  } else if (activeSearchFilterData.brand === '') {
    queryParameters.delete('brand')
  } else {
    queryParameters.delete('brand')
  }

  if (activeSearchFilterData.bookingType) {
    queryParameters.set('bookingType', activeSearchFilterData.bookingType)
  } else {
    queryParameters.delete('bookingType')
  }

  if (activeSearchFilterData.cruiseType) {
    queryParameters.set('cruiseType', activeSearchFilterData.cruiseType)
  } else {
    queryParameters.delete('cruiseType')
  }

  if (activeSearchFilterData.currency) {
    queryParameters.set('currency', activeSearchFilterData.currency)
    queryParameters.delete('cur')
    queryParameters.delete('Cur')
  } else {
    queryParameters.delete('currency')
  }

  if (activeSearchFilterData.startDate) {
    queryParameters.set('startDate', activeSearchFilterData.startDate)
  } else {
    queryParameters.delete('startDate')
  }

  if (activeSearchFilterData.endDate) {
    queryParameters.set('endDate', activeSearchFilterData.endDate)
  } else {
    queryParameters.delete('endDate')
  }

  if (activeSearchFilterData.duration) {
    queryParameters.set('duration', activeSearchFilterData.duration)
  } else {
    queryParameters.delete('duration')
  }

  if (activeSearchFilterData.ship) {
    queryParameters.set('ship', activeSearchFilterData.ship)
  } else if (activeSearchFilterData.ship === '') {
    queryParameters.delete('ship')
  } else {
    queryParameters.delete('ship')
  }

  if (activeSearchFilterData.port) {
    queryParameters.set('port', activeSearchFilterData.port)
  } else {
    queryParameters.delete('port')
  }

  if (activeSearchFilterData.region) {
    queryParameters.set('region', activeSearchFilterData.region)
  } else if (activeSearchFilterData.region === '') {
    queryParameters.delete('region')
  } else {
    queryParameters.delete('region')
  }

  if (activeSearchFilterData.isSeniorCitizen) {
    queryParameters.set('seniorReqd', activeSearchFilterData.isSeniorCitizen)
  } else {
    queryParameters.delete('seniorReqd')
  }

  if (activeSearchFilterData.isServiceMember) {
    queryParameters.set('militaryReqd', activeSearchFilterData.isServiceMember)
  } else {
    queryParameters.delete('militaryReqd')
  }

  if (activeSearchFilterData.isPolice) {
    queryParameters.set('policeReqd', activeSearchFilterData.isPolice)
  } else {
    queryParameters.delete('policeReqd')
  }

  if (activeSearchFilterData.isFireFighter) {
    queryParameters.set('fireFighterReqd', activeSearchFilterData.isFireFighter)
  } else {
    queryParameters.delete('fireFighterReqd')
  }

  if (activeSearchFilterData.residentOf) {
    queryParameters.set('residentOf', activeSearchFilterData.residentOf)
  } else {
    queryParameters.delete('residentOf')
  }

  if (activeSearchFilterData.loyaltyId) {
    queryParameters.set('loyaltyId', activeSearchFilterData.loyaltyId)
  } else {
    queryParameters.delete('loyaltyId')
  }

  if (activeSearchFilterData.sso) {
    queryParameters.set('sso', activeSearchFilterData.sso)
    queryParameters.delete('z')
    queryParameters.delete('cptok')
  } else {
    queryParameters.delete('sso')
  }

  if (activeSearchFilterData.count) {
    queryParameters.set('count', activeSearchFilterData.count)
  } else {
    queryParameters.delete('count')
  }

  if (activeSearchFilterData.sortBy) {
    queryParameters.set('sortBy', activeSearchFilterData.sortBy)
  } else {
    queryParameters.delete('sortBy')
  }

  if (activeSearchFilterData.guestCount) {
    queryParameters.set('guestCount', activeSearchFilterData.guestCount)
    queryParameters.delete('occupancy')
  } else {
    queryParameters.delete('guestCount')
  }

  // Redirect with updated parameters
  window.history.pushState({}, '', '/quote/?' + queryParameters)
}
