import React, { Component } from 'react'

import EQuoteReviewConstructEmailInput from './EQuoteReviewConstructEmailInput'
import EQuoteReviewConstructHeadlineInput from './EQuoteReviewConstructHeadlineInput'
import EQuoteReviewConstructCommentsInput from './EQuoteReviewConstructCommentsInput'
import EQuoteReviewConstructTermsCheckbox from './EQuoteReviewConstructTermsCheckbox'

import fullSearchIcon from '../../assets/icons/full-search.svg'

class EQuoteReviewConfigureEmail extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { labelsData, quoteError } = this.props

    return (
      <div className="equote__review-configure-email">
        <div className="equote__review-configure-email--header">
          <div className="equote__review-configure-email--header--img-container">
            <img src={ fullSearchIcon } alt="Ship icon" />
          </div>
          <h6 className="u-remove-margin">Set Up Email</h6>
        </div>

        <div className="equote__review-configure-col">
          <EQuoteReviewConstructEmailInput />
        </div>

        <div className="equote__review-configure-col">
          <EQuoteReviewConstructHeadlineInput
	          labelsData={ labelsData }
          />
        </div>

        <div className="equote__review-configure-col">
          <EQuoteReviewConstructCommentsInput
	          labelsData={ labelsData }
          />
        </div>

        <div className="equote__review-configure-col">
          <EQuoteReviewConstructTermsCheckbox />
        </div>

	      { quoteError ?
		      <p className="u-font-error">{ quoteError.message ? quoteError.message : quoteError }</p>
        :
		      null
	      }
      </div>
    )
  }
}


export default EQuoteReviewConfigureEmail
