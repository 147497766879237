import React, { useState } from 'react'
import Slider from 'react-slick/lib'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { PrevArrow, NextArrow } from '../elements/CarouselArrows'

import '../../styles/scss/components/equote-package-details-ship-image-slider.scss'
import VideoSlide from '../packageDetails/VideoSlide'

const EQuotePackageCarousel = props => {
  const [activeSlide, setActiveSlide] = useState(0)
  const settings = {
    lazyLoad: 'progressive',
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow {...props} numberOfSlidesToShow={1} infinite />,
    nextArrow: <NextArrow {...props} numberOfSlidesToShow={1} infinite />,
    afterChange: current => setActiveSlide(current)
  }
  return (
    <div className="equote__package-details-ship-image-slider">
      {Boolean(props.slides && props.slides.length) && (
        <Slider {...settings}>
          {props.slides.map((slide, i) => {
            if (slide.type === 'video') {
              return (
                <div
                  key={i}
                  style={{
                    position: 'relative'
                  }}
                  className="equote__package-details-ship-image-slider-img-container"
                >
                  <VideoSlide slide={slide} isActive={activeSlide === i} />
                </div>
              )
            }

            return (
              <div key={i}>
                <div
                  style={{
                    backgroundImage: `url(${slide.displayImage})`
                  }}
                  className="equote__package-details-ship-image-slider-img-container"
                >
                  <img
                    className="equote__package-details-ship-image-slider-img"
                    src={slide.displayImage}
                    alt={slide.marketing_title}
                  />
                  {!props.shouldHideText && (
                    <div className="equote__package-details-ship-image-slider--text">
                      <h1>{slide.marketing_title}</h1>
                      <p>{slide.marketing_subtitle}</p>
                    </div>
                  )}
                  {!props.shouldHideText &&
                    Boolean(
                      slide.marketing_title.length ||
                        slide.marketing_subtitle.length
                    ) && (
                      <div className="equote__package-details-ship-image-slider-img--overlay" />
                    )}
                </div>
              </div>
            )
          })}
        </Slider>
      )}
    </div>
  )
}

export default EQuotePackageCarousel
