import React from 'react'
import { connect } from 'react-redux'
import { getPackagesDetails } from '../../../../reducers/fetchPackagesReducers'
import shouldDisableRowButtons from '../shouldDisableRowButtons'

const EQuoteTablePreviewButtonCell = props => {
  return (
    <button
      className="btn u-transparent equote__sailings-full-search-table-tabs-details"
      onClick={props.onClick}
      disabled={props.isDisabled}
    >
      preview
    </button>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { identifier, justACruise } = ownProps

  const packageDetails = getPackagesDetails(state, !justACruise, identifier)

  return {
    isDisabled: shouldDisableRowButtons(packageDetails)
  }
}

export default connect(mapStateToProps)(EQuoteTablePreviewButtonCell)
