import React, { Component } from 'react'
import { connect } from 'react-redux'
import setActiveCabin from '../../actions/setActiveCabin'
import removeActiveCabin from '../../actions/removeActiveCabin'

import Checkbox from '../../components/elements/Checkbox'

import '../../styles/scss/components/equote-cabin-table-selection.scss'

class EQuoteCabinsTableButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isCabinSelected: props.isSelected
    }
  }

  onRemoveActiveCabin = () => {
    this.props.handleRemoveActiveCabin()
  }

  onSetActiveCabin = () => {
    this.props.handleSetActiveCabin()
  }

  handleCheckboxClick = () => {
    const { numberOfActiveCabinsBySailing } = this.props
    const disableCheckBox = numberOfActiveCabinsBySailing >= 8

    this.state.isCabinSelected
      ? this.onRemoveActiveCabin()
      : !disableCheckBox && this.onSetActiveCabin()
  }

  render() {
    const { label, packageData, cabinData } = this.props
    const key = `${packageData.identifier}-${cabinData.pricingDisplayName}-${
      cabinData.stateroomCategoryCode
    }`
    return (
      <div className="equote-cabin-table-cabin-selection">
        <label>
          <Checkbox
            checked={this.state.isCabinSelected}
            key={key}
            onClick={this.handleCheckboxClick}
            id={key}
          />
          <span>{label}</span>
        </label>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    isRefundable,
    stateroomCategoryCode,
    superCategoryCode
  } = ownProps.cabinData

  const {
    id,
    sailDate,
    landPackageIdentifier,
    isLandPackage
  } = ownProps.packageData

  const packageIdentifier = isLandPackage
    ? landPackageIdentifier
    : `${id}-${sailDate}`
  const pricingIdentifier = `${
    isRefundable ? 'refundable' : 'nonRefundable'
  }-${stateroomCategoryCode}`

  let isSelected = Boolean(
    state.activeCabinData &&
      state.activeCabinData[packageIdentifier] &&
      state.activeCabinData[packageIdentifier][pricingIdentifier]
  )

  let numberOfActiveCabinsBySailing = 0

  // need to only count non-super-category cabins
  // and super-category cabins NOT associated with this cabin
  // that way we can still select a cabin when it's super category was auto selected
  if (state.activeCabinData && state.activeCabinData[packageIdentifier]) {
    numberOfActiveCabinsBySailing = Object.values(
      state.activeCabinData[packageIdentifier]
    ).reduce((total, selectedCabin) => {
      if (
        selectedCabin.isSuperCategory &&
        selectedCabin.superCategoryCode === superCategoryCode
      ) {
        return total
      }
      total += 1
      return total
    }, 0)
  }
  return {
    isSelected,
    numberOfActiveCabinsBySailing
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    superCategoryCode,
    isRefundable,
    isSuperCategory,
    stateroomCategoryCode
  } = ownProps.cabinData

  const {
    stateKey,
    id,
    sailDate,
    isLandPackage,
    landPackageIdentifier
  } = ownProps.packageData

  const packageIdentifier = isLandPackage
    ? landPackageIdentifier
    : `${id}-${sailDate}`
  const pricingIdentifier = `${
    isRefundable ? 'refundable' : 'nonRefundable'
  }-${stateroomCategoryCode}`
  const activeCabinData = {
    superCategoryCode,
    isRefundable,
    isSuperCategory,
    stateKey,
    id,
    sailDate,
    stateroomType: stateroomCategoryCode,
    packageIdentifier
  }

  return {
    handleSetActiveCabin() {
      return dispatch(
        setActiveCabin(packageIdentifier, pricingIdentifier, activeCabinData)
      )
    },
    handleRemoveActiveCabin() {
      return dispatch(removeActiveCabin(packageIdentifier, pricingIdentifier))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteCabinsTableButton)
