export const provinces = [
	{"value": "AB", "name": "Alberta", "country": "CA"},
	{"value": "BC", "name": "British Columbia", "country": "CA"},
	{"value": "MB", "name": "Manitoba", "country": "CA"},
	{"value": "NB", "name": "New Brunswick", "country": "CA"},
	{"value": "NL", "name": "Newfoundland and Labrador", "country": "CA", "alt": ["Newfoundland", "Labrador"]},
	{"value": "NS", "name": "Nova Scotia", "country": "CA"},
	{"value": "NU", "name": "Nunavut", "country": "CA"},
	{"value": "NT", "name": "Northwest Territories", "country": "CA"},
	{"value": "ON", "name": "Ontario", "country": "CA"},
	{"value": "PE", "name": "Prince Edward Island", "country": "CA"},
	{"value": "QC", "name": "Quebec", "country": "CA"},
	{"value": "SK", "name": "Saskatchewan", "country": "CA"},
	{"value": "YT", "name": "Yukon", "country": "CA"},
]