import React, { useState } from 'react'
import Slider from 'react-slick/lib'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { PrevArrow, NextArrow } from '../elements/CarouselArrows'

import '../../styles/scss/components/equote-package-details-image-slider.scss'
import triggerCustomGaEvent from '../../utilities/triggerCustomGaEvent'
import VideoSlide from './VideoSlide'

function onCarouselChevClick(direction) {
  triggerCustomGaEvent('sailingPreview-bannerImage', {
    trigger: `${direction}-arrow`
  })
}

function renderDots(dots) {
  return dots.map((dot, i) => (
    <li
      key={i}
      className={dot.props.className}
      {...dot.props}
      onClick={() =>
        triggerCustomGaEvent('sailingPreview-bannerImage', {
          target: `dot-${i}`
        })
      }
    />
  ))
}

const EQuotePackageDetailsImageSlider = props => {
  const [activeSlide, setActiveSlide] = useState(0)
  const settings = {
    lazyLoad: 'progressive',
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => <ul>{renderDots(dots)}</ul>,
    prevArrow: (
      <PrevArrow
        {...props}
        numberOfSlidesToShow={1}
        infinite
        gaEvent={() => onCarouselChevClick('previous')}
      />
    ),
    nextArrow: (
      <NextArrow
        {...props}
        numberOfSlidesToShow={1}
        infinite
        gaEvent={() => onCarouselChevClick('next')}
      />
    ),
    afterChange: current => setActiveSlide(current)
  }

  return (
    <div className="equote__package-details-image-slider">
      {props.slides && (
        <Slider {...settings}>
          {props.slides
            .filter(slide => !!slide)
            .map((slide, i) => {
              if (slide.type === 'video') {
                return (
                  <div key={i}>
                    <div
                      className="equote__package-details-image-slider--item"
                      style={{
                        position: 'relative'
                      }}
                    >
                      <VideoSlide slide={slide} isActive={activeSlide === i} />
                    </div>
                  </div>
                )
              }

              return (
                slide && (
                  <div key={i}>
                    <div
                      style={{
                        backgroundImage: `url(${slide.displayImage})`
                      }}
                      className="equote__package-details-image-slider--item"
                    >
                      <img
                        className="equote__package-details-image-slider--img"
                        src={slide.displayImage}
                        alt={slide.marketing_title}
                        style={{ display: 'none' }}
                      />
                      {!props.shouldHideText && (
                        <div className="equote__package-details-image-slider--text">
                          <h1>{slide.marketing_title}</h1>
                          <p>{slide.marketing_subtitle}</p>
                        </div>
                      )}
                      {!props.shouldHideText &&
                        Boolean(
                          (slide.marketing_title &&
                            slide.marketing_title.length) ||
                            (slide.marketing_subtitle &&
                              slide.marketing_subtitle.length)
                        ) && (
                          <div className="equote__package-details-image-slider--overlay" />
                        )}
                    </div>
                  </div>
                )
              )
            })}
        </Slider>
      )}
    </div>
  )
}

export default EQuotePackageDetailsImageSlider
