import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment/moment'
import Slider from 'react-slick/lib'

import { PrevArrow, NextArrow } from '../elements/CarouselArrows'

import '../../styles/scss/components/equote-package-details-itinerary.scss'

//Import Default Images
import cel_atSea from '../../assets/images/defaults/atSea/cel_at_sea_persian_gardens.jpg'
import rci_atSea from '../../assets/images/defaults/atSea/rci_at_sea_sunset_lounge.jpg'

import cel_port from '../../assets/images/defaults/ports/cel_generic_port.jpg'
import rci_port from '../../assets/images/defaults/ports/rci_generic_port.jpg'
import EQuotePackageItineraryPrint from './EQuotePackageDetailsItineraryPrint'
import getDisplayImage from '../../utilities/getDisplayImage'

const renderNumberOfSlidesToShow = () => {
  if (window.innerWidth > 1200) {
    return 3
  }
  if (window.innerWidth > 1000) {
    return 2
  }
  if (window.innerWidth > 768) {
    return 2
  }
  return 1
}

const renderDayName = name => {
  if (name.length < 40) {
    return name
  }
  return name.slice(0, 40) + '...'
}

const filterInitialSlide = (props, type, embarkDay) => {
  const { itinerary } = props

  const activity = type === 'cruise' ? 'EMBARK' : 'Cruisetour'

  let dayToSlideTo = 0

  for (const [i, day] of itinerary.entries()) {
    if (activity === 'EMBARK' && day.activity === activity) {
      dayToSlideTo = i
      break
    }
    if (
      type === 'pre-cruise' &&
      day.activity === activity &&
      parseInt(day.day) < parseInt(embarkDay)
    ) {
      dayToSlideTo = i
      break
    }
    if (
      type === 'post-cruise' &&
      day.activity === activity &&
      parseInt(day.day) > parseInt(embarkDay)
    ) {
      dayToSlideTo = i
      break
    }
  }
  return dayToSlideTo
}

const EQuotePackageDetailsItinerary = props => {
  const [numberOfSlidesToShow, setSlideCount] = useState(() =>
    renderNumberOfSlidesToShow()
  )
  const settings = {
    lazyLoad: 'progressive',
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: numberOfSlidesToShow,
    swipeToSlide: true,
    prevArrow: (
      <PrevArrow {...props} numberOfSlidesToShow={numberOfSlidesToShow} />
    ),
    nextArrow: (
      <NextArrow {...props} numberOfSlidesToShow={numberOfSlidesToShow} />
    )
  }

  const slider = useRef(null)
  const hasCruiseTour = !props.packageData.justACruise
  const { packageData } = props

  const embarkDay = props.itinerary.find(day => day.activity === 'EMBARK')
    ? props.itinerary.find(day => day.activity === 'EMBARK').day
    : 0

  const handleResize = () => {
    setSlideCount(() => renderNumberOfSlidesToShow())
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return function cleanup() {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const renderBackgroundImage = day => {
    if (day.highlight && day.highlight.image && day.highlight.image.length) {
      const displayImage = getDisplayImage(day.highlight.image, [
        'itineraries-not-a-thing',
        'itineraries-and-destinations'
      ])
      return displayImage.src
    } else {
      if (day.name === 'Cruising' || day.name === 'AT SEA') {
        switch (props.brandClassName) {
          case 'royal':
            return rci_atSea
          case 'celebrity':
            return cel_atSea
          default:
            return ''
        }
      } else {
        switch (props.brandClassName) {
          case 'royal':
            return rci_port
          case 'celebrity':
            return cel_port
          default:
            return ''
        }
      }
    }
  }

  const renderSlides = (props, embarkDay) => {
    const hasCruiseTour = !props.packageData.justACruise

    return props.itinerary.map((day, i) => {
      return (
        <div key={day.code + i}>
          <div
            className={`equote__package-details-itinerary-day ${
              props.brandClassName
            }`}
          >
            <div
              className={`equote__package-details-itinerary-day--header ${
                props.brandClassName
              }`}
            >
              <p className="equote__package-details-itinerary-day--header--day-text">
                Day {day.day}
              </p>
              <p className="equote__package-details-itinerary-day--header--date">
                {moment(props.startDate, 'YYYY-MM-DD')
                  .add(day.day - 1, 'days')
                  .format('MMMM DD, YYYY')}
              </p>
              {hasCruiseTour && day.activity === 'Cruisetour' && (
                <div className="equote__package-details-itinerary-day--header--cruise-tour-flag">
                  <p>
                    {parseInt(day.day) > parseInt(embarkDay)
                      ? 'Post-Cruisetour'
                      : 'Pre-Cruisetour'}
                  </p>
                </div>
              )}
            </div>
            <div className="equote__package-details-itinerary-day--sub-header">
              <p className="equote__package-details-itinerary-day--sub-header-arrival">
                {day.arrivalTime && `Arrives: `}
                <span>
                  {day.arrivalTime &&
                    `${moment(day.arrivalTime, 'kk:mm:ss').format('h:mm a')}`}
                </span>
              </p>
              <p className="equote__package-details-itinerary-day--sub-header-arrival">
                {day.departureTime && `Departs: `}
                <span>
                  {day.departureTime &&
                    `${moment(day.departureTime, 'kk:mm:ss').format('h:mm a')}`}
                </span>
              </p>
            </div>
            <div
              className="equote__package-details-itinerary-day--image-container"
              style={{
                backgroundImage: 'url(' + renderBackgroundImage(day) + ')'
              }}
            />
            <div className="equote__package-details-itinerary-day--description">
              <p className="equote__package-details-itinerary-day--description--title">
                {day.name && renderDayName(day.name)}
              </p>
              {/* dangerouslySetInnerHTML can be changed if the endpoint removed the <p>'s */}
              {day.highlight && day.highlight.description && (
                <div
                  className="equote__package-details-itinerary-day--description--description"
                  dangerouslySetInnerHTML={{
                    __html: day.highlight.description
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )
    })
  }

  const handleSlickGoTo = e => {
    const position = e.currentTarget.dataset.position
    slider.current.slickGoTo(filterInitialSlide(props, position, embarkDay))
  }

  return (
    <div className="equote__package-details-itinerary" id="itinerary">
      <div className="equote__package-details-itinerary--header u-content-container">
        <h1 className={`section-title ${props.brandClassName}`}>
          Itinerary and Destination
        </h1>

        <div className="equote__package-details-itinerary--header--links">
          {props.startDate && (
            <div className="equote__package-details-itinerary--header--links--detail">
              Sailing{' '}
              {moment(props.startDate, 'YYYY-MM-DD').format('MMMM DD, YYYY')}
            </div>
          )}
          <EQuotePackageItineraryPrint {...props} />
          {hasCruiseTour &&
            (packageData.preTourNights || packageData.postTourNights) &&
            props.itinerary.find(day => day.activity === 'Cruisetour') && (
              <div className="equote__package-details-itinerary--header--links--wrapper">
                <div className="equote__package-details-itinerary--header--links--group">
                  {packageData.preTourNights > 0 && (
                    <>
                      <div
                        onClick={handleSlickGoTo}
                        data-position="pre-cruise"
                        className="equote__package-details-itinerary--header--link"
                      >
                        Pre-Cruise
                      </div>{' '}
                      <span className="divisor">|</span>
                    </>
                  )}
                  <div
                    onClick={handleSlickGoTo}
                    data-position="cruise"
                    className="equote__package-details-itinerary--header--link"
                  >
                    Cruise Itinerary
                  </div>{' '}
                  {packageData.postTourNights > 0 && (
                    <>
                      <span className="divisor">|</span>
                      <div
                        onClick={handleSlickGoTo}
                        data-position="post-cruise"
                        className="equote__package-details-itinerary--header--link"
                      >
                        Post Cruise
                      </div>{' '}
                    </>
                  )}
                </div>
              </div>
            )}
        </div>
      </div>

      {Boolean(props.itinerary && props.itinerary.length) && (
        <Slider ref={slider} {...settings}>
          {renderSlides(props, embarkDay)}
        </Slider>
      )}
    </div>
  )
}

export default EQuotePackageDetailsItinerary
