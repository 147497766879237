import axios from 'axios'
import { get } from 'lodash'
import fetchBrands, { receiveBrandsData } from '../sessionStorage/fetchBrands'
import fetchDestinations, {
  receiveDestinationsData
} from '../sessionStorage/fetchDestinations'
import fetchLabels, { receiveLabelsData } from '../sessionStorage/fetchLabels'
import fetchShips, { receiveShipsData } from '../sessionStorage/fetchShips'
import fetchShipDestinations, {
  receiveShipsDestinationData
} from '../sessionStorage/fetchShipDestinations'

export const FETCH_STATIC_DATA = 'FETCH_STATIC_DATA'
export const RECEIVE_STATIC_DATA = 'RECEIVE_STATIC_DATA'
export const ENABLE_MAINTENANCE_MODE = 'ENABLE_MAINTENANCE_MODE'
const beginFetchStaticData = () => ({
  type: FETCH_STATIC_DATA
})

const endFetchStaticData = () => ({
  type: RECEIVE_STATIC_DATA
})

const fetchStaticData = () => dispatch => {
  dispatch(beginFetchStaticData())
  // reset our sessionStorage
  sessionStorage.clear()
  const promiseList = [
    fetchBrands(),
    fetchDestinations(),
    fetchLabels(),
    fetchShips(),
    fetchShipDestinations()
  ]
  axios
    .all(promiseList)
    .then(
      ([
        brandsResponse,
        destinationsResponse,
        labelsResponse,
        shipsResponse,
        shipDestinationResponse
      ]) => {
        receiveBrandsData(brandsResponse)
        receiveDestinationsData(destinationsResponse)
        receiveLabelsData(labelsResponse)
        receiveShipsData(shipsResponse)
        receiveShipsDestinationData(shipDestinationResponse)

        const maintenanceModeFlag = get(
          labelsResponse,
          'data.maintenance.enabled',
          false
        )
        if (maintenanceModeFlag === 'true') {
          dispatch({
            type: ENABLE_MAINTENANCE_MODE,
            payload: get(labelsResponse, 'data.maintenance', {})
          })
        }
        dispatch(endFetchStaticData())
      }
    )
}

export default fetchStaticData
