import formatImages from './formatImages'

export default function formatItinerary(itinerary) {
  if (!itinerary || !itinerary.length) return []
  return itinerary.map(day => {
    let highlight = day.highlight
    if (day.highlight && day.highlight.image) {
      highlight = {
        ...day.highlight,
        image: formatImages(day.highlight.image, [
          'itineraries-and-destinations'
        ])
      }
    }
    return { ...day, highlight }
  })
}
