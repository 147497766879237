import React from 'react'
import EQuoteAgentAccoladeAwardDetail from './EQuoteAgentAccoladeDetail'

import '../../styles/scss/components/equote-package-details-agent-details-footer.scss'
import BestEurope from '../../assets/images/celebrity-best-europe.png'

const EQuotePackageDetailsAgentAccolades = props => {
  const { brandClassName } = props
  return (
    <div className="equote__package-details-agent-footer">
      <div className="equote__package-details-agent-footer--accolades">
        {props.brandCode && (
          <div className="equote__package-details-agent-footer--accolades-wrapper">
            {brandClassName === 'celebrity' && (
              <div className="equote-agent-accolade--black">
                <EQuoteAgentAccoladeAwardDetail>
                  <>
                    <h3>2020 GOLD AWARD </h3>
                    <h1>
                      BEST PREMIUM
                      <br />
                      CRUISE SHIP
                    </h1>
                    <h3>Celebrity EDGE</h3>
                    <p>Travvy Awards</p>
                  </>
                </EQuoteAgentAccoladeAwardDetail>
                <>
                  <img
                    src={BestEurope}
                    alt="Best Europe"
                    style={{ height: '150px', width: '200px' }}
                  />
                </>
                <EQuoteAgentAccoladeAwardDetail>
                  <>
                    <h3>2020 GOLD AWARD </h3>
                    <h1>
                      BEST PREMIUM
                      <br />
                      CRUISE SHIP
                    </h1>
                    <h3>Celebrity Cruises</h3>
                    <p>Travvy Awards</p>
                  </>
                </EQuoteAgentAccoladeAwardDetail>
              </div>
            )}
            {brandClassName === 'royal' && (
              <div className="equote-agent-accolade--blue">
                <EQuoteAgentAccoladeAwardDetail>
                  <>
                    <h1>
                      BEST CRUISE LINE{' '}
                      <span>
                        <small>IN</small>
                        <small>THE</small>
                      </span>{' '}
                      CARIBBEAN
                    </h1>
                    <h3>SINCE 2003</h3>
                    <p>Travel Weekly Reader's Choice Award</p>
                  </>
                </EQuoteAgentAccoladeAwardDetail>
                <div className="equote-agent-accolade-text">
                  BEST CRUISE LINE SINCE 2003
                </div>
                <EQuoteAgentAccoladeAwardDetail>
                  <>
                    <h1>BEST CRUISE LINE OVERALL</h1>
                    <h3>SINCE 2003</h3>
                    <p>Travel Weekly Reader's Choice Award</p>
                  </>
                </EQuoteAgentAccoladeAwardDetail>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default EQuotePackageDetailsAgentAccolades
