import React, { Component } from 'react'
import moment from 'moment'

import EQuotePackageItineraryModalSlider from './EQuotePackageItineraryPreviewSliders'

import fetchItinerary from '../../actions/fetchItinerary'
import brandLoader from '../../assets/icons/brand-loader-final.gif'

import '../../styles/scss/base/modal.scss'
import '../../styles/scss/base/tables.scss'
import '../../styles/scss/components/equote-package-details-modal.scss'

import { connect } from 'react-redux'

class EQuotePackageItineraryModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      hasErrors: false
    }
  }

  componentDidMount() {
    const { handleFetchItinerary, packageItinerary } = this.props
    // Request itinerary for brand package

    if (!packageItinerary) {
      this.setState({ isLoading: true })
      handleFetchItinerary().then(res => {
        if (res && res.data && res.data.package && res.data.package.length) {
          this.setState({ isLoading: false })
        } else {
          this.setState({
            isLoading: false,
            hasErrors: true
          })
        }
      })
    } else {
      this.setState({ isLoading: false })
    }
  }

  calculateCruiseDates = day => {
    return moment(this.props.packageData.startDate, 'YYYY-MM-DD')
      .add(day - 1, 'days')
      .format('DDMMMYYYY')
      .toUpperCase()
  }

  renderTableItinerary = (array, title) => {
    return (
      <>
        <h2>{title}</h2>
        <div className="equote__package-detail-modal-itinerary">
          <table className="u-table">
            <thead>
              <tr>
                <th>Day</th>
                <th>Port City</th>
                <th>Activity</th>
                <th>Arrival</th>
                <th>Departure</th>
              </tr>
            </thead>
            <tbody>
              {array.map(
                ({ day, activity, departureTime, arrivalTime, name }, i) => (
                  <tr key={i}>
                    <td className="center">{this.calculateCruiseDates(day)}</td>
                    <td>{name.toUpperCase()}</td>
                    <td className="center">{activity ? activity : '-'}</td>
                    <td className="center">
                      {arrivalTime ? this.timeConvert(arrivalTime) : '-'}
                    </td>
                    <td className="center">
                      {departureTime ? this.timeConvert(departureTime) : '-'}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
        <EQuotePackageItineraryModalSlider
          cruises={array}
          sailDate={this.props.packageData.sailDate}
        />
      </>
    )
  }

  renderTableTour = (array, title) => {
    return (
      array &&
      array.length && (
        <>
          <h2>{title}</h2>
          {this.props.showAdditionalCruiseTourDetails ? (
            <div className="equote__package-detail-modal-itinerary extended-view">
              {array.map(day => {
                return (
                  <div className="itinerary-day-container" key={day.day}>
                    <div className="column">
                      <div className="header">
                        <p className="u-remove-margin">Day {day.day}</p>
                      </div>
                      <div className="body">
                        <p className="u-remove-margin">
                          {this.calculateCruiseDates(day.day)}
                        </p>
                      </div>
                    </div>

                    <div className="column">
                      <div className="header">
                        <p className="u-remove-margin">{day.name}</p>
                      </div>
                      <div className="body">
                        <p className="u-remove-margin">
                          {day.highlight && day.highlight.description
                            ? day.highlight.description
                            : null}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          ) : (
            <div className="equote__package-detail-modal-itinerary">
              <table className="u-table">
                <thead>
                  <tr>
                    <th>Day</th>
                    <th>Port City</th>
                    <th>Activity</th>
                  </tr>
                </thead>
                <tbody>
                  {array.map(({ day, activity, name }, i) => (
                    <tr key={i}>
                      <td className="center">
                        {this.calculateCruiseDates(day)}
                      </td>
                      <td>{name}</td>
                      <td className="center">
                        {activity
                          ? activity === 'Cruisetour'
                            ? 'Cruisetour'
                            : activity
                          : '-'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <EQuotePackageItineraryModalSlider
            tours={array}
            sailDate={this.props.packageData.sailDate}
          />
        </>
      )
    )
  }

  renderTableSailing(itinerary) {
    itinerary = itinerary
      ? itinerary.filter(day => day.activity !== 'Cruisetour')
      : null
    if (itinerary) {
      return this.renderTableItinerary(itinerary, 'Sailing Itinerary')
    }
    return null
  }

  renderTablePreSailing(itinerary, showAdditionalCruiseTourDetails) {
    const embarkDay = itinerary.find(day => day.activity === 'EMBARK')
    if (embarkDay) {
      const preSailing = itinerary.filter(
        day =>
          day.activity === 'Cruisetour' &&
          Number(day.day) <= Number(embarkDay.day)
      )

      if (preSailing && preSailing.length) {
        return this.renderTableTour(
          preSailing,
          'Pre-Sailing Tour',
          showAdditionalCruiseTourDetails
        )
      }
    }

    return null
  }

  renderTablePostSailing(itinerary) {
    const debarkDay = itinerary.find(day => day.activity === 'DEBARK')
    const postSailing = itinerary.filter(day => {
      return (
        day.activity === 'Cruisetour' &&
        Number(day.day) >= Number(debarkDay.day)
      )
    })

    if (postSailing && postSailing.length) {
      return this.renderTableTour(postSailing, 'Post-Sailing Tour')
    }
    return null
  }

  timeConvert(time) {
    return moment(time, 'HH:mm:ss').format('h:mm A')
  }

  render() {
    const { packageItinerary } = this.props
    const { isLoading, hasErrors } = this.state

    if (isLoading && !hasErrors) {
      return (
        <div className="equote__package-detail-modal-loading">
          <h2>Searching Details...</h2>
          <img src={brandLoader} alt="loading..." />
        </div>
      )
    }

    if (hasErrors) {
      return (
        <div style={{ paddingTop: '64px' }}>
          <p className="u-text-center">Unable to fetch itinerary data</p>
        </div>
      )
    }

    if (this.props.isCruiseTour) {
      return (
        <>
          {this.renderTablePreSailing(packageItinerary)}
          {this.renderTableSailing(packageItinerary)}
          {this.renderTablePostSailing(packageItinerary)}
        </>
      )
    }

    return this.renderTableSailing(packageItinerary)
  }
}

function mapStateToProps(state, ownProps) {
  const { brand, identifier } = ownProps.packageData
  const itineraryKey = `${identifier}-${brand}`
  const packageItinerary =
    state.itineraryData.itineraries &&
    state.itineraryData.itineraries[itineraryKey]
      ? state.itineraryData.itineraries[itineraryKey]
      : null

  return {
    packageItinerary
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { brand, id, sailDate, identifier, shipCode } = ownProps.packageData
  return {
    handleFetchItinerary() {
      return dispatch(fetchItinerary(brand, id, sailDate, identifier, shipCode))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuotePackageItineraryModal)
