import React, { Component } from 'react'

class EQuoteReviewEmailHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderBrandLogos() {
    const { brandData } = this.props
    let brandLogos = []

    for (const brand in brandData) {
      if (
        brandData[brand] &&
        brandData[brand].badge_light &&
        brandData[brand].badge_light.file
      ) {
        brandLogos.push(
          <div
            key={brandData[brand].brand_code}
            className="equote__review-email-header-brand"
            onClick={() => window.open(brandData[brand].website)}
          >
            <img
              src={brandData[brand].badge_light.file}
              alt={brandData[brand].name}
            />
          </div>
        )
      }
    }

    return brandLogos
  }

  render() {
    return (
      <div className="equote__review-email-header">
        {this.renderBrandLogos()}
      </div>
    )
  }
}

export default EQuoteReviewEmailHeader
