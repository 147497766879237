import {
	SUBMIT_QUOTE_BEGIN,
	SUBMIT_QUOTE_SUCCESS,
	SUBMIT_QUOTE_FAILURE,
} from '../actions/submitQuoteActions'

const initialState = {
	loading: false,
	error: null
}

export default function submitQuoteReducers(state = initialState, action) {
	switch(action.type) {
		case SUBMIT_QUOTE_BEGIN:
			// Request begin: set loading true
			return {
				...state,
				loading: true,
				error: null
			}

		case SUBMIT_QUOTE_SUCCESS:
			// Request success: set loading false, and display request data
			return {
				...state,
				loading: false,
				quote: action.payload
			}

		case SUBMIT_QUOTE_FAILURE:
			// Request failed: set loading false, save & display error
			return {
				...state,
				loading: false,
				error: action.payload.error,
				quote: []
			}

		default:
			return state;
	}
}
