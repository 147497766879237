import React, { Fragment, Component } from 'react'
import { connect } from 'react-redux'
import {getBrandSpecificEmailHeader} from '../../reducers/setActiveEmailConfigurationReducers';

import { setActiveHeadline } from '../../actions/setActiveEmailConfiguration'

var _ = require('lodash')

class EQuoteReviewConstructHeadlineInput extends Component {

	constructor(props) {
		super(props)
		const { handleSetActiveHeadline } = props
    handleSetActiveHeadline(this.props.initialEmailHeader)
		this.state = {
			headline: this.props.initialEmailHeader
		}
	}

	handleUpdateHeadline = (e) => {
		const { handleSetActiveHeadline } = this.props

		// Default headline content
		let headlineContent = this.props.initialEmailHeader

		if (!_.isEmpty(e.target.value)) {
			headlineContent = e.target.value
		}

		this.setState({
			headline: headlineContent
		})

		handleSetActiveHeadline(headlineContent)
	}

	render() {
		return (
			<Fragment>
				<label
					id="label-headline"
					htmlFor="headline"
				>
					Personalized Headline
				</label>
				<input
					type="text"
					name="headline"
					placeholder="If left blank, a default headline will be used"
					onChange={ e => this.handleUpdateHeadline(e)}
					aria-labelledby='label-headline'
				/>
			</Fragment>
		)
	}
}

function mapStateToProps(state) {
	return {
    initialEmailHeader: getBrandSpecificEmailHeader(state)
	}
}

const mapDispatchToProps = dispatch => ({
	handleSetActiveHeadline(headline) {
		return dispatch(setActiveHeadline(headline))
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(EQuoteReviewConstructHeadlineInput)