import React from 'react'
import { connect } from 'react-redux'
import EQuoteSailingsFullSearchCabinTable from './EQuoteSailingsFullSearchCabinTable'

import CloseImage from '../../../../assets/icons/close.svg'

import { getPackagesDetails } from '../../../../reducers/fetchPackagesReducers'

const EQuoteFullSearchSubTableCabinsList = props => {
  const {
    cabinData,
    toggleLandPackagesCabinTable,
    activeRowData,
    currencySymbol
  } = props
  if (cabinData && Object.keys(cabinData).length) {
    // Render table if cabins available for category
    return (
      <div className="equote__sailings-full-search--land-packages-cabins-container">
        <div className="equote__sailings-full-search--land-packages-cabins-container-heading">
          <div
            className="u-modal__close equote__sailings-full-search--land-packages-cabins-container-heading-button"
            role="button"
            onClick={() => toggleLandPackagesCabinTable(activeRowData, true)}
            onKeyPress={() => toggleLandPackagesCabinTable(activeRowData, true)}
            tabIndex={0}
          >
            CLOSE
            <img
              className="u-modal__close-icon--large"
              src={CloseImage}
              alt="Close Table"
            />
          </div>
        </div>
        {Object.values(cabinData).map(cabin => {
          if (
            cabin.pricing &&
            Object.keys(cabin.pricing).length &&
            cabin.superCategoryData &&
            cabin.superCategoryData.bestGuestCountRate
          ) {
            return (
              <EQuoteSailingsFullSearchCabinTable
                key={cabin.superCategoryData.stateroomType}
                cabins={cabin.pricing}
                superCategoryFullName={cabin.superCategoryData.categoryName}
                type={cabin.superCategoryData.stateroomDescription.toLowerCase()}
                currencySymbol={currencySymbol}
              />
            )
          }

          return null
        })}
      </div>
    )
  } else {
    return 'No Available Cabins'
  }
}

const mapStateToProps = (state, ownProps) => {
  const packageData = getPackagesDetails(
    state,
    ownProps.isCruiseTour,
    ownProps.cruiseIdentifier
  )

  return {
    cabinData: packageData.tabularPricing,
    currencySymbol:
      packageData && packageData.currencySymbol
        ? packageData.currencySymbol
        : null
  }
}

export default connect(mapStateToProps)(EQuoteFullSearchSubTableCabinsList)
