import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'

import Modal from 'react-modal'
import moment from 'moment'
import _ from 'lodash'

import EQuoteSearchFiltersFullFormBrand from '../../search/fullSearchForm/EQuoteSearchFiltersFullFormBrand'
import EQuoteSearchFiltersFullFormShip from '../../search/fullSearchForm/EQuoteSearchFiltersFullFormShip'
import EQuoteSearchFiltersFullFormDestination from '../../search/fullSearchForm/EQuoteSearchFiltersFullFormDestination'
import EQuoteSearchFiltersFullFormDateRange from '../../search/fullSearchForm/EQuoteSearchFiltersFullFormDateRange'
import EQuoteSearchFiltersFullFormNights from '../../search/fullSearchForm/EQuoteSearchFiltersFullFormNights'
import EQuoteSearchFiltersFullFormPromo from '../../search/fullSearchForm/EQuoteSearchFiltersFullFormPromo'
import EQuoteSearchFiltersFullFormResident from '../../search/fullSearchForm/EQuoteSearchFiltersFullFormResident'
import EQuoteSearchFiltersFullFormCurrency from '../../search/fullSearchForm/EQuoteSearchFiltersFullFormCurrency'
import EQuoteSearchFiltersFullFormSubmit from '../../search/fullSearchForm/EQuoteSearchFiltersFullFormSubmit'

import {
  setActiveBrand,
  setActiveCurrency,
  setActiveShip,
  setActiveRegion,
  setActiveStartDate,
  setActiveEndDate,
  setActiveSailingNights,
  setActiveSeniorCitizenDiscount,
  setActiveServiceMemberDiscount,
  setActivePoliceDiscount,
  setActiveFireFighterDiscount,
  setActiveResidentOf,
  setEndDateVisibility
} from '../../../actions/setActiveSearchFilters'

import fetchLoyalty from '../../../actions/fetchLoyalty'
import removeLoyalty from '../../../actions/removeActiveLoyalty'

import closeIcon from '../../../assets/icons/close.svg'

import '../../../styles/scss/components/equote-sailings-full-search-package-filters.scss'
import '../../../styles/scss/components/equote-search-filter-form-promos.scss'
import triggerCustomGaEvent from '../../../utilities/triggerCustomGaEvent'
import { getBrandsData } from '../../../sessionStorage/getBrandsData'
import { getDestinationsData } from '../../../sessionStorage/getDestinationsData'
import { getShipsData } from '../../../sessionStorage/getShipsData'
import { getShipDestinationsData } from '../../../sessionStorage/getShipDestinationsData'
import {
  getCanUseQualifiers,
  getDisabledBrandsFromSearchCriteria
} from '../../../reducers/fetchSsoReducers'

class EQuoteSailingsFullSearchPackageFilters extends Component {
  constructor(props) {
    super(props)

    const { activeSearchFilterData } = props
    // Initialize local state form values
    this.state = {
      startDateModalVisible: false,
      endDateModalVisible: false,
      promoModalVisible: false,
      endDateFieldVisible: false,
      currentBrand: activeSearchFilterData.brand
        ? activeSearchFilterData.brand
        : 0,
      currentShip: activeSearchFilterData.ship
        ? activeSearchFilterData.ship
        : 0,
      currentDestination: activeSearchFilterData.region
        ? activeSearchFilterData.region
        : 0,
      currentEmbark: activeSearchFilterData.startDate
        ? moment(activeSearchFilterData.startDate, 'YYYY-MM-DD')
        : null,
      currentDebark: activeSearchFilterData.endDate
        ? moment(activeSearchFilterData.endDate)
        : null,
      currentCount: activeSearchFilterData.count
        ? activeSearchFilterData.count
        : null,
      duration: activeSearchFilterData.duration
        ? activeSearchFilterData.duration
        : null,
      isSeniorCitizen: activeSearchFilterData.isSeniorCitizen
        ? activeSearchFilterData.isSeniorCitizen
        : false,
      isServiceMember: activeSearchFilterData.isServiceMember
        ? activeSearchFilterData.isServiceMember
        : false,
      isPolice: activeSearchFilterData.isPolice
        ? activeSearchFilterData.isPolice
        : false,
      isFireFighter: activeSearchFilterData.isFireFighter
        ? activeSearchFilterData.isFireFighter
        : false,
      currentResidency: activeSearchFilterData.residentOf
        ? activeSearchFilterData.residentOf
        : 0,
      currentCurrency: activeSearchFilterData.currency
        ? activeSearchFilterData.currency
        : 'USD',
      isFormPristine: true,
      hasLoyaltyNumber: false,
      guestCount: activeSearchFilterData.guestCount
        ? activeSearchFilterData.guestCount
        : '1'
    }

    this.nightInput = createRef()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.activeSearchFilterData.loyaltyId !==
      this.props.activeSearchFilterData.loyaltyId
    ) {
      this.setState({
        isFormPristine: false
      })
    }
  }

  componentDidMount() {
    const { activeSearchFilterData } = this.props
    if (activeSearchFilterData.endDateVisible) {
      this.nightInput.current.firstChild.classList.toggle('u-reset-margin')
    }

    this.scrollToResults()
  }

  //Scroll view port to the view results
  scrollToResults() {
    const resultTable = document.querySelector(
      '.equote__sailings-full-search-table'
    )
    resultTable &&
      resultTable.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
  }

  // Update brand in local state and redux activeSearchFilter Data
  handleSelectBrand = brand => {
    const {
      handleActiveBrand,
      handleActiveShip,
      handleActiveDestination
    } = this.props
    triggerCustomGaEvent('equoteSearchResults-filterButton-brand', { brand })

    this.setState({
      currentBrand: brand,
      currentShip: 0,
      currentDestination: 0,
      isFormPristine: false
    })

    // Dispatch current brand and reset ship - region to redux
    handleActiveBrand(brand)
    handleActiveShip()
    handleActiveDestination()

    if (brand === 'Z' && Number(this.state.guestCount) === 4) {
      this.handleSelectGuestCount('3')
    }
  }

  // Update ship in local state and redux activeSearchFilter Data
  handleSelectShip = ship => {
    const { handleActiveShip } = this.props
    triggerCustomGaEvent('equoteSearchResults-filterButton-ship', { ship })

    this.setState({
      currentShip: ship,
      isFormPristine: false
    })

    // Dispatch ship to redux
    handleActiveShip(ship)
  }

  // Update destination in local state and redux activeSearchFilter Data
  handleSelectDestination = destination => {
    const { handleActiveDestination } = this.props
    triggerCustomGaEvent('equoteSearchResults-filterButton-destination', {
      destination
    })
    this.setState({
      currentDestination: destination,
      isFormPristine: false
    })

    // Dispatch destination to redux
    handleActiveDestination(destination)
  }

  // Update guestCount
  handleSelectGuestCount = guestCount => {
    // this.props.handleActiveGuestCount(guestCount)
    triggerCustomGaEvent('guestsTotalSelected', guestCount)
    this.setState({
      guestCount,
      isFormPristine: false
    })
  }

  // Trigger custom date fields
  handleOpenStartDateModal = () => {
    this.setState({ startDateModalVisible: true })
  }
  handleCloseStartDateModal = () => {
    this.setState({ startDateModalVisible: false })
  }

  // Trigger custom date fields
  handleOpenEndDateModal = () => {
    this.setState({ endDateModalVisible: true })
  }

  handleCloseEndDateModal = () => {
    this.setState({ endDateModalVisible: false })
  }

  // Update startDate in local state and redux activeSearchFilter Data
  onStartDateChange = startDate => {
    const { handleActiveStartDate, handleActiveEndDate } = this.props
    triggerCustomGaEvent('equoteSearchResults-filterButton-startDate', {
      startDate
    })
    this.setState({
      currentEmbark: startDate,
      isFormPristine: false
    })

    // Dispatch startDate to redux when set
    if (startDate) {
      handleActiveStartDate(moment(startDate).format('YYYY-MM-DD'))
      this.handleCloseStartDateModal()
    }

    // Push endDate beyond start date when the same or greater
    if (this.state.currentDebark && startDate >= this.state.currentDebark) {
      this.setState({
        currentDebark: moment(startDate).add(1, 'days')
      })

      handleActiveEndDate(
        moment(startDate)
          .add(1, 'days')
          .format('YYYY-MM-DD')
      )
    }
  }

  // Update endDate in local state and redux activeSearchFilter Data
  onEndDateChange = endDate => {
    const { handleActiveEndDate } = this.props
    triggerCustomGaEvent('equoteSearchResults-filterButton-endDate', {
      endDate
    })
    this.setState({
      currentDebark: endDate,
      isFormPristine: false
    })

    // Dispatch endDate to redux when set
    if (endDate) {
      handleActiveEndDate(moment(endDate).format('YYYY-MM-DD'))
      this.handleCloseEndDateModal()
    }
  }

  // Update duration in local state and redux
  handleInputNights = nights => {
    const { handleActiveSailingNights } = this.props
    triggerCustomGaEvent('equoteSearchResults-filterButton-nights', { nights })
    this.setState({
      duration: nights,
      isFormPristine: false
    })

    handleActiveSailingNights(nights)
  }

  // Render end date field
  toggleEndDateField = () => {
    this.nightInput.current.firstChild.classList.toggle('u-reset-margin')

    const { handleEndDataVisibility } = this.props
    this.setState({
      endDateFieldVisible: !this.state.endDateFieldVisible
    })

    handleEndDataVisibility(!this.state.endDateFieldVisible)
  }

  // Trigger custom date fields
  handleOpenPromoModal = () => {
    this.setState({ promoModalVisible: true })
  }

  handleClosePromoModal = () => {
    this.setState({ promoModalVisible: false })
  }

  // Update isSeniorCitizen in local state and redux
  handleSelectSeniorCitizen = () => {
    const { handleActiveSeniorCitizenDiscount } = this.props

    this.setState({
      isSeniorCitizen: !this.state.isSeniorCitizen,
      isFormPristine: false
    })

    // Dispatch isSeniorCitizen to redux
    handleActiveSeniorCitizenDiscount(!this.state.isSeniorCitizen)
  }

  // Update isServiceMember in local state and redux
  handleSelectServiceMember = () => {
    const { handleActiveServiceMemberDiscount } = this.props

    this.setState({
      isServiceMember: !this.state.isServiceMember,
      isFormPristine: false
    })

    // Dispatch isServiceMember to redux
    handleActiveServiceMemberDiscount(!this.state.isServiceMember)
  }

  // Update isPolice in local state and redux
  handleSelectPolice = () => {
    const { handleActivePoliceDiscount } = this.props

    this.setState({
      isPolice: !this.state.isPolice,
      isFormPristine: false
    })

    // Dispatch isPolice to redux
    handleActivePoliceDiscount(!this.state.isPolice)
  }

  // Update isFireFighter in local state and redux
  handleSelectFireFighter = () => {
    const { handleActiveFireFighterDiscount } = this.props

    this.setState({
      isFireFighter: !this.state.isFireFighter,
      isFormPristine: false
    })

    // Dispatch isFireFighter to redux
    handleActiveFireFighterDiscount(!this.state.isFireFighter)
  }

  // Update residentOf in local state and redux activeSearchFilter Data
  handleSelectResidentOf = residentOf => {
    const { handleActiveResidentOf } = this.props
    triggerCustomGaEvent('equoteSearchResults-filterButton-residentOf', {
      residentOf
    })

    this.setState({
      currentResidency: residentOf,
      isFormPristine: false
    })

    // Dispatch residentOf to redux
    handleActiveResidentOf(residentOf)
  }

  // handle update amount of

  handlePromoFaresPlaceholder = () => {
    const {
      isSeniorCitizen,
      isServiceMember,
      isPolice,
      isFireFighter
    } = this.state
    const fares = [isSeniorCitizen, isServiceMember, isPolice, isFireFighter]
    const appliedFares = fares.filter(fare => fare)
    return appliedFares.length < 1
      ? 'Choose Qualifier'
      : appliedFares.length === 1
      ? '1 Qualifier Applied'
      : `${appliedFares.length} Qualifiers Applied`
  }
  // Update currency in local state and redux activeSearchFilter Data
  handleSelectCurrency = currency => {
    const { handleActiveCurrency, handleActiveBrand } = this.props

    const matchingDisabledBrands = this.props.getDisabledBrands({ currency })

    let brand = this.state.currentBrand
    if (
      matchingDisabledBrands &&
      matchingDisabledBrands.length &&
      matchingDisabledBrands.some(brand => brand === this.state.currentBrand)
    ) {
      brand = 'C,R'
    }
    this.setState({
      currentCurrency: currency,
      isFormPristine: false
    })
    handleActiveCurrency(currency)

    if (this.state.currentBrand !== brand) {
      this.handleSelectBrand(brand)
    }

    handleActiveBrand(brand)
  }

  render() {
    const {
      activeSearchFilterData,
      brands,
      ships,
      destinations,
      loyaltyData,
      shipDestinations,
      ssoData,
      handleFetchLoyalty,
      handleRemoveLoyalty,
      allowSpecialQualifiers,
      getDisabledBrands
    } = this.props

    let isArrivalSet = false,
      isSearchEnabled = false

    // Flag to enable search based on end date OR count
    if (activeSearchFilterData.endDate || activeSearchFilterData.count) {
      isArrivalSet = true
    }

    if (
      activeSearchFilterData.bookingType &&
      activeSearchFilterData.cruiseType &&
      activeSearchFilterData.brand &&
      activeSearchFilterData.brand !== '0' &&
      activeSearchFilterData.startDate &&
      isArrivalSet
    ) {
      isSearchEnabled = true
    }

    return (
      <div className="equote__package-filters-form">
        <div className="u-content-container">
          <div className="equote__package-filters-form-groups">
            <div className="equote__package-filters-title">
              <div className="equote__package-filters-form-field">
                <h6>Search Based On</h6>
              </div>
            </div>

            <div className="equote__package-filters-form-group">
              <div className="equote__package-filters-form-field">
                <EQuoteSearchFiltersFullFormBrand
                  brands={brands}
                  currentBrand={this.state.currentBrand}
                  handleSelectBrand={this.handleSelectBrand}
                  disabledBrands={getDisabledBrands(activeSearchFilterData)}
                />
              </div>

              <div className="equote__package-filters-form-field">
                <EQuoteSearchFiltersFullFormShip
                  currentBrand={this.state.currentBrand}
                  currentShip={this.state.currentShip}
                  handleSelectShip={this.handleSelectShip}
                  ships={ships}
                />
              </div>

              <div className="equote__package-filters-form-field">
                <EQuoteSearchFiltersFullFormDestination
                  currentBrand={this.state.currentBrand}
                  currentShip={this.state.currentShip}
                  currentDestination={this.state.currentDestination}
                  destinations={destinations}
                  handleSelectDestination={this.handleSelectDestination}
                  shipDestinations={shipDestinations}
                />
              </div>
            </div>

            <div className="equote__package-filters-form-group equote__package-filters-form-group--margin">
              <div className="equote__package-filters-form-field">
                <div
                  className="equote__package-filters-form-custom-date"
                  onClick={() => this.handleOpenStartDateModal()}
                  tabIndex={1}
                >
                  <label className="u-custom-select__label">Depart</label>
                  <div className="u-custom-select u-custom-select--inc-label">
                    {this.state.currentEmbark ? (
                      <span className="u-custom-select__field u-capitalize">
                        {moment(this.state.currentEmbark).format(
                          'MMM DD, YYYY'
                        )}
                      </span>
                    ) : (
                      <span className="u-custom-select__field">
                        Choose Departure Date
                      </span>
                    )}
                    <span className="u-custom-select__arrow" />
                  </div>
                </div>

                {this.state.startDateModalVisible ? (
                  <EQuoteSearchFiltersFullFormDateRange
                    handleCloseDateRangeModal={this.handleCloseStartDateModal}
                    dateRangeModalVisible={true}
                    currentDate={
                      this.state.currentEmbark
                        ? this.state.currentEmbark
                        : moment(activeSearchFilterData.startDate, 'YYYY-MM-DD')
                    }
                    onDateChange={this.onStartDateChange}
                    activeSearchFilterData={activeSearchFilterData}
                  />
                ) : null}

                {activeSearchFilterData.endDateVisible ? null : (
                  <div
                    className="equote__package-filters-toggle-date u-font-xs"
                    onClick={() => this.toggleEndDateField()}
                  >
                    Show Date Range
                  </div>
                )}
              </div>

              {activeSearchFilterData.endDateVisible ? (
                <div className="equote__package-filters-form-field ">
                  <div
                    className="equote__package-filters-form-custom-date equote__package-filters-form-group--margin u-custom-date-show"
                    onClick={() => this.handleOpenEndDateModal()}
                    tabIndex={1}
                  >
                    <label className="u-custom-select__label">Return</label>
                    <div className="u-custom-select u-custom-select--inc-label">
                      {this.state.currentDebark ? (
                        <span className="u-custom-select__field u-capitalize">
                          {moment(this.state.currentDebark).format(
                            'MMM DD, YYYY'
                          )}
                        </span>
                      ) : (
                        <span className="u-custom-select__field u-font-initial">
                          Choose Arrival Date
                        </span>
                      )}
                      <span className="u-custom-select__arrow" />
                    </div>
                  </div>

                  {this.state.endDateModalVisible ? (
                    <EQuoteSearchFiltersFullFormDateRange
                      handleCloseDateRangeModal={this.handleCloseEndDateModal}
                      dateRangeModalVisible={true}
                      currentDate={
                        this.state.currentDebark
                          ? this.state.currentDebark
                          : moment(
                              activeSearchFilterData.startDate,
                              'YYYY-MM-DD'
                            )
                      }
                      onDateChange={this.onEndDateChange}
                      activeSearchFilterData={activeSearchFilterData}
                      minStartDate={
                        this.state.currentEmbark
                          ? moment(this.state.currentEmbark).add(1, 'days')
                          : null
                      }
                    />
                  ) : null}
                </div>
              ) : null}

              <div
                ref={this.nightInput}
                className="equote__package-filters-form-field"
              >
                <EQuoteSearchFiltersFullFormNights
                  activeSearchFilterData={activeSearchFilterData}
                  handleActiveSailingNights={duration =>
                    this.handleInputNights(duration)
                  }
                />
              </div>
            </div>

            <div className="equote__package-filters-form-group">
              {allowSpecialQualifiers && (
                <div className="equote__package-filters-form-field">
                  <EQuoteSearchFiltersFullFormResident
                    residentOf={this.state.currentResidency}
                    handleSelectResidentOf={this.handleSelectResidentOf}
                  />
                </div>
              )}

              <div className="equote__package-filters-form-field">
                <div
                  className="equote__package-filters-form-custom-promos u-custom-select"
                  onClick={() => this.handleOpenPromoModal()}
                  tabIndex={1}
                >
                  <span className="u-custom-select__field">
                    {this.handlePromoFaresPlaceholder()}
                  </span>
                  <span className="u-custom-select__arrow" />
                </div>

                <Modal
                  className={{
                    base:
                      'equote__search-filters-form-promos u-modal u-modal__xs',
                    afterOpen: 'u-modal--visible',
                    beforeClose: 'u-modal--close',
                    ariaHideApp: false
                  }}
                  overlayClassName={{
                    base: 'u-fade',
                    afterOpen: 'u-fade--visible',
                    beforeClose: 'u-fade--visible'
                  }}
                  isOpen={this.state.promoModalVisible}
                  onRequestClose={this.handleClosePromoModal}
                  contentLabel="eQuote Package Promo Modal"
                  shouldCloseOnOverlayClick={true}
                  ariaHideApp={false}
                  htmlOpenClassName="ReactModal__Html--open"
                >
                  <div className="u-modal__header">
                    <h6>Promotional Qualifiers</h6>
                    <div
                      className="u-modal__close"
                      onClick={this.handleClosePromoModal}
                      tabIndex={0}
                    >
                      Close{' '}
                      <img
                        className="u-modal__close-icon"
                        src={closeIcon}
                        alt="eQuote Date Range Close"
                      />
                    </div>
                  </div>
                  <div className="u-modal__body">
                    <EQuoteSearchFiltersFullFormPromo
                      isSeniorCitizen={this.state.isSeniorCitizen}
                      handleSelectSeniorCitizen={this.handleSelectSeniorCitizen}
                      isServiceMember={this.state.isServiceMember}
                      handleSelectServiceMember={this.handleSelectServiceMember}
                      isPolice={this.state.isPolice}
                      handleSelectPolice={this.handleSelectPolice}
                      isFireFighter={this.state.isFireFighter}
                      handleSelectFireFighter={this.handleSelectFireFighter}
                      ssoData={ssoData}
                      loyaltyData={loyaltyData}
                      handleFetchLoyalty={(loyaltyId, agencyId) =>
                        handleFetchLoyalty(loyaltyId, agencyId)
                      }
                      handleRemoveLoyalty={activeLoyaltyId =>
                        handleRemoveLoyalty(activeLoyaltyId)
                      }
                      activeSearchFilterData={activeSearchFilterData}
                      guestCount={this.state.guestCount}
                      allowSpecialQualifiers={allowSpecialQualifiers}
                    />
                  </div>
                </Modal>
              </div>
              <div className="equote__package-filters-form-field">
                <div className="equote__search-filters-form-field-input u-custom-select">
                  <select
                    name="guests"
                    onChange={e => this.handleSelectGuestCount(e.target.value)}
                    value={this.state.guestCount}
                    tabIndex={0}
                    aria-labelledby="guestToggleLabel"
                  >
                    <option value="1">Number of Guests: 1</option>
                    <option value="2">Number of Guests: 2</option>
                    <option value="3">Number of Guests: 3</option>
                    {activeSearchFilterData.brand !== 'Z' && (
                      <option value="4">Number of Guests: 4</option>
                    )}
                  </select>
                  <span className="u-custom-select__arrow" />
                </div>
              </div>
              {ssoData &&
              ssoData.transactionData &&
              !_.isEmpty(ssoData.transactionData.paymentLevels) &&
              ssoData.transactionData.paymentLevels.length > 1 ? (
                <div className="equote__package-filters-form-field">
                  <EQuoteSearchFiltersFullFormCurrency
                    currentCurrency={this.state.currentCurrency}
                    handleSelectCurrency={this.handleSelectCurrency}
                    currencies={ssoData.transactionData.paymentLevels}
                  />
                </div>
              ) : null}
            </div>

            <div className="equote__package-filters-submit">
              <div className="equote__package-filters-form-field">
                <EQuoteSearchFiltersFullFormSubmit
                  isSearchEnabled={
                    isSearchEnabled && !this.state.isFormPristine
                  }
                  activeSearchFilterData={{
                    ...activeSearchFilterData,
                    guestCount: this.state.guestCount
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const getDisabledBrands = getDisabledBrandsFromSearchCriteria(state)

  return {
    activeSearchFilterData: state.activeSearchFilterData,
    brands: getBrandsData(),
    destinations: getDestinationsData(),
    loyaltyData: state.loyaltyData,
    ships: getShipsData(),
    shipDestinations: getShipDestinationsData(),
    ssoData: state.ssoData,
    allowSpecialQualifiers: getCanUseQualifiers(state),
    getDisabledBrands
  }
}

const mapDispatchToProps = dispatch => ({
  handleActiveBrand(brand) {
    return dispatch(setActiveBrand(brand))
  },
  handleActiveCurrency(currency) {
    return dispatch(setActiveCurrency(currency))
  },
  handleActiveShip(ship) {
    return dispatch(setActiveShip(ship))
  },
  handleActiveDestination(destination) {
    return dispatch(setActiveRegion(destination))
  },
  handleActiveStartDate(startDate) {
    return dispatch(setActiveStartDate(startDate))
  },
  handleActiveEndDate(endDate) {
    return dispatch(setActiveEndDate(endDate))
  },
  handleActiveSailingNights(duration) {
    return dispatch(setActiveSailingNights(duration))
  },
  handleActiveSeniorCitizenDiscount(isSeniorCitizen) {
    return dispatch(setActiveSeniorCitizenDiscount(isSeniorCitizen))
  },
  handleActiveServiceMemberDiscount(isServiceMember) {
    return dispatch(setActiveServiceMemberDiscount(isServiceMember))
  },
  handleActivePoliceDiscount(isPolice) {
    return dispatch(setActivePoliceDiscount(isPolice))
  },
  handleActiveFireFighterDiscount(isFireFighter) {
    return dispatch(setActiveFireFighterDiscount(isFireFighter))
  },
  handleActiveResidentOf(residentOf) {
    return dispatch(setActiveResidentOf(residentOf))
  },
  handleFetchLoyalty(loyaltyId, agencyId) {
    return dispatch(fetchLoyalty(loyaltyId, agencyId))
  },
  handleRemoveLoyalty(activeLoyaltyId) {
    return dispatch(removeLoyalty(activeLoyaltyId))
  },
  handleEndDataVisibility(endDateVisible) {
    return dispatch(setEndDateVisibility(endDateVisible))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteSailingsFullSearchPackageFilters)
