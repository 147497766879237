import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import reducers from './reducers/index'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'core-js/stable'
import 'airbnb-browser-shims'
import App from './containers/structure/App'

import './styles/scss/base/base.scss'
import './styles/scss/base/buttons.scss'
import './styles/scss/base/forms.scss'
import './styles/scss/base/modal.scss'
import './styles/scss/base/print.scss'
import './styles/scss/base/typography.scss'
import './styles/scss/base/utilities.scss'
import './styles/scss/base/grid.scss'
import './styles/scss/base/select.scss'

function configureStore(preloadedState) {
  const middlewares = [thunk]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [middlewareEnhancer]

  if (
    process.env.NODE_ENV === 'development' &&
    window.__REDUX_DEVTOOLS_EXTENSION__
  ) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__())
  }

  const composedEnhancers = compose(...enhancers)

  const store = createStore(reducers, preloadedState, composedEnhancers)

  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./reducers/index', () => store.replaceReducer(reducers))
  }

  return store
}
const store = configureStore()
const renderApp = () => {
  render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('equote__generate-offers')
  )
}

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./containers/structure/App', renderApp)
}

renderApp()
