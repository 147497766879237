import React, { Component } from 'react'
import { filterImagesByFileName } from '../../utilities/filterImagesByFileName'
import EQuotePackageDetailsImageSlider from './EQuotePackageDetailsImageSlider'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

class EQuotePackageDetailsHeroImage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  getFeaturedItineraryImage = itinerary => {
    if (!itinerary || itinerary.length < 1) {
      return null
    }

    let featurePortSlide = null
    const featuredPortImage = itinerary.find(port => port.isFeatured)

    if (featuredPortImage) {
      featurePortSlide = filterImagesByFileName(
        featuredPortImage.highlight.image,
        'details-hero'
      )
    }

    return featurePortSlide
  }

  getShipImages() {
    const shipDetails = this.props.shipsData.find(
      ship => ship.ship_code === this.props.packageData.shipCode
    )
    return (
      shipDetails &&
      shipDetails.image &&
      filterImagesByFileName(shipDetails.image, 'details-hero')
    )
  }

  getFeaturedVideo() {
    const shipDetails = this.props.shipsData.find(
      ship => ship.ship_code === this.props.packageData.shipCode
    )
    return shipDetails && shipDetails['featured_video']
      ? shipDetails['featured_video']
      : null
  }

  getDestinationImage() {
    const brandCode =
      this.props.brandDetails && this.props.brandDetails['brand_code']
        ? this.props.brandDetails['brand_code'].toLowerCase()
        : null
    const destinationCode = this.props.packageData.destinationCode
    const destinationDetails = this.props.destinationsData.find(
      destination => destination.destination_code === destinationCode
    )
    const destinationImageSource = destinationDetails[`image_${brandCode}`]
      ? destinationDetails[`image_${brandCode}`]
      : destinationDetails.image

    return filterImagesByFileName(destinationImageSource, 'details-hero')
  }
  getBrandImage() {
    return filterImagesByFileName(this.props.brandDetails.image, 'details-hero')
  }

  getImages() {
    const shipImages = this.getShipImages() && this.getShipImages().slice(0, 4) // Just display first 3 images on carousel
    const featuredPortImage = this.getFeaturedItineraryImage(
      this.props.itinerary
    )
    const destinationImage = featuredPortImage
      ? featuredPortImage
      : this.getDestinationImage()

    const brandImage = this.getBrandImage()

    const shipVideo = this.getFeaturedVideo()
    const images = [
      ...(destinationImage || []),
      ...(shipImages || []),
      ...(brandImage || [])
    ]

    if (shipVideo) {
      return [
        {
          type: 'video',
          file: shipVideo
        },
        ...images
      ]
    }
    return images
  }

  render() {
    const slides = this.getImages()
    return <EQuotePackageDetailsImageSlider slides={slides} />
  }
}

export default EQuotePackageDetailsHeroImage
