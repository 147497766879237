import axios from 'axios'
import 'url-search-params-polyfill'
import triggerCustomGaEvent from '../utilities/triggerCustomGaEvent'
import storageKeys from '../configuration/storageKeys'

// Handle ShipDestinations Fetch & Functionality
export default function fetchShipDestinations() {
  // Formatted API path
  const shipDestinationsApi =
    process.env.REACT_APP_CONTENT_ENDPOINT + 'ship-destinations'

  return axios.get(shipDestinationsApi)
}

export const receiveShipsDestinationData = response => {
  const shipDestinations = response.data

  if (shipDestinations) {
    sessionStorage.setItem(
      storageKeys.shipsDestinations,
      JSON.stringify(shipDestinations)
    )

    // Dispatch GA event for destinations by ship
    triggerCustomGaEvent(
      'equoteTracking-destinationsByShipRequest',
      shipDestinations
    )
  }
}
