import RoyalLogo from '../assets/images/rci-logo.png'
import RoyalAccolades from '../assets/images/royal-accolades.svg'
import eQuoteLogo from '../assets/images/equote-logo.svg'
import eQuoteLogoCelebrity from '../assets/images/equote-logo-celebrity.png'
import CelebrityLogo from '../assets/images/celebrity-logo.png'
import CelebrityLogoDark from '../assets/images/celebrity-logo-dark.png'
import CelebrityAccolades from '../assets/images/celebrity-accolades.svg'

import RoyalInteriorStateRoomImage from '../assets/images/defaults/staterooms/Royal/rci_interior_bedroom-790x592.jpg'
import RoyalOceanViewStateRoomImage from '../assets/images/defaults/staterooms/Royal/rci_oceanview_bedroom-790x592.jpg'
import RoyalBalconyStateRoomImage from '../assets/images/defaults/staterooms/Royal/rci_veranda_bedroom-790x592.jpg'
import RoyalDeluxeStateRoomImage from '../assets/images/defaults/staterooms/Royal/rci_suite_bedroom-790x592.jpg'

import CelebrityInteriorStateRoomImage from '../assets/images/defaults/staterooms/Celebrity/cel_interior_bedroom-790x444.jpg'
import CelebrityOceanViewStateRoomImage from '../assets/images/defaults/staterooms/Celebrity/cel_oceanview_bedroom-790x444.jpg'
import CelebrityBalconyStateRoomImage from '../assets/images/defaults/staterooms/Celebrity/cel_veranda_bedroom-790x444.jpg'
import CelebrityDeluxeStateRoomImage from '../assets/images/defaults/staterooms/Celebrity/cel_suite_bedroom-790x444.jpg'

export default {
  R: {
    name: 'Royal Caribbean',
    img: RoyalLogo,
    imgDark: RoyalLogo,
    eQuoteLogo: eQuoteLogo,
    className: 'royal',
    accolades: RoyalAccolades,
    links: {
      0: 'https://www.facebook.com/royalcaribbean',
      1: 'https://twitter.com/royalcaribbean',
      2: 'https://www.instagram.com/RoyalCaribbean/',
      3: 'http://www.youtube.com/royalcaribbeanintl',
      4: 'https://www.pinterest.ca/RoyalCaribbean/',
      5: 'https://shippedonroyal.tumblr.com/',
      6: 'https://www.royalcaribbean.com/blog',
      7: 'http://www.royalcaribbean.com/royaliq'
    },
    stateRoomImages: {
      I: {
        src: RoyalInteriorStateRoomImage,
        width: 790,
        height: 592
      },
      O: {
        src: RoyalOceanViewStateRoomImage,
        width: 790,
        height: 592
      },
      B: {
        src: RoyalBalconyStateRoomImage,
        width: 790,
        height: 592
      },
      D: {
        src: RoyalDeluxeStateRoomImage,
        width: 790,
        height: 592
      }
    },
    stateRooms: {
      I: {
        title: 'Interior',
        description:
          'Our Interior staterooms include a wide array of amenities for your whole family to enjoy.'
      },
      O: {
        title: 'Ocean View',
        description:
          'Spectacular sights and amazing views from the comfort of your stateroom.'
      },
      B: {
        title: 'Balcony',
        description:
          'Breathtaking views to take in all the wonder and excitement of your destination.'
      },
      D: {
        title: 'Suites',
        description:
          'Priority boarding and departure, dedicated entertainment seating, and more.'
      }
    }
  },
  C: {
    name: 'Celebrity',
    img: CelebrityLogo,
    imgDark: CelebrityLogoDark,
    eQuoteLogo: eQuoteLogoCelebrity,
    className: 'celebrity',
    accolades: CelebrityAccolades,
    links: {
      0: 'https://www.facebook.com/CelebrityCruises',
      1: 'https://twitter.com/CelebrityCruise',
      2: 'https://www.instagram.com/celebritycruises/?hl=en',
      3: 'http://www.youtube.com/celebritycruises',
      4: 'https://www.pinterest.ca/celebritycruise/'
    },
    stateRoomImages: {
      I: {
        src: CelebrityInteriorStateRoomImage,
        width: 790,
        height: 444
      },
      O: {
        src: CelebrityOceanViewStateRoomImage,
        width: 790,
        height: 444
      },
      B: {
        src: CelebrityBalconyStateRoomImage,
        width: 790,
        height: 444
      },
      D: {
        src: CelebrityDeluxeStateRoomImage,
        width: 790,
        height: 444
      }
    },
    stateRooms: {
      I: {
        title: 'Inside',
        description:
          'Celebrity’s eXhale™ bedding & cashmere mattress. Twice daily turndown service.  Custom blended bath products, shower cap, cotton balls and cotton swabs.'
      },
      O: {
        title: 'Ocean View',
        description:
          'Expansive view of the ocean. Celebrity’s eXhale™ bedding & cashmere mattress.  Twice daily turndown service.'
      },
      B: {
        title: 'Veranda',
        description:
          'Private veranda with outdoor seating.  Celebrity’s eXhale™ bedding & cashmere mattress.  Plush bathrobes, slippers and towels.'
      },
      D: {
        title: 'Suite Class',
        description:
          'Exclusive access to the Retreat. 24-Hour Personal butler service.  Premium beverage package (excluding Celebrity Suites and Sky Suites).'
      }
    }
  }
}
