export const RESET_ACTIVE_CABINS = 'RESET_ACTIVE_CABINS'
export const RESET_ACTIVE_PACKAGES = 'RESET_ACTIVE_PACKAGES'
export const RESET_ACTIVE_FILTERS = 'RESET_ACTIVE_FILTERS'
export const RESET_ACTIVE_EMAIL_CONFIGURATION = 'RESET_ACTIVE_EMAIL_CONFIGURATION'

export const resetCabins = () => ({
	type: RESET_ACTIVE_CABINS
})
export const resetPackages= () => ({
	type: RESET_ACTIVE_PACKAGES
})
export const resetFilters = () => ({
	type: RESET_ACTIVE_FILTERS
})
export const resetEmailConfiguration = () => ({
	type: RESET_ACTIVE_EMAIL_CONFIGURATION
})

export function resetActiveCabins() {
	return dispatch => {
		dispatch(resetCabins())
	}
}
export function resetActivePackages() {
	return dispatch => {
		dispatch(resetPackages())
	}
}
export function resetActiveFilters() {
	return dispatch => {
		dispatch(resetFilters())
	}
}
export function resetActiveEmailConfiguration() {
	return dispatch => {
		dispatch(resetEmailConfiguration())
	}
}