import React, { Fragment, Component } from 'react'
import { connect } from 'react-redux'
import { ReactMultiEmail, isEmail } from 'react-multi-email'

import {
  setActiveRecipients,
  setAgentEmailCopyOptions
} from '../../actions/setActiveEmailConfiguration'

import 'react-multi-email/style.css'

import CheckMark from '../../assets/icons/checkmark.svg'
import { getLabelsData } from '../../sessionStorage/getLabelsData'

class EQuoteReviewConstructEmailInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      emails: []
    }
  }

  handleUpdateRecipientEmails = emails => {
    const { handleSetActiveRecipients } = this.props

    this.setState({
      emails: emails
    })
    handleSetActiveRecipients(emails)
  }

  render() {
    const { emails } = this.state
    const { labelsData } = this.props

    return (
      <Fragment>
        <label id="label-recipient-emails" htmlFor="recipientEmails">
          {labelsData &&
          labelsData.email &&
          labelsData.email['configure_emails']
            ? labelsData.email['configure_emails']
            : 'Recipient Email(s)'}
        </label>

        <ReactMultiEmail
          className="u-custom-email__chips"
          placeholder={
            labelsData &&
            labelsData.email &&
            labelsData.email['configure_emails_placeholder']
              ? labelsData.email['configure_emails_placeholder']
              : 'Separate multiple addresses using commas (,)'
          }
          emails={emails}
          onChange={_emails => {
            this.handleUpdateRecipientEmails(_emails)
          }}
          validateEmail={email => {
            return isEmail(email)
          }}
          getLabel={(email, index, removeEmail) => {
            return (
              <div data-tag key={index}>
                {email}
                <span data-tag-handle onClick={() => removeEmail(index)}>
                  &times;
                </span>
              </div>
            )
          }}
        />
        {this.props.agentEmail && (
          <button
            className="equote__review-configure-email-terms-check btn"
            onClick={() => {
              this.props.setAgentEmailCopyOptions(
                !this.props.shouldSendCopyToAgent
              )
            }}
          >
            <span
              className={`equote__review-configure-email-terms-check-icon equote__review-configure-email_send_to_agent_checkmark ${
                this.props.shouldSendCopyToAgent ? 'active' : ''
              }`}
            >
              {this.props.shouldSendCopyToAgent && (
                <img src={CheckMark} alt="checked" />
              )}
            </span>
            Send a copy to {this.props.agentEmail}
          </button>
        )}
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  const agentEmail =
    state.modifiedSsoData &&
    state.modifiedSsoData.agent &&
    state.modifiedSsoData.agent.email
      ? state.modifiedSsoData.agent.email
      : state.ssoData.agent.email
      ? state.ssoData.agent.email
      : null
  return {
    labelsData: getLabelsData(),
    agentEmail,
    shouldSendCopyToAgent: state.activeEmailConfigurationData.sendCopyToAgent
  }
}

const mapDispatchToProps = dispatch => ({
  handleSetActiveRecipients(recipients) {
    return dispatch(setActiveRecipients(recipients))
  },
  setAgentEmailCopyOptions(shouldSendEmail) {
    dispatch(setAgentEmailCopyOptions(shouldSendEmail))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteReviewConstructEmailInput)
