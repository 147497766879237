import React, { useState } from 'react'
import '../../styles/scss/components/equote-package-details-navigation.scss'
import triggerCustomGaEvent from '../../utilities/triggerCustomGaEvent'

const links = [
  {
    text: 'ITINERARY & DESTINATION',
    target: 'itinerary',
    gaEvent: 'sailingPreview-itineraryDestination'
  },
  {
    text: 'SHIP INFORMATION',
    target: 'ship',
    gaEvent: 'sailingPreview-shipInformation'
  },
  {
    text: 'STATEROOM INFORMATION',
    target: 'staterooms',
    gaEvent: 'sailingPreview-stateRoomInformation'
  },
  {
    text: 'SIMILAR SAILINGS',
    target: 'similar-sailings',
    gaEvent: 'sailingPreview-similarSailing'
  },
  {
    text: 'Request Sailing',
    target: 'request',
    gaEvent: 'sailingPreview-requestSailingTab'
  }
]

export default function EQuotePreviewPackageNavigation() {
  const [activeIndex, setActiveIndex] = useState(null)
  return (
    <div className="equote__package-details-navigation">
      {links.map((link, i) => {
        return (
          <button
            key={link.target}
            className={`equote__package-details-navigation--button btn ${
              i === activeIndex ? 'active' : ''
            }`}
            onClick={() => {
              setActiveIndex(() => i)
              if (link.gaEvent) {
                triggerCustomGaEvent(link.gaEvent)
              }
              document
                .getElementById(link.target)
                .scrollIntoView({ behavior: 'smooth' })
            }}
          >
            {link.text}
          </button>
        )
      })}
    </div>
  )
}
