import brands from '../configuration/brands'

export default function formatPackageData(
  availablePackages,
  destinationsData,
  shipsData
) {
  let formattedPackages = []

  for (const currentPackage of Object.values(availablePackages)) {
    // Initialize default placeholders
    let packageDestinationName = '-',
      packageShipName = '-'

    // Set package destination
    if (currentPackage.destinationCode) {
      if (destinationsData && destinationsData.length) {
        const currentDestination = destinationsData.find(
          destinations =>
            destinations['destination_code'] === currentPackage.destinationCode
        )

        if (currentDestination && currentDestination.name) {
          packageDestinationName = currentDestination.name
        }
      }
    }

    // Set package ship
    if (currentPackage.shipCode && shipsData && shipsData.length) {
      const currentShip = shipsData.find(
        ships => ships['ship_code'] === currentPackage.shipCode
      )
      if (currentShip && currentShip.name) {
        packageShipName = currentShip.name
      }
    }

    formattedPackages.push({
      ...currentPackage,
      brandName: currentPackage.brand
        ? brands[currentPackage.brand].name
        : null,
      departure: currentPackage.sailDate || '-',
      nights: currentPackage.sailingNights || '-',
      tourNights: currentPackage.tourNights,
      destinationName: packageDestinationName,
      shipCode: currentPackage.shipCode || '-',
      shipName: packageShipName,
      cruiseTourName: currentPackage.description || null,
      cruiseTourPreNights: currentPackage.preTourNights || null,
      cruiseTourPostNights:
        currentPackage.postTourNights && currentPackage.postTourNights > 0
          ? currentPackage.postTourNights
          : null
    })
  }

  return formattedPackages
}
