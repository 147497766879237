import {
	SET_ACTIVE_STEP,
  SET_PREVIOUS_STEP
} from '../actions/setActiveStepActions'

const initialState = {
	activeStep: 0,
  previousStep: null,
}

export default function setActiveStepReducers(state = initialState, action) {
	switch(action.type) {

		case SET_ACTIVE_STEP:
			return {
				...state,
				activeStep: action.payload,
			}
    case SET_PREVIOUS_STEP:
      return {
        ...state,
        previousStep: action.payload,
      }

		default:
			return state;
	}
}
