import React, { Component } from 'react'
import { connect } from 'react-redux'

import setActivePackage from '../../../../actions/setActivePackage'
import removeActivePackage from '../../../../actions/removeActivePackage'
import setActiveStep from '../../../../actions/setActiveStepActions'
import Checkbox from '../../../../components/elements/Checkbox'
import updateSearchParams from '../../../../utilities/updateSearchParams'
import shouldDisableRowButtons from '../shouldDisableRowButtons'
import { getPackagesDetails } from '../../../../reducers/fetchPackagesReducers'
import { getActivePackageKeys } from '../../../../reducers/setActivePackageReducers'
import triggerCustomGaEvent from '../../../../utilities/triggerCustomGaEvent'
import { clearPackageCode } from '../../../../actions/setActiveSearchFilters'

class EQuoteSailingsSelectPackageButton extends Component {
  componentDidMount() {
    this.checkForPreSelectedPackage()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.checkForPreSelectedPackage()
  }

  checkForPreSelectedPackage() {
    const {
      activeStep,
      activeSearchFilterData,
      isDisabled,
      handleNextStep,
      isSelected,
      packageDetails
    } = this.props
    if (
      !isSelected &&
      activeSearchFilterData.packageCode &&
      activeSearchFilterData.packageCode === packageDetails.id &&
      activeSearchFilterData.startDate &&
      activeSearchFilterData.startDate === packageDetails.sailDate &&
      !isDisabled
    ) {
      this.props.handleAddPackage()
      handleNextStep(activeStep)
      updateSearchParams(activeSearchFilterData)
      this.props.clearPackageCode()
    }
  }

  render() {
    const {
      packageDetails,
      isDisabled,
      activePackageCount,
      isSelected
    } = this.props
    if (!isDisabled) {
      return (
        <Checkbox
          key={packageDetails.id}
          checked={isSelected}
          onClick={
            isSelected
              ? this.props.handleRemovePackage
              : this.props.handleAddPackage
          }
          disabled={!isSelected && activePackageCount === 5}
        />
      )
    }
    return null
  }
}

function mapStateToProps(state, ownProps) {
  const { identifier, isLandPackage, cruiseIdentifier, isCruiseTour } = ownProps
  const activePackageKeys = getActivePackageKeys(state)
  const activePackageCount = activePackageKeys.length

  const packageIdentifier = isLandPackage ? cruiseIdentifier : identifier
  const packageDetails = getPackagesDetails(
    state,
    isCruiseTour,
    packageIdentifier
  )

  const isSelected = activePackageKeys.some(key => key === identifier)

  return {
    activeStep: state.activeStepData.activeStep,
    isDisabled: shouldDisableRowButtons(packageDetails),
    activeSearchFilterData: state.activeSearchFilterData,
    identifier,
    isSelected,
    activePackageCount,
    packageDetails
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    identifier,
    isLandPackage,
    cruiseIdentifier,
    landPackageId,
    stateKey,
    landProgramType
  } = ownProps
  return {
    handleAddPackage() {
      if (isLandPackage) {
        triggerCustomGaEvent('isLandPackage', { ...ownProps })
      }
      dispatch(
        setActivePackage(identifier, {
          stateKey,
          cruiseIdentifier: isLandPackage ? cruiseIdentifier : identifier,
          isLandPackage,
          landPackageId: isLandPackage ? landPackageId : null,
          landPackageType:
            isLandPackage && landProgramType ? landProgramType : null
        })
      )
    },
    handleRemovePackage() {
      return dispatch(removeActivePackage(identifier))
    },
    handleNextStep(activeStep) {
      return dispatch(setActiveStep(activeStep + 1))
    },
    clearPackageCode() {
      dispatch(clearPackageCode())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteSailingsSelectPackageButton)
