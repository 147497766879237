import React, { Component } from 'react'
import { connect } from 'react-redux'

import setActiveStep from '../../../actions/setActiveStepActions'
import fetchDestinationMonths from '../../../actions/fetchDestinationMonths'

import priceIcon from '../../../assets/icons/price.svg'
import downArrow from '../../../assets/icons/arrow-down.svg'
import { getLabelsData } from '../../../sessionStorage/getLabelsData'

class EQuoteSearchFilterPrice extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { labelsData, handleSubmitQueryPackages } = this.props

    let searchLabels = null

    if (labelsData && labelsData.search) {
      searchLabels = labelsData.search
    }

    return (
      <div className="equote__search-filter-group equote__search-filter-group--destination">
        <div className="equote__search-filter-icon">
          <img src={priceIcon} alt="Ship icon" />
        </div>

        <h6 className="u-small-title">
          {searchLabels && searchLabels.by_price_title
            ? searchLabels.by_price_title
            : 'Search By Price'}
        </h6>

        <div
          onClick={() => handleSubmitQueryPackages()}
          className="equote__search-filter"
          tabIndex={0}
        >
          <p className="equote__search-filter-description u-remove-margin u-font-medium">
            {searchLabels && searchLabels.by_price_body
              ? searchLabels.by_price_body
              : 'Use our price search tool to find the lowest price on your ideal cruise.'}
          </p>
          <p className="equote__search-filter-title u-remove-margin u-font-medium">
            {searchLabels && searchLabels.by_price_cta
              ? searchLabels.by_price_cta
              : 'View Sailings'}
            <img
              className="equote__search-filter-arrow"
              alt="Filter Down Arrow"
              src={downArrow}
            />
          </p>
        </div>
      </div>
    )
  }
}

function mapStateToProps() {
  return {
    labelsData: getLabelsData()
  }
}

const mapDispatchToProps = dispatch => ({
  handleSubmitQueryPackages() {
    dispatch(fetchDestinationMonths())
    dispatch(setActiveStep(5))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteSearchFilterPrice)
