export default function(leadPromoDesc, promosArray) {
  // we need to format the leadPromoDesc to add a plus sign if there are  multiple promos
  if(!leadPromoDesc) return null;
  // if we don't have more than one promo we don't need to modify the string
  // even if it already has a plus sign at the end 
  if (!promosArray || promosArray.length < 2) return leadPromoDesc

  // it is possible the leadPromoDesc already has a plus sign
  // we need to check for that before we add another one
  if (leadPromoDesc[leadPromoDesc.length - 1] === '+') return leadPromoDesc

  return leadPromoDesc + '+'
};
