import { omit } from 'lodash'
import { SET_ACTIVE_CABIN } from '../actions/setActiveCabin'
import { REMOVE_ACTIVE_CABIN } from '../actions/removeActiveCabin'
import { RESET_ACTIVE_CABINS } from '../actions/resetQuoteFlow'
import { REMOVE_ACTIVE_PACKAGE } from '../actions/removeActivePackage'
import { CLEAR_PACKAGE_DATA } from '../actions/fetchPackages'

const initialState = {}

export default function setActiveCabinReducers(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_CABIN:
      // Set cabin manually
      const { payload } = action
      return Object.assign({}, state, {
        ...state,
        [payload.packageIdentifier]: {
          ...state[payload.packageIdentifier],
          [payload.activeCabinKey]: payload.activeCabin
        }
      })

    case REMOVE_ACTIVE_CABIN:
      // Remove cabin by ID
      const currentPackageActiveCabins = {
        ...state[action.payload.packageIdentifier]
      }

      delete currentPackageActiveCabins[action.payload.activeCabinKey]

      return Object.assign({}, state, {
        ...state,
        [action.payload.packageIdentifier]: currentPackageActiveCabins
      })
    case REMOVE_ACTIVE_PACKAGE:
      // Remove package by ID
      return omit(state, action.payload)

    case RESET_ACTIVE_CABINS:
    case CLEAR_PACKAGE_DATA:
      // Reset all cabins
      return {}

    default:
      return state
  }
}

export const getAllActiveCabinData = state => {
  if (!state.activeCabinData) return {}
  return Object.entries(state.activeCabinData).reduce(
    (obj, [cabinKey, cabinData]) => {
      const {
        stateKey,
        subStateKey,
        packageIdentifier,
        isSuperCategory,
        superCategoryCode
      } = cabinData

      const relatedPackageDetails =
        state[stateKey] &&
        state[stateKey][subStateKey] &&
        state[stateKey][subStateKey][packageIdentifier]
          ? state[stateKey][subStateKey][packageIdentifier]
          : null

      let stateRoom

      if (relatedPackageDetails) {
        const pricing = relatedPackageDetails.pricing.find(
          cat => cat.stateroomType === superCategoryCode
        )
        if (pricing) {
          if (isSuperCategory) {
            stateRoom = pricing.bestRate ? pricing.bestRate.stateroom : null
          }
        }
      }

      if (stateRoom) {
        cabinData = {
          ...cabinData,
          superCatInfo: stateRoom
        }
      }

      obj[cabinKey] = cabinData

      return obj
    },
    {}
  )
}
