import React, { Component } from 'react'
import { connect } from 'react-redux'

import setActiveStep from '../../actions/setActiveStepActions'

import EQuoteAgentDetailsBanner from '../../components/agent/EQuoteAgentDetailsBanner'
import EQuoteReviewConfigureEmail from '../../components/review/EQuoteReviewConfigureEmail'
import EQuoteReviewConstructEmail from '../../components/review/EQuoteReviewConstructEmail'
import PageHeader from '../../components/elements/PageHeader'

import triggerCustomGaEvent from '../../utilities/triggerCustomGaEvent'

import '../../styles/scss/components/equote-cabins.scss'
import '../../styles/scss/components/equote-review.scss'
import { getAllActivePackagesDetails } from '../../reducers/setActivePackageReducers'
import { getDestinationsData } from '../../sessionStorage/getDestinationsData'
import { getLabelsData } from '../../sessionStorage/getLabelsData'

class EQuoteReviewContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    // Dispatch GA event for search component
    const trackingComponentData = {
      currentStep: 4,
      component: 'Configure and Send Email'
    }

    triggerCustomGaEvent(
      'equoteTracking-currentActiveStep',
      trackingComponentData
    )
  }

  render() {
    const {
      activeStep,
      handleBack,
      activeEmailConfigurationData,
      activeSearchFilterData,
      activePackageData,
      destinationsData,
      labelsData,
      quoteError,
      ssoData
    } = this.props

    return (
      <div className="equote__review">
        <div className="u-content-container">
          <PageHeader
            buttonText="Back to Confirm Categories and Pricing"
            clickHandler={() => handleBack(activeStep)}
            title={labelsData.email.title}
          />
        </div>

        <EQuoteAgentDetailsBanner />

        <div className="equote__review-content u-content-container">
          <div className="equote__review-content--col">
            <EQuoteReviewConfigureEmail
              labelsData={labelsData}
              quoteError={quoteError}
            />
          </div>
          <div className="equote__review-content--col">
            <EQuoteReviewConstructEmail
              activeEmailConfigurationData={activeEmailConfigurationData}
              activeSearchFilterData={activeSearchFilterData}
              activePackageData={activePackageData}
              destinationsData={destinationsData}
              labelsData={labelsData}
              ssoData={ssoData}
            />
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    activeEmailConfigurationData: state.activeEmailConfigurationData,
    activeStep: state.activeStepData.activeStep,
    activePackageData: getAllActivePackagesDetails(state),
    activeSearchFilterData: state.activeSearchFilterData,
    cabinStepData: state.activeCabinStepData,
    destinationsData: getDestinationsData(),
    labelsData: getLabelsData(),
    quoteError: state.quoteData.error,
    ssoData: state.ssoData
  }
}

const mapDispatchToProps = dispatch => ({
  handleBack(activeStep) {
    return (
      dispatch(setActiveStep(activeStep - 1)),
      // Dispatch GA events
      triggerCustomGaEvent('backToSearchResults', true)
    )
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteReviewContainer)
