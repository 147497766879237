import React from 'react'
import { get } from 'lodash'
import EQuotePackageCarousel from '../../components/carousel/EQuotePackageCarousel'
import { filterImagesByFileName } from '../../utilities/filterImagesByFileName'
import '../../styles/scss/components/equote-package-details-ship-details.scss'
import '../../styles/scss/components/equote-package-details-ship-image-slider.scss'

import VirtualTourArrowButton from '../elements/VirtualTourArrowButton'
import ShipSpecs from './ShipSepcs'

const renderSlides = (shipDetails, numberOfSlides) => {
  const slides =
    shipDetails &&
    shipDetails.image &&
    shipDetails.image.slice(0, numberOfSlides)

  const images = shipDetails['featured_video']
    ? [
        {
          type: 'video',
          file: shipDetails['featured_video']
        }
      ]
    : []

  const filteredSlide =
    (slides && filterImagesByFileName(slides, 'details-hero')) || []
  images.push(...filteredSlide)

  return images
}

const EQuotePackageDetailsShipInformation = props => {
  const virtualTourLink = get(
    props,
    'shipDetails.virtual_tour_button_link',
    null
  )
  const tourText = get(props, 'shipDetails.virtual_tour_button_text', null)
  const virtualTourButtonText =
    tourText && tourText !== 0 ? tourText : 'Take a virtual tour'

  if (!props.shipDetails) {
    return (
      <p className="u-text-center">Ship information unavailable at this time</p>
    )
  }

  return (
    <div className="u-content-container">
      <div className="equote__package-details-ship-details" id="ship">
        <h1 className={`section-title ${props.brandClassName}`}>
          Ship Information{' '}
          <small>{props.shipDetails && props.shipDetails.name}</small>
        </h1>

        <div className="content">
          {/*  LEFT IS CAROUSEL + DESCRIPTION */}
          <div className="left">
            <EQuotePackageCarousel
              shouldHideText
              slides={renderSlides(props.shipDetails, 4)}
            />
            {props.shipDetails && props.shipDetails.body && (
              <div className={`ship-description`}>
                <p
                  dangerouslySetInnerHTML={{ __html: props.shipDetails.body }}
                />
              </div>
            )}
            <ShipSpecs shipDetails={props.shipDetails} useToggle />
          </div>
          <div className="right">
            {props.shipDetails && <ShipSpecs shipDetails={props.shipDetails} />}
            {virtualTourLink && virtualTourLink.length > 0 ? (
              <div className="virtual-tour-container">
                <VirtualTourArrowButton
                  text={virtualTourButtonText}
                  onClick={() =>
                    props.openVirtualTour({
                      link: virtualTourLink,
                      title: virtualTourButtonText || 'Virtual Tour'
                    })
                  }
                  brandClassName={props.brandClassName}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EQuotePackageDetailsShipInformation
