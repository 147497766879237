import React, { Component } from 'react'

class EQuoteReviewEmailAgentBanner extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { modifiedSsoData, ssoData } = this.props
    let agentName = null,
      agentEmail = null,
      // agentPhone = null,
      agencyName = null,
      agencyEmail = null,
      agencyPhone = null,
      agencyWebsite = null,
      agencyLogo = null

    // Set Agent data edit VIA edit agent response, content hub or ESL
    if (
      modifiedSsoData &&
      modifiedSsoData.agent &&
      modifiedSsoData.agent.name
    ) {
      agentName = modifiedSsoData.agent.name
    } else if (ssoData && ssoData.agent && ssoData.agent.name) {
      agentName = ssoData.agent.name
    }

    if (
      modifiedSsoData &&
      modifiedSsoData.agent &&
      modifiedSsoData.agent.email
    ) {
      agentEmail = modifiedSsoData.agent.email
    } else if (ssoData && ssoData.agent && ssoData.agent.email) {
      agentEmail = ssoData.agent.email
    }

    // if (
    //   modifiedSsoData &&
    //   modifiedSsoData.agent &&
    //   modifiedSsoData.agent.phone
    // ) {
    //   agentPhone = modifiedSsoData.agent.phone
    // } else if (
    //   ssoData &&
    //   ssoData.custom &&
    //   ssoData.custom.agent &&
    //   ssoData.custom.agent.phone
    // ) {
    //   agentPhone = ssoData.custom.agent.phone
    // } else if (ssoData && ssoData.agent && ssoData.agent.phone) {
    //   agentPhone = ssoData.agent.phone
    // }

    // Set Agency data edit VIA edit agent response, content hub or ESL
    if (
      modifiedSsoData &&
      modifiedSsoData.agency &&
      modifiedSsoData.agency.name
    ) {
      agencyName = modifiedSsoData.agency.name
    } else if (ssoData && ssoData.agency && ssoData.agency.name) {
      agencyName = ssoData.agency.name
    }

    if (
      modifiedSsoData &&
      modifiedSsoData.agency &&
      modifiedSsoData.agency.email
    ) {
      agencyEmail = modifiedSsoData.agency.email
    } else if (ssoData && ssoData.agency && ssoData.agency.email) {
      agencyEmail = ssoData.agency.email
    }

    if (
      modifiedSsoData &&
      modifiedSsoData.agency &&
      modifiedSsoData.agency.phone
    ) {
      agencyPhone = modifiedSsoData.agency.phone
    } else if (ssoData && ssoData.agency && ssoData.agency.phone) {
      agencyPhone = ssoData.agency.phone
    }

    if (
      modifiedSsoData &&
      modifiedSsoData.agency &&
      modifiedSsoData.agency.logo
    ) {
      agencyLogo = modifiedSsoData.agency.logo
    } else if (ssoData && ssoData.agency && ssoData.agency.logo) {
      agencyLogo = ssoData.agency.logo
    }

    if (
      modifiedSsoData &&
      modifiedSsoData.agency &&
      modifiedSsoData.agency.website
    ) {
      agencyWebsite = modifiedSsoData.agency.website
    } else if (ssoData && ssoData.agency && ssoData.agency.website) {
      agencyWebsite = ssoData.agency.website
    }

    return (
      <>
        <h6 className="equote__review-email-agent-banner-title">
          Start Planning Your Vacation
        </h6>
        <p className="equote__review-email-agent-banner-subtitle">
          Get in touch with your agent to confirm your cruise and get sailing.
        </p>
        <div className="equote__review-email-agent-banner">
          {agencyLogo && (
            <div className="equote__review-email-agent-image">
              <img
                src={agencyLogo}
                alt={
                  agencyName
                    ? agencyName + ' Brand Logo'
                    : 'Agency Placeholder Logo'
                }
              />
            </div>
          )}

          <div
            className={`equote__review-email-agent-data${
              !agencyLogo ? ' u-magin-auto' : ''
            } `}
          >
            {agentName ? (
              <div className={`equote__review-email-agent-data-col`}>
                <p className="u-remove-margin title">Agent Name:</p>
                <p className="u-remove-margin details">{agentName}</p>
              </div>
            ) : null}

            {agencyName ? (
              <div className={`equote__review-email-agent-data-col`}>
                <p className="u-remove-margin title">Agency:</p>
                <p className="u-remove-margin details">
                  {agencyName}
                </p>
              </div>
            ) : null}

            {agentEmail ? (
              <div className={`equote__review-email-agent-data-col`}>
                <p className="u-remove-margin title">Email:</p>
                <p className="u-remove-margin details">
                  {agentEmail}
                </p>
              </div>
            ) : agencyEmail ? (
              <div className={`equote__review-email-agent-data-col`}>
                <p className="u-remove-margin title">Email:</p>
                <p className="u-remove-margin details">
                  {agencyEmail}
                </p>
              </div>
            ) : null}

            {agencyPhone ? (
              <div className={`equote__review-email-agent-data-col`}>
                <p className="u-remove-margin title">Phone:</p>
                <a
                  className="u-remove-margin details"
                  href={'tel:' + agencyPhone}
                >
                  {agencyPhone}
                </a>
              </div>
            ) : (
              <div className={`equote__review-email-agent-data-col`}>
                <p className="u-remove-margin title">Phone:</p>
                <span className="u-remove-margin details">{'-'}</span>
              </div>
            )}

            {agencyWebsite ? (
              <div className={`equote__review-email-agent-data-col`}>
                <p className="u-remove-margin title">Website:</p>
                <a
                  className="u-remove-margin details"
                  href={agencyWebsite}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {agencyWebsite}
                </a>
              </div>
            ) : null}
          </div>
        </div>
      </>
    )
  }
}

export default EQuoteReviewEmailAgentBanner
