export default function formatLandPackages(landPackages) {
  return Object.entries(landPackages).reduce((obj, [key, packageData]) => {
    const {
      name,
      code,
      cityCode,
      cruiseTourType,
      packageCode,
      nights,
      priceAmount,
      currencyCode,
      content
    } = packageData
    const isPreCruiseTour = cruiseTourType === 'PRECRUISE'
    obj[key] = {
      name,
      code,
      cityCode,
      cruiseTourType,
      prePostTagName: isPreCruiseTour ? 'pre' : 'post',
      isPreCruiseTour,
      packageCode,
      tourNights: nights,
      netPrice: priceAmount,
      perGuestPrice: priceAmount,
      currencyCode,
      title: content ? content.title : null,
      packageImage: content ? content.packageImage : null,
      description: content ? content.body : null,
      shortDescription: content ? content.description : null,
      highlights: content ? content.highlights : null,
      itinerary:
        content && content.itinerary
          ? formatLandProgramItinerary(content.itinerary)
          : []
    }

    return obj
  }, {})
}

function formatLandProgramItinerary(itinerary) {
  // the drupal feed was broken and sometimes returning a string
  // I believe this is fixed now but I'll leave this as a safety measure.
  if (typeof itinerary === 'string') return []
  return itinerary.map(day => ({
    day: day['field_day'],
    title: day['field_title'],
    description: day['field_description'],
    image: day['field_package_event_image']
  }))
}
