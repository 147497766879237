import { removeCabin } from './removeActiveCabin'

export const SET_ACTIVE_CABIN = 'SET_ACTIVE_CABIN'

const addCabin = (packageIdentifier, activeCabinKey, activeCabin) => {
  return {
    type: SET_ACTIVE_CABIN,
    payload: { packageIdentifier, activeCabinKey, activeCabin }
  }
}

export default function setActiveCabin(
  packageIdentifier,
  activeCabinKey,
  activeCabin
) {
  return (dispatch, getState) => {
    if (activeCabin.isSuperCategory) {
      dispatch(addCabin(packageIdentifier, activeCabinKey, activeCabin))
      return
    }
    // remove the pre selected super category if it exists
    const state = getState()
    if (
      activeCabin.superCategoryCode &&
      state.activeCabinData &&
      state.activeCabinData[packageIdentifier] &&
      state.activeCabinData[packageIdentifier][activeCabin.superCategoryCode]
    ) {
      dispatch(removeCabin(packageIdentifier, activeCabin.superCategoryCode))
    }
    dispatch(addCabin(packageIdentifier, activeCabinKey, activeCabin))
  }
}
