import React from 'react'
import MaterialTable from 'material-table'
import { get } from 'lodash'

import closeIcon from '../../assets/icons/close.svg'
import renderCabinTableColumns from '../../utilities/renderCabinTableColumns'

const headerStyle = {
  maxWidth: '177px',
  whiteSpace: 'nowrap'
}

const EQuoteCabinsTable = props => {
  const { isEditOpen, handleToggleEdit, availableCabins, activePackage } = props
  const currencySymbol = get(activePackage, 'currencySymbol', '')
  const initialColumns = [
    {
      title: 'Category',
      field: 'category',
      isPricing: false,
      order: 1,
      cellStyle: {
        padding: 20
      },
      headerStyle: {
        padding: 20
      }
    },
    {
      title: 'Category Name',
      field: 'categoryName',
      isPricing: false,
      order: 2,
      cellStyle: {
        padding: 20
      },
      headerStyle: {
        padding: 20
      }
    }
  ]
  const columns = renderCabinTableColumns({
    columns: initialColumns,
    cabins: availableCabins,
    headerStyle,
    isSelectableTable: true,
    packageData: activePackage,
    currencySymbol
  })

  // We don't need the blank rows
  const data = Object.values(availableCabins)
    .reduce((arr, cabin) => {
      if (cabin.category && cabin.category.length >= 1) {
        arr.push(cabin)
      }
      return arr
    }, [])
    .sort((a, b) => {
      return a.bestGuestCountRate - b.bestGuestCountRate
    })

  return (
    <div
      className={
        isEditOpen ? 'equote__cabins-table active' : 'equote__cabins-table'
      }
    >
      <div className="equote__cabins-table--header">
        <button className="btn btn-text" onClick={() => handleToggleEdit()}>
          <span>
            Close
            <img
              className="u-modal__close-icon"
              src={closeIcon}
              alt="eQuote Edit Cabin Close"
            />
          </span>
        </button>
      </div>

      <MaterialTable
        components={{
          Container: props => (
            <div className="u-table u-nested-cabins-table-container">
              {props.children}
            </div>
          )
        }}
        columns={columns}
        data={data}
        options={{
          paging: false,
          showEmptyDataSourceMessage: true,
          toolbar: false,
          sorting: false
        }}
        localization={{
          body: {
            emptyDataSourceMessage:
              'No cabins available, please try another date range.'
          }
        }}
      />
    </div>
  )
}

export default EQuoteCabinsTable
