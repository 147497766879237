import React from 'react'

import leafLeft from '../../assets/icons/leaf1_left.svg'
import leafRight from '../../assets/icons/leaf1_right.svg'
import star from '../../assets/icons/star.svg'

import '../../styles/scss/components/equote-package-details-accolade-detail.scss'

export default function EQuoteAgentAccoladeAwardDetail({
  iconLeft,
  iconRight,
  children
}) {
  const iLeft = iconLeft || leafLeft
  const iRight = iconRight || leafRight

  return (
    <div className="accolade__detail">
      <div className="accolade__detail-icon-container">
        <img src={iLeft} className="accolade__detail-icon" alt="award icon" />
      </div>
      <div className="accolade__detail-text">
        {children || (
          <>
            <h2>2019</h2>
            <h1>
              BEST CRUISE LINE{' '}
              <span>
                <small>IN</small>
                <small>THE</small>
              </span>{' '}
              CARIBBEAN
            </h1>
            <h3>SINCE 2003</h3>
            <p>Travel Weekly Reader's Choice Award</p>
          </>
        )}
      </div>
      <div className="accolade__detail-icon-container">
        <img src={iRight} className="accolade__detail-icon" alt="award icon" />
      </div>
    </div>
  )
}

export function EQuoteAgentAccoladeAwardDetailBanner({
  iconLeft,
  iconRight,
  children,
  middleText
}) {
  const iLeft = iconLeft || leafLeft
  const iRight = iconRight || leafRight
  const mainText = middleText || 'BEST CRUISE LINE SINCE 2003'

  return (
    <div className="accolade__banner">
      <EQuoteAgentAccoladeAwardDetail
        iconLeft={iLeft}
        iconRight={iRight}
        children={children}
      />
      <div className="accolade__banner-text">{mainText}</div>
      <EQuoteAgentAccoladeAwardDetail
        iconLeft={iLeft}
        iconRight={iRight}
        children={children}
      />
    </div>
  )
}

export function EQuoteAgentAccoladeColumnsBanner() {
  return (
    <div className="accolade__columns">
      <h1 className="u-text-center">WHY AZAMARA</h1>
      <div className="accolade__columns--wrapper">
        <div className="accolade__columns--column">
          <div className="accolade__columns--circle">
            <div className="accolade__columns--circle-title">
              CULTURAL
              <br />
              IMMERSION
              <img src={star} alt="detail in a format of a star" />
            </div>
          </div>
          <p>
            Longer stays. More overnights. Country-intensive itineraries. Land
            and sea packages. Authentic Shore Excursions. Complimentary
            AzAmazing Evenings<sup>&reg;</sup>
          </p>
        </div>
        <div className="accolade__columns--column">
          <div className="accolade__columns--circle">
            <div className="accolade__columns--circle-title">
              INCLUSIVE
              <br />
              AMENITIES
              <img src={star} alt="detail in a format of a star" />
            </div>
          </div>
          <p>Gratuities. Beverages. Self service laundry. And more!</p>
        </div>
        <div className="accolade__columns--column">
          <div className="accolade__columns--circle">
            <div className="accolade__columns--circle-title">
              CULTURAL
              <br />
              IMMERSION
              <img src={star} alt="detail in a format of a star" />
            </div>
          </div>
          <p>
            Boutique Hotel. Concierge services. Shuttle services to and from
            port communities.
          </p>
        </div>
      </div>
    </div>
  )
}
