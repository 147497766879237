import React, { Component } from 'react'

import EQuoteCabinsTableAccordion from './EQuoteCabinsTableAccordion'
import EQuotePackageDetailsContainer from '../../containers/packageDetails/EQuotePackageDetailsContainer'

import triggerCustomGaEvent from '../../utilities/triggerCustomGaEvent'

import _ from 'lodash'
import EQuoteSearchDisclaimer from '../search/brand/EQuoteSearchDisclaimer'
import EQuoteCabinsLandProgramsSelection from './EQuoteCabinsLandProgramsSelection'
import EQuoteCabinsHeader from './EQuoteCabinsHeader'

class EQuoteCabinsTableContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      interiorOpen: false,
      oceanviewOpen: false,
      balconyOpen: false,
      deluxeOpen: false,
      activeDetailsData: null
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.activePackage.identifier !==
        this.props.activePackage.identifier ||
      prevProps.activePackage.landPackageIdentifier !==
        this.props.activePackage.landPackageIdentifier
    ) {
      this.setState({
        interiorOpen: false,
        oceanviewOpen: false,
        balconyOpen: false,
        deluxeOpen: false
      })
    }
  }

  handleToggleEditInterior = () => {
    this.setState(
      {
        interiorOpen: !this.state.interiorOpen,
        oceanviewOpen: false,
        balconyOpen: false,
        deluxeOpen: false
      },
      () => {
        // Dispatch GA events
        triggerCustomGaEvent('buildYourQuote-cabinCategoryEdits-counter', {
          category: 'interior',
          toggledOpen: this.state.interiorOpen
        })
      }
    )
  }

  handleToggleEditOcenview = () => {
    this.setState(
      {
        interiorOpen: false,
        oceanviewOpen: !this.state.oceanviewOpen,
        balconyOpen: false,
        deluxeOpen: false
      },
      () => {
        triggerCustomGaEvent('buildYourQuote-cabinCategoryEdits-counter', {
          category: 'oceanview',
          toggledOpen: this.state.oceanviewOpen
        })
      }
    )
  }

  handleToggleEditBalcony = () => {
    this.setState(
      {
        interiorOpen: false,
        oceanviewOpen: false,
        balconyOpen: !this.state.balconyOpen,
        deluxeOpen: false
      },
      () => {
        triggerCustomGaEvent('buildYourQuote-cabinCategoryEdits-counter', {
          category: 'balcony',
          toggledOpen: this.state.balconyOpen
        })
      }
    )
  }

  handleToggleEditDeluxe = () => {
    this.setState(
      {
        interiorOpen: false,
        oceanviewOpen: false,
        balconyOpen: false,
        deluxeOpen: !this.state.deluxeOpen
      },
      () => {
        triggerCustomGaEvent('buildYourQuote-cabinCategoryEdits-counter', {
          category: 'deluxe',
          toggledOpen: this.state.deluxeOpen
        })
      }
    )
  }

  // Trigger ship details modal
  handleOpenPackageDetailsModal = (packageId, sailDate) => {
    this.props.handleOpenPackageDetailsModal()
    // Dispatch GA events
    const previewDetailsData = {
      id: packageId,
      sailDate: sailDate
    }
    triggerCustomGaEvent('equoteSearch-setSailDetails', previewDetailsData)
  }

  renderCabinTableAccordion = () => {
    const { activePackage } = this.props

    let cabinTableData = []

    if (!_.isEmpty(activePackage.tabularPricing)) {
      const possibleStateRoomKeys = ['I', 'O', 'B', 'D']
      possibleStateRoomKeys.forEach(pricingKey => {
        if (
          activePackage.tabularPricing[pricingKey] &&
          activePackage.tabularPricing[pricingKey].pricing &&
          Object.keys(activePackage.tabularPricing[pricingKey].pricing)
            .length &&
          activePackage.tabularPricing[pricingKey].superCategoryData &&
          activePackage.tabularPricing[pricingKey].superCategoryData
            .bestGuestCountRate
        ) {
          const stateKey =
            pricingKey === 'I'
              ? 'interiorOpen'
              : pricingKey === 'O'
              ? 'oceanviewOpen'
              : pricingKey === 'B'
              ? 'balconyOpen'
              : pricingKey === 'D'
              ? 'deluxeOpen'
              : ''

          const toggleFunction =
            pricingKey === 'I'
              ? this.handleToggleEditInterior
              : pricingKey === 'O'
              ? this.handleToggleEditOcenview
              : pricingKey === 'B'
              ? this.handleToggleEditBalcony
              : pricingKey === 'D'
              ? this.handleToggleEditDeluxe
              : null
          cabinTableData.push(
            <EQuoteCabinsTableAccordion
              key={pricingKey}
              activePackage={activePackage}
              currentCategoryData={activePackage.tabularPricing[pricingKey]}
              isEditOpen={this.state[stateKey]}
              handleToggleEdit={toggleFunction}
            />
          )
        }
      })
    }

    return cabinTableData
  }

  render() {
    const {
      activePackage,
      confirmationLabelsData,
      handleClosePackageDetailsModal,
      detailsModalVisible,
      showingLandPackages,
      allActivePackages
    } = this.props
    return (
      <>
        <div className={'equote__cabins-table-container active'}>
          <EQuoteCabinsHeader
            activePackage={activePackage}
            handleOpenPackageDetailsModal={this.handleOpenPackageDetailsModal}
          />

          {showingLandPackages ? (
            <EQuoteCabinsLandProgramsSelection
              activePackage={activePackage}
              allActivePackages={allActivePackages}
            />
          ) : (
            <div className="equote__cabins-table-content">
              <div className="equote__cabins-table-content--header">
                <h6>{confirmationLabelsData.super_cat_section_title}</h6>
                <p>{confirmationLabelsData.super_cat_section_body}</p>
              </div>
              <div className="equote__cabins-table-content--instructions">
                <p className="u-remove-margin">
                  Use these options to select specific categories
                </p>
              </div>
              <div className="equote__cabins-table-accordions">
                {this.renderCabinTableAccordion()}
              </div>

              <EQuoteSearchDisclaimer brand={activePackage.brand} />
            </div>
          )}
        </div>
        {detailsModalVisible && (
          <EQuotePackageDetailsContainer
            activeDetailsData={{
              sailDate: activePackage.departure,
              ...activePackage
            }}
            isCruiseTour={!activePackage.justACruise}
            handleClosePackageDetailsModal={handleClosePackageDetailsModal}
            detailsModalVisible={detailsModalVisible}
          />
        )}
      </>
    )
  }
}

export default EQuoteCabinsTableContainer
