import React, { Component } from 'react'
import { connect } from 'react-redux'

import Modal from 'react-modal'

import {
  setActiveBrand,
  setActiveRegion,
  setActiveStartDate
} from '../../../actions/setActiveSearchFilters'

import updateSearchParams from '../../../utilities/updateSearchParams'
import setActiveStep from '../../../actions/setActiveStepActions'
import fetchPackages from '../../../actions/fetchPackages'

import triggerCustomGaEvent from '../../../utilities/triggerCustomGaEvent'

import closeIcon from '../../../assets/icons/close.svg'
import destinationIcon from '../../../assets/icons/destination.svg'
import arrowRight from '../../../assets/icons/arrow-right.svg'

import '../../../styles/scss/components/equote-search-filter-destination-modal.scss'

import moment from 'moment'
import { getDestinationsData } from '../../../sessionStorage/getDestinationsData'
let _ = require('lodash')

class EQuoteSearchFilterDestinationModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  // Submit quick destination query
  submitQuickDestinationQuery(destination) {
    const {
      activeSearchFilterData,
      handleActiveBrand,
      handleActiveDestination,
      handleActiveStartDate,
      handleSubmitQueryPackages,
      loyaltyData
    } = this.props

    const brands = 'C,R',
      startDate = moment().format('YYYY-MM-DD'),
      destinationQuery = {
        brand: brands,
        bookingType: activeSearchFilterData.bookingType,
        count: '150',
        cruiseType: activeSearchFilterData.cruiseType,
        currency: activeSearchFilterData.currency,
        startDate: startDate,
        region: destination,
        sso: activeSearchFilterData.sso
      }

    handleActiveBrand(brands)
    handleActiveDestination(destination)
    handleActiveStartDate(startDate)
    updateSearchParams(destinationQuery)
    handleSubmitQueryPackages()

    // Dispatch initial GA events
    triggerCustomGaEvent('equoteSearch-destination', {
      ...destinationQuery,
      ...loyaltyData
    })
  }

  renderFeaturedDestinations = () => {
    const { destinations } = this.props
    const featureDestinationsData = []

    if (!_.isEmpty(destinations)) {
      let featuredDestinations = destinations.filter(
        destinations => destinations.is_popular === 1
      )

      for (const destination in featuredDestinations) {
        const currentDestination = featuredDestinations[destination]

        const hasImage = Boolean(
          currentDestination.image &&
            currentDestination.image[0] &&
            currentDestination.image[0].file
        )

        const hasImageSmall = Boolean(
          currentDestination.image &&
            currentDestination.image[0] &&
            currentDestination.image[0].file &&
            currentDestination.image[0].sizes
        )

        if (currentDestination && currentDestination.is_active) {
          featureDestinationsData.push(
            <div
              key={'destination-' + currentDestination.destination_code}
              className="equote__search-featured-destination"
              onClick={() =>
                this.submitQuickDestinationQuery(
                  currentDestination.destination_code
                )
              }
              tabIndex={0}
            >
              <div
                className="equote__search-featured-destination-image"
                style={{
                  backgroundImage: hasImageSmall
                    ? 'url(' +
                      currentDestination.image[0].sizes['email-trip-image'] +
                      ')'
                    : hasImage
                    ? 'url(' + currentDestination.image[0].file + ')'
                    : ''
                }}
              >
                {hasImage && (
                  <img
                    src={
                      currentDestination.image[0].sizes[
                        'itineraries-and-destinations'
                      ]
                    }
                    className="u-element-remove"
                    alt={
                      currentDestination.caption
                        ? currentDestination.currentDestination.caption
                        : 'Featured Destination Image'
                    }
                  />
                )}
              </div>

              <div className="equote__search-featured-destination-content">
                {currentDestination.name ? (
                  <h6>{currentDestination.name}</h6>
                ) : null}

                {currentDestination.body ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `${currentDestination.body}`
                    }}
                  />
                ) : (
                  <p />
                )}

                <div className="equote__search-featured-destination-btn">
                  <span>See Cruises</span>
                  <img
                    src={arrowRight}
                    alt={
                      currentDestination.name
                        ? 'See ' + currentDestination.name + ' Cruises'
                        : 'See Cruises'
                    }
                  />
                </div>
              </div>
            </div>
          )
        }
      }

      if (!_.isEmpty(featureDestinationsData)) {
        return (
          <div className="equote__search-featured-destinations">
            {featureDestinationsData}
          </div>
        )
      }
    }
  }

  renderBasicDestinations = () => {
    const { destinations } = this.props
    const basicDestinationsData = []

    if (!_.isEmpty(destinations)) {
      let basicDestinations = destinations.filter(
        destinations => destinations.is_popular !== '1'
      )

      for (const destination in basicDestinations) {
        const currentDestination = basicDestinations[destination]
        if (
          currentDestination &&
          currentDestination.name &&
          currentDestination.destination_code &&
          currentDestination.is_active
        ) {
          basicDestinationsData.push(
            <button
              key={`destination-${
                currentDestination.destination_code
              }-${destination}`}
              className="equote__search-basic-destination btn"
              onClick={() =>
                this.submitQuickDestinationQuery(
                  currentDestination.destination_code
                )
              }
              tabIndex={0}
            >
              <span className="equote__search-basic-destination-button-inner">
                <span>{currentDestination.name}</span>
                <img src={arrowRight} alt={currentDestination.name} />
              </span>
            </button>
          )
        }
      }

      if (!_.isEmpty(basicDestinationsData)) {
        return (
          <div className="equote__search-basic-destinations">
            <div className="equote__search-basic-destinations-title">
              <h6 className="u-small-title">All Destinations</h6>
            </div>
            {basicDestinationsData}
          </div>
        )
      }
    }
  }

  render() {
    const { handleCloseDestinationModal, destinationModalVisible } = this.props
    return (
      <Modal
        className={{
          base: 'equote__search-filters-destinations-modal u-modal',
          afterOpen: 'u-modal--visible',
          beforeClose: 'u-modal--close',
          ariaHideApp: false
        }}
        overlayClassName={{
          base: 'u-fade',
          afterOpen: 'u-fade--visible',
          beforeClose: 'u-fade--visible'
        }}
        isOpen={destinationModalVisible}
        onRequestClose={handleCloseDestinationModal}
        contentLabel="eQuote Date Range Modal"
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        htmlOpenClassName="ReactModal__Html--open"
      >
        <div className="u-modal__header equote__search__modal-header">
          <div className="equote__search__title-container">
            <div className="equote__search-filter-icon">
              <img src={destinationIcon} alt="Ship icon" />
            </div>
            <h6 className="u-small-title equote__search-filter-title">
              Popular Destinations
            </h6>
          </div>
          <div
            className="u-modal__close equote__search-filter-close"
            onClick={handleCloseDestinationModal}
            tabIndex={0}
          >
            Close{' '}
            <img
              className="u-modal__close-icon equote__search-filter-close-icon"
              src={closeIcon}
              alt="eQuote Date Range Close"
            />
          </div>
        </div>
        <div className="u-modal__body">
          {this.renderFeaturedDestinations()}
          {this.renderBasicDestinations()}
        </div>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    destinations: getDestinationsData(),
    activeSearchFilterData: state.activeSearchFilterData,
    loyaltyData: state.loyaltyData
  }
}

const mapDispatchToProps = dispatch => ({
  handleActiveBrand(brand) {
    return dispatch(setActiveBrand(brand))
  },
  handleActiveDestination(destination) {
    return dispatch(setActiveRegion(destination))
  },
  handleActiveStartDate(startDate) {
    return dispatch(setActiveStartDate(startDate))
  },
  handleSubmitQueryPackages() {
    dispatch(setActiveStep(1))
    return dispatch(fetchPackages())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteSearchFilterDestinationModal)
