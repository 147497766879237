import React, { useEffect } from 'react'
import MaterialTable, { MTableHeader, MTableBodyRow } from 'material-table'
import FirstPageArrow from '../../../assets/icons/paginate-first-page.svg'
import PrevPage from '../../../assets/icons/pagination-previous-page.svg'
import NextPage from '../../../assets/icons/pagination-next-page.svg'
import LastPage from '../../../assets/icons/pagination-last-page.svg'
import getArrayElementsByClass from '../../../utilities/getArrayElementsByClass'

const EQuoteSearchPriceTable = React.forwardRef((props, ref) => {
  const { tableColumns, headerHeight, destinationCellWidth, data } = props
  let shouldAddScrollEventListener = true

  const simulateClickOn = element => {
    const event = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    })
    element.dispatchEvent(event)
  }
  const onScrollPropagateClick = () => {
    if (
      ref &&
      ref.current.getBoundingClientRect().top <= -56 &&
      shouldAddScrollEventListener
    ) {
      //Propagate click on dates header
      const movableHeader = getArrayElementsByClass(
        '.equote__search-price-table-mock-destination-header th'
      )

      const fixedHeader = getArrayElementsByClass(
        '#equote__search-price-table-container th'
      )

      movableHeader &&
        movableHeader.forEach((th, index) => {
          th.firstChild.childNodes[1].style.opacity = 0
          th.firstChild.addEventListener('click', e => {
            const event = new MouseEvent('click', {
              view: window,
              bubbles: true,
              cancelable: true
            })
            fixedHeader[index].firstChild.dispatchEvent(event)
          })
        })
      shouldAddScrollEventListener = false
    } else if (ref && ref.current.getBoundingClientRect().top >= -56) {
      shouldAddScrollEventListener = true
    }
  }

  const propagateClickToTable = () => {
    //Propagate click on destination header
    const destinationButton = ref.current.querySelector(
      '#equote__search-price-table-container th span'
    )
    simulateClickOn(destinationButton)

    window.addEventListener('scroll', onScrollPropagateClick)
  }

  useEffect(() => {
    //Sort default by destination
    propagateClickToTable()

    return () => {
      window.removeEventListener('scroll', onScrollPropagateClick)
    }
  }, [])

  const onTableChange = () => {
    const resultTable = document.querySelector(
      '.equote__sailings-search-price-table'
    )
    resultTable &&
      resultTable.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
  }

  return (
    <MaterialTable
      onOrderChange={onTableChange}
      style={{
        paddingLeft: '200px'
      }}
      columns={tableColumns}
      data={data}
      components={{
        Container: props => (
          <div
            ref={ref}
            className="equote__sailings-search-price-table u-table"
          >
            <div
              className="equote__search-price-table-mock-destination-header"
              style={{
                height: headerHeight,
                width: destinationCellWidth
              }}
            >
              <p onClick={propagateClickToTable}>Destination</p>
              <div
                className="equote__search-price-table-fixed-destination-header"
                style={{
                  height: headerHeight,
                  width: destinationCellWidth
                }}
              >
                <p onClick={propagateClickToTable}>Destination</p>
              </div>
            </div>

            <div id="equote__search-price-table-container">
              {props.children}
            </div>
          </div>
        ),
        Row: props => {
          return (
            <MTableBodyRow
              className={`equote__sailings-search-price--table-body-row primary-row ${
                Number.isInteger((props.index + 1) / 2)
                  ? 'secondary-background'
                  : ''
              }`}
              id={`destination-${props.data.tableData.id}`}
              {...props}
            />
          )
        },
        Header: props => {
          return <MTableHeader {...props} />
        }
      }}
      options={{
        paging: false,
        pageSize: 20,
        pageSizeOptions: [5, 20, 30, 50],
        showEmptyDataSourceMessage: true,
        toolbar: false,
        // filtering: true,
        // paginationType: 'stepped'
        emptyRowsWhenPaging: false
      }}
      icons={{
        FirstPage: () => <img src={FirstPageArrow} alt="first page" />,
        PreviousPage: () => <img src={PrevPage} alt="previous page" />,
        NextPage: () => <img src={NextPage} alt="next page" />,
        LastPage: () => <img src={LastPage} alt="last page" />
      }}
    />
  )
})

export default EQuoteSearchPriceTable
