export const SET_ACTIVE_PACKAGE = 'SET_ACTIVE_PACKAGE'

export const addPackage = (packageKey, stateKey) => ({
  type: SET_ACTIVE_PACKAGE,
  payload: {
    packageKey,
    stateKey
  }
})

export default function setActivePackage(packageKey, stateKey) {
  return (dispatch) => {
    dispatch(addPackage(packageKey, stateKey))
  }
}
