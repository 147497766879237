import 'url-search-params-polyfill'
import { formatGuestCountPricing } from '../utilities/formatPackages'
export const RECEIVE_UNSUPPORTED_QUALIFIER_PRICE =
  'RECEIVE_UNSUPPORTED_QUALIFIER_PRICE'
export const RECEIVE_UNSUPPORTED_QUALIFIER_PRICE_ERROR =
  'RECEIVE_UNSUPPORTED_QUALIFIER_PRICE_ERROR'

export const receiveUnsupportedQualifierPricingError = (
  stateKey,
  sailing
) => dispatch => {
  const { sailDate, id } = sailing
  const payload = {
    stateKey,
    sailDate,
    id
  }

  dispatch({
    type: RECEIVE_UNSUPPORTED_QUALIFIER_PRICE_ERROR,
    payload
  })
}

export const receiveUnsupportedQualifierPricing = (stateKey, sailing) => (
  dispatch,
  getState
) => {
  const { sailDate, id, pricing, brand } = sailing
  const guestCount = getState().activeSearchFilterData.guestCount
  const formattedPricing = pricing
    ? formatGuestCountPricing(pricing, guestCount, brand)
    : { isSoldOut: true }

  const payload = {
    stateKey,
    sailDate,
    id,
    pricing: formattedPricing
  }

  dispatch({
    type: RECEIVE_UNSUPPORTED_QUALIFIER_PRICE,
    payload
  })
}
