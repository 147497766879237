export const states = [
	{"value": "AL", "name": "Alabama", "country": "US"},
	{"value": "AK", "name": "Alaska", "country": "US"},
	{"value": "AZ", "name": "Arizona", "country": "US"},
	{"value": "AR", "name": "Arkansas", "country": "US"},
	{"value": "CA", "name": "California", "country": "US"},
	{"value": "CO", "name": "Colorado", "country": "US"},
	{"value": "CT", "name": "Connecticut", "country": "US"},
	{"value": "DC", "name": "Washington D.C.", "country": "US"},
	{"value": "DE", "name": "Delaware", "country": "US"},
	{"value": "FL", "name": "Florida", "country": "US"},
	{"value": "GA", "name": "Georgia", "country": "US"},
	{"value": "HI", "name": "Hawaii", "country": "US"},
	{"value": "ID", "name": "Idaho", "country": "US"},
	{"value": "IL", "name": "Illinois", "country": "US"},
	{"value": "IN", "name": "Indiana", "country": "US"},
	{"value": "IA", "name": "Iowa", "country": "US"},
	{"value": "KS", "name": "Kansas", "country": "US"},
	{"value": "KY", "name": "Kentucky", "country": "US"},
	{"value": "LA", "name": "Louisiana", "country": "US"},
	{"value": "ME", "name": "Maine", "country": "US"},
	{"value": "MD", "name": "Maryland", "country": "US"},
	{"value": "MA", "name": "Massachusetts", "country": "US"},
	{"value": "MI", "name": "Michigan", "country": "US"},
	{"value": "MN", "name": "Minnesota", "country": "US"},
	{"value": "MS", "name": "Mississippi", "country": "US"},
	{"value": "MO", "name": "Missouri", "country": "US"},
	{"value": "MT", "name": "Montana", "country": "US"},
	{"value": "NE", "name": "Nebraska", "country": "US"},
	{"value": "NV", "name": "Nevada", "country": "US"},
	{"value": "NH", "name": "New Hampshire", "country": "US"},
	{"value": "NJ", "name": "New Jersey", "country": "US"},
	{"value": "NM", "name": "New Mexico", "country": "US"},
	{"value": "NY", "name": "New York", "country": "US"},
	{"value": "NC", "name": "North Carolina", "country": "US"},
	{"value": "ND", "name": "North Dakota", "country": "US"},
	{"value": "OH", "name": "Ohio", "country": "US"},
	{"value": "OK", "name": "Oklahoma", "country": "US"},
	{"value": "OR", "name": "Oregon", "country": "US"},
	{"value": "PA", "name": "Pennsylvania", "country": "US"},
	{"value": "RI", "name": "Rhode Island", "country": "US"},
	{"value": "SC", "name": "South Carolina", "country": "US"},
	{"value": "SD", "name": "South Dakota", "country": "US"},
	{"value": "TN", "name": "Tennessee", "country": "US"},
	{"value": "TX", "name": "Texas", "country": "US"},
	{"value": "UT", "name": "Utah", "country": "US"},
	{"value": "VT", "name": "Vermont", "country": "US"},
	{"value": "VA", "name": "Virginia", "country": "US"},
	{"value": "WA", "name": "Washington", "country": "US"},
	{"value": "WV", "name": "West Virginia", "country": "US"},
	{"value": "WI", "name": "Wisconsin", "country": "US"},
	{"value": "WY", "name": "Wyoming", "country": "US"},
	{"value": "AS", "name": "American Samoa", "country": "US"},
	{"value": "GU", "name": "Guam", "country": "US"},
	{"value": "MP", "name": "Northern Mariana Islands", "country": "US"},
	{"value": "PR", "name": "Puerto Rico", "country": "US"},
	{"value": "UM", "name": "United States Minor Outlying Islands", "country": "US"},
	{"value": "VI", "name": "Virgin Islands", "country": "US"},
]