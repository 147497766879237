import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import EQuotePackageDetailsRelatedSailingsSlider from './EQuotePackageDetailsRelatedSailingsSlider'
import '../../styles/scss/components/equote-package-details-related-sailings.scss'
import '../../styles/scss/base/table.scss'
import { fetchRelatedSailings } from '../../actions/fetchRelatedSailings'
import brandLoader from '../../assets/icons/brand-loader-final.gif'
import { getLabelsData } from '../../sessionStorage/getLabelsData'
import _ from 'lodash'

import EQuotePackageDetailsRelatedSailingsTable from './EQuotePackageDetailsRelatedSailingsTable'

const EQuotePackageRelatedSailings = props => {
  //delete content from last th for accessibility
  useEffect(() => {
    const lastHeaderColumn = document.querySelector(
      '.equote-package-related-sailings-table-container th:last-of-type'
    )
    lastHeaderColumn &&
      lastHeaderColumn.firstChild &&
      lastHeaderColumn.removeChild(lastHeaderColumn.firstChild)
  })

  // fetch related sailings
  useEffect(() => {
    if (!props.relatedSailings.length) {
      props.fetchRelatedSailings()
    }
  }, [])

  return (
    <div
      id="similar-sailings"
      className="equote-package-related-sailings-container "
    >
      <h1 className={`section-title ${props.brandClassName} u-text-center`}>
        Other Departure Dates
      </h1>
      {Boolean(props.relatedSailings.length) ? (
        <>
          <div className="equote-package-related-sailings-table-container">
            {/* For Large Screens up */}
            <EQuotePackageDetailsRelatedSailingsTable {...props} />
          </div>

          {/* For Medium screens down */}
          <EQuotePackageDetailsRelatedSailingsSlider {...props} />
        </>
      ) : (
        <div className="similar-sailings--no-results-text">
          {props.relatedSailingsDataIsLoading ? (
            <div className="u-loading-spinner">
              <img src={brandLoader} alt="loading..." />
            </div>
          ) : (
            <p>No related sailings</p>
          )}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const currentCurrency = state.activeSearchFilterData
    ? state.activeSearchFilterData.currency
    : null

  const labelsData = getLabelsData()

  let searchTermsBody =
    labelsData && labelsData.search ? labelsData.search.terms_body : null

  if (currentCurrency && currentCurrency !== 'USD' && searchTermsBody) {
    searchTermsBody = searchTermsBody.replace(/USD/gi, currentCurrency)
  }

  const relatedSailingData =
    state.relatedSailingsData.items[
      `${ownProps.packageData.id}${ownProps.packageData.sailDate}`
    ]

  return {
    searchTermsBody,
    relatedSailings: relatedSailingData ? relatedSailingData : [],
    relatedSailingsDataIsLoading: state.relatedSailingsData.isLoading
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const packageKey = `${ownProps.packageData.id}${
    ownProps.packageData.sailDate
  }`
  const filteredRelatedSailings = ownProps.relatedSailings.filter(
    sailing =>
      sailing.id !== ownProps.packageData.id &&
      sailing.sailDate !== ownProps.packageData.sailDate
  )
  //filteredRelatedSailings, ownProps.packageData.sailDate, ownProps.packageData.shipCode, ownProps.packageData.brand, packageKey
  const { sailDate, shipCode, brand, stateroomTypes } = ownProps.packageData

  return {
    fetchRelatedSailings() {
      dispatch(
        fetchRelatedSailings({
          relatedSailings: filteredRelatedSailings,
          sailDate,
          shipCode,
          brand,
          packageKey,
          stateroomTypes
        })
      )
    },
    destinationImage: _.get(ownProps.destinationImage, [
      0,
      'sizes',
      'user-agreement'
    ])
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuotePackageRelatedSailings)
