import React, { Component } from 'react'

var _ = require('lodash')

class EQuoteSearchFiltersFullFormDestination extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      shipDestinations,
      destinations,
      currentBrand,
      currentDestination,
      currentShip,
      handleSelectDestination
    } = this.props

    const renderDestination = []
    if (destinations) {
      // Render destinations based on brand and ship OR brand only OR show all
      if (
        currentBrand &&
        currentBrand !== 'C,R' &&
        currentShip &&
        currentShip !== '0'
      ) {
        let activeDestinations = [],
          activeDestinationIds = null

        for (const brandKey in shipDestinations) {
          const brand = shipDestinations[brandKey]

          // Locate ships based on active brand
          if (brandKey === currentBrand) {
            for (const shipKey in brand) {
              const ship = brand[shipKey]

              // Locate destinations based on active brand ships
              if (shipKey === currentShip) {
                activeDestinationIds = ship

                for (const activeDestinationKey in activeDestinationIds) {
                  const activeDestinationCode =
                      activeDestinationIds[activeDestinationKey],
                    destinationData = destinations.filter(
                      destination =>
                        String(destination.destination_code) ===
                        String(activeDestinationCode)
                    )
                  if (destinationData && destinationData[0]) {
                    activeDestinations.push(destinationData[0])
                  }
                }
              }
            }
          }
        }

        // Remove duplicates entries based on ship destinations
        const uniqueDestinations = _.uniqBy(
          activeDestinations,
          'destination_code'
        )

        for (const destination in uniqueDestinations) {
          // Render only active destinations
          if (uniqueDestinations[destination].is_active) {
            renderDestination.push(
              <option
                key={
                  'full-search-' +
                  uniqueDestinations[destination].destination_code +
                  '-' +
                  destination
                }
                value={uniqueDestinations[destination].destination_code}
              >
                {uniqueDestinations[destination].name}
              </option>
            )
          }
        }
      } else if (currentBrand && currentBrand !== 'C,R') {
        let activeDestinations = [],
          activeDestinationIds = null

        for (const brandKey in shipDestinations) {
          const brand = shipDestinations[brandKey]

          // Locate ships based on active brand
          if (brandKey === currentBrand) {
            for (const shipKey in brand) {
              const ship = brand[shipKey]

              // Locate destinations based on active brand ships
              activeDestinationIds = ship

              for (const activeDestinationKey in activeDestinationIds) {
                const activeDestinationCode =
                    activeDestinationIds[activeDestinationKey],
                  destinationData = destinations.filter(
                    destination =>
                      String(destination.destination_code) ===
                      String(activeDestinationCode)
                  )

                if (destinationData && destinationData[0]) {
                  activeDestinations.push(destinationData[0])
                }
              }
            }
          }
        }

        // Remove duplicates entries based on ship destinations
        const uniqueDestinations = _.uniqBy(
          activeDestinations,
          'destination_code'
        )
        for (const destination in uniqueDestinations) {
          // Render only active destinations
          if (uniqueDestinations[destination].is_active) {
            renderDestination.push(
              <option
                key={
                  'full-search-' +
                  uniqueDestinations[destination].destination_code +
                  '-' +
                  destination
                }
                value={uniqueDestinations[destination].destination_code}
              >
                {uniqueDestinations[destination].name}
              </option>
            )
          }
        }
      } else if (currentShip && currentShip !== '0') {
        let activeDestinations = [],
          activeDestinationIds = null

        for (const brandKey in shipDestinations) {
          const brand = shipDestinations[brandKey]

          // Locate ships based on active brand
          for (const shipKey in brand) {
            const ship = brand[shipKey]

            // Locate destinations based on active brand ships
            if (shipKey === currentShip) {
              activeDestinationIds = ship

              for (const activeDestinationKey in activeDestinationIds) {
                const activeDestinationCode =
                    activeDestinationIds[activeDestinationKey],
                  destinationData = destinations.filter(
                    destination =>
                      String(destination.destination_code) ===
                      String(activeDestinationCode)
                  )
                if (destinationData && destinationData[0]) {
                  activeDestinations.push(destinationData[0])
                }
              }
            }
          }
        }

        // Remove duplicates entries based on ship destinations
        const uniqueDestinations = _.uniqBy(
          activeDestinations,
          'destination_code'
        )
        for (const destination in uniqueDestinations) {
          // Render only active destinations
          if (uniqueDestinations[destination].is_active) {
            renderDestination.push(
              <option
                key={
                  'full-search-' +
                  uniqueDestinations[destination].destination_code +
                  '-' +
                  destination
                }
                value={uniqueDestinations[destination].destination_code}
              >
                {uniqueDestinations[destination].name}
              </option>
            )
          }
        }
      } else {
        for (const destination in destinations) {
          // Render only active destinations
          if (destinations[destination].is_active) {
            renderDestination.push(
              <option
                key={
                  'full-search-' +
                  destinations[destination].destination_code +
                  '-' +
                  destination
                }
                value={destinations[destination].destination_code}
              >
                {destinations[destination].name}
              </option>
            )
          }
        }
      }
    }

    return (
      <div className="equote__search-filters-form-field-input u-custom-select">
        <select
          name="destination"
          onChange={e => handleSelectDestination(e.target.value)}
          value={currentDestination}
          tabIndex={0}
          aria-labelledby="destinationToggleLabel"
        >
          <option value="">All Destinations</option>
          {renderDestination.sort((a, b) => {
            if (a.props.children < b.props.children) {
              return -1
            }
            if (a.props.children > b.props.children) {
              return 1
            }
            return 0
          })}
        </select>
        <span className="u-custom-select__arrow" />
      </div>
    )
  }
}

export default EQuoteSearchFiltersFullFormDestination
