import React, { useEffect, useState } from 'react'
import Slider from 'react-slick/lib'
import Modal from 'react-modal'
import { PrevArrow, NextArrow } from '../elements/CarouselArrows'

import landIcon from '../../assets/icons/land-package-icon.svg'
import '../../styles/scss/components/equote-package-details-land-program.scss'
import moment from 'moment'
import closeIcon from '../../assets/icons/close.svg'

const renderNumberOfSlidesToShow = () => (window.innerWidth >= 1280 ? 2 : 1)

const EQuotePackageDetailsLandProgram = props => {
  const [numberOfSlidesToShow, setSlideCount] = useState(() =>
    renderNumberOfSlidesToShow()
  )

  const handleResize = () => {
    setSlideCount(() => renderNumberOfSlidesToShow())
  }

  const [activeDayDetails, setActiveDayDetails] = useState(null)

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return function cleanup() {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const settings = {
    lazyLoad: 'progressive',
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: numberOfSlidesToShow,
    swipeToSlide: true,
    prevArrow: (
      <PrevArrow {...props} numberOfSlidesToShow={numberOfSlidesToShow} />
    ),
    nextArrow: (
      <NextArrow {...props} numberOfSlidesToShow={numberOfSlidesToShow} />
    )
  }
  const { landProgramData } = props
  return (
    <div className="equote__package-details--land-programs">
      <div className="equote__package-details--land-programs--header">
        <h1 className="equote__package-details--land-programs--header--title">
          Land Program Details
        </h1>

        {Object.values(landProgramData).map(landProgram => {
          return (
            <div
              className={'equote__package-details--land-program'}
              key={landProgram.packageCode}
            >
              <p className="equote__package-details--land-program--header--sub-title">
                {landProgram.title}
                <span className="equote__package-details--land-program--header--pre-post-flag">
                  <span>
                    {landProgram.prePostTagName}
                    -Cruise Land Program
                  </span>
                  <img src={landIcon} alt="Land Program" />
                </span>
              </p>
              <p className="equote__package-details--land-program--header--date">
                Beginning{' '}
                {moment(landProgram.tourStartDate, 'YYYY-MM-DD').format(
                  'MMM DD YYYY'
                )}
              </p>

              <div className="equote__package-details--land-program--slider-container">
                <Slider {...settings}>
                  {landProgram.itinerary.map((day, i) => {
                    return (
                      <div
                        className="equote__package-details--land-program--slide"
                        key={`land-program-day-slide-${i}`}
                      >
                        <div className="equote__package-details--land-program--slide--header">
                          <p>
                            {moment(day.date, 'YYYY-MM-DD').format(
                              'dddd, MMMM Do, YYYY'
                            )}
                          </p>
                          <div className="equote__package-details--land-program--slide--header-image-container">
                            <img src={landIcon} alt="Land Program" />
                          </div>
                        </div>
                        <div className="equote__package-details--land-program--slide--body">
                          <div
                            className="equote__package-details--land-program--slide--img-container"
                            style={{
                              backgroundImage:
                                day.image && day.image.src
                                  ? `url(${day.image.src})`
                                  : ''
                            }}
                          >
                            {day.image && day.image.src ? (
                              <img
                                src={day.image.src}
                                alt={day.image.alt || day.description}
                                style={{ display: 'none' }}
                              />
                            ) : null}
                          </div>
                          <div className="equote__package-details--land-program--slide--details">
                            <h4 className="equote__package-details--land-program--slide--day">
                              Day {day.day}
                            </h4>
                            <p className="equote__package-details--land-program--slide--description">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html:
                                    day.description.length < 200
                                      ? day.description
                                      : day.description.slice(0, 200)
                                }}
                              />
                              {day.description.length >= 200 && (
                                <>
                                  ...
                                  <button
                                    onClick={() => setActiveDayDetails(day)}
                                  >
                                    read more
                                  </button>
                                </>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </Slider>
              </div>
            </div>
          )
        })}
      </div>
      {activeDayDetails && (
        <DayDescriptionModal
          day={activeDayDetails}
          onRequestClose={() => setActiveDayDetails(null)}
        />
      )}
    </div>
  )
}

export default EQuotePackageDetailsLandProgram

const DayDescriptionModal = ({ day, onRequestClose }) => (
  <Modal
    isOpen={Boolean(day)}
    onRequestClose={onRequestClose}
    ariaHideApp={false}
    className={{
      base: 'u-modal',
      afterOpen: 'u-modal--visible',
      beforeClose: 'u-modal--close'
    }}
    overlayClassName={{
      base: 'u-fade',
      afterOpen: 'u-fade--visible',
      beforeClose: 'u-fade--visible'
    }}
    style={{
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '32px'
      }
    }}
  >
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <h4>
          Day {day.day}
          {' - '}
          {moment(day.date, 'YYYY-MM-DD').format('dddd, MMMM Do, YYYY')}
        </h4>
        <div className="u-modal__close" onClick={onRequestClose} tabIndex={0}>
          Close{' '}
          <img
            className="u-modal__close-icon"
            src={closeIcon}
            alt="eQuote Ship Modal Close"
          />
        </div>
      </div>
      <p dangerouslySetInnerHTML={{ __html: day.description }} />
    </div>
  </Modal>
)
