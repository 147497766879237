import axios from 'axios'
import 'url-search-params-polyfill'

import triggerCustomGaEvent from '../utilities/triggerCustomGaEvent'

import cleanObject from '../utilities/cleanObject'
var _ = require('lodash')

export const SUBMIT_EDIT_AGENT_BEGIN = 'SUBMIT_EDIT_AGENT_BEGIN'
export const SUBMIT_EDIT_AGENT_SUCCESS = 'SUBMIT_EDIT_AGENT_SUCCESS'
export const SUBMIT_EDIT_AGENT_FAILURE = 'SUBMIT_EDIT_AGENT_FAILURE'

export const submitEditAgentBegin = () => ({
  type: SUBMIT_EDIT_AGENT_BEGIN
})

export const submitEditAgentSuccess = quote => ({
  type: SUBMIT_EDIT_AGENT_SUCCESS,
  payload: quote
})

export const submitEditAgentError = error => ({
  type: SUBMIT_EDIT_AGENT_FAILURE,
  payload: { error }
})

// Handle Brands Fetch & Functionality
export default function submitEditAgent() {
  return (dispatch, getState) => {
    const editAgentData = getState().editAgentData,
      agent = getState().ssoData.agent,
      agency = getState().ssoData.agency
    if (
      !_.isEmpty(editAgentData) &&
      !_.isEmpty(agent.rccl_id) &&
      !_.isEmpty(agency.agency_id)
    ) {
      const agentEdits = {
        updateParameters: {
          agent: {
            rccl_id: agent.rccl_id,
            name: editAgentData.agentName,
            email: editAgentData.agentEmail
          },
          agency: {
            agency_id: agency.agency_id,
            name: editAgentData.agencyName,
            phone: editAgentData.agencyPhone,
            logo: agency.logo,
            website: editAgentData.agencyWebsite
          }
        }
      }
      let formattedAgentEdits = cleanObject(agentEdits)

      // Formatted API path
      const quoteApi = process.env.REACT_APP_FLOW_ENDPOINT_URL + 'agent'

      // Dispatch GA events
      triggerCustomGaEvent('editAgentUpdate', formattedAgentEdits)

      dispatch(submitEditAgentBegin())

      axios
        .post(quoteApi, JSON.stringify(formattedAgentEdits), {
          headers: {
            Accept: 'application/ld+json',
            'Content-Type': 'application/ld+json'
          }
        })
        .then(response => {
          const agent = response
          if (
            agent.status >= 200 &&
            agent.status <= 300 &&
            agent.data.status >= 200 &&
            agent.data.status <= 300 &&
            agent.data.data.image.status >= 200 &&
            agent.data.data.image.status <= 300 &&
            !_.isEmpty(agent.data.data)
          ) {
            const formattedAgent = cleanObject(agent.data.data)
            dispatch(submitEditAgentSuccess(formattedAgent))
          } else {
            if (
              (agent.status < 200 || agent.status > 300) &&
              (agent.data.status < 200 || agent.data.status > 300)
            ) {
              throw new Error(agent.statusText)
            } else if (
              (agent.data.data.image.status > 300 ||
                agent.data.data.image.status < 200) &&
              agent.data.data.image.message
            ) {
              throw new Error(agent.data.data.image.message)
            }
          }
        })
        .catch(error => {
          dispatch(submitEditAgentError(error))
        })
    }
  }
}
