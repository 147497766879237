export const SET_PRICE_TABLE_BRAND_FILTER = 'SET_PRICE_TABLE_BRAND_FILTER';
export const SET_PRICE_TABLE_CATEGORY_FILTER = "SET_PRICE_TABLE_CATEGORY_FILTER";
export const SET_PRICE_TABLE_DESTINATION_FILTER = "SET_PRICE_TABLE_DESTINATION_FILTER"
export const SET_PRICE_TABLE_CURRENCY_FILTER = "SET_PRICE_TABLE_CURRENCY_FILTER"


export const setActivePriceTableBrandFilter = brand => dispatch => {
  dispatch({
    type: SET_PRICE_TABLE_BRAND_FILTER,
    payload: brand,
  })
}

export const setActivePriceTableCategoryFilter = category => dispatch => {
  dispatch({
    type: SET_PRICE_TABLE_CATEGORY_FILTER,
    payload: category,
  })
}
export const setActivePriceTableDestinationFilter = destination => dispatch => {
  dispatch({
    type: SET_PRICE_TABLE_DESTINATION_FILTER,
    payload: destination,
  })
}
export const setActivePriceTableCurrencyFilter = currency => dispatch => {
  dispatch({
    type: SET_PRICE_TABLE_CURRENCY_FILTER,
    payload: currency,
  })
}
