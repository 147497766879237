import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import '../../styles/scss/components/equote-footer.scss'
import EQuoteTermsModal from './EQuoteTermsModal'
import { getLabelsData } from '../../sessionStorage/getLabelsData'

class EQuoteHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      modalTitle: null,
      modalContent: null
    }
  }

  render() {
    const { labelsData } = this.props
    let privacyTitle = null,
      privacyContent = null
    if (
      labelsData &&
      labelsData['privacy-policy'] &&
      labelsData['privacy-policy'].title
    ) {
      privacyTitle = labelsData['privacy-policy'].title
    }

    if (
      labelsData &&
      labelsData['privacy-policy'] &&
      labelsData['privacy-policy'].content
    ) {
      privacyContent = labelsData['privacy-policy'].content
    }

    return (
      <div className="equote__footer">
        <div className="equote__footer-container u-content-container">
          <div className="equote__footer-rights">
            &copy; {moment().format('YYYY')} Royal Caribbean Cruises Ltd. Ships’
            registry: Bahamas, Malta, and Ecuador.
          </div>

          <div className="equote__footer-links">
            <button
              className="equote__footer-link btn btn-text"
              onClick={
                privacyContent
                  ? () =>
                      this.setState({
                        modalIsOpen: true,
                        modalTitle: privacyTitle,
                        modalContent: privacyContent
                      })
                  : null
              }
            >
              Privacy Policy
            </button>
          </div>
        </div>

        {this.state.modalIsOpen && this.state.modalContent ? (
          <EQuoteTermsModal
            isOpen={this.state.modalIsOpen}
            onRequestClose={() => this.setState({ modalIsOpen: false })}
            title={this.state.modalTitle}
            content={this.state.modalContent}
          />
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {
    labelsData: getLabelsData()
  }
}

export default connect(mapStateToProps)(EQuoteHeader)
