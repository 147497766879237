export const REMOVE_ACTIVE_CABIN = 'REMOVE_ACTIVE_CABIN'

export const removeCabin = (packageIdentifier, activeCabinKey) => ({
  type: REMOVE_ACTIVE_CABIN,
  payload: { packageIdentifier, activeCabinKey }
})

export default function removeActiveCabin(packageIdentifier, activeCabinKey) {
  return dispatch => {
    dispatch(removeCabin(packageIdentifier, activeCabinKey))
  }
}
