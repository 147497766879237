export const filterImagesByFileName = (images, fileName) => {
  const filteredImages = []
  if (images && images.length) {
    for (const image of images) {
      if (!image) {
        continue
      }
      if (image.sizes && fileName && image.sizes[fileName]) {
        filteredImages.push({
          ...image,
          displayImage: image.sizes[fileName]
        })
      } else {
        filteredImages.push({
          ...image,
          displayImage: image.file
        })
      }
    }
  }

  return filteredImages
}
