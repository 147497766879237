/*
 * This is used for various tables throughout the app and will return an object keys by super categoryKey
 * {
 *   I: {
 *    superCategoryData: {}
 *    pricing: {}
 *   }
 *   B: {...}
 *   ...
 * }
 * */

export default function getCruiseTabularPricing(state, pricing) {
  const guestCount = Number(state.activeSearchFilterData.guestCount)

  return Object.entries(pricing).reduce((obj, [superCatKey, superCategory]) => {
    obj[superCatKey] = {
      superCategoryData: {
        category: superCategory.category,
        categoryName: superCategory.categoryName,
        stateroomType: superCategory.stateroomType,
        stateroomDescription: superCategory.stateroomDescription,
        bestGuestCountRate: superCategory.bestGuestCountRate
      },
      pricing: {
        pricingHeader: {
          // these are need for the tables to render correctly
          category: '',
          categoryName: ''
        }
      }
    }

    // When formatting the api response for redux we checked if third and fourth pricing was the same.
    // If so we only display '3rd/4th' rather than 3rd and 4th, however our tables need to know this on
    // a super category level or we can end up with too many columns and empty row
    const superCatRequiresThirdAndFourthPricingColumns = Boolean(
      Number(guestCount) === 4 &&
        superCategory.availableStateRooms.some(availableStateRoom => {
          return availableStateRoom.additionalPricing[3]
        })
    )

    superCategory.availableStateRooms.forEach(stateRoom => {
      const refundabilityKey = stateRoom.isRefundable
        ? 'refundable'
        : 'nonRefundable'

      if (!obj[superCatKey].pricing[stateRoom.stateroomCategoryCode]) {
        obj[superCatKey].pricing[stateRoom.stateroomCategoryCode] = {
          category: stateRoom.stateroomCategoryCode,
          categoryName: stateRoom.stateroom.name,
          bestGuestCountRate: null // used to sort our rows
        }
      }

      if (
        !obj[superCatKey].pricing[stateRoom.stateroomCategoryCode]
          .bestGuestCountRate ||
        obj[superCatKey].pricing[stateRoom.stateroomCategoryCode]
          .bestGuestCountRate > stateRoom.perPersonAveragePrice
      ) {
        obj[superCatKey].pricing[
          stateRoom.stateroomCategoryCode
        ].bestGuestCountRate = stateRoom.perPersonAveragePrice
      }

      if (stateRoom.additionalPricing) {
        Object.entries(stateRoom.additionalPricing).forEach(
          ([priceKey, price]) => {
            let pricingKey = price.displayName
            if (
              Number(priceKey) === 4 &&
              price.displayName === '3rd/4th' &&
              superCatRequiresThirdAndFourthPricingColumns
            ) {
              pricingKey = '4th'

              // we need to create a 3rd column that is the same as the 4th.
              if (
                !obj[superCatKey].pricing.pricingHeader[
                  `${refundabilityKey}-3rd`
                ]
              ) {
                obj[superCatKey].pricing.pricingHeader[
                  `${refundabilityKey}-3rd`
                ] = {
                  displayName: '3rd',
                  order: 3
                }
              }

              obj[superCatKey].pricing[stateRoom.stateroomCategoryCode][
                `${refundabilityKey}-3rd`
              ] = {
                ...stateRoom.additionalPricing[4],
                perPersonCreditAmt: stateRoom.perPersonCreditAmt,
                isRefundable: stateRoom.isRefundable,
                order: 3,
                pricingDisplayName: '3rd',
                superCategoryCode: superCatKey,
                isSuperCategory: false,
                stateroomCategoryCode: stateRoom.stateroomCategoryCode
              }
            }

            if (
              !obj[superCatKey].pricing.pricingHeader[
                `${refundabilityKey}-${pricingKey}`
              ]
            ) {
              obj[superCatKey].pricing.pricingHeader[
                `${refundabilityKey}-${pricingKey}`
              ] = {
                displayName: pricingKey,
                order: priceKey // we use order later to sort our columns and rows
              }
            }

            obj[superCatKey].pricing[stateRoom.stateroomCategoryCode][
              `${refundabilityKey}-${pricingKey}`
            ] = {
              ...price,
              isRefundable: stateRoom.isRefundable,
              order: Number(priceKey),
              pricingDisplayName: pricingKey,
              superCategoryCode: superCatKey,
              isSuperCategory: false,
              stateroomCategoryCode: stateRoom.stateroomCategoryCode
            }

            if (
              !obj[superCatKey].pricing.pricingHeader[
                `${refundabilityKey}-${pricingKey}`
              ]
            ) {
              obj[superCatKey].pricing.pricingHeader[
                `${refundabilityKey}-${pricingKey}`
              ] = { displayName: pricingKey, order: Number(priceKey) }
            }
          }
        )
      }
    })

    return obj
  }, {})
}
