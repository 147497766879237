import { SET_CRUISE_TOURS } from '../actions/setCruiseTours'
import { CLEAR_PACKAGE_DATA } from '../actions/fetchPackages'

const initialState = {}

export default function setCruiseToursReducers(state = initialState, action) {
  switch (action.type) {
    case SET_CRUISE_TOURS:
      return {
        ...state,
        availablePackages: action.payload
      }
    case CLEAR_PACKAGE_DATA:
      return initialState
    default:
      return state
  }
}
