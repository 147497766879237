import { omit } from 'lodash'

import { SET_ACTIVE_PACKAGE } from '../actions/setActivePackage'
import { REMOVE_ACTIVE_PACKAGE } from '../actions/removeActivePackage'
import { RESET_ACTIVE_PACKAGES } from '../actions/resetQuoteFlow'
import { getPackagesDetails } from './fetchPackagesReducers'
import { CLEAR_PACKAGE_DATA } from '../actions/fetchPackages'
import { formatLandPackageDateDetails } from './fetchLandPackagesReducer'

const initialState = {}

export default function setActivePackagesReducers(
  state = initialState,
  action
) {
  switch (action.type) {
    case SET_ACTIVE_PACKAGE:
      // Set package manually
      return Object.assign({}, state, {
        [action.payload.packageKey]: action.payload.stateKey
      })

    case REMOVE_ACTIVE_PACKAGE:
      // Remove package by ID
      return omit(state, action.payload)

    case RESET_ACTIVE_PACKAGES:
    case CLEAR_PACKAGE_DATA:
      // Reset all packages
      return {}

    default:
      return state
  }
}

// Returns an object of selected packages with additional information,
// keyed by identifier (id-sailDate).

export const getAllActivePackagesDetails = state => {
  return Object.entries(state.activePackageData).reduce(
    (obj, [packageKey, packageData]) => {
      const {
        stateKey,
        cruiseIdentifier,
        isLandPackage,
        landPackageId
      } = packageData
      const packageDetails =
        state[stateKey] && state[stateKey].availablePackages
          ? state[stateKey].availablePackages[cruiseIdentifier]
          : null
      if (!packageDetails) {
        return obj
      }

      const activeLandProgramStateKey = isLandPackage
        ? `${cruiseIdentifier}-${landPackageId}`
        : cruiseIdentifier

      // we need two different objects here
      // one for selections made on the full sailings search table
      // and one for additional land programs added on the cabins page
      // We need the separated because selectedLandPackage affects our availableLandPackages
      // while additionallySelectedLandPackages does not
      const preSelectedLandPackage = {}

      const additionallySelectedLandPackages =
        state.activeLandPackages &&
        state.activeLandPackages[activeLandProgramStateKey]

      // if we selected from the land package list
      if (isLandPackage) {
        const landPackage = state.landPackagesData[landPackageId]

        const dateDetails = formatLandPackageDateDetails(
          landPackage,
          packageDetails
        )

        const { tourStartDate, itinerary } = dateDetails

        const landPackageType = landPackage.prePostTagName
        preSelectedLandPackage[landPackageType] = {
          ...landPackage,
          tourStartDate,
          itinerary
        }
      }

      // if we have available land packages we want to show
      const availableLandPackages =
        packageDetails.hasLandPackages &&
        packageDetails.landPackages &&
        packageDetails.landPackages.length
          ? packageDetails.landPackages.reduce(
              (obj, landPackageKey) => {
                // we don't want our already selected landPackages
                if (
                  Object.values(preSelectedLandPackage).some(
                    val => val.packageCode === landPackageKey
                  )
                ) {
                  return obj
                }
                const details = state.landPackagesData[landPackageKey]
                if (details) {
                  const dateDetails = formatLandPackageDateDetails(
                    details,
                    packageDetails
                  )

                  const { tourStartDate, itinerary } = dateDetails
                  const packageKey = details.prePostTagName
                  // we can only select one pre and one post. so if we already
                  // have one of those selected we won't show those options

                  if (
                    Object.keys(preSelectedLandPackage).some(
                      key => key === packageKey
                    )
                  ) {
                    return obj
                  }

                  if (!obj[packageKey]) {
                    obj[packageKey] = []
                  }

                  obj[packageKey].push({
                    ...details,
                    brand: packageDetails.brand,
                    tourStartDate,
                    itinerary
                  })
                }

                return obj
              },

              {}
            )
          : null

      const availableLandPackagesKeys = availableLandPackages
        ? Object.keys(availableLandPackages).sort(a => (a === 'pre' ? -1 : 1))
        : null

      const sortAvailableLandPackages = availableLandPackagesKeys
        ? availableLandPackagesKeys.reduce(
            (availablePackageObj, availableLandPackagesKey) => {
              availablePackageObj[availableLandPackagesKey] =
                availableLandPackages[availableLandPackagesKey]
              return availablePackageObj
            },
            {}
          )
        : null

      obj[packageKey] = {
        isLandPackage,
        landPackageIdentifier: isLandPackage
          ? `${packageDetails.identifier}-${landPackageId}`
          : null,
        ...getPackagesDetails(
          state,
          !packageDetails.justACruise,
          cruiseIdentifier,
          isLandPackage,
          isLandPackage ? `${packageDetails.identifier}-${landPackageId}` : null
        ),
        preSelectedLandPackage,
        additionallySelectedLandPackages,
        allSelectedLandPrograms: {
          ...preSelectedLandPackage,
          ...additionallySelectedLandPackages
        },
        availableLandPackages: sortAvailableLandPackages
      }

      return obj
    },
    {}
  )
}

export const getActivePackageKeys = state =>
  Object.keys(state.activePackageData)
