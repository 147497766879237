import React, { Component } from 'react'
import { connect } from 'react-redux'

import closeIcon from '../../assets/icons/close.svg'

import '../../styles/scss/components/equote-agent-details-drawer.scss'

class EQuoteAgentDetailsDrawer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const {
      modifiedSsoData,
      ssoData,
      isAgentDrawerOpen,
      handleToggleAgentDrawer
    } = this.props
    let agentName = null,
      agentEmail = null,
      agencyName = null,
      agencyPhone = null,
      agencyEmail = null,
      agencyWebsite = null,
      agencyLogo = null

    // Set Agent data edit VIA edit agent response, content hub or ESL
    if (
      modifiedSsoData &&
      modifiedSsoData.agent &&
      modifiedSsoData.agent.name
    ) {
      agentName = modifiedSsoData.agent.name
    } else if (ssoData && ssoData.agent && ssoData.agent.name) {
      agentName = ssoData.agent.name
    }

    if (
      modifiedSsoData &&
      modifiedSsoData.agent &&
      modifiedSsoData.agent.email
    ) {
      agentEmail = modifiedSsoData.agent.email
    } else if (ssoData && ssoData.agent && ssoData.agent.email) {
      agentEmail = ssoData.agent.email
    }

    // Set Agency data edit VIA edit agent response, content hub or ESL
    if (
      modifiedSsoData &&
      modifiedSsoData.agency &&
      modifiedSsoData.agency.name
    ) {
      agencyName = modifiedSsoData.agency.name
    } else if (ssoData && ssoData.agency && ssoData.agency.name) {
      agencyName = ssoData.agency.name
    }

    if (
      modifiedSsoData &&
      modifiedSsoData.agency &&
      modifiedSsoData.agency.email
    ) {
      agencyEmail = modifiedSsoData.agency.email
    } else if (ssoData && ssoData.agency && ssoData.agency.email) {
      agencyEmail = ssoData.agency.email
    }

    if (
      modifiedSsoData &&
      modifiedSsoData.agency &&
      modifiedSsoData.agency.phone
    ) {
      agencyPhone = modifiedSsoData.agency.phone
    } else if (ssoData && ssoData.agency && ssoData.agency.phone) {
      agencyPhone = ssoData.agency.phone
    }

    if (
      modifiedSsoData &&
      modifiedSsoData.agency &&
      modifiedSsoData.agency.logo
    ) {
      agencyLogo = modifiedSsoData.agency.logo
    } else if (ssoData && ssoData.agency && ssoData.agency.logo) {
      agencyLogo = ssoData.agency.logo
    }

    if (
      modifiedSsoData &&
      modifiedSsoData.agency &&
      modifiedSsoData.agency.website
    ) {
      agencyWebsite = modifiedSsoData.agency.website
    } else if (ssoData && ssoData.agency && ssoData.agency.website) {
      agencyWebsite = ssoData.agency.website
    }

    return (
      <div
        className={
          isAgentDrawerOpen
            ? 'equote__agent-details-drawer active'
            : 'equote__agent-details-drawer'
        }
      >
        <div className="equote__agent-details-drawer-navigation">
          <div
            className="u-modal__close"
            onClick={handleToggleAgentDrawer}
            tabIndex={0}
          >
            Close{' '}
            <img
              className="u-modal__close-icon"
              src={closeIcon}
              alt="eQuote Ship Modal Close"
            />
          </div>
        </div>

        <div className="equote__agent-details-data">
          {agencyLogo && (
            <div className="equote__agent-details-drawer-data-col equote__agent-details-drawer-data-image">
              <img
                src={agencyLogo}
                alt={
                  agencyName
                    ? agencyName + ' Brand Logo'
                    : 'Agency Placeholder Logo'
                }
              />
            </div>
          )}

          {agentName ? (
            <div className="equote__agent-details-drawer-data-col">
              <p className="u-remove-margin u-font-xxs title">Agent Name</p>
              <p className="u-remove-margin u-font-xs details">{agentName}</p>
            </div>
          ) : null}

          {agencyName ? (
            <div className="equote__agent-details-drawer-data-col">
              <p className="u-remove-margin u-font-xxs title">Agency Name</p>
              <p className="u-remove-margin u-font-xs details">{agencyName}</p>
            </div>
          ) : null}

          {agentEmail ? (
            <div className="equote__agent-details-drawer-data-col">
              <p className="u-remove-margin u-font-xxs title title">
                Agent Email
              </p>
              <p className="u-remove-margin u-font-xs details">{agentEmail}</p>
            </div>
          ) : agencyEmail ? (
            <div className="equote__agent-details-drawer-data-col">
              <p className="u-remove-margin u-font-xxs title title">
                Agency Email
              </p>
              <p className="u-remove-margin u-font-xs details">{agencyEmail}</p>
            </div>
          ) : null}

          {agencyPhone ? (
            <div className="equote__agent-details-drawer-data-col">
              <p className="u-remove-margin u-font-xxs title">Agency Phone</p>
              <a
                className="u-remove-margin u-font-xs details"
                href={'tel:' + agencyPhone}
              >
                {agencyPhone}
              </a>
            </div>
          ) : null}

          {agencyWebsite ? (
            <div className="equote__agent-details-drawer-data-col">
              <p className="u-remove-margin u-font-xxs title">Agency Website</p>
              <a
                className="u-remove-margin u-font-xs details"
                href={agencyWebsite}
                target="_blank"
                rel="noopener noreferrer"
              >
                {agencyWebsite}
              </a>
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    modifiedSsoData: state.modifiedSsoData
  }
}

const mapDispatchToProps = () => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteAgentDetailsDrawer)
