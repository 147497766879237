import { RECEIVE_LAND_PACKAGES } from '../actions/setLandPackages'
import { getAvailableCruises } from './fetchPackagesReducers'
import moment from 'moment'
import { CLEAR_PACKAGE_DATA } from '../actions/fetchPackages'

export default function(state = {}, action) {
  if (action.type === RECEIVE_LAND_PACKAGES) {
    return action.payload
  }

  if (action.type === CLEAR_PACKAGE_DATA) {
    return {}
  }

  return state
}

const dateFormat = 'YYYY-MM-DD'

// we need to match all possible cruise data with land program data for our search results.
export function getLandPackageCruiseList(state) {
  const allCruises = getAvailableCruises(state)
  const landPackages = state.landPackagesData

  if (
    !landPackages ||
    !Object.keys(landPackages).length ||
    !allCruises ||
    !Object.keys(allCruises).length
  )
    return null

  return Object.values(allCruises).reduce((obj, cruise) => {
    if (
      cruise.hasLandPackages &&
      cruise.hasOwnProperty('landPackages') &&
      cruise.landPackages &&
      cruise.landPackages.length
    ) {
      for (const landPackageKey of cruise.landPackages) {
        if (landPackages[landPackageKey]) {
          const landPackageIdentifier = `${cruise.identifier}-${landPackageKey}`
          const landPackageData = landPackages[landPackageKey]

          const dateDetails = formatLandPackageDateDetails(
            landPackageData,
            cruise
          )
          const { tourStartDate, itinerary } = dateDetails
          obj[landPackageIdentifier] = {
            ...landPackageData,
            id: landPackageData.packageCode,
            brand: cruise.brand,
            landPackageIdentifier,
            cruiseIdentifier: cruise.identifier,
            isPreCruiseTour: landPackageData.isPreCruiseTour,
            sailDate: cruise.sailDate,
            tourStartDate,
            tableDisplayDate: landPackageData.isPreCruiseTour
              ? moment(tourStartDate, dateFormat).format(dateFormat)
              : cruise.sailDate,
            cruiseName: cruise.description,
            destinationCode: cruise.destinationCode,
            shipCode: cruise.shipCode,
            sailingNights: cruise.sailingNights,
            landProgramType: landPackageData.prePostTagName,
            itinerary,
            totalNights: landPackageData.tourNights + cruise.sailingNights,
            cruiseData: cruise
          }
        }
      }
    }
    return obj
  }, {})
}

export function getLandPackageByIdentifier(state, identifier) {
  const allLandPrograms = getLandPackageCruiseList(state)
  return allLandPrograms ? allLandPrograms[identifier] : null
}
function formatTourStartDate(landPackage, cruise) {
  if (landPackage.isPreCruiseTour) {
    return moment(cruise.sailDate)
      .subtract(landPackage.tourNights, 'days')
      .format(dateFormat)
  }

  return moment(cruise.sailDate)
    .add(cruise.sailingNights, 'days')
    .format(dateFormat)
}

export function formatLandPackageDateDetails(landPackageData, cruise) {
  const tourStartDate = formatTourStartDate(landPackageData, cruise)

  const itinerary = landPackageData.itinerary
    ? landPackageData.itinerary.map(tourDay => {
        const daysToAdd = Number(tourDay.day) - 1 // doing it this way just in case they are out of order
        const date = moment(tourStartDate, dateFormat).add(daysToAdd, 'days')
        const displayDate = date.format('MMM DD YYYY')

        return {
          ...tourDay,
          displayDate,
          date: date.format('YYYY-MM-DD')
        }
      })
    : null

  return { tourStartDate, itinerary }
}
