import React, { Component } from 'react'
import ReactModal from 'react-modal'
import moment from 'moment'
import closeIcon from '../../assets/icons/close.svg'
import deleteIcon from '../../assets/icons/delete.svg'
import '../../styles/scss/components/equote-selected-package-list-modal.scss'

class EQuoteSelectedPackagesListModal extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    const hasPackages = Object.keys(this.props.activePackages).length
    const hadPackages = Object.keys(prevProps.activePackages).length

    if (Boolean(hadPackages && !hasPackages)) {
      if (this.props.activeStep > 1) {
        this.props.goBackToSearch()
      }
      this.props.onRequestClose()
    }
  }

  removePackage(packageObj) {
    const packageData = packageObj[1]

    if (Object.keys(this.props.activeCabinData).length) {
      for (const cabin of Object.entries(this.props.activeCabinData)) {
        const cabinData = cabin[1]
        if (
          cabinData.packageId === packageData.id &&
          cabinData.sailDate === packageData.departure
        ) {
          this.props.removeActiveCabin(cabin[0])
        }
      }

      /*
       * Scenarios
       * 1. removing future sailing
       * 2. removing past sailing
       * 3. removing current sailing  => removingCurrentCabinStep
       * */

      const totalCabinSteps = Object.keys(this.props.activePackages).length - 1
      let activeCabinStepId = this.props.cabinStepData.activeCabinId
      let cabinIndex = this.props.cabinStepData.activeCabin

      const removingCurrentCabinStep = activeCabinStepId === packageObj[0]
      if (removingCurrentCabinStep) {
        //  we need a new id, and new index
        // try to advance one package, or go back one,
        if (
          Object.keys(this.props.activeCabinData)[
            this.props.cabinStepData.activeCabin + 1
          ]
        ) {
          // advance one package
          // don't increment the index as we're removing an item.
          cabinIndex = this.props.cabinStepData.activeCabin
          // grab the next id
          activeCabinStepId = Object.keys(this.props.activeCabinData)[
            this.props.cabinStepData.activeCabin + 1
          ]
        } else if (
          Object.keys(this.props.activeCabinData)[
            this.props.cabinStepData.activeCabin - 1
          ]
        ) {
          // go back one
          // decrement the index as we are moving backwards
          cabinIndex = this.props.cabinStepData.activeCabin - 1
          // grab the previous id.
          activeCabinStepId = Object.keys(this.props.activeCabinData)[
            this.props.cabinStepData.activeCabin - 1
          ]
        } else {
          // we have nothing left.
          cabinIndex = 0
          activeCabinStepId = null
        }
      } else {
        const isEarlierPackage =
          Object.keys(this.props.activeCabinData).indexOf(packageObj[0]) <
          this.props.cabinStepData.activeCabin

        if (isEarlierPackage) {
          // decrement the index as we are removing an item before the current one
          cabinIndex = this.props.cabinStepData.activeCabin - 1
        }
      }

      this.props.setActiveCabinStep(
        totalCabinSteps,
        cabinIndex,
        activeCabinStepId
      )
    }
    this.props.removeActivePackage(packageObj[0])
  }

  renderPackageList() {
    const { activePackages, getBrandByCode } = this.props

    return Object.entries(activePackages).map(currentPackage => {
      const key = currentPackage[0]
      const packageData = currentPackage[1]
      const brandData = getBrandByCode(packageData.brand)
      const bestRate =
        packageData &&
        packageData.selectedStateRooms &&
        packageData.selectedStateRooms.bestGuestCountRate
          ? packageData.selectedStateRooms.bestGuestCountRate
          : null
      return (
        <div
          key={key}
          className="equote-selected-package-list-modal--package-list--item"
        >
          <div className="equote-selected-package-list-modal--package-list--item-header">
            <p className="u-remove-margin">{packageData.description}</p>
            <button
              className="btn "
              onClick={() => this.removePackage(currentPackage)}
            >
              <div className="equote-selected-package-list-modal--package-list--item-header--remove-btn--inner">
                <img alt="delete" src={deleteIcon} />
                <span>remove</span>
              </div>
            </button>
          </div>
          {brandData && brandData.name && (
            <div className="equote-selected-package-list-modal--package-list--item-row">
              <p>Cruise Line</p>
              <p>{brandData.name}</p>
            </div>
          )}

          {bestRate && (
            <div className="equote-selected-package-list-modal--package-list--item-row">
              <p>Price From</p>
              <p>${bestRate}</p>
            </div>
          )}

          {Boolean(packageData.shipName) && (
            <div className="equote-selected-package-list-modal--package-list--item-row">
              <p>Ship Name</p>
              <p>{packageData.shipName}</p>
            </div>
          )}

          {Boolean(packageData.destinationName) && (
            <div className="equote-selected-package-list-modal--package-list--item-row">
              <p>Destination</p>
              <p>{packageData.destinationName}</p>
            </div>
          )}

          {Boolean(packageData.startDate) && (
            <div className="equote-selected-package-list-modal--package-list--item-row">
              <p>Sail Date</p>
              <p>
                {moment(packageData.startDate, 'YYYY-MM-DD').format(
                  'MMMM Do, YYYY'
                )}
              </p>
            </div>
          )}
        </div>
      )
    })
  }

  render() {
    const { isOpen, onRequestClose } = this.props
    return (
      <ReactModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={{
          overlay: {
            zIndex: 22
          },
          content: {
            top: 'auto',
            left: '40px',
            bottom: '96px',
            right: 'auto',
            width: 'calc(100% - 40px)', // centered on smaller devices
            maxWidth: '560px',
            maxHeight: '80vh',
            padding: 0
          }
        }}
        ariaHideApp={false}
        htmlOpenClassName="ReactModal__Html--open"
      >
        <div className="equote-selected-package-list-modal">
          <div className="equote-selected-package-list-modal--header">
            <h4 className="u-remove-margin">EDIT SAILINGS</h4>
            <button
              className="btn equote-selected-package-list-modal--header-close-btn"
              onClick={onRequestClose}
            >
              <div className="equote-selected-package-list-modal--header-close-btn--inner">
                <span>Close </span>
                <img alt="close" src={closeIcon} />
              </div>
            </button>
          </div>
          <div className="equote-selected-package-list-modal--package-list">
            {this.renderPackageList()}
          </div>
        </div>
      </ReactModal>
    )
  }
}

export default EQuoteSelectedPackagesListModal
