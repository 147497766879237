export default function formatPrice(figure, decimals = 2) {
  return Number(figure).toFixed(decimals)
}

export const formatDisplayPrice = (price, options) => {
  const number = +price
  if (isNaN(number)) {
    return ''
  }
  if (Number.isInteger(number) && options && options.showAsInt) {
    return number.toFixed(0)
  }
  return number.toFixed(2)
}
