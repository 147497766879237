import {
  FETCH_CALENDAR_BEGIN,
  FETCH_CALENDAR_SUCCESS,
  FETCH_CALENDAR_FAILURE
} from '../actions/fetchCalendar'

const initialState = {
  loading: false,
  error: null
}

export default function fetchCalendarReducers(state = initialState, action) {
  switch (action.type) {
    case FETCH_CALENDAR_BEGIN:
      // Request begin: set loading true
      return {
        ...state,
        loading: true,
        error: null
      }

    case FETCH_CALENDAR_SUCCESS:
      // Request success: set loading false, and display request data
      return {
        ...state,
        loading: false,
        availableDates: action.payload.availableCalenderDates,
        blackoutDates: action.payload.blackoutDates
      }

    case FETCH_CALENDAR_FAILURE:
      // Request failed: set loading false, save & display error
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        availableDates: []
      }

    default:
      return state
  }
}
