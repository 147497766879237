import { updateLoyalty } from './setActiveSearchFilters'
export const REMOVE_ACTIVE_LOYALTY = 'REMOVE_ACTIVE_LOYALTY'
export const REMOVE_ALL_ACTIVE_LOYALTY = 'REMOVE_ALL_ACTIVE_LOYALTY'

export const removeLoyalty = activeLoyaltyId => ({
  type: REMOVE_ACTIVE_LOYALTY,
  payload: activeLoyaltyId
})

export const removeAllLoyalty = () => ({
  type: REMOVE_ALL_ACTIVE_LOYALTY
})

export default function removeActiveLoyalty(activeLoyaltyId) {
  return dispatch => {
    dispatch(updateLoyalty(null))
    if (activeLoyaltyId) {
      dispatch(removeLoyalty(activeLoyaltyId))
    } else {
      dispatch(removeAllLoyalty())
    }
  }
}
