import React, { Component } from 'react'
import { connect } from 'react-redux'
import setActiveStep from '../../actions/setActiveStepActions'

import EQuoteSearchFilters from '../../components/search/EQuoteSearchFilters'
import triggerCustomGaEvent from '../../utilities/triggerCustomGaEvent'

class EQuoteSearchContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

   componentDidMount() {

     // Dispatch GA event for search component
     const trackingComponentData = {
       currentStep: 1,
       component: 'Cruise / Cruise Tour Search'
     }

     triggerCustomGaEvent('equoteTracking-currentActiveStep', trackingComponentData)
   }

  render() {
    return (
      <div className="equote__search-sailings">
        <EQuoteSearchFilters />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    activeStep: state.activeStepData.activeStep
  }
}

const mapDispatchToProps = dispatch => ({
  handleSkipStep(activeStep) {
    return dispatch(setActiveStep(activeStep + 1))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteSearchContainer)
