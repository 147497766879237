import axios from 'axios'
import 'url-search-params-polyfill'
import triggerCustomGaEvent from '../utilities/triggerCustomGaEvent'
import storageKeys from '../configuration/storageKeys'

// Handle Destinations Fetch & Functionality
export default function fetchDestinations() {
  // Formatted API path
  const destinationsApi =
    process.env.REACT_APP_CONTENT_ENDPOINT + 'destinations'

  return axios.get(destinationsApi)
}

export const receiveDestinationsData = response => {
  const destinations = response.data.sort(function(a, b) {
    return a.name.localeCompare(b.name)
  })
  if (destinations) {
    sessionStorage.setItem(
      storageKeys.destinations,
      JSON.stringify(destinations)
    )

    // Dispatch GA event for all destinations
    triggerCustomGaEvent('equoteTracking-allDestinationsRequest', destinations)
  }
}
