import React, { forwardRef } from 'react'
import '../../styles/scss/components/equote-package-details-agent-banner.scss'

const EQuotePackageDetailsAgentInfoBanner = forwardRef((props, ref) => {
  const { brandClassName, modifiedSsoData, ssoData } = props

  let agentName = null,
    agentEmail = null,
    agencyName = null,
    agencyPhone = null,
    agencyEmail = null,
    agencyWebsite = null

  if (modifiedSsoData && modifiedSsoData.agent && modifiedSsoData.agent.name) {
    agentName = modifiedSsoData.agent.name
  } else if (ssoData && ssoData.agent && ssoData.agent.name) {
    agentName = ssoData.agent.name
  }

  if (modifiedSsoData && modifiedSsoData.agent && modifiedSsoData.agent.email) {
    agentEmail = modifiedSsoData.agent.email
  } else if (ssoData && ssoData.agent && ssoData.agent.email) {
    agentEmail = ssoData.agent.email
  }

  // Set Agency data edit VIA edit agent response, content hub or ESL
  if (
    modifiedSsoData &&
    modifiedSsoData.agency &&
    modifiedSsoData.agency.name
  ) {
    agencyName = modifiedSsoData.agency.name
  } else if (ssoData && ssoData.agency && ssoData.agency.name) {
    agencyName = ssoData.agency.name
  }

  if (
    modifiedSsoData &&
    modifiedSsoData.agency &&
    modifiedSsoData.agency.email
  ) {
    agencyEmail = modifiedSsoData.agency.email
  } else if (ssoData && ssoData.agency && ssoData.agency.email) {
    agencyEmail = ssoData.agency.email
  }
  if (
    modifiedSsoData &&
    modifiedSsoData.agency &&
    modifiedSsoData.agency.phone
  ) {
    agencyPhone = modifiedSsoData.agency.phone
  } else if (ssoData && ssoData.agency && ssoData.agency.phone) {
    agencyPhone = ssoData.agency.phone
  }
  if (
    modifiedSsoData &&
    modifiedSsoData.agency &&
    modifiedSsoData.agency.website
  ) {
    agencyWebsite = modifiedSsoData.agency.website
  } else if (ssoData && ssoData.agency && ssoData.agency.website) {
    agencyWebsite = ssoData.agency.website
  }

  return (
    <div ref={ref} className="equote-agent-details-banner">
      <div className="u-content-container">
        <div className="equote-agent-details-banner--inner">
          <div className="equote-agent-details-banner--contacts">
            {agentName && (
              <div className={`item ${brandClassName}`}>
                <p className="item--title u-remove-margin">Agent Name</p>
                <p className="item--info u-remove-margin">{agentName}</p>
              </div>
            )}

            {agencyName && (
              <div className={`item ${brandClassName}`}>
                <p className="item--title u-remove-margin">Agency</p>
                <p className="item--info u-remove-margin">{agencyName}</p>
              </div>
            )}

            {agentEmail ? (
              <div className={`item ${brandClassName}`}>
                <p className="item--title u-remove-margin">Email</p>
                <p className="item--info u-remove-margin">{agentEmail}</p>
              </div>
            ) : agentEmail ? (
              <div className={`item ${brandClassName}`}>
                <p className="item--title u-remove-margin">Email</p>
                <p className="item--info u-remove-margin">{agencyEmail}</p>
              </div>
            ) : null}

            {agencyPhone && (
              <div className={`item ${brandClassName}`}>
                <p className="item--title u-remove-margin">Phone</p>
                <p className="item--info u-remove-margin u-link">
                  {agencyPhone}
                </p>
              </div>
            )}

            {agencyWebsite && (
              <div className={`item ${brandClassName}`}>
                <p className="item--title u-remove-margin">Website</p>
                <p className="item--info u-remove-margin">
                  <a
                    href={
                      agencyWebsite.includes('http://') ||
                      agencyWebsite.includes('https://')
                        ? agencyWebsite
                        : `http://${agencyWebsite}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {agencyWebsite}
                  </a>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
})

export default EQuotePackageDetailsAgentInfoBanner
