import React, { Component } from 'react'
import { connect } from 'react-redux'

import setActiveStep from '../../actions/setActiveStepActions'
import setActiveCabinStep, {
  goToLandPackages
} from '../../actions/setActiveCabinStepActions'

import EQuoteReviewEmailSubmitQuote from '../../components/email/EQuoteReviewEmailSubmitQuote'

import triggerCustomGaEvent from '../../utilities/triggerCustomGaEvent'

import '../../styles/scss/components/equote-navigation.scss'

import EQuoteSelectedPackageListContainer from '../../containers/selectedPackageListContainer/EQuoteSelectedPackageListContainer'
import { getAllActivePackagesDetails } from '../../reducers/setActivePackageReducers'
import { getAllActiveCabinData } from '../../reducers/setActiveCabinReducers'

class EQuoteNavigation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedPackageListModalIsOpen: false
    }
  }

  renderAddSailingsStep = () => {
    const { activeStep, activePackages, handleNextStep } = this.props
    const packageCount = Object.keys(activePackages).length

    return (
      <div className="equote__navigation">
        <div className="equote__navigation-content">
          <h6 className="u-remove-margin">
            {packageCount} of 5 packages added
          </h6>
          {Boolean(packageCount) && (
            <button
              className="btn sailing-list-modal-trigger"
              onClick={() =>
                this.setState({
                  selectedPackageListModalIsOpen: true
                })
              }
            >
              view/remove sailings
            </button>
          )}
        </div>
        <div className="equote__navigation--arrows">
          {packageCount > 0 ? (
            <button
              className="btn pointer active"
              onClick={() => handleNextStep(activeStep, 1)}
            >
              <div className="pointer-inner">Continue</div>
            </button>
          ) : (
            <button className="btn pointer active" disabled={true}>
              <div className="pointer-inner">Add a Sailing to Continue</div>
            </button>
          )}
        </div>
      </div>
    )
  }

  renderAddCabinsStep = () => {
    const {
      activeStep,
      activeCabinData,
      cabinStepData,
      handleSetActiveCabinStep,
      handleNextStep,
      activePackages,
      hasLandProgramOptions,
      currentlyShowingLandPackages
    } = this.props

    const filteredActiveCabinData =
      cabinStepData.activeCabinId &&
      activeCabinData &&
      activeCabinData[cabinStepData.activeCabinId]
        ? activeCabinData[cabinStepData.activeCabinId]
        : {}

    const cabinCount = Object.keys(filteredActiveCabinData).length
    const packageCount = Object.keys(activePackages).length

    return (
      <div className="equote__navigation">
        <div className="equote__navigation-content">
          <h6 className="u-remove-margin">
            Sailing {cabinStepData.activeCabin + 1} of{' '}
            {cabinStepData.cabinTotal}
          </h6>
          {Boolean(packageCount) && (
            <button
              className="btn sailing-list-modal-trigger"
              onClick={() =>
                this.setState({
                  selectedPackageListModalIsOpen: true
                })
              }
            >
              view/remove sailings
            </button>
          )}
        </div>
        <div className="equote__navigation--arrows">
          {packageCount > 0 &&
          (cabinStepData.activeCabin < cabinStepData.cabinTotal - 1 ||
            (hasLandProgramOptions && !currentlyShowingLandPackages)) &&
          cabinCount ? (
            <button
              className="btn pointer active"
              onClick={() =>
                hasLandProgramOptions && !currentlyShowingLandPackages
                  ? this.props.goToLandProgramOptions()
                  : handleSetActiveCabinStep(
                      cabinStepData.cabinTotal,
                      cabinStepData.activeCabin + 1,
                      Object.keys(activePackages)[cabinStepData.activeCabin + 1]
                    )
              }
            >
              <div className="pointer-inner">
                {hasLandProgramOptions && !currentlyShowingLandPackages
                  ? 'View Land Program Options'
                  : 'Edit Next Sailing'}
              </div>
            </button>
          ) : packageCount > 0 &&
            cabinStepData.activeCabin === cabinStepData.cabinTotal - 1 &&
            cabinCount ? (
            <button
              className="btn pointer active"
              onClick={() => handleNextStep(activeStep, 1)}
            >
              <div className="pointer-inner">Review Email</div>
            </button>
          ) : (
            <button className="btn pointer active" disabled={true}>
              <div className="pointer-inner">Edit a sailing to Continue</div>
            </button>
          )}

          {cabinCount > 0 &&
            packageCount > 1 &&
            (packageCount !== cabinStepData.activeCabin + 1 ||
              (hasLandProgramOptions && !currentlyShowingLandPackages)) && (
              <button
                className="btn pointer"
                onClick={() => handleNextStep(activeStep, 1)}
              >
                <div className="pointer-inner">Skip To Email</div>
              </button>
            )}
        </div>
      </div>
    )
  }

  renderSubmitEmailStep = () => {
    const { activePackages } = this.props
    const packageCount = Object.keys(activePackages).length

    return (
      <div className="equote__navigation">
        <div className="equote__navigation-content">
          <h6 className="u-remove-margin">
            {packageCount} of 5 packages added
            {Boolean(packageCount) && (
              <button
                className="btn sailing-list-modal-trigger"
                onClick={() =>
                  this.setState({
                    selectedPackageListModalIsOpen: true
                  })
                }
              >
                view/remove sailings
              </button>
            )}
          </h6>
        </div>

        <EQuoteReviewEmailSubmitQuote />
      </div>
    )
  }

  renderNavigation() {
    const { activeStep } = this.props

    switch (activeStep) {
      case 0:
        return null
      case 1:
        return this.renderAddSailingsStep()
      case 2:
        return this.renderAddCabinsStep()
      case 3:
        return this.renderSubmitEmailStep()
      case 4:
        return null
      default:
        return null
    }
  }

  render() {
    return (
      <>
        {this.renderNavigation()}
        <EQuoteSelectedPackageListContainer
          isOpen={this.state.selectedPackageListModalIsOpen}
          onRequestClose={() =>
            this.setState({
              selectedPackageListModalIsOpen: false
            })
          }
        />
      </>
    )
  }
}

function mapStateToProps(state) {
  const activePackages = getAllActivePackagesDetails(state)
  const activeCabinData = getAllActiveCabinData(state)
  const cabinStepData = state.activeCabinStepData

  const currentActivePackage = activePackages[cabinStepData.activeCabinId]

  const hasLandProgramOptions =
    currentActivePackage &&
    (currentActivePackage.isLandPackage || currentActivePackage.hasLandPackages)
  const currentlyShowingLandPackages = cabinStepData.showingLandPackages

  return {
    activeStep: state.activeStepData.activeStep,
    activePackages,
    activeCabinData,
    cabinStepData,
    hasLandProgramOptions,
    currentlyShowingLandPackages
  }
}

const mapDispatchToProps = dispatch => ({
  handleSetActiveCabinStep(totalCabinSteps, activeCabinStep, activeCabinId) {
    // Dispatch GA events
    triggerCustomGaEvent('buildYourQuote-editNextSailing', true)

    return dispatch(
      setActiveCabinStep(totalCabinSteps, activeCabinStep, activeCabinId)
    )
  },
  handleNextStep(activeStep, increment) {
    const currentStep = activeStep + increment

    if (currentStep === 2) {
      // Dispatch GA events
      triggerCustomGaEvent('equoteSearch-continueToPricing', true)
    } else if (currentStep === 3) {
      triggerCustomGaEvent('buildYourQuote-reviewEmail', true)
    }

    return dispatch(setActiveStep(currentStep))
  },
  goToLandProgramOptions() {
    dispatch(goToLandPackages())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteNavigation)
