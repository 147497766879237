import React, { Fragment, Component } from 'react'
import { connect } from 'react-redux'

import { setActiveUserAgreement } from '../../actions/setActiveEmailConfiguration'
import CheckMark from '../../assets/icons/checkmark.svg'

class EQuoteReviewConstructTermsCheckbox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isTermsChecked: false
    }
  }

  handleUpdateTerms = () => {
    const { handleSetActiveUserAgreement } = this.props

    this.setState({
      isTermsChecked: !this.state.isTermsChecked
    })

    handleSetActiveUserAgreement(!this.state.isTermsChecked)
  }

  render() {
    return (
      <Fragment>
        <label id="label-terms" htmlFor="terms">
          Terms & Conditions
        </label>
        <p>I understand that my use of the eQuote Tool is governed by the terms and conditions of the eQuote User Agreement. I further understand and agree that this eQuote tool shall only be used to send ‘transactional e-mail’ messages (as that term is defined in the federal CAN-SPAM Act.)</p>
	      <button
          className={this.state.isTermsChecked ? "equote__review-configure-email-terms-check btn btn-primary" : "equote__review-configure-email-terms-check btn btn-primary" }
		      onClick={() => this.handleUpdateTerms()}
	      >
		      { this.state.isTermsChecked ?
            <span className="equote__review-configure-email-terms-check-icon active">
              <img src={ CheckMark } alt="checked" />
            </span>
          :
			      <span className="equote__review-configure-email-terms-check-icon"></span>
		      }
		      I Agree to the Terms and Conditions
        </button>
      </Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  handleSetActiveUserAgreement(userAgreement) {
    return dispatch(setActiveUserAgreement(userAgreement))
  }
})

export default connect(null, mapDispatchToProps)(EQuoteReviewConstructTermsCheckbox)
