import React, { Component } from 'react'

class EQuoteSearchFiltersFullFormShip extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { ships, currentBrand, currentShip, handleSelectShip } = this.props
    const renderShips = []

    if (ships) {
      // Filter out retired ships
      const activeShips = ships.filter(ships => !ships.is_retired)

      // Render ships based on brand or display all results
      if (currentBrand && currentBrand !== 'C,R') {
        // Filter for brand matches
        const brandShips = activeShips.filter(
          ships => ships.brand_code === currentBrand
        )

        for (const ship in brandShips) {
          renderShips.push(
            <option
              key={brandShips[ship].ship_code}
              value={brandShips[ship].ship_code}
            >
              {brandShips[ship].name}
            </option>
          )
        }
      } else {
        for (const ship in activeShips) {
          renderShips.push(
            <option
              key={activeShips[ship].ship_code + activeShips[ship].name}
              value={activeShips[ship].ship_code}
            >
              {activeShips[ship].name}
            </option>
          )
        }
      }
    }

    return (
      <div className="equote__search-filters-form-field-input u-custom-select">
        <select
          name="ship"
          onChange={e =>
            e.target.value === ''
              ? handleSelectShip()
              : handleSelectShip(e.target.value)
          }
          value={currentShip}
          tabIndex={0}
          aria-labelledby="shipToggleLabel"
        >
          <option value="">All Ships</option>
          {renderShips}
        </select>
        <span className="u-custom-select__arrow" />
      </div>
    )
  }
}

export default EQuoteSearchFiltersFullFormShip
