export const SET_ACTIVE_LAND_PACKAGE = 'SET_ACTIVE_LAND_PACKAGE'

export const setActiveLandPackages = (packageKey, landProgramKey, data) => ({
  type: SET_ACTIVE_LAND_PACKAGE,
  payload: {
    packageKey,
    landProgramKey,
    data
  }
})
