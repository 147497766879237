export const SET_CRUISE_TOURS = 'SET_CRUISE_TOURS'

export const updateCruiseTours = cruiseTours => ({
  type: SET_CRUISE_TOURS,
  payload: cruiseTours
})

export default function setCruiseTours(cruiseTours) {
  return dispatch => {
    dispatch(updateCruiseTours(cruiseTours))
  }
}
