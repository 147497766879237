import React, { Fragment, Component } from 'react'
import { connect } from 'react-redux'
import { getBrandSpecificEmailComment } from '../../reducers/setActiveEmailConfigurationReducers'
import { setActiveComments } from '../../actions/setActiveEmailConfiguration'

var _ = require('lodash')

class EQuoteReviewConstructCommentsInput extends Component {

	constructor(props) {
		super(props)
		const { handleSetActiveComments } = props

		handleSetActiveComments(this.props.initialEmailComment)

		this.state = {
			comments: this.props.initialEmailComment
		}
	}

	handleUpdateComments = (e) => {
		const { handleSetActiveComments } = this.props

		// Default comments content
		let commentContent = this.props.initialEmailComment

    if (!_.isEmpty(e.target.value)) {
			commentContent = e.target.value
		}

		this.setState({
			comments: commentContent
		})

		handleSetActiveComments(commentContent)
	}

	render() {
		return (
			<Fragment>
				<label
					id="label-comments"
					htmlFor="comments"
				>
					Additional Comments
				</label>
				<textarea
					name="comments"
					placeholder="Add an optional personal message to your client here"
					onChange={ e => this.handleUpdateComments(e) }
					aria-labelledby='label-comments'
					rows="4"
				/>
			</Fragment>
		)
	}
}

function mapStateToProps(state) {
	return {
	  initialEmailComment: getBrandSpecificEmailComment(state)
	}
}

const mapDispatchToProps = dispatch => ({
	handleSetActiveComments(comments) {
		return dispatch(setActiveComments(comments))
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(EQuoteReviewConstructCommentsInput)