import storageKeys from '../configuration/storageKeys'

export const getBrandsData = () =>
  JSON.parse(sessionStorage.getItem(storageKeys.brands))

export const getBrandByCode = code => {
  const brands = getBrandsData()
  let brandData

  if (brands) {
    brandData = brands.find(brand => brand.brand_code === code)
  }
  return brandData
}
