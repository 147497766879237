import React, { Component } from 'react'
import brandLoader from '../../../assets/icons/brand-loader-final.gif'

class EQuoteSearchFiltersFullFormLoyalty extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loyaltyId: '',
      error: false
    }
  }

  componentDidMount() {
    const { activeSearchFilterData } = this.props

    if (activeSearchFilterData && activeSearchFilterData.loyaltyId) {
      this.setState({
        loyaltyId: activeSearchFilterData.loyaltyId
      })
    }
  }

  handleUpdateLoyaltyId = e => {
    this.setState({
      loyaltyId: e.target.value
    })
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.activeSearchFilterData.brand !==
        prevProps.activeSearchFilterData.brand &&
      this.state.error
    ) {
      this.setState({ error: false })
    }
  }

  handleRemoveLoyaltyClick = () => {
    const { handleRemoveLoyalty, ssoData } = this.props
    const { loyaltyId } = this.state

    const currentLoyaltyKey = loyaltyId + '-' + ssoData.agency.agency_id

    handleRemoveLoyalty(currentLoyaltyKey)
    this.setState({
      loyaltyId: ''
    })
  }

  renderLoyaltyContent = () => {
    const { loyaltyId } = this.state
    const {
      activeSearchFilterData,
      handleFetchLoyalty,
      loyaltyData,
      ssoData
    } = this.props
    const ssoAgency = ssoData.agency,
      currentLoyaltyKey = loyaltyId + '-' + ssoAgency.agency_id

    let isLoyaltyEnabled = false

    if (
      loyaltyId &&
      ssoAgency.agency_id &&
      activeSearchFilterData &&
      activeSearchFilterData.brand !== 'C,R' &&
      this.state.loyaltyId
    ) {
      isLoyaltyEnabled = true
    }

    if (
      loyaltyData &&
      loyaltyData.loyalty &&
      loyaltyData.loyalty[currentLoyaltyKey] &&
      loyaltyData.loyalty[currentLoyaltyKey].member
    ) {
      const isActiveLoyalty = loyaltyData.loyalty.hasOwnProperty(
        currentLoyaltyKey
      )

      if (isActiveLoyalty) {
        const activeLoyaltyEntry = loyaltyData.loyalty[currentLoyaltyKey]

        return (
          <button
            className="btn btn-text u-font-xxs"
            onClick={this.handleRemoveLoyaltyClick}
          >
            {activeLoyaltyEntry.member.LoyaltyNumber
              ? activeLoyaltyEntry.member.LoyaltyNumber + ' - '
              : null}
            {activeLoyaltyEntry.member.Tier}{' '}
            <span className="u-font-bold u-font-medium">&times;</span>
          </button>
        )
      } else {
        const activeLoyaltyEntry = loyaltyData.loyalty[currentLoyaltyKey]

        return (
          <div
            onClick={() => this.setState({ error: true })}
            className="equote__search-filters-form-field-input equote__search-filters-loyalty"
          >
            <input
              type="text"
              onChange={this.handleUpdateLoyaltyId}
              placeholder="Loyalty Number"
              value={this.state.loyaltyId}
            />

            {loyaltyData.loading ? (
              <img src={brandLoader} alt="loading..." />
            ) : (
              <button
                className="btn btn-secondary"
                onClick={
                  isLoyaltyEnabled
                    ? () => handleFetchLoyalty(loyaltyId, ssoAgency.agency_id)
                    : null
                }
                disabled={!isLoyaltyEnabled}
              >
                Validate
              </button>
            )}

            {activeLoyaltyEntry &&
            !activeLoyaltyEntry.member &&
            activeLoyaltyEntry.error ? (
              <p className="u-font-error u-font-xs">
                Loyalty number is not valid for the Cruise Line brand selected.
              </p>
            ) : null}
            {loyaltyData.error ? (
              <p className="u-font-error u-font-xs">
                {loyaltyData.error ? loyaltyData.error : null}
              </p>
            ) : null}

            {activeSearchFilterData &&
            activeSearchFilterData.brand === 'C,R' ? (
              <p
                className={`u-font-xxs ${
                  this.state.error ? 'u-font-sold-out' : ''
                }`}
              >
                Please select a brand before validating a loyalty number.
              </p>
            ) : null}
          </div>
        )
      }
    } else {
      return (
        <div
          onClick={() => this.setState({ error: true })}
          className="equote__search-filters-form-field-input equote__search-filters-loyalty"
        >
          <input
            type="text"
            onChange={this.handleUpdateLoyaltyId}
            placeholder="Loyalty Number"
            value={this.state.loyaltyId}
            disabled={
              activeSearchFilterData && activeSearchFilterData.brand === 'C,R'
            }
          />

          {loyaltyData.loading ? (
            <img src={brandLoader} alt="loading..." />
          ) : (
            <button
              className="btn btn-secondary"
              onClick={
                isLoyaltyEnabled
                  ? () => handleFetchLoyalty(loyaltyId, ssoAgency.agency_id)
                  : null
              }
              disabled={!isLoyaltyEnabled}
            >
              Validate
            </button>
          )}

          {loyaltyData &&
          loyaltyData.loyalty &&
          loyaltyData.loyalty[currentLoyaltyKey] &&
          !loyaltyData.loyalty[currentLoyaltyKey].member &&
          loyaltyData.loyalty[currentLoyaltyKey].error ? (
            <p className="u-font-error u-font-xs">
              Loyalty number is not valid for the Cruise Line brand selected.
            </p>
          ) : null}
          {loyaltyData.error ? (
            <p className="u-font-error">
              {loyaltyData.error && typeof loyaltyData.error === 'string'
                ? loyaltyData.error
                : 'Unable to validate'}
            </p>
          ) : null}
          {activeSearchFilterData && activeSearchFilterData.brand === 'C,R' ? (
            <p
              className={`u-font-xxs ${
                this.state.error ? 'u-font-sold-out' : ''
              }`}
            >
              Please select a brand before validating a loyalty number.
            </p>
          ) : null}
        </div>
      )
    }
  }

  render() {
    return this.renderLoyaltyContent()
  }
}

export default EQuoteSearchFiltersFullFormLoyalty
