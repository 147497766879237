export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP'
export const SET_PREVIOUS_STEP = 'SET_PREVIOUS_STEP'

export const updateStep = activeStep => ({
	type: SET_ACTIVE_STEP,
	payload: activeStep
})

export default function setActiveStep(activeRoom) {
	return dispatch => {
		dispatch(updateStep(activeRoom))
	}
}

export const updatePreviousStep = prevStep => ({
  type: SET_PREVIOUS_STEP,
  payload: prevStep,
})

export function setPreviousStep(prevRoom) {
  return dispatch => {
    dispatch(updatePreviousStep(prevRoom))
  }
}