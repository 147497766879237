import React, { Component } from 'react'
import { connect } from 'react-redux'
import clearUnsupportedQualifierPrices from '../../../actions/clearUnsupportedQualifierPrices'
import setActiveStep from '../../../actions/setActiveStepActions'
import fetchPackages from '../../../actions/fetchPackages'

import updateSearchParams from '../../../utilities/updateSearchParams'
import triggerCustomGaEvent from '../../../utilities/triggerCustomGaEvent'
import { setActiveGuestCount } from '../../../actions/setActiveSearchFilters'

class EQuoteSearchFiltersFullFormSubmit extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  submitQueryPackages() {
    const {
      activeSearchFilterData,
      handleSubmitQueryPackages,
      loyaltyData
    } = this.props
    updateSearchParams(activeSearchFilterData)
    this.props.handleActiveGuestCount(activeSearchFilterData.guestCount)
    this.props.clearUnsupportedQualifierPrices()
    handleSubmitQueryPackages()
    // Dispatch submit and edit click events
    triggerCustomGaEvent('equoteSearchClick', {
      ...activeSearchFilterData,
      ...loyaltyData
    })
  }

  render() {
    const { isSearchEnabled } = this.props

    return (
      <button
        className="btn btn-primary"
        disabled={!isSearchEnabled}
        onClick={isSearchEnabled ? () => this.submitQueryPackages() : null}
      >
        Search
      </button>
    )
  }
}

const mapStateToProps = state => {
  return {
    loyaltyData: state.loyaltyData
  }
}

const mapDispatchToProps = dispatch => ({
  handleSubmitQueryPackages() {
    dispatch(fetchPackages())
    dispatch(setActiveStep(1))
  },
  clearUnsupportedQualifierPrices() {
    dispatch(clearUnsupportedQualifierPrices())
  },
  handleActiveGuestCount(count) {
    return dispatch(setActiveGuestCount(count))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteSearchFiltersFullFormSubmit)
