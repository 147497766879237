import {
  SET_ACTIVE_RECIPIENTS,
  SET_ACTIVE_HEADLINE,
  SET_ACTIVE_COMMENTS,
  SET_ACTIVE_USER_AGREEMENT,
  SET_AGENT_EMAIL_COPY_OPTIONS
} from '../actions/setActiveEmailConfiguration'
import { RESET_ACTIVE_EMAIL_CONFIGURATION } from '../actions/resetQuoteFlow'
import { getAllActivePackagesDetails } from './setActivePackageReducers'
import { getLabelsData } from '../sessionStorage/getLabelsData'

const initialState = {
  userAgreement: false,
  sendCopyToAgent: true
}

export default function setActiveEmailConfigurationReducers(
  state = initialState,
  action
) {
  switch (action.type) {
    case SET_ACTIVE_RECIPIENTS:
      return {
        ...state,
        recipients: action.payload
      }

    case SET_ACTIVE_HEADLINE:
      return {
        ...state,
        headline: action.payload
      }

    case SET_ACTIVE_COMMENTS:
      return {
        ...state,
        comments: action.payload
      }

    case SET_ACTIVE_USER_AGREEMENT:
      return {
        ...state,
        userAgreement: action.payload
      }

    case RESET_ACTIVE_EMAIL_CONFIGURATION:
      return {
        recipients: null,
        headline: null,
        comments: null,
        userAgreement: false,
        sendCopyToAgent: true
      }

    case SET_AGENT_EMAIL_COPY_OPTIONS:
      return {
        ...state,
        sendCopyToAgent: action.payload
      }

    default:
      return state
  }
}

const sortBrands = activePackageData => {
  const brands = []

  for (const brand of Object.values(activePackageData)) {
    if (!brands.find(addedBrand => addedBrand === brand.brand)) {
      brands.push(brand.brand)
    }
  }

  return brands
}

export const getBrandSpecificEmailHeader = state => {
  const labelsData = getLabelsData()
  if (!labelsData || !labelsData.email || !state.activePackageData) {
    return 'Personal or Default Headline'
  }

  const brands = sortBrands(getAllActivePackagesDetails(state))

  if (brands.length === 1) {
    return labelsData.email[
      `configure_headline_preview_${brands[0].toLowerCase()}`
    ]
  }
  return labelsData.email['configure_headline_preview']
}

export const getBrandSpecificEmailComment = state => {
  const labelsData = getLabelsData()
  if (!labelsData || !labelsData.email || !state.activePackageData) {
    return 'Comments Preview'
  }
  const brands = sortBrands(getAllActivePackagesDetails(state))

  if (brands.length === 1) {
    return labelsData.email[
      `configure_comments_preview_${brands[0].toLowerCase()}`
    ]
  }

  return labelsData.email['configure_comments_preview']
}
