import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import moment from 'moment'
import { PrevArrow, NextArrow } from '../elements/CarouselArrows'

import formatLeadPromoDesc from '../../utilities/formatLeadPromoDesc'

import '../../styles/scss/components/equote-package-details-related-sailings-slider.scss'

const renderPricing = pricing => {
  if (!pricing) return

  return Object.values(pricing).map(price => {
    if (!price || !price.bestGuestCountRate) return null
    return (
      <div
        className="equote__package-details-related-sailings-slider__row"
        key={price.stateroomDescription}
      >
        <div className="equote__package-details-related-sailings-slider__row-head equote__package-details-related-sailings-slider__row-head--indent">
          {price.stateroomDescription}
        </div>
        <div className="equote__package-details-related-sailings-slider__row-body">
          {price.bestGuestCountRate.netPrice ? (
            <>
              <p className="equote__package-details-related-sailings-slider__row-price">
                $ {price.bestGuestCountRate.netPrice}
              </p>
              <p className="equote__package-details-related-sailings-slider__row-promo">
                {formatLeadPromoDesc(
                  price.bestGuestCountRate.leadPromoDesc,
                  price.bestGuestCountRate.promos
                )}
              </p>
              <p className="equote__package-details-related-sailings-slider__row-promo">
                {price.bestGuestCountRate.isRefundable
                  ? `Regular Deposit`
                  : `Non-Refundable Deposit`}
              </p>
            </>
          ) : (
            <div className="equote__package-details-related-sailings-slider__row-sold-out">
              <p>Sold Out</p>
            </div>
          )}
        </div>
      </div>
    )
  })
}

const renderTable = props => {
  const tableItems = []
  const { relatedSailings } = props
  relatedSailings.forEach((sailing, i) => {
    tableItems.push(
      <div key={i} className="equote__package-details-related-sailings-slider">
        <div className="equote__package-details-related-sailings-slider__wrapper">
          <div className="equote__package-details-related-sailings-slider__row">
            <div className="equote__package-details-related-sailings-slider__row-head">
              Departure
            </div>
            <div className="equote__package-details-related-sailings-slider__row-body">
              {moment(sailing.sailDate, 'YYYY-MM-DD').format('MMMM DD, YYYY')}
            </div>
          </div>
          <div className="equote__package-details-related-sailings-slider__row">
            <div className="equote__package-details-related-sailings-slider__row-head">
              Nights
            </div>
            <div className="equote__package-details-related-sailings-slider__row-body">
              {sailing.sailingNights} Nights
            </div>
          </div>
          <div className="equote__package-details-related-sailings-slider__row">
            <div className="equote__package-details-related-sailings-slider__row-head">
              Destination
            </div>
            <div className="equote__package-details-related-sailings-slider__row-body">
              {props.packageData && props.packageData.destinationName
                ? props.packageData.destinationName
                : null}
            </div>
          </div>
          <div className="equote__package-details-related-sailings-slider__row">
            <div className="equote__package-details-related-sailings-slider__row-head">
              Ship
            </div>
            <div className="equote__package-details-related-sailings-slider__row-body">
              {props.packageData && props.packageData.shipName
                ? props.packageData.shipName
                : null}
            </div>
          </div>
          <div className="equote__package-details-related-sailings-slider__row">
            <div className="equote__package-details-related-sailings-slider__row-head">
              PRICING
            </div>
            <div className="equote__package-details-related-sailings-slider__row-body" />
          </div>
          {renderPricing(sailing.pricing)}
        </div>
        <div className="equote__package-details-related-sailings-slider__row-buttons">
          <button className="btn u-uppercase similar not-allowed" disabled>
            Request
          </button>
          <button className="btn u-uppercase btn-info not-allowed" disabled>
            View Details
          </button>
        </div>
      </div>
    )
  })

  return tableItems
}

const renderNumberOfSlidesToShow = () => {
  if (window.innerWidth > 1200) {
    return 3
  }
  if (window.innerWidth >= 768) {
    return 2
  }
  return 1
}

export default function EQuotePackageDetailsRelatedSailingsSlider(props) {
  const [numberOfSlidesToShow, setSlideCount] = useState(() =>
    renderNumberOfSlidesToShow()
  )

  const settings = {
    lazyLoad: 'progressive',
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: numberOfSlidesToShow,
    swipeToSlide: true,
    prevArrow: (
      <PrevArrow {...props} numberOfSlidesToShow={numberOfSlidesToShow} large />
    ),
    nextArrow: (
      <NextArrow {...props} numberOfSlidesToShow={numberOfSlidesToShow} large />
    )
  }

  const handleResize = () => {
    setSlideCount(() => renderNumberOfSlidesToShow())
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    //Clean up function
    return function cleanup() {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className="equote__package-details-related-sailings-slider ">
      <Slider {...settings}>{renderTable(props)}</Slider>
      <p className="equote__package-details-related-sailings-slider__text">
        {props.searchTermsBody}
      </p>
    </div>
  )
}
