import axios from 'axios'
import 'url-search-params-polyfill'

// Handle Brands Fetch & Functionality
export default function fetchBrands() {
  // Formatted API path
  const labelsApi = process.env.REACT_APP_CONTENT_ENDPOINT + 'brands'
  return axios.get(labelsApi)
}

export const receiveBrandsData = response => {
  const brands = response.data
    .filter(brand => brand.brand_code !== 'Z')
    .sort((a, b) => (a.name < b.name ? -1 : 1))
  sessionStorage.setItem('eQuoteBrandsData', JSON.stringify(brands))
}
