import React from 'react'

import '../../styles/scss/components/equote-package-details-cruise-highlights.scss'

export default function EQuotePackageDetailsHighlights(props) {
  const { brandDetails, brandClassName } = props

  return (
    <div
      className={`equote__package-details-cruise-highlights ${brandClassName}`}
    >
      <h1 className={`section-title ${brandClassName} u-text-center`}>
        BRAND HIGHLIGHTS
      </h1>

      <p
        dangerouslySetInnerHTML={{
          __html: brandDetails.highlights.replace(/(?:\r\n|\r|\n)/g, '<br>')
        }}
      />
    </div>
  )
}
