import React, { Component } from 'react'

import '../../../styles/scss/components/equote-search-filter-form-nights.scss'

class EQuoteSearchFiltersFullFormNights extends Component {

	constructor(props) {
		super(props)
		this.state = {
			currentDuration: ''
		}
	}

	componentDidMount() {
		const { activeSearchFilterData } = this.props

		if (activeSearchFilterData.duration) {
			this.setState({
				currentDuration: activeSearchFilterData.duration
			})
		}
	}

	handleSetNights = (e) => {
		const { handleActiveSailingNights } = this.props

		if (e.target.value <= 0) {
			this.setState({
				currentDuration: ''
			})

			handleActiveSailingNights()
		} else {
			this.setState({
				currentDuration: e.target.value
			})

			handleActiveSailingNights(e.target.value)
		}
	}

	render() {
		return (
			<div className="equote__search-filters-form-nights equote__search-filters-form-field-input">
				<label className="u-custom-select__label">Nights</label>
				<input
					type="number"
					name="nights"
					onChange={ (e) => this.handleSetNights(e) }
					value={ this.state.currentDuration ? this.state.currentDuration : '' }
					min="0"
					max="20"
					aria-labelledby='label-nights'
				/>
			</div>
		)
	}
}

export default EQuoteSearchFiltersFullFormNights