import {
	FETCH_PACKAGE_BRAND_BEGIN,
	FETCH_PACKAGE_BRAND_FAILURE,
} from '../actions/fetchPackageBrand'

const initialState = {
	loading: false,
	error: null
}

export default function fetchPackageBrandReducers(state = initialState, action) {
	switch(action.type) {
		case FETCH_PACKAGE_BRAND_BEGIN:
			// Request begin: set loading true
			return {
				...state,
				loading: true,
				error: null
			}


		case FETCH_PACKAGE_BRAND_FAILURE:
			// Request failed: set loading false, save & display error
			return {
				...state,
				loading: false,
				error: action.payload.error,
			}

		default:
			return state;
	}
}
