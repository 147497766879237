import React, { useRef } from 'react'
import EQuotePackageDetailsAgentInfoBanner from './EQuotePackageDetailsAgentInfoBanner'

import '../../styles/scss/components/equote-package-details-agent-details-footer.scss'

const EQuotePackageDetailsAgentFooter = props => {
  const { agency, agent, brandClassName, modifiedSsoData, ssoData } = props
  const agentDetailRef = useRef()
  let agencyLogo = null

  const handleShowAgentDetailBanner = e => {
    agentDetailRef.current.classList.toggle('show')
    agentDetailRef.current.classList.contains('show')
      ? (e.target.innerHTML = 'Close X')
      : (e.target.innerHTML = 'Agent Info')

    window.setTimeout(() => {
      agentDetailRef.current.scrollIntoView()
    }, 270)
  }

  if (
    modifiedSsoData &&
    modifiedSsoData.agency &&
    modifiedSsoData.agency.logo
  ) {
    agencyLogo = modifiedSsoData.agency.logo
  } else if (
    ssoData &&
    ssoData.agency &&
    ssoData.agency.logo
  ) {
    agencyLogo = ssoData.agency.logo
  }

  return (
    <div className="equote-agent-footer" id="request">
      <div className="u-content-container">
        <div className="equote-agent-footer--header">
          <h1 className={`section-title ${props.brandClassName} u-text-center`}>
            Start Planning Your Vacation
          </h1>
          <p>Contact me to confirm your cruise and get sailing.</p>
          <div className="equote-agent-footer--header-buttons-wrapper">
            <EQuotePackageDetailsAgentInfoBanner
              ref={agentDetailRef}
              agency={agency}
              agent={agent}
              brandClassName={brandClassName}
            />
            <div className="equote-agent-footer--header-buttons">
              <button
                className="btn btn-info btn-agent-info u-uppercase"
                onClick={handleShowAgentDetailBanner}
              >
                Agent Info
              </button>
            </div>
          </div>
        </div>
        <div className="equote-agent-footer--inner">
          {agencyLogo ? (
            <div className="equote-agent-footer-agency-logo">
              <img src={agencyLogo} alt="eQuote" />
            </div>
          ) : null}

          <div className="equote-agent-footer--contacts">
            <div className="equote-agent-footer--names">
              {agent && agent.name && (
                <div className="item u-flex-container--center">
                  <p className="item--title u-remove-margin">Agent Name</p>
                  <p className="item--info u-remove-margin">{agent.name}</p>
                </div>
              )}

              {agency && agency.name && (
                <div className="item u-flex-container--center">
                  <p className="item--title u-remove-margin">Agency</p>
                  <p className="item--info u-remove-margin">{agency.name}</p>
                </div>
              )}
            </div>

            <div>
              {props.agent && props.agent.email && (
                <div className="item u-flex-container--center">
                  <p className="item--title u-remove-margin">Email</p>
                  <p className="item--info u-remove-margin">
                    {props.agent.email}
                  </p>
                </div>
              )}
              {props.agent && props.agent.phone && (
                <div className="item u-flex-container--center">
                  <p className="item--title u-remove-margin">Phone</p>
                  <p className="item--info u-remove-margin u-link">
                    {props.agent.phone}
                  </p>
                </div>
              )}
              {props.agency && agency.website && (
                <div className="item u-flex-container--center">
                  <p className="item--title u-remove-margin">Website</p>
                  <p className="item--info u-remove-margin">
                    <a
                      target="_blank"
                      href={agency.website}
                      rel="noopener noreferrer"
                    >
                      {agency.website}
                    </a>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EQuotePackageDetailsAgentFooter
