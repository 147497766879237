import axios from 'axios'
import 'url-search-params-polyfill'
import storageKeys from '../configuration/storageKeys'

// Handle Availability Fetch & Functionality
export default function fetchLabels() {
  // Formatted API path
  const labelsApi = process.env.REACT_APP_CONTENT_ENDPOINT + 'labels/eQuote'
  return axios.get(labelsApi)
}

export const receiveLabelsData = response => {
  const labels = response.data
  if (labels) {
    sessionStorage.setItem(storageKeys.labels, JSON.stringify(labels))
  }
}
