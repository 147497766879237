import {
  SET_PRICE_TABLE_BRAND_FILTER,
  SET_PRICE_TABLE_CATEGORY_FILTER,
  SET_PRICE_TABLE_DESTINATION_FILTER,
  SET_PRICE_TABLE_CURRENCY_FILTER
} from '../actions/setActivePriceTableSearchFilters';

const initialState = {
  brand: 'C,R',
  category: 'bestUnfilteredRate',
  destination: 'all',
  currency: 'USD'
}

const setActiveSearchByPriceTableFiltersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRICE_TABLE_BRAND_FILTER:
      return Object.assign({}, state, {
        brand: action.payload,
      })
    case SET_PRICE_TABLE_CATEGORY_FILTER:
      return Object.assign({}, state, {
        category: action.payload,
      })
    case SET_PRICE_TABLE_DESTINATION_FILTER:
      return Object.assign({}, state, {
        destination: action.payload,
      })
    case SET_PRICE_TABLE_CURRENCY_FILTER:
      return Object.assign({}, state, {
        currency: action.payload,
      })
    default: return state;
  }
}



export default setActiveSearchByPriceTableFiltersReducer;
