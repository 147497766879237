import { combineReducers } from 'redux'
import fetchConfigReducers from './fetchConfigurationReducers'
import fetchDestinationMonthsReducers from './fetchDestinationMonthsReducers'
import fetchItineraryReducers from './fetchItineraryReducers'
import fetchLoyaltyReducers from './fetchLoyaltyReducers'
import fetchRelatedSailingsReducers from './fetchRelatedSailingsReducer'
import fetchSsoReducers from './fetchSsoReducers'
import fetchCalendarReducers from './fetchCalendarReducers'
import fetchPackagesReducers from './fetchPackagesReducers'
import fetchPackageBrandReducers from './fetchPackageBrandReducers'
import setActiveStepReducers from './setActiveStepReducers'
import setActiveSearchFiltersReducers from './setActiveSearchFiltersReducers'
import setActivePackageReducers from './setActivePackageReducers'
import setActiveCabinStepReducers from './setActiveCabinStepReducers'
import setActiveCabinReducers from './setActiveCabinReducers'
import setActiveEmailConfigurationReducers from './setActiveEmailConfigurationReducers'
import setCruiseToursReducers from './setCruiseToursReducers'
import setEditAgentInfoReducers from './setEditAgentInfoReducers'
import submitEditAgentReducers from './submitEditAgentReducers'
import submitQuoteReducers from './submitQuoteReducers'
import unsupportedQualifierPrices from './unsupportedQualifierPricesReducer'
import setActiveSearchByPriceTableFiltersReducer from './setActiveSearchByPriceTableFiltersReducer'
import fetchLandPackagesReducer from './fetchLandPackagesReducer'
import staticDataReducer from './staticDataReducer'
import setActiveLandPackageReducer from './setActiveLandPackageReducer'

const rootReducer = combineReducers({
  activeCabinData: setActiveCabinReducers,
  activeCabinStepData: setActiveCabinStepReducers,
  activeEmailConfigurationData: setActiveEmailConfigurationReducers,
  activeLandPackages: setActiveLandPackageReducer,
  activePackageData: setActivePackageReducers,
  activeSearchFilterData: setActiveSearchFiltersReducers,
  activeSearchByPriceTableFilters: setActiveSearchByPriceTableFiltersReducer,
  activeStepData: setActiveStepReducers,
  calendarData: fetchCalendarReducers,
  configurationData: fetchConfigReducers,
  cruiseToursData: setCruiseToursReducers,
  destinationMonthsData: fetchDestinationMonthsReducers,
  editAgentData: setEditAgentInfoReducers,
  itineraryData: fetchItineraryReducers,
  landPackagesData: fetchLandPackagesReducer,
  loyaltyData: fetchLoyaltyReducers,
  modifiedSsoData: submitEditAgentReducers,
  packageBrandData: fetchPackageBrandReducers,
  packagesData: fetchPackagesReducers,
  quoteData: submitQuoteReducers,
  relatedSailingsData: fetchRelatedSailingsReducers,
  ssoData: fetchSsoReducers,
  staticData: staticDataReducer,
  unsupportedQualifierPricesData: unsupportedQualifierPrices
})

export default rootReducer
