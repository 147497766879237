import React, { Component } from 'react'

class EQuoteSearchFiltersFullFormCurrency extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const { currentCurrency, handleSelectCurrency, currencies } = this.props

    return (
      <div className="equote__search-filters-form-currency u-custom-select">
        <select
          name="currency"
          onChange={e => handleSelectCurrency(e.target.value)}
          value={currentCurrency}
          tabIndex={0}
          aria-labelledby="currencyToggleLabel"
          className="limit-width"
        >
          {currencies && currencies.length > 0
            ? currencies.map(currency => (
                <option
                  key={currency.paymentCurrencyCode}
                  value={currency.paymentCurrencyCode}
                >
                  {currency.paymentCurrencyText}
                </option>
              ))
            : null}
        </select>
        <span className="u-custom-select__arrow" />
      </div>
    )
  }
}

export default EQuoteSearchFiltersFullFormCurrency
