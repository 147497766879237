import React, { useRef } from 'react'
import brandDetailsConfig from '../../configuration/brands'
import { filterImagesByFileName } from '../../utilities/filterImagesByFileName'
import EQuotePackageDetailsAgentInfoBanner from './EQuotePackageDetailsAgentInfoBanner'

import '../../styles/scss/components/equote-package-details-header.scss'

export default function EQuotePackageDetailsHeader(props) {
  const { brandDetails, brandClassName, modifiedSsoData, ssoData } = props
  const agentDetailBannerRef = useRef()
  let agencyLogo = null

  const handleShowAgentDetail = () => {
    agentDetailBannerRef.current.classList.toggle('show')
  }

  const brandImage = filterImagesByFileName(
    [brandDetails.badge_light],
    'details-hero'
  )[0].displayImage

  if (
    modifiedSsoData &&
    modifiedSsoData.agency &&
    modifiedSsoData.agency.logo
  ) {
    agencyLogo = modifiedSsoData.agency.logo
  } else if (ssoData && ssoData.agency && ssoData.agency.logo) {
    agencyLogo = ssoData.agency.logo
  }

  return (
    <>
      <div className={`equote__package-details-header ${brandClassName}`}>
        <div className="u-content-container u-flex-container--center equote__package-details-header--inner">
          {agencyLogo && (
            <div className="equote__package-details-header--agent-logo-container">
              <img
                onClick={handleShowAgentDetail}
                className="equote__package-details-header--agent-logo"
                src={agencyLogo}
                alt="eQuote"
              />
            </div>
          )}

          <div className="equote__package-details-header--equote-logo-container">
            <img
              src={brandDetailsConfig[brandDetails.brand_code].eQuoteLogo}
              alt="eQuote"
            />
          </div>
          <div className="equote__package-details-header--brand-logo-container">
            {brandDetails.brand_code && (
              <img src={brandImage} alt={brandDetails.name} />
            )}
          </div>
        </div>
      </div>
      {modifiedSsoData && ssoData && (
        <EQuotePackageDetailsAgentInfoBanner
          brandDetails={brandDetails}
          brandClassName={brandClassName}
          ref={agentDetailBannerRef}
          hideEditButton
          modifiedSsoData={modifiedSsoData}
          ssoData={ssoData}
        />
      )}
    </>
  )
}
