export default function getDisplayImage(images, filesNameByPriority) {
  /*
   * images is the array of images
   * filesByPriority is an array of file names, in order of preference,
   * */

  let displayImage

  // first check if we have any of the desired filesNameByPriority
  for (const image of images) {
    if (image && image.sizes) {
      if (displayImage) break
      for (const fileName of filesNameByPriority) {
        if (image.sizes[fileName]) {
          displayImage = image.sizes[fileName]
          break
        }
      }
    }
  }

  if (!displayImage) {
    // we didn't find any of our desired file name, try and fall back to the image.file

    for (const image of images) {
      if (image.file) {
        const { file, width, height } = image
        displayImage = {
          src: file,
          width,
          height
        }
      }
    }
  }

  return displayImage
}
