import {
  SET_AGENT_NAME,
  SET_AGENT_EMAIL,
  SET_AGENCY_NAME,
  SET_AGENCY_PHONE,
  SET_AGENCY_LOGO,
  SET_AGENCY_WEBSITE,
  RESET_AGENT_INFO
} from '../actions/setEditAgentInfo'

const initialState = {}

export default function setEditAgentInfoReducers(state = initialState, action) {
  switch (action.type) {
    case SET_AGENT_NAME:
      return {
        ...state,
        agentName: action.payload
      }

    case SET_AGENT_EMAIL:
      return {
        ...state,
        agentEmail: action.payload
      }

    case SET_AGENCY_NAME:
      return {
        ...state,
        agencyName: action.payload
      }

    case SET_AGENCY_PHONE:
      return {
        ...state,
        agencyPhone: action.payload
      }

    case SET_AGENCY_LOGO:
      return {
        ...state,
        agencyLogo: action.payload
      }

    case SET_AGENCY_WEBSITE:
      return {
        ...state,
        agencyWebsite: action.payload
      }

    case RESET_AGENT_INFO:
      return {
        ...state
      }

    default:
      return state
  }
}
