import axios from 'axios'
import 'url-search-params-polyfill'
import { setActiveBrand } from './setActiveSearchFilters'

export const FETCH_PACKAGE_BRAND_BEGIN = 'FETCH_PACKAGE_BRAND_BEGIN'
export const FETCH_PACKAGE_BRAND_FAILURE = 'FETCH_PACKAGE_BRAND_FAILURE'

export const fetchPackageBrandBegin = () => ({
  type: FETCH_PACKAGE_BRAND_BEGIN
})

export const fetchPackageBrandError = error => ({
  type: FETCH_PACKAGE_BRAND_FAILURE,
  payload: { error }
})

// Handle Package Brand Fetch & Functionality
export default function fetchPackageBrand(packageId) {
  return async dispatch => {
    // Formatted API path
    const packageBrandApi =
      process.env.REACT_APP_FLOW_ENDPOINT_URL +
      'content/package/' +
      packageId +
      '/brand'

    dispatch(fetchPackageBrandBegin())
    return await axios
      .get(packageBrandApi)
      .then(response => {
        const packageBrand = response.data
        if (packageBrand) {
          dispatch(setActiveBrand(packageBrand))
        }
      })
      .catch(error => {
        dispatch(fetchPackageBrandError(error))
      })
  }
}
