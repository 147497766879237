import React, { Component } from 'react'
import { states } from '../../../utilities/supportedStates'
import { provinces } from '../../../utilities/supportedProvinces'

class EQuoteSearchFiltersFullFormResident extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderStateOptions = () => {
    const renderStatesData = []

    if (states) {
      for (const state in states) {
        renderStatesData.push(
          <option key={states[state].value} value={states[state].value}>
            {states[state].name}
          </option>
        )
      }

      return <optgroup label="United States">{renderStatesData}</optgroup>
    }
  }

  renderProvinceOptions = () => {
    const renderProvincesData = []

    if (provinces) {
      for (const province in provinces) {
        renderProvincesData.push(
          <option
            key={provinces[province].value}
            value={provinces[province].value}
          >
            {provinces[province].name}
          </option>
        )
      }

      return <optgroup label="Canada">{renderProvincesData}</optgroup>
    }
  }

  render() {
    const { residentOf, handleSelectResidentOf } = this.props

    return (
      <div className="equote__search-filters-form-resident u-custom-select">
        <select
          name="residency"
          onChange={e => handleSelectResidentOf(e.target.value)}
          value={residentOf}
          tabIndex={0}
          aria-labelledby="residencyToggleLabel"
        >
          <option value="">Choose Resident Location</option>
          {this.renderStateOptions()}
          {this.renderProvinceOptions()}
        </select>
        <span className="u-custom-select__arrow" />
      </div>
    )
  }
}

export default EQuoteSearchFiltersFullFormResident
