import React, { Component } from 'react'

class EQuoteSearchFiltersFullFormBrand extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      brands,
      currentBrand,
      handleSelectBrand,
      disabledBrands
    } = this.props

    return (
      <div className="equote__search-filters-form-field-input u-custom-select">
        <select
          name="brand"
          onChange={e => handleSelectBrand(e.target.value)}
          value={currentBrand}
          tabIndex={0}
          aria-labelledby="brandToggleLabel"
        >
          <option value="C,R">All Brands</option>
          {brands
            .filter(brand => brand.brand_code !== 'D')
            .map(brand => (
              <option
                key={brand.brand_code}
                value={brand.brand_code}
                disabled={
                  disabledBrands &&
                  disabledBrands.some(code => code === brand.brand_code)
                }
              >
                {brand.name}
              </option>
            ))}
        </select>
        <span className="u-custom-select__arrow" />
      </div>
    )
  }
}

export default EQuoteSearchFiltersFullFormBrand
