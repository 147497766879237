import React, { Component } from 'react'
import { connect } from 'react-redux'

import submitQuote from '../../actions/submitQuoteActions'

import brandLoader from '../../assets/icons/spinner.gif'

import '../../styles/scss/components/equote-review-email-submit-quote.scss'
import {getAllActivePackagesDetails} from "../../reducers/setActivePackageReducers";
import {getAllActiveCabinData} from "../../reducers/setActiveCabinReducers";

var _ = require('lodash')

class EQuoteReviewEmailSubmitQuote extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleSubmissionQuotes = () => {
    if (!this.isSubmitQuoteDisabled()) {
      this.props.handleSubmitQuote()
    }
  }

  isSubmitQuoteDisabled = () => {
    const {
      activePackages,
      activeCabinData,
      activeEmailConfigurationData,
      ssoData
    } = this.props

    let submitQuoteDisabled = true

    // Enable submit button if all flags pass
    if (
      !_.isEmpty(activePackages) &&
      !_.isEmpty(activeCabinData) &&
      !_.isEmpty(ssoData.agent) &&
      !_.isEmpty(activeEmailConfigurationData.recipients) &&
      !_.isEmpty(activeEmailConfigurationData.headline) &&
      !_.isEmpty(activeEmailConfigurationData.comments) &&
      activeEmailConfigurationData.userAgreement
    ) {
      submitQuoteDisabled = false
    }

    return submitQuoteDisabled
  }

  render() {
    const { activeEmailConfigurationData, quoteLoading } = this.props

    return (
      <div className=" equote__review-email-submit-quote equote__review-email-social">
        {quoteLoading ? (
          <div className="equote__navigation--arrows">
            <button className="btn pointer active" disabled>
              <div className="pointer-inner">
                Sending email... <img src={brandLoader} alt="loading..." />
              </div>
            </button>
          </div>
        ) : (
          <div className="equote__navigation--arrows">
            <button
              className="btn pointer active"
              onClick={this.handleSubmissionQuotes}
              disabled={this.isSubmitQuoteDisabled()}
            >
              <div className="pointer-inner">
                {this.isSubmitQuoteDisabled() &&
                _.isEmpty(activeEmailConfigurationData.recipients)
                  ? 'Please enter recipient emails'
                  : this.isSubmitQuoteDisabled() &&
                    !activeEmailConfigurationData.userAgreement
                  ? 'Please acknowledge terms & conditions'
                  : 'Looks Good. Send Email.'}
              </div>
            </button>
          </div>
        )}
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    activePackages: getAllActivePackagesDetails(state),
    activeCabinData: getAllActiveCabinData(state),
    activeSearchFilterData: state.activeSearchFilterData,
    activeEmailConfigurationData: state.activeEmailConfigurationData,
    quoteLoading: state.quoteData.loading,
    ssoData: state.ssoData
  }
}

const mapDispatchToProps = dispatch => ({
  handleSubmitQuote() {
    return dispatch(submitQuote())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteReviewEmailSubmitQuote)
