import React, { Component } from 'react'
import { connect } from 'react-redux'

import triggerCustomGaEvent from '../../../utilities/triggerCustomGaEvent'

import EQuoteSearchPriceTableContainer from '../../../components/search/price/EQuoteSearchPriceTableContainer'
import brandLoader from '../../../assets/icons/brand-loader-final.gif'

class EQuoteSearchPriceContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    // Dispatch GA event for search component
    const trackingComponentData = {
      currentStep: 5,
      component: 'Price Search By Destination'
    }

    triggerCustomGaEvent(
      'equoteTracking-currentActiveStep',
      trackingComponentData
    )
  }

  render() {
    if (this.props.isLoading) {
      return (
        <div className="u-loading-spinner">
          <div className="u-loading-spinner">
            <img src={brandLoader} alt="loading..." />
          </div>
        </div>
      )
    }

    const { destinationMonthsData, availableMonths } = this.props
    return (
      <div className="equote__search-price-container ">
        <EQuoteSearchPriceTableContainer
          destinationMonthsData={destinationMonthsData}
          availableMonths={availableMonths}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    destinationMonthsData: state.destinationMonthsData.destinations,
    availableMonths: state.destinationMonthsData.months,
    isLoading: state.destinationMonthsData.loading
  }
}

const mapDispatchToProps = () => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteSearchPriceContainer)
