import React from 'react'
import ReactModal from 'react-modal'
import closeIcon from '../../assets/icons/close.svg'

import '../../styles/scss/components/equote-terms-modal.scss'

const EQuoteTermsModal = props => {
  return (
    <ReactModal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      className={{
        base: 'equote__terms_modal u-modal',
        afterOpen: 'u-modal--visible',
        beforeClose: 'u-modal--close',
        ariaHideApp: false
      }}
      overlayClassName={{
        base: 'u-fade',
        afterOpen: 'u-fade--visible',
        beforeClose: 'u-fade--visible'
      }}
      ariaHideApp={false}
      htmlOpenClassName="ReactModal__Html--open"
    >
      <div className="u-modal__header">
        <div
          className="u-modal__close u-margin-l-auto"
          onClick={props.onRequestClose}
          tabIndex={0}
        >
          Close{' '}
          <img
            className="u-modal__close-icon"
            src={closeIcon}
            alt={`${props.title} Modal Close`}
          />
        </div>
      </div>
      <div
        className="u-modal__body u-font-body"
        dangerouslySetInnerHTML={{ __html: props.content }}
      />
    </ReactModal>
  )
}

export default EQuoteTermsModal
