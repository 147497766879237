export default function(packageDetails) {
  let isDisabled = Boolean(
    packageDetails.mustFetchUnsupportedQualifiers ||
      packageDetails.isSoldOut ||
      !packageDetails.tabularPricing
  )

  if (packageDetails.tabularPricing) {
    // make sure we have at least one super category with pricing results based on guestCount
    isDisabled = !Object.values(packageDetails.tabularPricing).some(
      superCat => {
        return (
          superCat.superCategoryData &&
          superCat.superCategoryData.bestGuestCountRate
        )
      }
    )
  }

  return isDisabled
}
