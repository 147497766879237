import React, { useEffect, useRef, useState } from 'react'
import useElementEnteredScreen from '../../hooks/useElementEnteredScreen'

const VideoSlide = ({ slide, isActive }) => {
  const [hasAutoPlayed, setHasPlayed] = useState(false)
  const ref = useRef()
  const isIntersecting = useElementEnteredScreen({
    ref,
    continueObserving: true
  })
  useEffect(() => {
    const current = ref.current

    if (current && !current.paused && (!isActive || !isIntersecting)) {
      current.pause()
    }
  }, [isActive, ref, isIntersecting])

  useEffect(() => {
    const current = ref.current
    if (!hasAutoPlayed && isIntersecting && isActive) {
      setHasPlayed(true)
      current.play()
    }
  }, [isIntersecting, isActive, hasAutoPlayed, ref])
  return (
    <>
      <video
        controls
        ref={ref}
        playsInline
        muted
        style={{
          bottom: 0,
          height: '100%',
          left: 0,
          objectFit: 'cover',
          position: 'absolute',
          right: 0,
          top: 0,
          width: '100%'
        }}
        src={slide.file}
      />
    </>
  )
}

export default VideoSlide
