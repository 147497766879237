import React, { Component } from 'react'

class EQuoteReviewEmailHero extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { brandData, brandTotal, brands, selectedShipImage } = this.props
    let brandImage = brandTotal > 1 ? brands[2].image[0] : brandData[0].image[0]
    if (selectedShipImage) {
      brandImage = selectedShipImage
    }

    return (
      brandImage &&
      brandImage.sizes &&
      brandImage.sizes['email-header'] && (
        <div
          className="equote__review-email-hero u-bg-image"
          style={{
            backgroundImage: 'url(' + brandImage.sizes['email-header'] + ')'
          }}
        >
          <img
            src={brandImage.sizes['email-header']}
            className="u-element-remove"
            alt={
              brandImage.caption
                ? brandImage.caption
                : 'Featured Destination Image'
            }
          />
        </div>
      )
    )
  }
}

export default EQuoteReviewEmailHero
