import React from 'react'

import xRoyal from '../../assets/icons/x-royal.svg'
import xCelebrity from '../../assets/icons/x-celebrity.svg'
import facebookRoyal from '../../assets/icons/facebook-royal.svg'
import facebookCelebrity from '../../assets/icons/facebook-celebrity.svg'

export default function EQuotePackageSocialMedia(props) {
  const { brandCode } = props
  const isCelebrity = brandCode === 'C'
  return (
    <>
      <p>Forward to a Friend</p>
      {brandCode && (
        <div className="equote__package-details-outline-share-icons">
          <img src={isCelebrity ? xCelebrity : xRoyal} alt="x" />
          <img src={isCelebrity ? facebookCelebrity : facebookRoyal} alt="facebook" />
        </div>
      )}
    </>
  )
}
