export const ACTIVE_CABIN_TOTAL = 'ACTIVE_CABIN_TOTAL'
export const ACTIVE_CABIN_STEP = 'ACTIVE_CABIN_STEP'
export const ACTIVE_CABIN_ID = 'ACTIVE_CABIN_ID'
export const SHOW_LAND_PACKAGES = 'SHOW_LAND_PACKAGES'
export const setCabinTotal = totalCabinSteps => ({
  type: ACTIVE_CABIN_TOTAL,
  payload: totalCabinSteps
})

export const setCabinStep = activeCabinStep => ({
  type: ACTIVE_CABIN_STEP,
  payload: activeCabinStep
})

export const setActiveCabinStepId = id => ({
  type: ACTIVE_CABIN_ID,
  payload: id
})

export const goToLandPackages = () => ({
  type: SHOW_LAND_PACKAGES
})

export default function setActiveCabinStep(
  totalCabinSteps,
  activeCabinStep,
  activeCabinStepId
) {
  return dispatch => {
    dispatch(setCabinTotal(totalCabinSteps))
    dispatch(setCabinStep(activeCabinStep))
    dispatch(setActiveCabinStepId(activeCabinStepId))
  }
}
