import React, { Component } from 'react'
import { connect } from 'react-redux'

import Modal from 'react-modal'
import EQuoteEditAgentInfoInput from './EQuoteEditAgentInfoInput'

import {
  setAgentName,
  setAgentEmail,
  setAgencyName,
  setAgencyPhone,
  setAgencyWebsite,
  resetAgentInfo
} from '../../actions/setEditAgentInfo'

import submitEditAgent from '../../actions/submitEditAgentActions'

import closeIcon from '../../assets/icons/close.svg'
import brandLoader from '../../assets/icons/brand-loader-final.gif'
import errorIcon from '../../assets/icons/times-solid.svg'
import checkIcon from '../../assets/icons/check-solid.svg'

import '../../styles/scss/components/equote-agent-edit-info-modal.scss'

class EQuoteAgentEditInfoModal extends Component {
  state = {
    isModified: false,
    isPristine: true
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.editAgentData !== this.props.editAgentData &&
      this.state.isModified
    ) {
      this.setState({ isModified: false })
    }
  }

  handleCloseAgentModal = () => {
    const {
      handleToggleEditAgentInformation,
      handleResetAgentInfo
    } = this.props

    handleToggleEditAgentInformation()
    handleResetAgentInfo()
    this.setState({ isModified: false })
  }

  handleSubmitEditAgent = () => {
    this.setState({ isModified: true })
    this.props.handleSubmitEditAgent()
  }

  handleInputChange = e => {
    this.setState({
      isPristine: false
    })
    const {
      handleSetAgentName,
      handleSetAgentEmail,
      handleSetAgencyName,
      handleSetAgencyPhone,
      handleSetAgencyWebsite
    } = this.props

    switch (e.target.name) {
      case 'editAgentName':
        handleSetAgentName(e.target.value)
        break

      case 'editAgentEmails':
        handleSetAgentEmail(e.target.value)
        break

      case 'editAgencyName':
        handleSetAgencyName(e.target.value)
        break

      case 'editAgencyPhone':
        handleSetAgencyPhone(e.target.value)
        break

      case 'editAgencyWebsite':
        handleSetAgencyWebsite(e.target.value)
        break

      default:
        break
    }
  }

  render() {
    const {
      editAgentModalVisible,
      editAgentData,
      ssoData,
      modifiedSsoData
    } = this.props

    return (
      <Modal
        className={{
          base: 'equote__agent-edit-info u-modal u-modal__md',
          afterOpen: 'u-modal--visible',
          beforeClose: 'u-modal--close',
          ariaHideApp: false
        }}
        overlayClassName={{
          base: 'u-fade',
          afterOpen: 'u-fade--visible',
          beforeClose: 'u-fade--visible'
        }}
        isOpen={editAgentModalVisible}
        onRequestClose={this.handleCloseAgentModal}
        contentLabel="eQuote Edit Agent Info Modal"
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        htmlOpenClassName="ReactModal__Html--open"
      >
        <div
          className="u-modal__close"
          onClick={this.handleCloseAgentModal}
          tabIndex={0}
        >
          Close{' '}
          <img
            className="u-modal__close-icon"
            src={closeIcon}
            alt="eQuote Ship Modal Close"
          />
        </div>
        <div className="u-modal__header">
          <h6 className="u-remove-margin">Edit Agent Information</h6>
        </div>

        <div className="u-modal__body">
          <div className="equote__agent-edit-info-group-input">
            <EQuoteEditAgentInfoInput
              labelId="label-edit-agent-name"
              labelText="Agent Name"
              name="editAgentName"
              type="text"
              placeholder={
                ssoData && ssoData.agent && ssoData.agent.name
                  ? ssoData.agent.name
                  : 'Enter Agent Name'
              }
              editAgentEvent={this.handleInputChange}
              value={
                modifiedSsoData &&
                modifiedSsoData.agent &&
                modifiedSsoData.agent.name
                  ? modifiedSsoData.agent.name
                  : null
              }
            />

            <EQuoteEditAgentInfoInput
              labelId="label-edit-agent-emails"
              labelText="Agent Email"
              name="editAgentEmails"
              type="email"
              placeholder={
                ssoData && ssoData.agent && ssoData.agent.email
                  ? ssoData.agent.email
                  : 'Enter Agent email'
              }
              editAgentEvent={this.handleInputChange}
              value={
                modifiedSsoData &&
                modifiedSsoData.agent &&
                modifiedSsoData.agent.email
                  ? modifiedSsoData.agent.email
                  : null
              }
            />
          </div>
          <div className="equote__agent-edit-info-group-input">
            <EQuoteEditAgentInfoInput
              labelId="label-edit-agency-name"
              labelText="Agency Name"
              name="editAgencyName"
              type="text"
              placeholder={
                ssoData && ssoData.agency && ssoData.agency.name
                  ? ssoData.agency.name
                  : 'Enter Agency Name'
              }
              editAgentEvent={this.handleInputChange}
              value={
                modifiedSsoData &&
                modifiedSsoData.agency &&
                modifiedSsoData.agency.name
                  ? modifiedSsoData.agency.name
                  : null
              }
            />

            <EQuoteEditAgentInfoInput
              labelId="label-edit-agency-phone"
              labelText="Agency Phone"
              name="editAgencyPhone"
              type="text"
              placeholder={
                ssoData && ssoData.agency && ssoData.agency.phone
                  ? ssoData.agency.phone
                  : 'Enter Agency Phone'
              }
              editAgentEvent={this.handleInputChange}
              value={
                modifiedSsoData &&
                modifiedSsoData.agency &&
                modifiedSsoData.agency.phone
                  ? modifiedSsoData.agency.phone
                  : null
              }
            />

            <EQuoteEditAgentInfoInput
              labelId="label-edit-agency-website"
              labelText="Agency Website"
              name="editAgencyWebsite"
              type="text"
              placeholder={
                ssoData && ssoData.agency && ssoData.agency.website
                  ? ssoData.agency.website
                  : 'Enter Agency Website'
              }
              editAgentEvent={this.handleInputChange}
              value={
                modifiedSsoData &&
                modifiedSsoData.agency &&
                modifiedSsoData.agency.website
                  ? modifiedSsoData.agency.website
                  : null
              }
            />
          </div>
          {modifiedSsoData.loading ? (
            <img
              className="equote__agent-edit-info-spinner"
              src={brandLoader}
              alt="loading..."
            />
          ) : (
            <div className="equote__agent-edit-info-button">
              <button
                className="btn btn-primary"
                onClick={
                  !this.state.isPristine
                    ? () => this.handleSubmitEditAgent()
                    : null
                }
                disabled={this.state.isPristine}
              >
                Submit
              </button>
            </div>
          )}

          {this.state.isModified &&
          editAgentData &&
          modifiedSsoData &&
          !modifiedSsoData.loading &&
          modifiedSsoData.agent &&
          modifiedSsoData.agency ? (
            <div className="equote__agent-edit-info-message">
              <p>
                {' '}
                <img src={checkIcon} alt="success icon" />
                Edit Agent Successful
              </p>

              <button onClick={this.handleCloseAgentModal}>
                Return to eQuote
              </button>
            </div>
          ) : !modifiedSsoData.loading && this.state.isModified ? (
            <div className="equote__agent-edit-info-message">
              <p>
                <img src={errorIcon} alt="error icon" />
                {`Something went wrong while processing the request. Please, try again!`}
              </p>
              <button onClick={this.handleCloseAgentModal}>
                Return to eQuote
              </button>
            </div>
          ) : null}

          {this.state.isModified &&
          modifiedSsoData.error &&
          !modifiedSsoData.loading ? (
            <div className="equote__agent-edit-info-message">
              <p>
                <img src={errorIcon} alt="error icon" />
                {modifiedSsoData.error.message
                  ? modifiedSsoData.error.message
                  : modifiedSsoData.error}
              </p>
              <button onClick={this.handleCloseAgentModal}>
                Return to eQuote
              </button>
            </div>
          ) : null}
        </div>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    ssoData: state.ssoData,
    editAgentData: state.editAgentData,
    modifiedSsoData: state.modifiedSsoData
  }
}

const mapDispatchToProps = dispatch => ({
  handleSetAgentName(agentName) {
    return dispatch(setAgentName(agentName))
  },
  handleSetAgentEmail(agentEmail) {
    return dispatch(setAgentEmail(agentEmail))
  },
  handleSetAgencyName(agencyName) {
    return dispatch(setAgencyName(agencyName))
  },
  handleSetAgencyPhone(agencyPhone) {
    return dispatch(setAgencyPhone(agencyPhone))
  },
  handleSetAgencyWebsite(agencyWebsite) {
    return dispatch(setAgencyWebsite(agencyWebsite))
  },
  // handleSetAgencyLogo(agencyLogo) {
  //   return dispatch(setAgencyLogo(agencyLogo))
  // },
  handleResetAgentInfo() {
    return dispatch(resetAgentInfo())
  },
  handleSubmitEditAgent() {
    dispatch(submitEditAgent())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteAgentEditInfoModal)
