import React from 'react'

import xRoyal from '../../assets/icons/x-royal.svg'
import xCelebrity from '../../assets/icons/x-celebrity.svg'
import facebookRoyal from '../../assets/icons/facebook-royal.svg'
import facebookCelebrity from '../../assets/icons/facebook-celebrity.svg'

export default function EQuoteReviewEmailSocial(props) {
  const {
    brandData,
    imageForSharerLink,
    linkForSharer,
    templateClass
  } = props

  const isCelebrity = templateClass === 'brand-c'

  const defaultText = `I’m really excited about this great offer from ${
    brandData[0].name
  } that my travel advisor found!`

  const twitter_link = `http://twitter.com/share?text=${encodeURI(
    defaultText
  )}&url=${linkForSharer}`
  const facebook_link = `https://www.facebook.com/sharer/sharer.php?u=${
    window.location.href
  }&picture=${imageForSharerLink}&quote=${encodeURI(
    defaultText
  )}`

  const openLinkOnNewWindow = e => {
    e.preventDefault()
    return window.open(
      e.currentTarget.href,
      e.currentTarget.name,
      'toolbar=0, status=0, width=600, height=400'
    )
  }

  return linkForSharer ? (
    <div className="equote__review-email-social">
      <h4 className="u-remove-margin">Forward to a Friend</h4>
      {brandData && (
        <div className="equote__review-email-social-icons">
          <a href={twitter_link} name="X" onClick={openLinkOnNewWindow}>
            <img src={isCelebrity ? xCelebrity : xRoyal} alt="X" />
          </a>
          <a href={facebook_link} name="Facebook" onClick={openLinkOnNewWindow}>
            <img src={isCelebrity ? facebookCelebrity : facebookRoyal} alt="facebook" />
          </a>
        </div>
      )}
    </div>
  ) : (
    <div className="equote__review-email-social">
      <h4 className="u-remove-margin">Forward to a Friend</h4>
      <div className="equote__review-email-social-icons">
        <img src={isCelebrity ? xCelebrity : xRoyal} alt="X" />
        <img src={isCelebrity ? facebookCelebrity : facebookRoyal} alt="Facebook" />
      </div>
    </div>
  )
}
