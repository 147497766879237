import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'

import EQuoteAgentEditInfoModal from './EQuoteAgentEditInfoModal'

import triggerCustomGaEvent from '../../utilities/triggerCustomGaEvent'

import '../../styles/scss/components/equote-package-details-agent-banner.scss'

class EQuoteAgentDetailsBanner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editAgentModalVisible: false,
      seeAgentDetailsVisible: false
    }

    this.agentInfoRef = createRef()
  }

  handleToggleEditAgentInformation = () => {
    this.setState({
      editAgentModalVisible: !this.state.editAgentModalVisible
    }, () => triggerCustomGaEvent('editAgentInfo', !this.state.editAgentModalVisible))   
  }

  handleToggleSeeAgentInformation = () => {
    this.setState(prevState => ({
      ...prevState,
      seeAgentDetailsVisible: !prevState.seeAgentDetailsVisible
    }))
    this.agentInfoRef.current.classList.toggle('show')
  }

  render() {
    const { modifiedSsoData, ssoData } = this.props
    let agentName = null,
      agentEmail = null,
      //agentPhone = null,
      agencyName = null,
      agencyEmail = null,
      agencyPhone = null,
      agencyWebsite = null

    // Set Agent data edit VIA edit agent response, content hub or ESL
    if (
      modifiedSsoData &&
      modifiedSsoData.agent &&
      modifiedSsoData.agent.name
    ) {
      agentName = modifiedSsoData.agent.name
    } else if (ssoData && ssoData.agent && ssoData.agent.name) {
      agentName = ssoData.agent.name
    }

    if (
      modifiedSsoData &&
      modifiedSsoData.agent &&
      modifiedSsoData.agent.email
    ) {
      agentEmail = modifiedSsoData.agent.email
    } else if (ssoData && ssoData.agent && ssoData.agent.email) {
      agentEmail = ssoData.agent.email
    }

    // if (
    //   modifiedSsoData &&
    //   modifiedSsoData.agent &&
    //   modifiedSsoData.agent.phone
    // ) {
    //   agentPhone = modifiedSsoData.agent.phone
    // } else if (
    //   ssoData &&
    //   ssoData.custom &&
    //   ssoData.custom.agent &&
    //   ssoData.custom.agent.phone
    // ) {
    //   agentPhone = ssoData.custom.agent.phone
    // } else if (ssoData && ssoData.agent && ssoData.agent.phone) {
    //   agentPhone = ssoData.agent.phone
    // }

    // Set Agency data edit VIA edit agent response, content hub or ESL
    if (
      modifiedSsoData &&
      modifiedSsoData.agency &&
      modifiedSsoData.agency.name
    ) {
      agencyName = modifiedSsoData.agency.name
    } else if (ssoData && ssoData.agency && ssoData.agency.name) {
      agencyName = ssoData.agency.name
    }

    if (
      modifiedSsoData &&
      modifiedSsoData.agency &&
      modifiedSsoData.agency.email
    ) {
      agencyEmail = modifiedSsoData.agency.email
    } else if (ssoData && ssoData.agency && ssoData.agency.email) {
      agencyEmail = ssoData.agency.email
    }

    if (
      modifiedSsoData &&
      modifiedSsoData.agency &&
      modifiedSsoData.agency.phone
    ) {
      agencyPhone = modifiedSsoData.agency.phone
    } else if (ssoData && ssoData.agency && ssoData.agency.phone) {
      agencyPhone = ssoData.agency.phone
    }

    if (
      modifiedSsoData &&
      modifiedSsoData.agency &&
      modifiedSsoData.agency.website
    ) {
      agencyWebsite = modifiedSsoData.agency.website
    } else if (ssoData && ssoData.agency && ssoData.agency.website) {
      agencyWebsite = ssoData.agency.website
    }

    return (
      <>
        <div
          className="equote-agent-details-banner-show-info equote-agent-details-banner-edit u-flex-container u-flex-container--justify-center"
          onClick={this.handleToggleSeeAgentInformation}
        >
          <button className="btn btn-transparent">
            {this.state.seeAgentDetailsVisible
              ? 'Close Agent Information'
              : 'See Agent Information'}
          </button>
        </div>

        <div ref={this.agentInfoRef} className="equote-agent-details-banner">
          <div className="u-content-container">
            <div className="equote-agent-details-banner--inner">
              <div className="equote-agent-details-banner--contacts">
                {agentName ? (
                  <div className="item">
                    <p className="item--title u-remove-margin">Agent Name</p>
                    <p className="item--info u-remove-margin">{agentName}</p>
                  </div>
                ) : null}

                {agencyName ? (
                  <div className="item">
                    <p className="item--title u-remove-margin">Agency</p>
                    <p className="item--info u-remove-margin">{agencyName}</p>
                  </div>
                ) : null}

                {agentEmail ? (
                  <div className="item">
                    <p className="item--title u-remove-margin">Email</p>
                    <p className="item--info u-remove-margin">{agentEmail}</p>
                  </div>
                ) : agencyEmail ? (
                  <div className="item">
                    <p className="item--title u-remove-margin">Email</p>
                    <p className="item--info u-remove-margin">{agencyEmail}</p>
                  </div>
                ) : null}

                {agencyPhone ? (
                  <div className="item">
                    <p className="item--title u-remove-margin">Phone</p>
                    <p className="item--info u-remove-margin u-link">
                      {agencyPhone}
                    </p>
                  </div>
                ) : null}

                {agencyWebsite ? (
                  <div className="item">
                    <p className="item--title u-remove-margin">Website</p>
                    <p className="item--info u-remove-margin">
                      {agencyWebsite}
                    </p>
                  </div>
                ) : null}
              </div>

              <div className="equote-agent-details-banner-edit">
                <button
                  onClick={() => this.handleToggleEditAgentInformation()}
                  className="btn btn-transparent"
                >
                  Edit Agent Information
                </button>
              </div>

              <EQuoteAgentEditInfoModal
                editAgentModalVisible={this.state.editAgentModalVisible}
                handleToggleEditAgentInformation={() =>
                  this.handleToggleEditAgentInformation()
                }
              />
            </div>
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    modifiedSsoData: state.modifiedSsoData,
    ssoData: state.ssoData
  }
}

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteAgentDetailsBanner)
