import {
  ACTIVE_CABIN_TOTAL,
  ACTIVE_CABIN_STEP,
  ACTIVE_CABIN_ID,
  SHOW_LAND_PACKAGES
} from '../actions/setActiveCabinStepActions'
import { CLEAR_PACKAGE_DATA } from '../actions/fetchPackages'

const initialState = {
  cabinTotal: null,
  activeCabin: null,
  activeCabinId: null,
  showingLandPackages: false
}

export default function setActiveCabinStepReducers(
  state = initialState,
  action
) {
  switch (action.type) {
    case ACTIVE_CABIN_TOTAL:
      return {
        ...state,
        cabinTotal: action.payload
      }

    case ACTIVE_CABIN_STEP:
      return {
        ...state,
        activeCabin: action.payload,
        showingLandPackages: false
      }
    case ACTIVE_CABIN_ID:
      return {
        ...state,
        activeCabinId: action.payload,
        showingLandPackages: false
      }
    case SHOW_LAND_PACKAGES:
      return {
        ...state,
        showingLandPackages: true
      }
    case CLEAR_PACKAGE_DATA:
      return initialState
    default:
      return state
  }
}
