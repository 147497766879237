import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import {
  receiveUnsupportedQualifierPricing,
  receiveUnsupportedQualifierPricingError
} from '../../../actions/fetchUnsupportedQualifierPrices'
import LoadingIcon from '../../../assets/icons/brand-loader-final.gif'
import formatPrice, { formatDisplayPrice } from '../../../utilities/formatPrice'
import formatLeadPromoDesc from '../../../utilities/formatLeadPromoDesc'
import { getPackagesDetails } from '../../../reducers/fetchPackagesReducers'

class EQuoteTablePriceCell extends Component {
  constructor(props) {
    super(props)
    this.onAjaxPricingLoad = this.onAjaxPricingLoad.bind(this)
    this.onAjaxPricingError = this.onAjaxPricingError.bind(this)
    const ajaxRequest = new XMLHttpRequest()
    ajaxRequest.onload = this.onAjaxPricingLoad
    ajaxRequest.onerror = this.onAjaxPricingError
    this.state = {
      isLoading: false,
      ajaxRequest
    }

    this.fetchUnsupportedQualifierPrices = this.fetchUnsupportedQualifierPrices.bind(
      this
    )
  }

  componentDidMount() {
    if (this.props.packageDetails.mustFetchUnsupportedQualifiers) {
      this.setState({ isLoading: true }, () => {
        this.fetchUnsupportedQualifierPrices()
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // when the table updates, it doesn't mount new price cells it just updates the ones already there.
    // to prevent the possibility of 100's of pending request
    // we need to cancel any pending ajax request if the cells change
    if (
      prevProps.packageIdentifier !== this.props.packageIdentifier &&
      (this.props.stateroomType === 'I' ||
        this.props.isCruiseTour ||
        this.props.isLandPackage) &&
      prevState.isLoading
    ) {
      // ------------ need to abort--------------
      prevState.ajaxRequest.abort()
      // check if we need the new pricing
      if (this.props.packageDetails.mustFetchUnsupportedQualifiers) {
        return this.setState({ isLoading: true }, () =>
          this.fetchUnsupportedQualifierPrices()
        )
      }
    }

    if (
      !this.props.packageDetails.mustFetchUnsupportedQualifiers &&
      this.state.isLoading
    ) {
      this.setState({ isLoading: false })
    }

    if (
      this.props.packageDetails.mustFetchUnsupportedQualifiers &&
      !this.state.isLoading &&
      !this.props.hasPricingError
    ) {
      //  ------------ still need prices  ------------
      this.setState({ isLoading: true }, () =>
        this.fetchUnsupportedQualifierPrices()
      )
    }
  }

  componentWillUnmount() {
    // If we do a new search, or switch from cruise to Cruisetours the cells will unmount
    // and we can cancel any pending network requests.
    if (
      this.state.isLoading &&
      this.state.ajaxRequest &&
      (this.props.stateroomType === 'I' ||
        this.props.isCruiseTour ||
        this.props.isLandPackage)
    ) {
      this.state.ajaxRequest.abort()
    }
  }
  fetchUnsupportedQualifierPrices() {
    if (
      this.props.stateroomType === 'I' ||
      this.props.isCruiseTour ||
      this.props.isLandPackage
    ) {
      //to prevent duplicate calls - (all prices are returned together)
      // we'll only make the call from the interior stateroom
      // or when we only have one price cell in the table
      this.state.ajaxRequest.open(
        'GET',
        this.props.packageDetails.unSupportedQualifiersReq,
        true
      )
      this.state.ajaxRequest.send()
    }
  }
  onAjaxPricingLoad() {
    const response = JSON.parse(this.state.ajaxRequest.response)

    if (response && response.id && response.sailDate) {
      // put this response in redux
      this.props.receiveUnsupportedQualifierPricing({
        sailDate: response.sailDate,
        id: response.id,
        pricing: response.package,
        brand: this.props.packageDetails.brand
      })
    } else {
      this.props.receiveUnsupportedQualifierPricingError({
        sailDate: this.props.packageDetails.sailDate,
        id: this.props.packageDetails.id
      })
    }
  }
  onAjaxPricingError() {
    this.props.receiveUnsupportedQualifierPricingError({
      sailDate: this.props.packageDetails.sailDate,
      id: this.props.packageDetails.id
    })
  }
  getElementsThatHasChildWithClass = (elementSelector, classSelector) => {
    const rowsElement = document.querySelectorAll(elementSelector)
    return [].slice.call(rowsElement).filter(row => {
      return row.querySelector(classSelector)
    })
  }

  getRowThatHasElement = (arrayElements, targetElement) => {
    let rowFound = null
    arrayElements.forEach(row => {
      const divsWithClass = row.querySelectorAll('.active-price')
      const arrayDivsWithClass = [].slice.call(divsWithClass)
      arrayDivsWithClass.forEach(div => {
        if (div === targetElement) {
          rowFound = row
        }
      })
    })
    return rowFound
  }

  removeClassAllElements = element => {
    const elements = element.querySelectorAll('.active-price')
    const elementsArray = [].slice.call(elements)
    elementsArray.forEach(el => el.classList.remove('active-price'))
  }

  handleCellClick = e => {
    e.preventDefault()

    //If elements is already active, remove class and close the detail pane
    if (e.currentTarget.classList.contains('active-price')) {
      e.currentTarget.classList.toggle('active-price')
      this.props.onClick()
      return
    }

    //Add active-price class in the target cell to comparison purposes
    e.currentTarget.classList.add('active-price')

    //Find Rows that have active cells
    const rowsWithClassActive = this.getElementsThatHasChildWithClass(
      'tr',
      'div.active-price'
    )

    //Find Row with the target cell
    const rowWithActiveElement = this.getRowThatHasElement(
      rowsWithClassActive,
      e.currentTarget
    )

    //Remove class from all divs in the row of the the target cell
    this.removeClassAllElements(rowWithActiveElement)

    //Add active-price class in the target cell
    e.currentTarget.classList.add('active-price')

    this.props.onClick()
  }

  render() {
    if (this.state.isLoading && !this.props.hasPricingError) {
      return (
        <div className="u-table-price-cell loading">
          <img
            className="u-table-price-cell--loading-spinner"
            src={LoadingIcon}
            alt={'loading price'}
          />
        </div>
      )
    }
    const {
      packageData,
      price,
      unavailableText,
      perPersonCreditAmt,
      currencySymbol,
      taxes,
      stateroomType,
      isCruiseTour
    } = this.props

    const showTaxBanner = stateroomType === 'I' && !isCruiseTour

    return (
      <>
        {packageData && price ? (
          <div
            className={`u-table-price-cell ${
              isCruiseTour ? 'cruise-tour-cell' : ''
            }`}
            onClick={this.handleCellClick}
          >
            <button className="btn price-info-button u-transparent">
              <span className="equote__sailings-table-best-price">
                {currencySymbol}
                <span className="equote__sailings-table-best-price-value">
                  {price}
                </span>
              </span>
              {Boolean(
                perPersonCreditAmt && Number(perPersonCreditAmt) > 0
              ) && (
                <span className="equote__sailings-table-best-price-description">
                  {currencySymbol}
                  {perPersonCreditAmt} OBC
                </span>
              )}
              {packageData.leadPromoDesc && (
                <span className="equote__sailings-table-best-price-description u-font-uppercase">
                  {formatLeadPromoDesc(
                    packageData.leadPromoDesc,
                    packageData.promos
                  )}
                </span>
              )}
              {isCruiseTour && taxes ? (
                <span className="equote__sailings-table-best-price-taxes">
                  Taxes, fees & port expenses: {currencySymbol}
                  {formatDisplayPrice(taxes)}
                </span>
              ) : null}
            </button>
          </div>
        ) : (
          <div className="u-table-price-cell">
            <span className="u-font-sold-out">
              {unavailableText ? unavailableText : 'Sold Out'}
            </span>
          </div>
        )}
        {showTaxBanner && taxes ? (
          <p className={`equote__sailings-table-best-price-taxes absolute`}>
            Taxes, fees & port expenses: {currencySymbol}
            {formatDisplayPrice(taxes)}
          </p>
        ) : null}
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const packageDetails = getPackagesDetails(
    state,
    ownProps.isCruiseTour,
    ownProps.packageIdentifier
  )

  let pricing = packageDetails.tabularPricing
  const hasPricingError = packageDetails.pricingHasError
  // pricing will be null for unsupported qualifiers
  let packageData, price, perPersonCreditAmt

  if (!hasPricingError && pricing) {
    if (ownProps.isLandPackage || ownProps.isCruiseTour) {
      for (const superCatPricing of Object.values(pricing)) {
        if (
          superCatPricing.superCategoryData &&
          superCatPricing.superCategoryData.bestGuestCountRate &&
          superCatPricing.superCategoryData.bestGuestCountRate.netPrice &&
          (!packageData ||
            packageData.netPrice >
              superCatPricing.superCategoryData.bestGuestCountRate.netPrice)
        ) {
          packageData = superCatPricing.superCategoryData.bestGuestCountRate
        }
      }
    } else if (
      pricing[ownProps.stateroomType] &&
      pricing[ownProps.stateroomType].superCategoryData &&
      pricing[ownProps.stateroomType].superCategoryData.bestGuestCountRate
    ) {
      packageData =
        pricing[ownProps.stateroomType].superCategoryData.bestGuestCountRate
    }
  }

  if (!hasPricingError && packageData && packageData.perPersonAveragePrice) {
    perPersonCreditAmt = formatPrice(packageData.perPersonCreditAmt)
    price = formatPrice(packageData.perPersonAveragePrice)
  }
  if (price && ownProps.landPackagePerGuestPrice) {
    price = formatPrice(
      Number(price) + Number(ownProps.landPackagePerGuestPrice)
    )
  }

  let unavailableText
  if (!price || packageDetails.isSoldOut || hasPricingError) {
    // we need to know if it is sold out or not available at all
    unavailableText = hasPricingError
      ? 'Error'
      : ownProps.stateroomExists
      ? 'Sold Out'
      : 'N/A'
  }

  const packagePricing = get(packageDetails, 'pricing', {})

  const pricingArray = packagePricing ? Object.values(packagePricing) : []

  const firstPriceWithTaxes = pricingArray.find(price =>
    get(price, 'bestGuestCountRate.priceInfo')
  )

  return {
    packageDetails,
    hasPricingError,
    packageData,
    price,
    unavailableText,
    perPersonCreditAmt,
    currencySymbol: get(packageDetails, 'currencySymbol', '$'),
    currency: get(packageDetails, 'currency', 'USD'),
    taxes: get(firstPriceWithTaxes, 'bestGuestCountRate.priceInfo.taxesFees')
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const stateKey = ownProps.isCruiseTour ? 'cruiseToursData' : 'packagesData'
  return {
    receiveUnsupportedQualifierPricing(sailing) {
      dispatch(receiveUnsupportedQualifierPricing(stateKey, sailing))
    },
    receiveUnsupportedQualifierPricingError(sailing) {
      dispatch(receiveUnsupportedQualifierPricingError(stateKey, sailing))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteTablePriceCell)
