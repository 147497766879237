import React, { Component } from 'react'
import { connect } from 'react-redux'

import EQuoteSearchFilterDestinationModal from './EQuoteSearchFilterDestinationModal'

import triggerCustomGaEvent from '../../../utilities/triggerCustomGaEvent'

import destinationIcon from '../../../assets/icons/destination.svg'
import downArrow from '../../../assets/icons/arrow-down.svg'

import '../../../styles/scss/components/equote-search-filters-destinations.scss'
import { getLabelsData } from '../../../sessionStorage/getLabelsData'

class EQuoteSearchFilterDestination extends Component {
  constructor(props) {
    super(props)
    this.state = {
      destinationModalVisible: false
    }
  }

  // Trigger custom destination modal
  handleOpenDestinationModal = () => {
    this.setState({ destinationModalVisible: true })

    // Dispatch GA events
    triggerCustomGaEvent('equoteSearch-toggleDestination', true)
  }
  handleCloseDestinationModal = () => {
    this.setState({ destinationModalVisible: false })

    // Dispatch GA events
    triggerCustomGaEvent('equoteSearch-toggleDestination', false)
  }

  render() {
    const { labelsData } = this.props

    let searchLabels = null

    if (labelsData && labelsData.search) {
      searchLabels = labelsData.search
    }

    return (
      <div className="equote__search-filters-destinations equote__search-filter-group">
        <div className="equote__search-filter-icon">
          <img src={destinationIcon} alt="Ship icon" />
        </div>
        <h6 className="u-small-title">
          {searchLabels && searchLabels.by_destination_title
            ? searchLabels.by_destination_title
            : 'Search By Destination'}
        </h6>
        <div
          onClick={() => this.handleOpenDestinationModal()}
          className="equote__search-filter"
          tabIndex={0}
        >
          <p className="equote__search-filter-description u-remove-margin u-font-medium">
            {searchLabels && searchLabels.by_destination_body
              ? searchLabels.by_destination_body
              : 'Search for your clients ideal vacation by destination.'}
          </p>
          <p className="equote__search-filter-title u-remove-margin u-font-medium">
            {searchLabels && searchLabels.by_destination_cta
              ? searchLabels.by_destination_cta
              : 'Select Destination'}
            <img
              className="equote__search-filter-arrow"
              alt="Filter Down Arrow"
              src={downArrow}
            />
          </p>
        </div>

        <EQuoteSearchFilterDestinationModal
          handleCloseDestinationModal={this.handleCloseDestinationModal}
          destinationModalVisible={this.state.destinationModalVisible}
        />
      </div>
    )
  }
}

function mapStateToProps() {
  return {
    labelsData: getLabelsData()
  }
}

export default connect(mapStateToProps)(EQuoteSearchFilterDestination)
