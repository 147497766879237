import React from 'react'

const EQuoteEditAgentInfoInput = props => {
  const {
    labelId,
    labelText,
    name,
    type,
    placeholder,
    editAgentEvent,
    value
  } = props

  return (
    <div className={'equote__agent-edit-info-input input-' + name}>
      <label id={labelId} htmlFor={name}>
        {labelText}
      </label>

      <input
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={e => editAgentEvent(e)}
        aria-labelledby={labelId}
        defaultValue={value ? value : ''}
      />
    </div>
  )
}

export default EQuoteEditAgentInfoInput
