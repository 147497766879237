import React, { Component } from 'react'
import { connect } from 'react-redux'

import fetchCalendar from '../../../actions/fetchCalendar'

import 'react-dates/initialize'
import { DayPickerSingleDateController } from 'react-dates'

import Modal from 'react-modal'
import moment from 'moment'

import 'react-dates/lib/css/_datepicker.css'
import '../../../styles/scss/base/datepickers.scss'
import '../../../styles/scss/components/equote-search-filters-form-date-range-picker.scss'

import brandLoader from '../../../assets/icons/brand-loader-final.gif'
import prevArrow from '../../../assets/icons/arrow-left.svg'
import nextArrow from '../../../assets/icons/arrow-right.svg'
import closeIcon from '../../../assets/icons/close.svg'

import _ from 'lodash'

class EQuoteSearchFiltersFullFormDateRange extends Component {
  state = {
    selectedYear: null,
    numberOfMonths: window.innerWidth > 880 ? 2 : 1,
    iterator: 0
  }

  componentDidMount() {
    const {
      activeSearchFilterData,
      handleFetchCalendarAvailability
    } = this.props

    // Get available dates
    if (
      activeSearchFilterData.brand ||
      activeSearchFilterData.ship ||
      activeSearchFilterData.region
    ) {
      handleFetchCalendarAvailability(activeSearchFilterData)
    }

    window.addEventListener('resize', this.handleWindowResizeDisplayMonths)

    this.setState({
      selectedYear: this.props.currentDate
        ? this.props.currentDate.format('YYYY')
        : this.props.currentSelectedStartDate
        ? moment(this.props.currentSelectedStartDate, 'YYYY-MM-DD').format(
            'YYYY'
          )
        : moment().format('YYYY')
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResizeDisplayMonths)
  }

  handleWindowResizeDisplayMonths = _.debounce(() => {
    this.setState({ numberOfMonths: window.innerWidth > 880 ? 2 : 1 })
  }, 300)

  renderCalendarLegend() {
    return (
      <div className="equote__daypicker-legend">
        <div className="equote__daypicker-legend-col legend-available">
          <span className="legend-icon">#</span>
          <span className="legend-label">Available Sailing</span>
        </div>
        <div className="equote__daypicker-legend-col legend-blackout">
          <span className="legend-icon">#</span>
          <span className="legend-label">No Sailing</span>
        </div>
      </div>
    )
  }

  handleYearChange = e => {
    this.setState({
      selectedYear: parseInt(e.target.dataset.year)
    })
  }

  handleTodaySelection = () => {
    this.setState(
      prevState => ({
        ...prevState,
        selectedYear: moment().format('YYYY'),
        iterator: prevState.iterator + 1
      }),
      () => {
        const today = document.querySelector(
          '.equote__search-filters-form-date-range-picker .CalendarDay__today'
        )

        const event = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true
        })

        today && today.dispatchEvent(event)
      }
    )
  }

  renderYearButtons = () => {
    const currentYear = moment().format('YYYY'),
      renderButtonsData = []

    let i = 0

    // Generate 2 year buttons for calendar
    while (i < 3) {
      let year = currentYear

      if (i > 0) {
        year = moment(currentYear, 'YYYY').add(i, 'years')
      }

      renderButtonsData.push(
        <button
          className={
            this.state.selectedYear &&
            this.state.selectedYear.toString() ===
              moment(year, 'YYYY').format('YYYY')
              ? 'active-year'
              : null
          }
          key={moment(year, 'YYYY').format('YYYY')}
          data-year={moment(year, 'YYYY').format('YYYY')}
          onClick={this.handleYearChange}
        >
          {moment(year, 'YYYY').format('YYYY')}
        </button>
      )

      i++
    }

    let shouldRenderTodayButton = true

    //Check if should render today button
    if (this.props.blackoutDates) {
      const todayDate = moment()
      const isTodayInBlackout = this.props.blackoutDates.find(
        day => day === todayDate.format('YYYY-MM-DD')
      )
      const isTodayBeforeMinDate = this.props.minStartDate
        ? todayDate.isBefore(this.props.minStartDate)
        : false
      if (isTodayBeforeMinDate || isTodayInBlackout) {
        shouldRenderTodayButton = null
      }
    }

    // Render container and generated year buttons
    return (
      <div className="u-datepicker__years">
        {shouldRenderTodayButton && (
          <button
            className="u-datepicker__today"
            onClick={this.handleTodaySelection}
          >
            Today
          </button>
        )}

        {renderButtonsData}
      </div>
    )
  }

  render() {
    let {
      handleCloseDateRangeModal,
      dateRangeModalVisible,
      currentDate,
      onDateChange,
      activeSearchFilterData,
      blackoutDates,
      calendarLoading,
      minStartDate,
      currentSelectedStartDate,
      availableDates
    } = this.props
    const startSelectedDate = moment(currentSelectedStartDate)
    const lastDay = moment(
      availableDates && availableDates[availableDates.length - 1],
      'YYYY-MM-DD'
    )

    if (this.state.selectedYear && currentDate) {
      const yearDifferrence =
        this.state.selectedYear -
        parseInt(moment(currentDate, 'YYYY').format('YYYY'))
      currentDate.add(yearDifferrence, 'years')
    }
    if (this.state.selectedYear && currentSelectedStartDate && minStartDate) {
      const yearDifferrence =
        this.state.selectedYear -
        parseInt(moment(currentSelectedStartDate, 'YYYY').format('YYYY'))
      startSelectedDate.add(yearDifferrence, 'years')
    }

    const renderDateCell = day => {
      if (blackoutDates) {
        const formattedDate = day.format('YYYY-MM-DD'),
          currentDate = blackoutDates.find(day => day === formattedDate)
        // Render click-able blackout date format
        if (
          currentDate ||
          (!day.isSameOrBefore(lastDay) &&
            day.format('YYYY-MM-DD') !== lastDay.format('YYYY-MM-DD'))
        ) {
          return (
            <div className="equote__daypicker-cell equote__daypicker-cell--unavailable">
              {day.format('D')}
            </div>
          )
        }
      }

      return day.format('D')
    }

    //Check if the today button was clicked and rerender with current month
    if (this.state.iterator !== 0) {
      currentDate = moment()
    }
    if (!currentDate) {
      currentDate = this.props.currentSelectedStartDate
        ? moment(this.props.currentSelectedStartDate, 'YYYY-MM-DD')
        : moment().format('YYYY')
    }
    // Render calendar for fetchCalender or without
    const renderCalender = () => {
      return (
        <DayPickerSingleDateController
          key={this.state.selectedYear + this.state.iterator}
          numberOfMonths={this.state.numberOfMonths}
          renderDayContents={renderDateCell}
          isOutsideRange={
            minStartDate
              ? date =>
                  date.isBefore(minStartDate) ||
                  (date.isAfter(lastDay) &&
                    date.format('YYYY-MM-DD') !== lastDay.format('YYYY-MM-DD'))
              : date =>
                  date.isBefore(moment().subtract(1, 'days')) ||
                  (date.isAfter(lastDay) &&
                    date.format('YYYY-MM-DD') !== lastDay.format('YYYY-MM-DD'))
          }
          date={currentDate}
          onDateChange={onDateChange}
          focused={true}
          initialVisibleMonth={
            currentDate
              ? () => moment(currentDate, 'YYYY-MM-DD')
              : () => startSelectedDate
          }
          noBorder={true}
          daySize={window.innerWidth > 768 ? 52 : 40}
          hideKeyboardShortcutsPanel={true}
          navPrev={
            <img
              className="custom-daypicker-icon"
              src={prevArrow}
              alt="eQuote Date Range Previous"
            />
          }
          navNext={
            <img
              className="custom-daypicker-icon"
              src={nextArrow}
              alt="eQuote Date Range Next"
            />
          }
          renderCalendarInfo={this.renderCalendarLegend}
        />
      )
    }

    return (
      <Modal
        className={{
          base: 'equote__search-filters-form-date-range-picker u-modal',
          afterOpen: 'u-modal--visible',
          beforeClose: 'u-modal--close',
          ariaHideApp: false
        }}
        overlayClassName={{
          base: 'u-fade',
          afterOpen: 'u-fade--visible',
          beforeClose: 'u-fade--visible'
        }}
        isOpen={dateRangeModalVisible}
        onRequestClose={handleCloseDateRangeModal}
        contentLabel="eQuote Date Range Modal"
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        htmlOpenClassName="ReactModal__Html--open"
      >
        <div className="u-modal__header">
          <div
            className="u-modal__close"
            onClick={handleCloseDateRangeModal}
            tabIndex={0}
          >
            Close{' '}
            <img
              className="u-modal__close-icon"
              src={closeIcon}
              alt="eQuote Date Range Close"
            />
          </div>
        </div>

        <div className="u-modal__body">
          {activeSearchFilterData.brand ||
          activeSearchFilterData.ship ||
          activeSearchFilterData.region ? (
            calendarLoading ? (
              <div className="u-loading-spinner">
                <img src={brandLoader} alt="loading..." />
              </div>
            ) : (
              <>
                {this.renderYearButtons()}
                {renderCalender()}
              </>
            )
          ) : (
            <>
              {this.renderYearButtons()}
              {renderCalender()}
            </>
          )}
        </div>
      </Modal>
    )
  }
}
function mapStateToProps(state) {
  return {
    calendarLoading: state.calendarData.loading,
    blackoutDates: state.calendarData.blackoutDates,
    availableDates: state.calendarData.availableDates,
    calendarError: state.calendarData.error,
    currentSelectedStartDate: state.activeSearchFilterData.startDate
  }
}

const mapDispatchToProps = dispatch => ({
  handleFetchCalendarAvailability(activeSearchFilterData) {
    return dispatch(fetchCalendar(activeSearchFilterData))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteSearchFiltersFullFormDateRange)
