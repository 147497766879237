import React, { Component } from 'react'

class EQuoteReviewEmailBasicContent extends Component {
	constructor(props) {
		super(props)

		this.state = {
		}
	}

	render() {
		const { activeEmailConfigurationData, labelsData } = this.props
		const emailLabels = labelsData.email

		return (
			<div className="equote__review-email-basic-content">
				{ activeEmailConfigurationData.headline ?
					<h6 className="basic-content__title">{ activeEmailConfigurationData.headline }</h6>
				: labelsData && emailLabels && emailLabels.configure_headline_preview ?
					<h6 className="basic-content__title">{ emailLabels.configure_headline_preview }</h6>
				:
					<h6 className="basic-content__title">Personal or Default Headline</h6>
				}

				<div className="basic-content__body">
				{ activeEmailConfigurationData.comments ?
					activeEmailConfigurationData.comments
				: labelsData && emailLabels && emailLabels.configure_comments_preview ?
					emailLabels.configure_comments_preview
				:
					'Best known for their fleet of large, innovative vessels, the activities onboard a Royal Caribbean ship appeal to a broad demographic.'
				}
				</div>
			</div>
		)
	}
}

export default EQuoteReviewEmailBasicContent