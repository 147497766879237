import formatImages from './formatImages'

export default function(stateRoom) {
  return {
    ...stateRoom,
    stateroom: {
      ...stateRoom.stateroom,
      image: stateRoom.stateroom ? formatImages(stateRoom.stateroom.image) : []
    }
  }
}
