import { get } from 'lodash'
import {
  FETCH_CONFIG_BEGIN,
  FETCH_CONFIG_SUCCESS,
  FETCH_CONFIG_FAILURE,
  SET_CURRENT_QUERY_PARAMS
} from '../actions/fetchConfiguration'
import { ENABLE_MAINTENANCE_MODE } from '../actions/fetchStaticData'
import { getLabelsData } from '../sessionStorage/getLabelsData'

const initialState = {
  configuration: [],
  loading: false,
  error: null,
  maintenanceMode: false
}

export default function configurationReducers(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_QUERY_PARAMS:
      // Request success: set loading false, and display request data
      return {
        ...state,
        loading: false,
        currentQueryParams: action.payload
      }
    case FETCH_CONFIG_BEGIN:
      // Request begin: set loading true
      return {
        ...state,
        loading: true,
        error: null
      }

    case FETCH_CONFIG_SUCCESS:
      // Request success: set loading false, set config data
      return {
        ...state,
        loading: false,
        configuration: action.payload
      }

    case FETCH_CONFIG_FAILURE:
      // Request failed: set loading false, save & display error
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        configuration: []
      }
    case ENABLE_MAINTENANCE_MODE:
      return {
        ...state,
        maintenanceMode: true,
        maintenanceData: action.payload
      }
    default:
      return state
  }
}

export const getTermsByCurrency = state => {
  const currency = get(state, 'activeSearchFilterData.currency', 'USD')
  const labels = getLabelsData()
  const currencyTerms = get(labels, [`${currency.toLowerCase()}-terms`])
  return currencyTerms ? currencyTerms : get(labels, 'usd-terms')
}
