import React from 'react'
import Modal from 'react-modal'
import chevRight from '../../../../assets/icons/arrow-right.svg'
import closeIcon from '../../../../assets/icons/close.svg'
const EQuoteLandProgramDetailsModal = props => {
  const { activeLandPackage, destinationsData } = props
  const destination = destinationsData.find(
    dest => dest['destination_code'] === activeLandPackage.destinationCode
  )

  return (
    <Modal
      isOpen={props.isOpen}
      className={{
        base: 'equote__land-programs-detail-modal u-modal',
        afterOpen: 'u-modal--visible',
        beforeClose: 'u-modal--close',
        ariaHideApp: false
      }}
      overlayClassName={{
        base: 'u-fade',
        afterOpen: 'u-fade--visible',
        beforeClose: 'u-fade--visible'
      }}
      onRequestClose={props.togglePackageDetailsModal}
      appElement={document.getElementById('equote__generate-offers')}
    >
      <div className="equote__land-programs-detail-modal-content">
        <div
          className="u-modal__close equote__land-programs-detail-modal-close-button"
          onClick={props.togglePackageDetailsModal}
          tabIndex={0}
        >
          Close{' '}
          <img
            className="u-modal__close-icon"
            src={closeIcon}
            alt="eQuote Ship Modal Close"
          />
        </div>
        {activeLandPackage ? (
          <>
            <div className="equote__land-programs-detail-modal-content-header section-header">
              <h4>{activeLandPackage.title}</h4>
            </div>
            <div className="equote__land-programs-detail-modal-table">
              <div className="equote__land-programs-detail-modal-table--column">
                <div className="equote__land-programs-detail-modal-table--column-header section-header" />
                <div className="equote__land-programs-detail-modal-table--column-details">
                  <div className="equote__land-programs-detail-modal-table--column-details-sub-table">
                    <p>Location</p>
                    <p>{destination ? destination.name : null}</p>
                  </div>
                  <div className="equote__land-programs-detail-modal-table--column-details-sub-table">
                    <p>Duration</p>
                    <p>3 Nights</p>
                  </div>
                </div>
              </div>
              <div className="equote__land-programs-detail-modal-table--column">
                <div className="equote__land-programs-detail-modal-table--column-header section-header">
                  <p>Description</p>
                </div>
                <div
                  className="equote__land-programs-detail-modal-table--column-details"
                  dangerouslySetInnerHTML={{
                    __html: activeLandPackage.description
                  }}
                />
              </div>
            </div>
            {activeLandPackage.highlights &&
            activeLandPackage.highlights.length ? (
              <div className="equote__land-programs-detail-modal-highlights">
                <div className="equote__land-programs-detail-modal-highlights-header section-header">
                  <p>Highlights</p>
                </div>

                <div className="equote__land-programs-detail-modal-highlights-list">
                  {activeLandPackage.highlights &&
                    activeLandPackage.highlights.map(highlight => (
                      <div
                        className="equote__land-programs-detail-modal-highlights-list-item"
                        key={highlight}
                      >
                        <img src={chevRight} alt="highlight" />
                        <p
                          className="equote__land-programs-detail-modal-highlights-list-item-description"
                          dangerouslySetInnerHTML={{ __html: highlight }}
                        />
                      </div>
                    ))}
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <p>Unable to fetch Land Program Details</p>
        )}
      </div>
    </Modal>
  )
}

export default EQuoteLandProgramDetailsModal
