import React, { Fragment, Component } from 'react'
import Modal from 'react-modal'

import closeIcon from '../../../../assets/icons/close.svg'
import shipPlaceholder from '../../../../assets/images/placeholder-ship-image.png'

import '../../../../styles/scss/components/equote-sailings-ships-modal.scss'
import { filterImagesByFileName } from '../../../../utilities/filterImagesByFileName'

const _ = require('lodash')

class EQuoteSailingsFullSearchShipModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderShipContent = () => {
    const { handleCloseShipModal, activeShipCode, shipsData } = this.props

    if (!_.isEmpty(shipsData)) {
      const currentShip = shipsData.filter(
        ship => ship.ship_code === activeShipCode
      )

      if (currentShip && !_.isEmpty(currentShip[0])) {
        const ship = currentShip[0]

        const shipImageData = filterImagesByFileName(
          ship.image,
          'ship-details'
        )[0]

        const shipImage = shipImageData && shipImageData.displayImage

        return (
          <Fragment>
            <div className="u-modal__header">
              {ship.name ? (
                <h2 className="u-remove-margin">
                  {ship.name}

                  <div
                    className="u-modal__close"
                    onClick={handleCloseShipModal}
                    tabIndex={0}
                  >
                    Close{' '}
                    <img
                      className="u-modal__close-icon"
                      src={closeIcon}
                      alt="eQuote Ship Modal Close"
                    />
                  </div>
                </h2>
              ) : null}
            </div>

            <div className="u-modal__body">
              <div className="equote__sailings-ship-modal-content">
                <div className="equote__sailings-ship-modal-image">
                  {ship.image &&
                  ship.image[0] &&
                  ship.image[0].file &&
                  shipImage ? (
                    <img
                      className="u-img-responsive"
                      src={shipImage}
                      alt={
                        ship.image[0].alt ? ship.image[0].alt : 'eQuote Ship'
                      }
                    />
                  ) : (
                    <img src={shipPlaceholder} alt="eQuote Ship Default" />
                  )}
                </div>

                <div className="equote__sailings-ship-modal-details">
                  {ship.class ? (
                    <div className="equote__sailings-ship-modal-details-row">
                      <p className="u-remove-margin ship-label">
                        Class and Type:
                      </p>
                      <p className="u-remove-margin ship-value">{ship.class}</p>
                    </div>
                  ) : null}

                  {ship.tonnage ? (
                    <div className="equote__sailings-ship-modal-details-row">
                      <p className="u-remove-margin ship-label">Tonnage:</p>
                      <p className="u-remove-margin ship-value">
                        {ship.tonnage}
                      </p>
                    </div>
                  ) : null}

                  {ship.length ? (
                    <div className="equote__sailings-ship-modal-details-row">
                      <p className="u-remove-margin ship-label">Length:</p>
                      <p className="u-remove-margin ship-value">
                        {ship.length}
                      </p>
                    </div>
                  ) : null}

                  {ship.beam ? (
                    <div className="equote__sailings-ship-modal-details-row">
                      <p className="u-remove-margin ship-label">Beam:</p>
                      <p className="u-remove-margin ship-value">{ship.beam}</p>
                    </div>
                  ) : null}

                  {ship.draft ? (
                    <div className="equote__sailings-ship-modal-details-row">
                      <p className="u-remove-margin ship-label">Draft:</p>
                      <p className="u-remove-margin ship-value">{ship.draft}</p>
                    </div>
                  ) : null}

                  {ship.speed ? (
                    <div className="equote__sailings-ship-modal-details-row">
                      <p className="u-remove-margin ship-label">Speed:</p>
                      <p className="u-remove-margin ship-value">{ship.speed}</p>
                    </div>
                  ) : null}

                  {ship.capacity ? (
                    <div className="equote__sailings-ship-modal-details-row">
                      <p className="u-remove-margin ship-label">Capacity:</p>
                      <p className="u-remove-margin ship-value">
                        {ship.capacity} Passengers
                      </p>
                    </div>
                  ) : null}

                  {ship.crew ? (
                    <div className="equote__sailings-ship-modal-details-row">
                      <p className="u-remove-margin ship-label">Crew:</p>
                      <p className="u-remove-margin ship-value">{ship.crew}</p>
                    </div>
                  ) : null}
                </div>
              </div>

              {ship.body ? (
                <p
                  className="equote__sailings-ship-modal-body u-remove-margin"
                  dangerouslySetInnerHTML={{ __html: ship.body }}
                />
              ) : null}
            </div>
          </Fragment>
        )
      } else {
        return 'There appears to be no data for this ship - please check back'
      }
    }
  }

  render() {
    const { handleCloseShipModal, shipModalVisible } = this.props

    return (
      <Modal
        className={{
          base: 'equote__sailings-ship-modal u-modal',
          afterOpen: 'u-modal--visible',
          beforeClose: 'u-modal--close',
          ariaHideApp: false
        }}
        overlayClassName={{
          base: 'u-fade',
          afterOpen: 'u-fade--visible',
          beforeClose: 'u-fade--visible'
        }}
        isOpen={shipModalVisible}
        onRequestClose={handleCloseShipModal}
        contentLabel="eQuote Ship Details Modal"
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        htmlOpenClassName="ReactModal__Html--open"
      >
        {this.renderShipContent()}
      </Modal>
    )
  }
}

export default EQuoteSailingsFullSearchShipModal
