import React, { Component } from 'react'
import { connect } from 'react-redux'

import EQuoteSearchFilterDestination from './destination/EQuoteSearchFilterDestination'
import EQuoteSearchFilterBrand from './brand/EQuoteSearchFilterBrand'
import EQuoteSearchFilterPrice from './price/EQuoteSearchFilterPrice'
import EQuoteSearchFiltersFullForm from './fullSearchForm/EQuoteSearchFiltersFullForm'

import '../../styles/scss/components/equote-search-filters.scss'
import { getLabelsData } from '../../sessionStorage/getLabelsData'

class EQuoteSearchFilters extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { labelsData } = this.props

    return (
      <div className="equote__search-filters">
        {labelsData && labelsData.search && labelsData.search.title ? (
          <div className="equote__search-filters-heading">
            <div className="equote__search-filters-heading--inner u-content-container">
              <h2>{labelsData.search.title}</h2>

              {labelsData.search.body ? (
                <p className="u-remove-margin">{labelsData.search.body}</p>
              ) : null}
            </div>
          </div>
        ) : null}

        <div className="equote__search-filters-content">
          <div className="equote__search-filter-header">
            <div className="equote__search-filter-header--inner u-content-container">
              <EQuoteSearchFilterDestination />
              <EQuoteSearchFilterBrand />
              <EQuoteSearchFilterPrice />
            </div>
          </div>
          <EQuoteSearchFiltersFullForm />
        </div>
      </div>
    )
  }
}

function mapStateToProps() {
  return {
    labelsData: getLabelsData()
  }
}

export default connect(mapStateToProps)(EQuoteSearchFilters)
