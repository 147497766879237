import React, { Component } from 'react'
import moment from 'moment'
import royalLogoWhite from '../../assets/icons/royal-caribbean-logo-white.svg'

class EQuoteReviewEmailFooter extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const {
      templateClass,
      brandsSelected
    } = this.props
    return (
      <div className={'equote__review-construct-email-footer ' + templateClass}>
        <p className="equote__review-construct-email-copyright">
          &copy; {moment().format('YYYY')}{' '}
          {`${
            templateClass === 'brand-c'
              ? "Celebrity Cruises Inc. Ship's registry: Malta and Ecuador"
              : "Royal Caribbean Cruises Ltd. Ships' registry: Bahamas, Malta, and Ecuador."
          }`}
        </p>
        <div className="equote__review-construct-email-footer-logo">
          {brandsSelected.map(brand => {
            let iconSrc
            if (brand.brand_code === 'R') {
              iconSrc = royalLogoWhite
            } else {
              iconSrc =
                brand && brand.badge_light ? brand.badge_light.file : null
            }
            const title =
              brand && brand.badge_light
                ? brand.badge_light.marketing_title
                : null
            return <img src={iconSrc} alt={title} key={title} />
          })}
        </div>
      </div>
    )
  }
}

export default EQuoteReviewEmailFooter
