import { SET_ACTIVE_LAND_PACKAGE } from '../actions/setActiveLandPackage'
import { REMOVE_ACTIVE_LAND_PACKAGE } from '../actions/removeActiveLandPackage'
import { REMOVE_ACTIVE_PACKAGE } from '../actions/removeActivePackage'
import { CLEAR_PACKAGE_DATA } from '../actions/fetchPackages'

export default function setActiveLandPackageReducer(state = {}, action) {
  switch (action.type) {
    case SET_ACTIVE_LAND_PACKAGE:
      return Object.assign({}, state, {
        [action.payload.packageKey]: {
          ...state[action.payload.packageKey],
          [action.payload.landProgramKey]: action.payload.data
        }
      })

    case REMOVE_ACTIVE_LAND_PACKAGE:
      const currentPackageSelectLandPrograms = {
        ...state[action.payload.packageKey]
      }
      delete currentPackageSelectLandPrograms[action.payload.landProgramKey]
      return {
        ...state,
        [action.payload.packageKey]: currentPackageSelectLandPrograms
      }

    case REMOVE_ACTIVE_PACKAGE:
      const initialLandPackages = { ...state }
      delete initialLandPackages[action.payload]
      return initialLandPackages

    case CLEAR_PACKAGE_DATA:
      return {}

    default:
      return state
  }
}
