import React from 'react'
import moment from 'moment'

const EQuoteCabinsHeader = ({
  activePackage,
  handleOpenPackageDetailsModal
}) => {
  function renderDepartureDate() {
    const { allSelectedLandPrograms } = activePackage
    let earliestDate = activePackage.startDate
    if (
      allSelectedLandPrograms &&
      allSelectedLandPrograms.pre &&
      allSelectedLandPrograms.pre.tourStartDate
    ) {
      earliestDate = allSelectedLandPrograms.pre.tourStartDate
    }
    return moment(earliestDate).format('MMM DD, YYYY')
  }
  return (
    <div className="equote__cabins-table-package">
      <div className="equote__cabins-table-package__details">
        {activePackage && Boolean(activePackage.preTourNights) ? (
          <div className="equote__cabins-table-package__details--pre-cruise">
            <div className="equote__cabins-table-package__details--pre-cruise-flag">
              <p>Pre-Cruise Package</p>
            </div>
            <p>
              {Boolean(
                activePackage.preTourName && activePackage.preTourName !== ''
              )
                ? activePackage.preTourName
                : `${activePackage.preTourNights} ${
                    activePackage.preTourNights === 1 ? 'Night' : 'Nights'
                  }`}
            </p>
          </div>
        ) : null}
      </div>
      <div className="equote__cabins-table-package__info">
        <div className="equote__cabins-table-package__info-description">
          <p className="equote__cabins-table-package__info-description-title">
            {activePackage.cruiseTourPreNights ||
            activePackage.cruiseTourPostNights
              ? activePackage.description
              : activePackage.sailingNights
              ? `${activePackage.sailingNights} Night ${
                  activePackage.description
                }`
              : activePackage.description}
          </p>
          <div className="equote__cabins-table-package__info-sail-wrapper">
            <div className="equote__cabins-table-package__info-sail">
              <p className="equote__cabins-table-package__info-sail-title">
                Sailing From
              </p>
              <p className="equote__cabins-table-package__info-sail-data">
                {activePackage.cruisePort[0].description}
              </p>
            </div>
            <div className="equote__cabins-table-package__info-sail">
              <p className="equote__cabins-table-package__info-sail-title">
                Ship
              </p>
              <p className="equote__cabins-table-package__info-sail-data">
                {activePackage.shipName}
              </p>
            </div>
            <div className="equote__cabins-table-package__info-sail">
              <p className="equote__cabins-table-package__info-sail-title">
                Departure Date
              </p>
              <p className="equote__cabins-table-package__info-sail-data">
                {renderDepartureDate()}
              </p>
            </div>
          </div>
        </div>
        <div className="equote__cabins-table-package__info-details-btn">
          <button
            className="btn btn-transparent"
            onClick={() =>
              handleOpenPackageDetailsModal(
                activePackage.id,
                activePackage.departure
              )
            }
          >
            View Details
          </button>
        </div>
      </div>

      <div className="equote__cabins-table-package__details">
        {activePackage && Boolean(activePackage.postTourNights) ? (
          <div className="equote__cabins-table-package__details--post-cruise">
            <div className="equote__cabins-table-package__details--post-cruise-flag">
              <p>Post-Cruise Package</p>
            </div>
            <p>
              {' '}
              {Boolean(
                activePackage.postTourName && activePackage.postTourName !== ''
              )
                ? activePackage.postTourName
                : `${activePackage.postTourNights} ${
                    activePackage.postTourNights === 1 ? 'Night' : 'Nights'
                  }`}
            </p>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default EQuoteCabinsHeader
