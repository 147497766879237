export const SET_ACTIVE_RECIPIENTS = 'SET_ACTIVE_RECIPIENTS'
export const SET_ACTIVE_HEADLINE = 'SET_ACTIVE_HEADLINE'
export const SET_ACTIVE_COMMENTS = 'SET_ACTIVE_COMMENTS'
export const SET_ACTIVE_USER_AGREEMENT = 'SET_ACTIVE_USER_AGREEMENT'
export const SET_AGENT_EMAIL_COPY_OPTIONS = 'SET_AGENT_EMAIL_COPY_OPTIONS'

export const updateRecipients = recipients => ({
	type: SET_ACTIVE_RECIPIENTS,
	payload: recipients
})
export const updateHeadline = headline => ({
	type: SET_ACTIVE_HEADLINE,
	payload: headline
})
export const updateComments = comments => ({
	type: SET_ACTIVE_COMMENTS,
	payload: comments
})
export const updateUserAgreement = userAgreement => ({
	type: SET_ACTIVE_USER_AGREEMENT,
	payload: userAgreement
})

export function setActiveRecipients(recipients) {
	return dispatch => {
		dispatch(updateRecipients(recipients))
	}
}

export function setActiveHeadline(headline) {
	return dispatch => {
		dispatch(updateHeadline(headline))
	}
}

export function setActiveComments(comments) {
	return dispatch => {
		dispatch(updateComments(comments))
	}
}

export function setActiveUserAgreement(userAgreement) {
	return dispatch => {
		dispatch(updateUserAgreement(userAgreement))
	}
}

export function setAgentEmailCopyOptions(shouldSendEmail) {
  return dispatch => {
    dispatch({
      type: SET_AGENT_EMAIL_COPY_OPTIONS,
      payload: shouldSendEmail
    })
  }
}