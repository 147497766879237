import React, { Fragment } from 'react'
import { get } from 'lodash'
import '../../styles/scss/components/equote-maintenance-page.scss'

import placeholder from '../../assets/images/defaults/ports/cel_generic_port.jpg'

const renderBody = textString => {
  const parts = textString.split('\n')
  return parts.map((part, index) => {
    return (
      <Fragment key={part}>
        {index > 0 && <br />}
        {part}
      </Fragment>
    )
  })
}

const MaintenancePage = ({ data }) => (
  <div className="maintenance-page u-content-container ">
    <div className="maintenance-page-copy-container">
      <div className="maintenance-page-copy">
        {data.title && <h1>{data.title}</h1>}
        {data.body && <p>{renderBody(data.body)}</p>}
      </div>
    </div>
    <div className="maintenance-page-image-container">
      <img
        src={get(data, 'image.file', placeholder)}
        alt={get(data, 'image.alt', 'Maintenance')}
      />
    </div>
  </div>
)

export default MaintenancePage
