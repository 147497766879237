import { omit } from 'lodash'

import {
  FETCH_LOYALTY_BEGIN,
  FETCH_LOYALTY_SUCCESS,
  SET_QUERIED_LOYALTY_ID,
  FETCH_LOYALTY_FAILURE
} from '../actions/fetchLoyalty'

import {
  REMOVE_ACTIVE_LOYALTY,
  REMOVE_ALL_ACTIVE_LOYALTY
} from '../actions/removeActiveLoyalty'

const initialState = {
  loading: false,
  error: null
}

export default function fetchLoyaltyReducers(state = initialState, action) {
  switch (action.type) {
    case FETCH_LOYALTY_BEGIN:
      // Request begin: set loading true
      return {
        ...state,
        loading: true,
        error: null
      }

    case FETCH_LOYALTY_SUCCESS:
      // Request success: set loading false, and display request data
      return {
        ...state,
        loading: false,
        loyalty: action.payload
      }

    case SET_QUERIED_LOYALTY_ID:
      return {
        ...state,
        loading: false,
        loyaltyId: action.payload
      }

    case FETCH_LOYALTY_FAILURE:
      // Request failed: set loading false, save & display error
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        ...[]
      }

    case REMOVE_ACTIVE_LOYALTY:
      // Remove loyalty by ID
      return {
        ...state,
        loyalty: omit(state.loyalty, action.payload),
        loyaltyId: null
      }

    case REMOVE_ALL_ACTIVE_LOYALTY:
      return {
        ...state,
        loyalty: null,
        loyaltyId: null
      }

    default:
      return state
  }
}
