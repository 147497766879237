// Helper created to add .modal-opened to html and body when modal is showing
// so page doesn't move when modal is scrolling on mobile
// PS: This problem is reported to happen more on Iphone

export default function modalOpenedStopScroll(
  isModalActive,
  previousScrollY = 0
) {
  const body = document.querySelector('body')
  const html = document.querySelector('html')

  if (isModalActive) {
    previousScrollY = window.scrollY
    body.classList.add('modal-opened')
    html.classList.add('modal-opened')
  } else {
    body.classList.remove('modal-opened')
    html.classList.remove('modal-opened')
    window.scrollTo(0, previousScrollY)
  }
  return previousScrollY
}
