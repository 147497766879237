import axios from 'axios'
import 'url-search-params-polyfill'
import triggerCustomGaEvent from '../utilities/triggerCustomGaEvent'
import storageKeys from '../configuration/storageKeys'
// Handle Ships Fetch & Functionality
export default function fetchShips() {
  // Formatted API path
  const shipsApi = process.env.REACT_APP_CONTENT_ENDPOINT + 'ships'
  return axios.get(shipsApi)
}

export const receiveShipsData = response => {
  const ships = response.data
    .filter(ship => ship.brand_code !== 'Z')
    .sort(function(a, b) {
      return a.name.localeCompare(b.name)
    })

  if (ships) {
    sessionStorage.setItem(storageKeys.ships, JSON.stringify(ships))
    // Dispatch GA event for ships
    triggerCustomGaEvent('equoteTracking-shipsRequest', ships)
  }
}
