import React from 'react'
import moment from 'moment'

const EQuoteLandPackageItineraryDetails = ({ activeLandPackage }) => {
  return (
    <div className="equote-land-program-itinerary-preview">
      <h4 className="equote-land-program-itinerary-preview-title">
        {activeLandPackage.prePostTagName}-Sailing Tour
      </h4>
      <div className="equote-land-program-itinerary-preview-header">
        <p>{activeLandPackage.title}</p>
      </div>
      {activeLandPackage.itinerary.map(day => {
        return (
          <div
            key={day.day}
            className="equote-land-program-itinerary-preview-day"
          >
            <div className="equote-land-program-itinerary-preview-day-column">
              <div className="equote-land-program-itinerary-preview-day-column-header">
                <p>Day {day.day}</p>
              </div>
              <div className="equote-land-program-itinerary-preview-day-column-body">
                <p>{moment(day.date, 'YYYY-MM-DD').format('DDMMMYYYY')}</p>
              </div>
            </div>

            <div className="equote-land-program-itinerary-preview-day-column">
              <div className="equote-land-program-itinerary-preview-day-column-header">
                <p> {day.title}</p>
              </div>

              <div className="equote-land-program-itinerary-preview-day-column-body">
                <p dangerouslySetInnerHTML={{ __html: day.description }} />
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default EQuoteLandPackageItineraryDetails
