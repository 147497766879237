import React from 'react'
import moment from 'moment'

import '../../styles/scss/components/equote-package-details-footer.scss'

const EQuotePackageDetailsFooter = () => {
  return (
    <div className="equote__package-details-footer">
      <div className="equote__package-details-footer-container">
        <p className="equote__package-details-footer-rights">
          &copy; {moment().format('YYYY')} Royal Caribbean Cruises Ltd. Ships’
          registry: Bahamas, Malta, and Ecuador.
        </p>

        <div className="equote__package-details-footer-links">
          <div
            className="equote__package-details-footer-link btn"
            style={{ cursor: 'default' }}
          >
            Privacy Policy
          </div>
        </div>
      </div>
    </div>
  )
}

export default EQuotePackageDetailsFooter
