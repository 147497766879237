import React, { Component, createRef } from 'react'
import MaterialTable, { MTableBodyRow } from 'material-table'
import moment from 'moment'
import FirstPageArrow from '../../../../assets/icons/paginate-first-page.svg'
import PrevPage from '../../../../assets/icons/pagination-previous-page.svg'
import NextPage from '../../../../assets/icons/pagination-next-page.svg'
import LastPage from '../../../../assets/icons/pagination-last-page.svg'
import EQuoteSailingsSelectPackageButton from '../Buttons/EQuoteSailingsSelectPackageButton'
import EQuoteTablePriceCell from '../EQuoteTablePriceCell'
import getArrayElementsByClass from '../../../../utilities/getArrayElementsByClass'
import removeClassAllElements from '../../../../utilities/removeClassAllElements'
import EQuoteFullSearchSubTableCabinsList from '../Cabins/EQuoteFullSearchSubTableCabinsList'
import triggerCustomGaEvent from '../../../../utilities/triggerCustomGaEvent'

class EQuoteSailingsFullSearchLandProgramsTab extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.onOrderChangeFlag = false
    this.tableRef = createRef()
  }

  shouldComponentUpdate(nextProps) {
    /*
     * we need this to prevent the table from re-rendering if the click handlers
     * change (which they do on every parent update)
     * as that will cause the price cells to make duplicate ajax request
     * when using unsupportedQualifiers.
     * This currently causes the table to never update because it un-mounts
     * and remounts with every new search
     */

    if (this.props.filterTerms !== nextProps.filterTerms) {
      this.tableRef &&
        this.tableRef.current &&
        this.tableRef.current.dataManager &&
        (this.tableRef.current.dataManager.currentPage = 0)
      return true
    }

    return false
  }
  // On table change (e.g click in any cell header), simulate click on each previous row that has a
  // detail panel opened to call 'OnRowClick'
  onTableChange = () => {
    const rowsWithClassActive = getArrayElementsByClass(
      '.equote__sailings-full-search--table-body-row ~ tr:not(.equote__sailings-full-search--table-body-row)'
    )

    rowsWithClassActive.forEach(row => {
      this.onOrderChangeFlag = true
      const event = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      })
      //Remove all active price cells
      row.previousSibling.dispatchEvent(event)
    })
    removeClassAllElements(document, 'active-price')
    this.onChangePageScroll()
  }

  //Get the detail panel, simulate a click on the active cell to close the panel
  onRowClick = (event, rowData) => {
    const detailPanel =
      event.currentTarget.nextSibling &&
      event.currentTarget.nextSibling.querySelector(
        '.equote__sailings-full-search-cabins-table'
      )
    if (this.onOrderChangeFlag && detailPanel && detailPanel.dataset.type) {
      removeClassAllElements(event.currentTarget, 'active-price')
      this.props.toggleSuperClassDetails(rowData, detailPanel.dataset.type)
    }

    this.onOrderChangeFlag = false
  }

  onChangePageScroll = () => {
    const resultTable = document.querySelector(
      '.equote__sailings-full-search-table'
    )
    resultTable &&
      resultTable.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
  }

  render() {
    const {
      handleOpenItineraryPreviewModal,
      handleOpenShipModal,
      landPrograms,
      toggleLandPackagesCabinTable
    } = this.props

    return (
      <MaterialTable
        tableRef={this.tableRef}
        onOrderChange={this.onTableChange}
        onRowClick={this.onRowClick}
        onChangePage={this.onTableChange}
        onChangeRowsPerPage={this.onTableChange}
        components={{
          Container: props => (
            <div className="equote__sailings-land-programs-table u-table hide-details-icons hide-filter-row">
              {props.children}
            </div>
          ),
          Row: props => {
            return (
              <MTableBodyRow
                /**
                 * We need the secondary-background class because MaterialTable will add a new <tr />
                 * when we toggle open the details which makes nth-child unreliable
                 **/
                className={`equote__sailings-full-search--table-body-row primary-row ${
                  Number.isInteger((props.index + 1) / 2)
                    ? 'secondary-background'
                    : ''
                }`}
                id={`land-package-${props.data.cruiseIdentifier}-${
                  props.data.id
                }-${props.data.departure}`}
                {...props}
              />
            )
          }
        }}
        icons={{
          FirstPage: () => <img src={FirstPageArrow} alt="first page" />,
          PreviousPage: () => <img src={PrevPage} alt="previous page" />,
          NextPage: () => <img src={NextPage} alt="next page" />,
          LastPage: () => <img src={LastPage} alt="last page" />
        }}
        options={{
          paging: true,
          pageSize: 20,
          pageSizeOptions: [5, 20, 30, 50],
          showEmptyDataSourceMessage: true,
          toolbar: false,
          filtering: true,
          // paginationType: 'stepped'
          emptyRowsWhenPaging: false
        }}
        localization={{
          body: {
            emptyDataSourceMessage:
              'No land program available, please try another date range.'
          }
        }}
        columns={[
          {
            title: 'Brand',
            field: 'brandName',
            defaultFilter: this.props.filterTerms.brand,
            cellStyle: {
              display: 'none'
            },
            headerStyle: {
              display: 'none'
            }
          },
          {
            title: 'Select up to 5',
            field: 'id',
            sorting: false,
            cellStyle: {
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: 10,
              paddingRight: 10,
              textAlign: 'center',
              minWidth: 90,
              maxWidth: 90,
              width: 90
            },
            headerStyle: {
              padding: 20,
              minWidth: 90,
              maxWidth: 90,
              width: 90,
              zIndex: 12
            },
            render: packageData => {
              return (
                <EQuoteSailingsSelectPackageButton
                  identifier={packageData.landPackageIdentifier}
                  landPackageId={packageData.id}
                  cruiseIdentifier={packageData.cruiseIdentifier}
                  isLandPackage={true}
                  stateKey={packageData.cruiseData.stateKey}
                  isCruiseTour={!packageData.cruiseData.justACruise}
                  landProgramType={packageData.landProgramType}
                />
              )
            }
          },
          {
            title: 'Date',
            field: 'tableDisplayDate',
            type: 'date',
            defaultSort: 'asc',
            customSort(a, b) {
              return moment(a.tableDisplayDate, 'YYYY-MM-DD').isBefore(
                moment(b.tableDisplayDate, 'YYYY-MM-DD'),
                'day'
              )
                ? -1
                : 1
            },
            cellStyle: {
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: 10,
              paddingRight: 10,
              textTransform: 'uppercase',
              minWidth: 120,
              maxWidth: 120
            },
            headerStyle: {
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: 10,
              paddingRight: 10,
              minWidth: 120,
              maxWidth: 120,
              zIndex: 12
            },
            render: rowData => {
              return (
                <button
                  className="btn btn-text u-font-uppercase"
                  onClick={() => {
                    handleOpenItineraryPreviewModal(
                      rowData.id,
                      rowData.sailDate,
                      rowData.brand,
                      rowData.cruiseIdentifier,
                      false,
                      rowData.landPackageIdentifier
                    )
                    triggerCustomGaEvent('itineryModalOpened', rowData)
                  }}
                >
                  {moment(rowData.tableDisplayDate, 'YYYY-MM-DD').format(
                    'MMM DD YYYY'
                  )}
                </button>
              )
            }
          },
          {
            title: 'Nights',
            field: 'totalNights',
            type: 'numeric',
            defaultFilter: this.props.filterTerms.nights,
            cellStyle: {
              maxWidth: 100,
              textAlign: 'center'
            },
            headerStyle: {
              maxWidth: 100,
              padding: '0 0 0 24px'
            },
            render: packageData => {
              return (
                <span style={{ whiteSpace: 'nowrap' }}>
                  {packageData.sailingNights} Sailing <br />
                  {packageData.tourNights} Tour
                </span>
              )
            }
          },
          {
            title: 'Type',
            field: 'landProgramType',
            defaultFilter: this.props.filterTerms.landProgramTypes
          },
          {
            title: 'Destination',
            field: 'destinationName',
            defaultFilter: this.props.filterTerms.destination
          },
          {
            title: 'Ship',
            field: 'shipName',
            defaultFilter: this.props.filterTerms.ship,
            render: packageData => {
              return (
                <button
                  className="btn btn-text"
                  onClick={() => handleOpenShipModal(packageData.shipCode)}
                >
                  {packageData.shipName}
                </button>
              )
            }
          },
          {
            title: 'Tour Name',
            field: 'name',
            render: packageData => {
              return (
                <div>
                  <button
                    className="btn btn-text"
                    onClick={() => {
                      triggerCustomGaEvent('tourNameSearchClick', {
                        ...packageData
                      })
                      this.props.togglePackageDetailsModal(
                        packageData.landPackageIdentifier
                      )
                    }}
                  >
                    {packageData.title}
                  </button>
                </div>
              )
            }
          },
          { title: 'Sailing', field: 'cruiseName' },
          {
            title: 'Pricing From',
            field: 'pricing',
            sorting: false,
            cellStyle: {
              padding: 0,
              minWidth: 100,
              maxWidth: 100
            },
            headerStyle: {
              maxWidth: 100,
              textAlign: 'center'
            },
            render: packageData => {
              return (
                <EQuoteTablePriceCell
                  packageIdentifier={packageData.cruiseIdentifier}
                  isCruiseTour={false}
                  isLandPackage={true}
                  landPackagePerGuestPrice={packageData.perGuestPrice}
                  stateroomExists={true}
                  // the above is misleading but if
                  // a cruise isn't sold out the PriceCell checks for the existence of a stateRoom
                  // type to render the unavailability text. We have to assume in this case at least
                  // one stateroom exists as this table only shows one price per cruise rather than
                  // one per super category
                  onClick={() => {
                    triggerCustomGaEvent('pricingSearchClick', packageData)
                    toggleLandPackagesCabinTable(packageData)
                  }}
                />
              )
            }
          },
          {
            title: '',
            field: 'detailsPreview',
            sorting: false,
            render: packageData => {
              return (
                <button
                  className="btn btn-text"
                  onClick={() =>
                    this.props.handleOpenPackageDetailsModal(
                      packageData.cruiseData.id,
                      packageData.sailDate,
                      packageData.brand,
                      packageData.destinationCode,
                      !packageData.cruiseData.justACruise,
                      packageData.landPackageIdentifier
                    )
                  }
                >
                  View Details
                </button>
              )
            }
          }
        ]}
        data={landPrograms}
        detailPanel={[
          {
            icon: () => (
              <span className="cabin-tables-trigger-inner-span">i</span>
            ),
            render: rowData => {
              return (
                <EQuoteFullSearchSubTableCabinsList
                  isCruiseTour={false}
                  cruiseIdentifier={rowData.cruiseIdentifier}
                  toggleLandPackagesCabinTable={toggleLandPackagesCabinTable}
                  activeRowData={rowData}
                />
              )
            }
          }
        ]}
      />
    )
  }
}

export default EQuoteSailingsFullSearchLandProgramsTab
