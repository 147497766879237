import React from 'react'
import CheckMark from '../../assets/icons/checkmark.svg'

const CheckBox = props => (
  <button
    onClick={ props.onClick }
    className={
      props.checked ? 'btn btn-checkbox checked' : 'btn btn-checkbox'
    }
    disabled={ props.disabled }
    id={props.id}
  >
    { props.checked && <img src={ CheckMark } alt="checked" /> }
  </button>
)

export default CheckBox
