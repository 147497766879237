export const SET_AGENT_NAME = 'SET_AGENT_NAME'
export const SET_AGENT_EMAIL = 'SET_AGENT_EMAIL'
export const SET_AGENCY_NAME = 'SET_AGENCY_NAME'
export const SET_AGENCY_PHONE = 'SET_AGENCY_PHONE'
export const SET_AGENCY_LOGO = 'SET_AGENCY_LOGO'
export const SET_AGENCY_WEBSITE = 'SET_AGENCY_WEBSITE'
export const RESET_AGENT_INFO = 'RESET_AGENT_INFO'

export const updateAgentName = agentName => ({
  type: SET_AGENT_NAME,
  payload: agentName
})
export const updateAgentEmail = agentEmail => ({
  type: SET_AGENT_EMAIL,
  payload: agentEmail
})
export const updateAgencyName = agencyName => ({
  type: SET_AGENCY_NAME,
  payload: agencyName
})
export const updateAgencyPhone = agencyPhone => ({
  type: SET_AGENCY_PHONE,
  payload: agencyPhone
})
export const updateAgencyLogo = agencyLogo => ({
  type: SET_AGENCY_LOGO,
  payload: agencyLogo
})
export const updateAgencyWebsite = agencyWebsite => ({
  type: SET_AGENCY_WEBSITE,
  payload: agencyWebsite
})
export const removeAgentInfo = () => ({
  type: RESET_AGENT_INFO
})

export function setAgentName(agentName) {
  return dispatch => {
    dispatch(updateAgentName(agentName))
  }
}
export function setAgencyPhone(agencyPhone) {
  return dispatch => {
    dispatch(updateAgencyPhone(agencyPhone))
  }
}
export function setAgentEmail(agentEmail) {
  return dispatch => {
    dispatch(updateAgentEmail(agentEmail))
  }
}
export function setAgencyName(agencyName) {
  return dispatch => {
    dispatch(updateAgencyName(agencyName))
  }
}
export function setAgencyLogo(agencyLogo) {
  return dispatch => {
    dispatch(updateAgencyLogo(agencyLogo))
  }
}
export function setAgencyWebsite(agencyWebsite) {
  return dispatch => {
    dispatch(updateAgencyWebsite(agencyWebsite))
  }
}
export function resetAgentInfo() {
  return dispatch => {
    dispatch(removeAgentInfo())
  }
}
