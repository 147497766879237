import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getBrandByCode } from '../../sessionStorage/getBrandsData'
import setActiveStep from '../../actions/setActiveStepActions'
import removeActivePackage from '../../actions/removeActivePackage'
import removeActiveCabin from '../../actions/removeActiveCabin'
import setActiveCabinStep from '../../actions/setActiveCabinStepActions'
import EQuoteSelectedPackagesListModal from '../../components/selectedPackageListModal/EQuoteSelectedPackageListModal'
import { getAllActivePackagesDetails } from '../../reducers/setActivePackageReducers'
import { getAllActiveCabinData } from '../../reducers/setActiveCabinReducers'

class EQuoteSelectedPackageListContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      isOpen,
      onRequestClose,
      activePackages,
      getBrandByCode,
      removeActivePackage,
      activeStep,
      goBackToSearch,
      activeCabinData,
      removeActiveCabin,
      setActiveCabinStep,
      cabinStepData
    } = this.props
    return (
      <EQuoteSelectedPackagesListModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        activePackages={activePackages}
        getBrandByCode={getBrandByCode}
        removeActivePackage={removeActivePackage}
        activeStep={activeStep}
        goBackToSearch={goBackToSearch}
        activeCabinData={activeCabinData}
        removeActiveCabin={removeActiveCabin}
        setActiveCabinStep={setActiveCabinStep}
        cabinStepData={cabinStepData}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    activePackages: getAllActivePackagesDetails(state),
    activeStep: state.activeStepData.activeStep,
    activeCabinData: getAllActiveCabinData(state),
    cabinStepData: state.activeCabinStepData,
    getBrandByCode(code) {
      return getBrandByCode(code, state)
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    removeActivePackage(id) {
      dispatch(removeActivePackage(id))
      // we also need to check the activeCabinData
    },
    removeActiveCabin(id) {
      dispatch(removeActiveCabin(id))
    },
    setActiveCabinStep(totalCabinSteps, activeCabinStep, activeCabinStepId) {
      dispatch(
        setActiveCabinStep(totalCabinSteps, activeCabinStep, activeCabinStepId)
      )
    },
    goBackToSearch() {
      dispatch(setActiveStep(1))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteSelectedPackageListContainer)
