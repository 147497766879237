import React, { Fragment, Component } from 'react'
import MaterialTable, { MTableBodyRow } from 'material-table'
import moment from 'moment'

import EQuoteSailingsSelectPackageButton from '../Buttons/EQuoteSailingsSelectPackageButton'
import EQuoteTablePriceCell from '../EQuoteTablePriceCell'

import FirstPageArrow from '../../../../assets/icons/paginate-first-page.svg'
import PrevPage from '../../../../assets/icons/pagination-previous-page.svg'
import NextPage from '../../../../assets/icons/pagination-next-page.svg'
import LastPage from '../../../../assets/icons/pagination-last-page.svg'
import EQuoteTablePreviewButtonCell from '../Buttons/EQuoteTablePreviewButtonCell'

import removeClassAllElements from '../../../../utilities/removeClassAllElements'
import getArrayElementsByClass from '../../../../utilities/getArrayElementsByClass'
import EQuoteFullSearchSubTableCabinsList from '../Cabins/EQuoteFullSearchSubTableCabinsList'
import triggerCustomGaEvent from '../../../../utilities/triggerCustomGaEvent'

class EQuoteSailingsFullSearchCruiseToursTab extends Component {
  shouldComponentUpdate(nextProps) {
    /*
     * we need this to prevent the table from re-rendering if the click handlers
     * change (which they do on every parent update)
     * as that will cause the price cells to make duplicate ajax request
     * when using unsupportedQualifiers.
     * This currently causes the table to never update because it un-mounts
     * and remounts with every new search
     */
    return Boolean(this.props.filterTerms !== nextProps.filterTerms)
  }

  // On table change (e.g click in any cell header), simulate click on each previous row that has a
  // detail panel opened to call 'OnRowClick'
  onTableChange = () => {
    const rowsWithClassActive = getArrayElementsByClass(
      '.equote__sailings-full-search--table-body-row ~ tr:not(.equote__sailings-full-search--table-body-row)'
    )

    rowsWithClassActive.forEach(row => {
      this.onOrderChangeFlag = true
      const event = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      })
      //Remove all active price cells
      row.previousSibling.dispatchEvent(event)
    })
    removeClassAllElements(document, 'active-price')
    this.onChangePageScroll()
  }

  //Get the detail panel, simulate a click on the active cell to close the panel
  onRowClick = (event, rowData) => {
    const detailPanel =
      event.currentTarget.nextSibling &&
      event.currentTarget.nextSibling.querySelector(
        '.equote__sailings-full-search-cabins-table'
      )
    if (this.onOrderChangeFlag && detailPanel && detailPanel.dataset.type) {
      removeClassAllElements(event.currentTarget, 'active-price')
      this.props.toggleSuperClassDetails(rowData, detailPanel.dataset.type)
    }

    this.onOrderChangeFlag = false
  }

  onChangePageScroll = () => {
    const resultTable = document.querySelector(
      '.equote__sailings-full-search-table'
    )
    resultTable &&
      resultTable.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
  }

  render() {
    const {
      cruiseTours,
      handleOpenShipModal,
      handleOpenPackageDetailsModal,
      handleOpenItineraryPreviewModal,
      toggleLandPackagesCabinTable
    } = this.props

    return (
      <>
        <MaterialTable
          onOrderChange={this.onTableChange}
          onRowClick={this.onRowClick}
          onChangePage={this.onTableChange}
          onChangeRowsPerPage={this.onTableChange}
          components={{
            Container: props => {
              return (
                <div className="equote__sailings-cruise-tours-table  u-table hide-details-icons hide-filter-row">
                  {props.children}
                </div>
              )
            },
            Row: props => (
              <MTableBodyRow
                /**
                 * We need the secondary-background class because MaterialTable will add a new <tr />
                 * when we toggle open the details which makes nth-child unreliable
                 **/
                className={`equote__sailings-full-search--table-body-row primary-row ${
                  Number.isInteger((props.index + 1) / 2)
                    ? 'secondary-background'
                    : ''
                }`}
                id={`package-${props.data.id}-${props.data.departure}`}
                {...props}
              />
            )
          }}
          icons={{
            FirstPage: () => <img src={FirstPageArrow} alt="first page" />,
            PreviousPage: () => <img src={PrevPage} alt="previous page" />,
            NextPage: () => <img src={NextPage} alt="next page" />,
            LastPage: () => <img src={LastPage} alt="last page" />
          }}
          columns={[
            {
              title: 'Brand',
              field: 'brandName',
              defaultFilter: this.props.filterTerms.brand,
              cellStyle: {
                display: 'none'
              },
              headerStyle: {
                display: 'none'
              }
            },

            {
              title: 'Select up to 5',
              field: 'id',
              sorting: false,
              cellStyle: {
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 10,
                textAlign: 'center',
                minWidth: 80,
                maxWidth: 80
              },
              headerStyle: {
                padding: 20,
                minWidth: 80,
                maxWidth: 80,
                zIndex: 12
              },
              render: packageData => {
                return (
                  <EQuoteSailingsSelectPackageButton
                    identifier={packageData.identifier}
                    isLandPackage={false}
                    stateKey={packageData.stateKey}
                    isCruiseTour={!packageData.justACruise}
                  />
                )
              }
            },
            {
              title: 'Date',
              field: 'startDate',
              type: 'date',
              defaultSort: 'asc',
              cellStyle: {
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 10,
                textTransform: 'uppercase',
                minWidth: 95,
                maxWidth: 95
              },
              headerStyle: {
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 10,
                minWidth: 95,
                maxWidth: 95,
                zIndex: 12
              },
              render: rowData => {
                return (
                  <button
                    className="btn btn-text u-font-uppercase"
                    onClick={() =>
                      handleOpenItineraryPreviewModal(
                        rowData.id,
                        rowData.sailDate,
                        rowData.brand,
                        rowData.identifier,
                        false
                      )
                    }
                  >
                    {moment(rowData.startDate).format('DDMMMYYYY')}
                  </button>
                )
              }
            },
            {
              title: 'Nights',
              field: 'totalNights',
              type: 'numeric',
              defaultFilter: this.props.filterTerms.nights,
              cellStyle: {
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 10,
                minWidth: 105,
                maxWidth: 105
              },
              headerStyle: {
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 10,
                minWidth: 105,
                maxWidth: 105,
                zIndex: 12
              },
              render: rowData => {
                return (
                  <Fragment>
                    <p className="u-remove-margin u-font-xxs">
                      {rowData.cruiseTourPreNights
                        ? rowData.cruiseTourPreNights + ' Pre-sail/ '
                        : null}

                      {rowData.nights ? rowData.nights + ' Sailing' : '-'}

                      {rowData.cruiseTourPostNights
                        ? ' /' + rowData.cruiseTourPostNights + ' Post-sail'
                        : null}
                    </p>
                  </Fragment>
                )
              }
            },
            {
              title: 'Type',
              field: 'tourTypeLabel',
              defaultFilter: this.props.filterTerms.tourTypeLabel,
              cellStyle: {
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 10,
                minWidth: 80,
                maxWidth: 80,
                zIndex: 12
              },
              headerStyle: {
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 10,
                minWidth: 80,
                maxWidth: 80,
                zIndex: 12
              },
              render: rowData => {
                return (
                  <p className="u-remove-margin u-font-xxs u-font-uppercase">
                    {rowData.tourTypeLabel === 'BOTH'
                      ? 'PRE/POST'
                      : rowData.tourTypeLabel}
                  </p>
                )
              }
            },
            {
              title: 'Destination',
              defaultFilter: this.props.filterTerms.destination,
              field: 'destinationName',
              cellStyle: {
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 10,
                maxWidth: 130,
                minWidth: 130
              },
              headerStyle: {
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 10,
                maxWidth: 130,
                minWidth: 130,
                zIndex: 12
              }
            },
            {
              title: 'Ship',
              defaultFilter: this.props.filterTerms.ship,
              field: 'shipName',
              cellStyle: {
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 10,
                maxWidth: 140,
                minWidth: 140
              },
              headerStyle: {
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 10,
                maxWidth: 140,
                minWidth: 140,
                zIndex: 12
              },
              render: rowData => {
                return (
                  <button
                    onClick={() => handleOpenShipModal(rowData.shipCode)}
                    className="btn btn-text"
                    tabIndex={0}
                  >
                    {rowData.shipName}
                  </button>
                )
              }
            },
            {
              title: 'Tour Name',
              field: 'cruiseTourName',
              sorting: false,
              cellStyle: {
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 10,
                minWidth: 135,
                maxWidth: 135
              },
              headerStyle: {
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 10,
                minWidth: 135,
                maxWidth: 135,
                zIndex: 12
              },
              render: rowData => {
                return (
                  <button
                    className="btn btn-text"
                    tabIndex={0}
                    onClick={() => {
                      triggerCustomGaEvent('tourNameSearchClick', {
                        ...rowData
                      })
                      handleOpenItineraryPreviewModal(
                        rowData.id,
                        rowData.departure,
                        rowData.brand,
                        rowData.identifier,
                        true
                      )
                    }}
                  >
                    {rowData.cruiseTourName}
                  </button>
                )
              }
            },
            {
              title: 'Pricing From',
              field: 'pricing',
              sorting: false,
              cellStyle: {
                padding: 0,
                minWidth: 100,
                maxWidth: 100
              },
              headerStyle: {
                maxWidth: 100,
                textAlign: 'center'
              },
              render: rowData => {
                return (
                  <EQuoteTablePriceCell
                    packageIdentifier={`${rowData.id}-${rowData.sailDate}`}
                    isCruiseTour={true}
                    isLandPackage={false}
                    stateroomExists={true}
                    // the above is misleading but if
                    // a cruise isn't sold out the PriceCell checks for the existence of a stateRoom
                    // type to render the unavailability text. We have to assume in this case at least
                    // one stateroom exists as this table only shows one price per cruise rather than
                    // one per super category
                    onClick={() => {
                      triggerCustomGaEvent('pricingSearchClick', rowData)
                      toggleLandPackagesCabinTable(rowData)
                    }}
                  />
                )
              }
            },
            {
              title: '',
              field: 'preview',
              loadingType: 'linear',
              sorting: false,
              cellStyle: {
                padding: 0,
                maxWidth: 80,
                minWidth: 80
              },
              headerStyle: {
                maxWidth: 80,
                minWidth: 80,
                zIndex: 12
              },
              render: rowData => {
                const {
                  id,
                  departure,
                  brand,
                  destinationCode,
                  identifier,
                  justACruise
                } = rowData
                return (
                  <EQuoteTablePreviewButtonCell
                    identifier={identifier}
                    justACruise={justACruise}
                    onClick={() =>
                      handleOpenPackageDetailsModal(
                        id,
                        departure,
                        brand,
                        destinationCode
                      )
                    }
                  />
                )
              }
            }
          ]}
          data={cruiseTours ? cruiseTours : []}
          options={{
            paging: true,
            pageSizeOptions: [5, 20, 30, 50],
            pageSize: 20,
            showEmptyDataSourceMessage: true,
            toolbar: false,
            filtering: true,
            // paginationType: 'stepped'
            emptyRowsWhenPaging: false
          }}
          localization={{
            body: {
              emptyDataSourceMessage:
                'No Cruisetours available, please try another date range.'
            }
          }}
          detailPanel={[
            {
              icon: () => (
                <span className="cabin-tables-trigger-inner-span">i</span>
              ),
              render: rowData => {
                return (
                  <EQuoteFullSearchSubTableCabinsList
                    isCruiseTour={true}
                    cruiseIdentifier={rowData.identifier}
                    toggleLandPackagesCabinTable={toggleLandPackagesCabinTable}
                    activeRowData={rowData}
                  />
                )
              }
            }
          ]}
        />
      </>
    )
  }
}

export default EQuoteSailingsFullSearchCruiseToursTab
