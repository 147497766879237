import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import EQuotePackageItineraryPreview from '../../components/packageItineraryPreview/EQuotePackageItineraryPreview'

import closeIcon from '../../assets/icons/close.svg'
import '../../styles/scss/components/equote-package-details-modal.scss'
import '../../styles/scss/components/equote-land-program-itinerary-preview.scss'
import { getPackagesDetails } from '../../reducers/fetchPackagesReducers'
import EQuoteLandPackageItineraryDetails from './EQuoteLandPackageItineraryDetails'

class EQuoteItineraryDetailsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      handleClosePackageDetailsModal,
      detailsModalVisible,
      packageData,
      isCruiseTour,
      activeLandPackage
    } = this.props

    return (
      <Modal
        className={{
          base: 'u-modal',
          afterOpen: 'u-modal--visible',
          beforeClose: 'u-modal--close',
          ariaHideApp: false
        }}
        overlayClassName={{
          base: 'u-fade',
          afterOpen: 'u-fade--visible',
          beforeClose: 'u-fade--visible'
        }}
        isOpen={detailsModalVisible}
        onRequestClose={handleClosePackageDetailsModal}
        contentLabel="eQuote Package Details Modal"
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        htmlOpenClassName="ReactModal__Html--open"
      >
        <div className="equote__package-detail-modal">
          <div className="u-modal__header">
            <div
              className="u-modal__close"
              onClick={handleClosePackageDetailsModal}
              tabIndex={0}
            >
              Close{' '}
              <img
                className="u-modal__close-icon"
                src={closeIcon}
                alt="eQuote Ship Modal Close"
              />
            </div>
          </div>

          <div className="equote__package-detail-modal-content itinerary-modal-content">
            <>
              {activeLandPackage &&
              activeLandPackage.itinerary &&
              activeLandPackage.prePostTagName === 'pre' ? (
                <EQuoteLandPackageItineraryDetails
                  activeLandPackage={activeLandPackage}
                />
              ) : null}
              <EQuotePackageItineraryPreview
                packageData={packageData}
                isCruiseTour={isCruiseTour}
                showAdditionalCruiseTourDetails={
                  this.props.showAdditionalCruiseTourDetails
                }
              />
              {activeLandPackage &&
              activeLandPackage.itinerary &&
              activeLandPackage.prePostTagName === 'post' ? (
                <EQuoteLandPackageItineraryDetails
                  activeLandPackage={activeLandPackage}
                />
              ) : null}
            </>
          </div>
        </div>
      </Modal>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const packageData = getPackagesDetails(
    state,
    ownProps.isCruiseTour,
    ownProps.activeDetailsData.identifier
  )
  return {
    isCruiseTour: ownProps.isCruiseTour,
    packageData,
    showAdditionalCruiseTourDetails:
      ownProps.activeDetailsData.showAdditionalCruiseTourDetails
  }
}

export default connect(
  mapStateToProps,
  null
)(EQuoteItineraryDetailsContainer)
