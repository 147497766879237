import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import moment from 'moment'
import { PrevArrow, NextArrow } from '../elements/CarouselArrows'

import '../../styles/scss/components/equote-package-details-modal.scss'

const calculateCruiseDates = (day, sailDate) => {
  return moment(sailDate, 'YYYY-MM-DD')
    .add(day - 1, 'days')
    .format('DDMMMYYYY')
    .toUpperCase()
}

const timeConvert = time => {
  time = time
    .split(':')
    .slice(0, 2)
    .join(':')
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)?$/) || [time]

  if (time.length > 1) {
    time = time.slice(1)
    time[5] = +time[0] < 12 ? ' AM' : ' PM'
    time[0] = +time[0] % 12 || 12
  }
  return time.join('')
}

const renderCruises = (items, sailDate) => {
  const tableItems = []
  items.forEach(({ day, activity, departureTime, arrivalTime, name }, i) => {
    tableItems.push(
      <div key={i} className="equote__package-detail-modal-itinerary-slider">
        <div className="equote__package-detail-modal-itinerary-slider__wrapper">
          <div className="equote__package-detail-modal-itinerary-slider__row">
            <div className="equote__package-detail-modal-itinerary-slider__row-head">
              Day
            </div>
            <div className="equote__package-detail-modal-itinerary-slider__row-body">
              {calculateCruiseDates(day, sailDate)}
            </div>
          </div>
          <div className="equote__package-detail-modal-itinerary-slider__row">
            <div className="equote__package-detail-modal-itinerary-slider__row-head">
              Port City
            </div>
            <div className="equote__package-detail-modal-itinerary-slider__row-body">
              {name.toUpperCase()}
            </div>
          </div>
          <div className="equote__package-detail-modal-itinerary-slider__row">
            <div className="equote__package-detail-modal-itinerary-slider__row-head">
              Activity
            </div>
            <div className="equote__package-detail-modal-itinerary-slider__row-body">
              {activity ? activity : '-'}
            </div>
          </div>
          <div className="equote__package-detail-modal-itinerary-slider__row">
            <div className="equote__package-detail-modal-itinerary-slider__row-head">
              Arrival
            </div>
            <div className="equote__package-detail-modal-itinerary-slider__row-body">
              {arrivalTime ? timeConvert(arrivalTime) : '-'}
            </div>
          </div>
          <div className="equote__package-detail-modal-itinerary-slider__row">
            <div className="equote__package-detail-modal-itinerary-slider__row-head">
              Departure
            </div>
            <div className="equote__package-detail-modal-itinerary-slider__row-body">
              {departureTime ? timeConvert(departureTime) : '-'}
            </div>
          </div>
        </div>
      </div>
    )
  })

  return tableItems
}

const renderTours = (items, sailDate) => {
  const tableItems = []
  items.forEach(({ day, activity, departureTime, arrivalTime, name }, i) => {
    tableItems.push(
      <div key={i} className="equote__package-detail-modal-itinerary-slider">
        <div className="equote__package-detail-modal-itinerary-slider__wrapper">
          <div className="equote__package-detail-modal-itinerary-slider__row">
            <div className="equote__package-detail-modal-itinerary-slider__row-head">
              Day
            </div>
            <div className="equote__package-detail-modal-itinerary-slider__row-body">
              {calculateCruiseDates(day, sailDate)}
            </div>
          </div>
          <div className="equote__package-detail-modal-itinerary-slider__row equote__package-detail-modal-itinerary-slider__row--nowrap">
            <div className="equote__package-detail-modal-itinerary-slider__row-head">
              Port City
            </div>
            <div className="equote__package-detail-modal-itinerary-slider__row-body">
              {name.toUpperCase()}
            </div>
          </div>
          <div className="equote__package-detail-modal-itinerary-slider__row">
            <div className="equote__package-detail-modal-itinerary-slider__row-head">
              Activity
            </div>
            <div className="equote__package-detail-modal-itinerary-slider__row-body">
              {activity ? activity : '-'}
            </div>
          </div>
        </div>
      </div>
    )
  })

  return tableItems
}

const renderNumberOfSlidesToShow = () => {
  //   if (window.innerWidth > 1200) {
  //     return 3
  //   }
  //   if (window.innerWidth >= 768) {
  //     return 2
  //   }
  return 1
}

export default function EQuotePackageItinerarySlider(props) {
  const [numberOfSlidesToShow, setSlideCount] = useState(() =>
    renderNumberOfSlidesToShow()
  )

  const settings = {
    lazyLoad: 'progressive',
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: numberOfSlidesToShow,
    swipeToSlide: true,
    prevArrow: (
      <PrevArrow {...props} numberOfSlidesToShow={numberOfSlidesToShow} large />
    ),
    nextArrow: (
      <NextArrow {...props} numberOfSlidesToShow={numberOfSlidesToShow} large />
    )
  }

  const handleResize = () => {
    setSlideCount(() => renderNumberOfSlidesToShow())
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    //Clean up function
    return function cleanup() {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className="equote__package-detail-modal-itinerary-sliders">
      <Slider {...settings}>
        {props.cruises && renderCruises(props.cruises, props.sailDate)}
        {props.tours && renderTours(props.tours, props.sailDate)}
      </Slider>
    </div>
  )
}
