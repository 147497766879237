import React from 'react';

const EQuoteSearchFilterPriceSelect = props => {
  return (
    <div className="equote__search-filters-form-field-input u-custom-select">
      <select
        value={props.value}
        onChange={props.onChange}
      >
        {props.defaultValue && (
          <option value={props.defaultValue}>{props.defaultLabel}</option>
        )}
        {props.options.map((option, i) => {
          if(!option) return null
          return (
            <option
              key={option.key ? option.key : i}
              value={option.value}
            >
              {option.label}
            </option>
          )
        })}
      </select>
      <span className="u-custom-select__arrow" />
    </div>
  )
}

export default EQuoteSearchFilterPriceSelect;
