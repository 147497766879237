import React from 'react'
import { connect } from 'react-redux'
import CheckBox from '../elements/Checkbox'
import setActiveCabin from '../../actions/setActiveCabin'
import removeActiveCabin from '../../actions/removeActiveCabin'

const EQuoteCabinsTableSuperCatCheckBox = props => {
  const {
    hasRelatedSubCategories,
    isChecked,
    handleRemoveActiveCabin,
    handleSetActiveCabin
  } = props
  return (
    <CheckBox
      disabled={hasRelatedSubCategories}
      onClick={() =>
        isChecked ? handleRemoveActiveCabin() : handleSetActiveCabin()
      }
      checked={isChecked}
    />
  )
}

function mapStateToProps(state, ownProps) {
  const { activePackage, currentCategoryData } = ownProps
  const packageIdentifier = activePackage.isLandPackage
    ? activePackage.landPackageIdentifier
    : activePackage.identifier
  const activeRoomIdentifier =
    currentCategoryData.superCategoryData.stateroomType

  const isSelected = Boolean(
    state.activeCabinData[packageIdentifier] &&
      state.activeCabinData[packageIdentifier][activeRoomIdentifier]
  )

  const hasRelatedSubCategories = Boolean(
    state.activeCabinData[packageIdentifier] &&
      Object.values(state.activeCabinData[packageIdentifier]).some(cabin => {
        return (
          !cabin.isSuperCategory &&
          cabin.superCategoryCode === activeRoomIdentifier
        )
      })
  )

  return {
    hasRelatedSubCategories,
    isChecked: isSelected || hasRelatedSubCategories
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  const { activePackage, currentCategoryData } = ownProps
  const packageIdentifier = activePackage.isLandPackage
    ? activePackage.landPackageIdentifier
    : activePackage.identifier
  const activeCabinKey = currentCategoryData.superCategoryData.stateroomType

  const activeCabin = {
    superCategoryCode: activeCabinKey,
    superCategoryDescription:
      currentCategoryData.superCategoryData.stateroomDescription,
    packageId: activePackage.id,
    sailDate: activePackage.sailDate,
    ship: activePackage.shipCode,
    stateKey: activePackage.stateKey,
    packageIdentifier,
    stateroomType: activeCabinKey,
    isSuperCategory: true
  }

  return {
    handleSetActiveCabin() {
      return dispatch(
        setActiveCabin(packageIdentifier, activeCabinKey, activeCabin)
      )
    },
    handleRemoveActiveCabin() {
      return dispatch(removeActiveCabin(packageIdentifier, activeCabinKey))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteCabinsTableSuperCatCheckBox)
