import {
  FETCH_ITINERARY_BEGIN,
  FETCH_ITINERARY_SUCCESS,
  FETCH_ITINERARY_FAILURE
} from '../actions/fetchItinerary'

const initialState = {
  loading: false,
  error: null,
  itineraries: {}
}

export default function fetchItineraryReducers(state = initialState, action) {
  switch (action.type) {
    case FETCH_ITINERARY_BEGIN:
      // Request begin: set loading true and pass urlData to state
      return {
        ...state,
        loading: true,
        error: null
      }

    case FETCH_ITINERARY_SUCCESS:
      // Request success: set loading false, and display request data
      return {
        ...state,
        loading: false,
        itineraries: {
          ...state.itineraries,
          [action.payload.key]: action.payload.itinerary
        }
      }

    case FETCH_ITINERARY_FAILURE:
      // Request failed: set loading false, save & display error
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }

    default:
      return state
  }
}
