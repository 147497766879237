import {
  FETCH_STATIC_DATA,
  RECEIVE_STATIC_DATA
} from '../actions/fetchStaticData'

const initialState = { loading: false }

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_STATIC_DATA:
      return { loading: true }
    case RECEIVE_STATIC_DATA:
      return { loading: false }
    default:
      return state
  }
}
