import React, { Component } from 'react'

class EQuoteConfirmQuoteHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
  	const { recipients } = this.props
	  const renderRecipients = []

	  for (const recipient in recipients) {
		  renderRecipients.push(
		  	<p
				  key={ recipients[recipient] }
				  className="u-font-bold u-remove-margin"
			  >
				  { recipients[recipient] }
			  </p>
		  )
	  }

    return (
	    <div className="equote__confirm-quote-header">
		    <h3>Success!</h3>
		    <p>Your email(s) to:</p>
		    { renderRecipients ?
			    renderRecipients
		    :
			    null
		    }
		    <p>has been delivered!</p>
	    </div>
    )
  }
}

export default EQuoteConfirmQuoteHeader
