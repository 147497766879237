import React, { Component } from 'react'
import Modal from 'react-modal'
import { get } from 'lodash'
import '../../styles/scss/components/equote-package-details-state-rooms.scss'
import EQuotePackageDetailsStateRoomsSlider from './EQuotePackageDetailsStateRoomsSlider'
import EQuotePackageDetailsStateRoom from './EQuotePackageDetailsStateRoom'

const stateRoomOrder = ['I', 'O', 'B', 'D']

const imgHeights = {
  // $bp-max-xs: 'max-width: 320px';
  // $bp-max-sm: 'max-width: 480px';
  // $bp-max-md: 'max-width: 768px';
  // $bp-max-lg: 'max-width: 1024px';
  // $bp-max-xl: 'max-width: 1180px';
  // $bp-max-xxl: 'max-width: 1920px';
  // we use the carousel view up to $bp-max-xlr

  z: {
    'xx-large': 300,
    'x-large': 200,
    large: 237,
    medium: 225,
    small: 150,
    'x-small': 125
  },
  r: {
    'xx-large': 237,
    'x-large': 237,
    large: 350,
    medium: 350,
    small: 300,
    'x-small': 200
  },
  c: {
    'xx-large': 223,
    'x-large': 200,
    large: 350,
    medium: 300,
    small: 225,
    'x-small': 150
  }
}

class EQuotePackageDetailsStateRooms extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sizing: 'large',
      imageModalIsOpen: false,
      imageModalSrc: null
    }

    this.getStateRoomImageSize = this.getStateRoomImageSize.bind(this)
    this.toggleImageModal = this.toggleImageModal.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.getStateRoomImageSize)
    this.getStateRoomImageSize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getStateRoomImageSize)
  }

  getStateRoomImageSize = () => {
    switch (true) {
      case window.innerWidth >= 1405:
        this.setState({
          sizing: 'xx-large'
        })
        break
      case window.innerWidth >= 1180:
        this.setState({
          sizing: 'x-large'
        })
        break
      case window.innerWidth >= 1024:
        this.setState({
          sizing: 'large'
        })
        break
      case window.innerWidth >= 768:
        this.setState({
          sizing: 'medium'
        })
        break
      case window.innerWidth >= 480:
        this.setState({
          sizing: 'small'
        })
        break
      default:
        this.setState({
          sizing: 'x-small'
        })
    }
  }

  toggleImageModal = src => {
    this.setState({
      imageModalIsOpen: !this.state.imageModalIsOpen,
      imageModalSrc: src ? src : null
    })
  }

  renderStateRooms({ stateRooms, isCarouselView, currency, currencySymbol }) {
    const {
      currentPackageData: { brand },
      activeSearchFilterData,
      openVirtualTour
    } = this.props
    const imgHeight = imgHeights[brand.toLowerCase()][this.state.sizing]
    if (!stateRooms) {
      return null
    }
    return Object.values(stateRooms)
      .sort((a, b) => {
        if (
          stateRoomOrder.indexOf(a.superCategoryCode) ===
          stateRoomOrder.indexOf(b.superCategoryCode)
        ) {
          return a.netPrice - b.netPrice
        }

        return stateRoomOrder.indexOf(a.superCategoryCode) <
          stateRoomOrder.indexOf(b.superCategoryCode)
          ? -1
          : 1
      })
      .map((stateRoom, i) => {
        return (
          <EQuotePackageDetailsStateRoom
            key={`${stateRoom.stateroom.name}-${i}`}
            stateRoom={stateRoom}
            brand={brand}
            imgHeight={imgHeight}
            activeSearchFilterData={activeSearchFilterData}
            isCarouselView={isCarouselView}
            currency={currency}
            currencySymbol={currencySymbol}
            openVirtualTour={openVirtualTour}
            brandClassName={this.props.brandClassName}
            handleImageClick={src => this.toggleImageModal(src)}
          />
        )
      })
  }

  render() {
    const { selectedStateRoomData, terms } = this.props
    const currency = get(this, 'props.currentPackageData.currency', '')
    const currencySymbol = get(
      this,
      'props.currentPackageData.currencySymbol',
      ''
    )
    const stateRooms = selectedStateRoomData
      ? selectedStateRoomData.stateRooms
      : null
    const termsCopy = terms && terms.terms_content ? terms.terms_content : null
    return (
      <div
        className="equote__package-details-state-rooms u-content-container"
        id="staterooms"
      >
        <h1
          className={`section-title ${this.props.brandClassName} u-text-center`}
        >
          Available Staterooms
        </h1>
        <p className="content-disclaimer">
          <em>
            *Stateroom images and features are samples only. Actual furniture,
            fixtures, colors and configurations may vary.
          </em>
        </p>
        {stateRooms && Object.keys(stateRooms).length ? (
          <>
            <div className="equote__package-details-state-rooms--desktop-container">
              {this.renderStateRooms({
                stateRooms,
                isCarouselView: false,
                currency,
                currencySymbol
              })}
            </div>
            <div className="equote__package-details-state-rooms--carousel-container">
              <EQuotePackageDetailsStateRoomsSlider
                stateRooms={this.renderStateRooms({
                  stateRooms,
                  isCarouselView: true,
                  currency,
                  currencySymbol
                })}
              />
            </div>
          </>
        ) : (
          <p className="equote__package-details-state-rooms--no-availability-text">
            No staterooms are currently available for this sailing
          </p>
        )}

        {termsCopy && (
          <em
            className="pricing-disclaimer"
            dangerouslySetInnerHTML={{ __html: termsCopy }}
          />
        )}

        <Modal
          isOpen={this.state.imageModalIsOpen}
          onRequestClose={this.toggleImageModal}
          shouldCloseOnOverlayClick={true}
          ariaHideApp={false}
          htmlOpenClassName="ReactModal__Html--open"
          className={{
            base: 'u-modal u-modal__md',
            afterOpen: 'u-modal--visible',
            beforeClose: 'u-modal--close',
            ariaHideApp: false
          }}
          overlayClassName={{
            base: 'u-fade',
            afterOpen: 'u-fade--visible',
            beforeClose: 'u-fade--visible'
          }}
        >
          <img src={this.state.imageModalSrc} alt="" />
        </Modal>
      </div>
    )
  }
}

export default EQuotePackageDetailsStateRooms
