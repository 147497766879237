import React from 'react'

export const PrevArrow = props => {
  return (
    <div
      onClick={() => {
        if(props.gaEvent) {
          props.gaEvent()
        }
        props.onClick()
      }}
      className={`slick-arrow slick-prev ${!props.currentSlide && !props.infinite ? 'hide' : ''} `}
    >
      <svg width="21" height="21" viewBox="0 0 21 21" fill="none">
        <path
          d="M11.7002 19.3721L3.0183 10.684L11.7084 2.00005"
          stroke="#FFFFFF"
          strokeWidth="3"
        />
      </svg>
    </div>
  )
}

export const NextArrow = props => {
  const lastSlideShowing =
    props.slideCount <= props.currentSlide + props.numberOfSlidesToShow
  return (
    <div
      onClick={() => {
        if(props.gaEvent) {
          props.gaEvent()
        }
        props.onClick()
      }}
      className={`slick-arrow slick-next ${lastSlideShowing && !props.infinite ? 'hide' : ''}`}
    >
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none">
        <path
          d="M8.72363 2.0127L17.3936 10.7127L8.69149 19.3847"
          stroke="#FFFFFF"
          strokeWidth="3"
        />
      </svg>
    </div>
  )
}
