import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import EQuoteSailingsFullSearchCabinTable from './EQuoteSailingsFullSearchCabinTable'

import { getPackagesDetails } from '../../../../reducers/fetchPackagesReducers'

const EQuoteSailingsFullSearchCabins = props => {
  const {
    packageData,
    superCategory,
    superCategoryFullName,
    type,
    cabinData,
    keyIdentifier,
    closeHandler,
    currencySymbol
  } = props
  if (
    !_.isEmpty(packageData) &&
    !_.isEmpty(cabinData) &&
    cabinData &&
    !_.isEmpty(cabinData[superCategory])
  ) {
    // Render table if cabins available for category
    return (
      <>
        <EQuoteSailingsFullSearchCabinTable
          key={packageData.id + keyIdentifier + packageData.departure}
          cabins={cabinData[superCategory].pricing}
          superCategory={superCategory}
          superCategoryFullName={superCategoryFullName}
          type={type}
          closeHandler={closeHandler}
          currencySymbol={currencySymbol}
          packageData={packageData}
        />
      </>
    )
  } else {
    return 'No Cabins for category'
  }
}

const mapStateToProps = (state, ownProps) => {
  const packageDetails = getPackagesDetails(
    state,
    !ownProps.packageData.justACruise,
    ownProps.packageData.identifier
  )
  return {
    cabinData: packageDetails.tabularPricing,
    currencySymbol: packageDetails.currencySymbol || '$',
    packageData: packageDetails
  }
}

export default connect(mapStateToProps)(EQuoteSailingsFullSearchCabins)
