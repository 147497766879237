import React, { Component } from 'react'
import { connect } from 'react-redux'

import EQuoteSearchContainer from '../../containers/search/EQuoteSearchContainer'
import EQuoteSearchPriceContainer from '../../containers/search/price/EQuoteSearchPriceContainer'
import EQuoteSailingsFullSearch from '../sailings/fullSearch/EQuoteSailingsFullSearch'
import EQuoteCabinsContainer from '../../containers/cabins/EQuoteCabinsContainer'
import EQuoteReviewContainer from '../../containers/review/EQuoteReviewContainer'
import EQuoteConfirmQuote from '../../containers/confirm/EQuoteConfirmQuote'

import {
  resetActiveCabins,
  resetActiveEmailConfiguration,
  resetActiveFilters,
  resetActivePackages
} from '../../actions/resetQuoteFlow'
import setActiveStep from '../../actions/setActiveStepActions'
import updateSearchParams from '../../utilities/updateSearchParams'

import EQuoteError from '../error/EQuoteError'
import brandLoader from '../../assets/icons/brand-loader-final.gif'
import { clearPackageData } from '../../actions/fetchPackages'

class EQuoteContentSteps extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderCurrentComponent() {
    const { activeStep } = this.props

    switch (activeStep) {
      case 0:
        return <EQuoteSearchContainer />
      case 1:
        return <EQuoteSailingsFullSearch />
      case 2:
        return <EQuoteCabinsContainer />
      case 3:
        return <EQuoteReviewContainer />
      case 4:
        return <EQuoteConfirmQuote />
      case 5:
        // Intermediary step between search and sailing - only triggered by Search By Price button on step 1
        return <EQuoteSearchPriceContainer />
      default:
        return <EQuoteSearchContainer />
    }
  }

  handleResetQuoteFlow() {
    const { activeSearchFilterData, resetQuoteFlow } = this.props

    let resetQuery = {
      sso: activeSearchFilterData.sso
    }

    resetQuoteFlow()
    updateSearchParams(resetQuery)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.activeStep !== prevProps.activeStep) {
      window.scrollTo(0, 0)
    }
  }

  getMainClassForThePage = () => {
    switch (this.props.activeStep) {
      case 0:
        return 'equote_home-page-page'
      case 1:
        return 'equote_search-result'
      case 2:
        return 'equote_category-detail'
      case 3:
        return 'equote_email-preview'
      case 4:
        return 'equote_confirm-quote'
      case 5:
        return 'equote_search-by-price'
      default:
        return null
    }
  }

  render() {
    const {
      configurationError,
      configurationLoading,
      packagesError,
      packagesLoading,
      ssoError,
      ssoData,
      ssoLoading,
      staticDataIsLoading
    } = this.props

    return configurationLoading ||
      ssoLoading ||
      packagesLoading ||
      staticDataIsLoading ? (
      <div className="u-loading-spinner">
        <img src={brandLoader} alt="loading..." />
      </div>
    ) : configurationError || ssoError || packagesError ? (
      <EQuoteError
        title="Something Went Wrong"
        subTitle={
          configurationError
            ? configurationError
            : ssoError
            ? ssoError
            : packagesError
            ? packagesError
            : 'There was an error processing your request. Please try again.'
        }
        buttonText="Start Over"
        clickEvent={() => this.handleResetQuoteFlow()}
      />
    ) : ssoData && ssoData.agent && ssoData.agency ? (
      <div className={`equote__content ${this.getMainClassForThePage()}`}>
        {this.renderCurrentComponent()}
      </div>
    ) : (
      <div className="u-loading-spinner">
        <img src={brandLoader} alt="loading..." />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    activeSearchFilterData: state.activeSearchFilterData,
    activeStep: state.activeStepData.activeStep,
    configurationLoading: state.configurationData.loading,
    configurationError: state.configurationData.error,
    packagesError: state.packagesData.error,
    packagesLoading: state.packagesData.loading,
    ssoLoading: state.ssoData.loading,
    ssoData: state.ssoData,
    ssoError: state.ssoData.error,
    staticDataIsLoading: state.staticData.loading
  }
}

const mapDispatchToProps = dispatch => ({
  handleNextStep(activeStep) {
    return dispatch(setActiveStep(activeStep + 1))
  },
  resetQuoteFlow() {
    dispatch(resetActiveCabins())
    dispatch(resetActivePackages())
    dispatch(resetActiveFilters())
    dispatch(resetActiveEmailConfiguration())
    dispatch(setActiveStep(0))
    dispatch(clearPackageData())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteContentSteps)
