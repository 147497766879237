import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import EQuoteHeader from '../../components/structure/EQuoteHeader'
import EQuoteContentSteps from '../../components/steps/EQuoteContentSteps'
import EQuoteFooter from '../../components/structure/EQuoteFooter'
import EQuoteNavigation from '../../components/structure/EQuoteNavigation'
import fetchConfiguration from '../../actions/fetchConfiguration'
import MaintenancePage from '../../components/structure/MaintenancePage'

const noMaintenanceModeIds = [
  '183919',
  '214258',
  '351795',
  '323496',
  '214107',
  '372756'
]

class App extends Component {
  componentDidMount() {
    const { configurationData, fetchConfig } = this.props
    if (!configurationData.loading && !configurationData.configuration.length) {
      fetchConfig()
    }
  }

  render() {
    const { configurationData, agencyId } = this.props
    const isMaintenance =
      get(configurationData, 'maintenanceMode', false) &&
      !noMaintenanceModeIds.some(id => id === `${agencyId}`)

    return (
      <div className="equote__generate-offers">
        <EQuoteHeader isMaintenance={isMaintenance} />
        {!isMaintenance ? (
          <EQuoteContentSteps />
        ) : (
          <MaintenancePage
            data={get(configurationData, 'maintenanceData', {})}
          />
        )}

        <EQuoteFooter />
        {!isMaintenance && <EQuoteNavigation />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const agencyId = get(state, 'ssoData.agency.agency_id', '')
  return {
    configurationData: state.configurationData,
    agencyId
  }
}
const mapDispatchToProps = dispatch => {
  return {
    fetchConfig() {
      return dispatch(fetchConfiguration())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
