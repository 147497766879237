import React from 'react'
import moment from 'moment'
import { get, isNaN, capitalize } from 'lodash'
import '../../styles/scss/components/equote-package-details-related-sailings.scss'
import '../../styles/scss/base/table.scss'
import formatLeadPromoDesc from '../../utilities/formatLeadPromoDesc'
import '../../styles/scss/components/equote-package-details-related-sailings-table.scss'
import { formatDisplayPrice } from '../../utilities/formatPrice'

const renderPricing = (pricing, currencySymbol = '$') => {
  if (!pricing || !pricing.bestGuestCountRate)
    return (
      <div className="table-cell-text table-cell-text--price u-text-center sold-out">
        Sold Out
      </div>
    )

  return (
    <div>
      {pricing.bestGuestCountRate.perPersonAveragePrice ? (
        <>
          <div className="table-cell-text table-cell-text--price">
            {currencySymbol}
            {formatDisplayPrice(
              pricing.bestGuestCountRate.perPersonAveragePrice
            )}
          </div>
          <div className="table-cell-text table-cell-text--promo">
            {formatLeadPromoDesc(
              pricing.bestGuestCountRate.leadPromoDesc,
              pricing.bestGuestCountRate.promos
            )}
          </div>
          <div className="table-cell-text table-cell-text--refundable">
            {pricing.bestGuestCountRate.isRefundable
              ? `Regular Deposit`
              : `Non-Refundable Deposit`}
          </div>
        </>
      ) : (
        <div className="sold-out">Sold Out</div>
      )}
    </div>
  )
}

const EQuotePackageDetailsRelatedSailingsTable = props => {
  const relatedSailings = props.relatedSailings

  return (
    <div className="equote-package-related-sailings-table u-content-container">
      <div className="featured-image">
        <img src={props.destinationImage} alt="Other Departures" />
      </div>

      <div
        style={{
          flex: 1
        }}
      >
        <table className="u-table">
          <thead>
            <tr>
              <th>Trip</th>
              <th>Interior</th>
              <th>Oceanview</th>
              <th>Balcony</th>
              <th>Deluxe / Suite</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {relatedSailings.map(sailing => {
              const totalTaxes = get(sailing, 'priceInfo.taxesFees', null)

              return (
                <tr key={`${sailing.id}-${sailing.sailDate}`}>
                  <td>
                    <div className="table-cell-text--title">
                      {capitalize(sailing.itinerary.description)}
                    </div>
                    <div>
                      Departure:{' '}
                      {moment(sailing.sailDate, 'YYYY-MM-DD').format(
                        'MMM DD, YYYY'
                      )}
                    </div>
                    <div>
                      Ship:{' '}
                      {props.packageData && props.packageData.shipName
                        ? props.packageData.shipName
                        : null}
                    </div>
                    {totalTaxes && !isNaN(totalTaxes) ? (
                      <div style={{ fontSize: '12px', fontWeight: 'normal' }}>
                        <i>
                          Taxes, fees & port expenses:{' '}
                          {sailing.currencySymbol || '$'}
                          {Number(totalTaxes).toFixed(2)}{' '}
                          {sailing.currency ? sailing.currency : ''}
                        </i>
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <div>
                      {renderPricing(
                        sailing.pricing['I'],
                        sailing.currencySymbol
                      )}
                    </div>
                  </td>
                  <td>
                    <div>
                      {renderPricing(
                        sailing.pricing['O'],
                        sailing.currencySymbol
                      )}
                    </div>
                  </td>
                  <td>
                    <div>
                      {renderPricing(
                        sailing.pricing['B'],
                        sailing.currencySymbol
                      )}
                    </div>
                  </td>
                  <td>
                    <div>
                      {renderPricing(
                        sailing.pricing['D'],
                        sailing.currencySymbol
                      )}
                    </div>
                  </td>
                  <td>
                    <button
                      className="btn btn-primary not-allowed u-uppercase similar"
                      disabled
                    >
                      Request
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default EQuotePackageDetailsRelatedSailingsTable
