import axios from 'axios'
import 'url-search-params-polyfill'
import { get } from 'lodash'

import setActiveStep from './setActiveStepActions'
import fetchPackages from './fetchPackages'

import { setActiveLoyalty } from './setActiveSearchFilters'

export const FETCH_LOYALTY_BEGIN = 'FETCH_LOYALTY_BEGIN'
export const FETCH_LOYALTY_SUCCESS = 'FETCH_LOYALTY_SUCCESS'
export const SET_QUERIED_LOYALTY_ID = 'SET_QUERIED_LOYALTY_ID'
export const FETCH_LOYALTY_FAILURE = 'FETCH_LOYALTY_FAILURE'

export const fetchLoyaltyBegin = () => ({
  type: FETCH_LOYALTY_BEGIN
})

export const fetchLoyaltySuccess = loyalty => ({
  type: FETCH_LOYALTY_SUCCESS,
  payload: loyalty
})

export const setQueriedLoyaltyId = loyaltyId => ({
  type: SET_QUERIED_LOYALTY_ID,
  payload: loyaltyId
})

export const fetchLoyaltyError = error => ({
  type: FETCH_LOYALTY_FAILURE,
  payload: { error }
})

// Handle Loyalty Fetch & Functionality
export default function fetchLoyalty(loyaltyId, agencyId) {
  return (dispatch, getState) => {
    // Import Query string & Parse URL
    const activeSearchFilterData = getState().activeSearchFilterData

    // Base API path
    const loyaltyBase = process.env.REACT_APP_FLOW_ENDPOINT_URL + 'loyalty/'

    // Formatted API path including base and params
    const loyaltyApi = loyaltyBase + loyaltyId + '?agencyId=' + agencyId

    dispatch(fetchLoyaltyBegin())
    return axios
      .get(loyaltyApi)
      .then(response => {
        let loyaltyData = {}
        const loyaltyTierCode = get(response, 'data.member.LoyaltyTierCode', '')
        loyaltyData[response.data.id + '-' + response.data.agencyId] = {
          id: response.data.id,
          agency: response.data.agencyId,
          member: response.data.member,
          error: response.data.error,
          loyaltyTierCode
        }

        if (loyaltyData) {
          dispatch(fetchLoyaltySuccess(loyaltyData))

          if (loyaltyData[response.data.id + '-' + response.data.agencyId]) {
            dispatch(setQueriedLoyaltyId(response.data.id))
          }

          // Set active filter if member loyalty number available

          if (
            loyaltyData[response.data.id + '-' + response.data.agencyId].member
          ) {
            const loyaltyId =
              loyaltyData[response.data.id + '-' + response.data.agencyId]
                .member.LoyaltyNumber
            dispatch(setActiveLoyalty(loyaltyId, loyaltyTierCode))

            let isArrivalSet = false

            // Flag to enable search based on end date OR count
            if (
              activeSearchFilterData.endDate ||
              activeSearchFilterData.count
            ) {
              isArrivalSet = true
            }

            if (
              activeSearchFilterData.brand &&
              activeSearchFilterData.bookingType &&
              activeSearchFilterData.cruiseType &&
              activeSearchFilterData.startDate &&
              isArrivalSet
            ) {
              dispatch(fetchPackages())
              dispatch(setActiveStep(1))
            }
          }
        }
      })
      .catch(error => {
        dispatch(fetchLoyaltyError(error))
      })
  }
}
