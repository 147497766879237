import React from 'react'

import moment from 'moment'

import timeConvert from '../../utilities/formatTime'

import { filterImagesByFileName } from '../../utilities/filterImagesByFileName'

import '../../styles/scss/components/equote-package-details-itinerary-print.scss'

const _ = require('lodash')

const calculateCruiseDates = (day, sailDate) => {
  return moment(sailDate, 'YYYY-MM-DD')
    .add(day - 1, 'days')
    .format('MMMM DD, YYYY')
}

const renderTableItinerary = (props, array, title) => {
  return (
    props.itinerary && (
      <>
        <h2>{title}</h2>
        <div className="u-overflow-x-scroll">
          <table className="u-table">
            <thead>
              <tr>
                <th>Day</th>
                <th>Port City</th>
                <th>Activity</th>
                <th>Arrival</th>
                <th>Departure</th>
              </tr>
            </thead>
            <tbody>
              {array.map(
                ({ day, activity, departureTime, arrivalTime, name }, i) => (
                  <tr key={i}>
                    <td className="center">
                      {calculateCruiseDates(day, props.startDate)}
                    </td>
                    <td>{name}</td>
                    <td className="center">{activity ? activity : '-'}</td>
                    <td className="center">
                      {arrivalTime ? timeConvert(arrivalTime) : '-'}
                    </td>
                    <td className="center">
                      {departureTime ? timeConvert(departureTime) : '-'}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </>
    )
  )
}

const renderTableTour = (props, array, title) => {
  return (
    props.itinerary && (
      <>
        <h2>{title}</h2>
        <table className="u-table">
          <thead>
            <tr>
              <th>Day</th>
              <th>Port City</th>
              <th>Activity</th>
            </tr>
          </thead>
          <tbody>
            {array.map(({ day, activity, name }, i) => (
              <tr key={i}>
                <td className="center">
                  {calculateCruiseDates(day, props.startDate)}
                </td>
                <td>{name}</td>
                <td className="center">
                  {activity
                    ? activity === 'Cruisetour'
                      ? 'Cruisetour'
                      : activity
                    : '-'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    )
  )
}

const renderTableSailing = props => {
  let { itinerary } = props
  itinerary = itinerary.filter(day => day.activity !== 'Cruisetour')
  return renderTableItinerary(props, itinerary, 'Sailing Itinerary')
}

const renderTablePreSailing = props => {
  const { itinerary, cruiseTourDetails } = props
  if (cruiseTourDetails && cruiseTourDetails.preTourCity) {
    let indexes = cruiseTourDetails.preTourCity.map(preTour => {
      return itinerary.findIndex(({ code }) => preTour.code === code) + 1
    })
    const preSailing = itinerary.filter((day, i) => indexes.includes(i))
    return renderTableTour(props, preSailing, 'Pre-Sailing Tour')
  }
}

const renderTablePostSailing = props => {
  const { itinerary, cruiseTourDetails } = props
  if (cruiseTourDetails && cruiseTourDetails.postTourCity) {
    let indexes = cruiseTourDetails.postTourCity.map(preTour => {
      return itinerary.findIndex(({ code }) => preTour.code === code) + 1
    })
    const postSailing = itinerary.filter((day, i) => indexes.includes(i))
    return renderTableTour(props, postSailing, 'Post-Sailing Tour')
  }
}

const renderModalContent = props => {
  let { itinerary, destinationImage } = props

  itinerary &&
    itinerary.forEach(port => {
      if (port.isFeatured) {
        destinationImage = filterImagesByFileName(
          port.highlight.image,
          'detail-hero'
        )
      }
    })

  if (!_.isEmpty(itinerary)) {
    return (
      <div className="equote__package-itinerary-print u-display-none">
        <div className="equote__package-itinerary-print-destination-image">
          {destinationImage &&
            destinationImage[0] &&
            destinationImage[0].displayImage && (
              <img src={destinationImage[0].displayImage} alt="destination" />
            )}
        </div>
        {renderTablePreSailing(props)}
        {renderTableSailing(props)}
        {renderTablePostSailing(props)}
      </div>
    )
  } else {
    return (
      <p className="error-message">
        'There appears to be no data for this ship - please check back'
      </p>
    )
  }
}

export default function EQuotePackageDetailsItineraryPrint(props) {
  return renderModalContent(props)
}
