import React, { useState } from 'react'
import { connect } from 'react-redux'
import { setActiveLandPackages } from '../../actions/setActiveLandPackage'
import { removeActiveLandPackages } from '../../actions/removeActiveLandPackage'
import '../../styles/scss/components/equote-cabins-land-packages.scss'

import checkMark from '../../assets/icons/checkmark.svg'
import EQuoteLandProgramDetailsModal from '../sailings/fullSearch/Modals/EQuoteLandProgramDetailsModal'
import { getDestinationsData } from '../../sessionStorage/getDestinationsData'
import CustomSelect from '../elements/CustomSelect'

const EQuoteCabinsLandProgramsSelection = props => {
  const {
    activePackage,
    activeLandPrograms,
    identifier,
    allMatchingActiveCruisesLandPrograms
  } = props

  const [
    landProgramDetailsModalIsOpen,
    toggleLandProgramDetailsModalIsOpen
  ] = useState(false)
  const [landProgramForModal, setLandProgramForModal] = useState(null)

  const [availableLandPackages, setAvailableLandPackages] = useState(
    activePackage.availableLandPackages
  )

  const numberOfNightsOptions = Object.values(
    activePackage.availableLandPackages
  )
    .reduce((arr, availablePackagesByType) => {
      availablePackagesByType.forEach(availablePackage => {
        if (!arr.some(night => night === Number(availablePackage.tourNights))) {
          arr.push(Number(availablePackage.tourNights))
        }
      })

      return arr
    }, [])
    .sort()

  const filterAvailableLandPackagesByNights = numberOfNights => {
    if (numberOfNights === 'all') {
      return setAvailableLandPackages(activePackage.availableLandPackages)
    }

    const filteredLandPackages = Object.entries(
      activePackage.availableLandPackages
    ).reduce((obj, [prePostKey, landPackages]) => {
      landPackages.forEach(landPackage => {
        if (Number(landPackage.tourNights) === Number(numberOfNights)) {
          if (!obj[prePostKey]) {
            obj[prePostKey] = []
          }
          obj[prePostKey].push(landPackage)
        }
      })
      return obj
    }, {})

    setAvailableLandPackages(filteredLandPackages)
  }

  const hasPreSelectedLandPackages =
    activePackage.preSelectedLandPackage &&
    Object.keys(activePackage.preSelectedLandPackage).length

  function compareCombinations(landPackage) {
    const landPackageType = landPackage.prePostTagName
    // we need to check if any other cruises have the same land program combination
    // we'll compare the other cruises with our current selections if we select this program.
    const selectedProgramsWithThisProgram = {
      ...activePackage.allSelectedLandPrograms,
      [landPackageType]: landPackage
    }
    const otherSelectedCruiseWithSameLandProgram = allMatchingActiveCruisesLandPrograms.filter(
      matchingCruise => {
        return (
          Object.keys(matchingCruise).length ===
            Object.keys(selectedProgramsWithThisProgram).length &&
          matchingCruise[landPackageType] &&
          matchingCruise[landPackageType].packageCode ===
            landPackage.packageCode
        )
      }
    )

    if (!otherSelectedCruiseWithSameLandProgram.length) {
      return false
    }

    return otherSelectedCruiseWithSameLandProgram.some(cruise => {
      return Object.values(cruise).every(program => {
        return (
          selectedProgramsWithThisProgram[program.prePostTagName] &&
          selectedProgramsWithThisProgram[program.prePostTagName]
            .packageCode === program.packageCode
        )
      })
    })
  }

  return (
    <div className="equote__cabins-land-packages-container">
      {hasPreSelectedLandPackages
        ? Object.entries(activePackage.preSelectedLandPackage).map(
            ([key, data]) => {
              return (
                <div
                  key={`${key}-${data.title}`}
                  className="equote__cabins-land-packages-pre-selected-package"
                >
                  <p className="equote__cabins-land-packages-pre-selected-package--title">
                    Your Added {key} land program
                  </p>
                  <div className="equote__cabins-land-packages-pre-selected-package--card">
                    <div className="equote__cabins-land-packages-pre-selected-package--card-header">
                      <div
                        className={
                          'equote__cabins-land-packages-pre-selected-package--card-header-check'
                        }
                      >
                        <img
                          src={checkMark}
                          alt={`Your Added ${key} land program`}
                        />
                      </div>
                      <p>{data.title}</p>
                      <p>{data.tourNights} Nights</p>
                      <p>${data.perGuestPrice}</p>
                    </div>

                    <div
                      className="equote__cabins-land-packages-pre-selected-package--card-body"
                      dangerouslySetInnerHTML={{ __html: data.description }}
                    />
                  </div>
                </div>
              )
            }
          )
        : null}
      {numberOfNightsOptions.length > 1 ? (
        <div className="equote__cabins-land-packages-filter-buttons">
          <p className="u-remove-margin equote__cabins-land-packages-filter-buttons-title">
            NARROW YOUR RESULTS
          </p>

          <CustomSelect
            onChange={e => filterAvailableLandPackagesByNights(e.target.value)}
            options={numberOfNightsOptions.map(num => ({
              value: num,
              text: `${num} Night${num > 1 ? 's' : ''}`
            }))}
            defaultOption={{
              value: 'all',
              text: 'Number of Nights'
            }}
          />
        </div>
      ) : null}
      {availableLandPackages && Object.keys(availableLandPackages).length ? (
        <div className="equote__cabins-land-packages-available-packages">
          {Object.entries(availableLandPackages).map(([key, packages]) => {
            const columnWidth =
              Object.keys(availableLandPackages).length > 1 ? 'half' : 'full'
            return (
              <div
                key={key}
                className={`equote__cabins-land-packages-available-packages-column ${columnWidth}`}
              >
                <p className="equote__cabins-land-packages-available-packages-column-title">
                  {hasPreSelectedLandPackages
                    ? 'Add an additional'
                    : 'Available '}{' '}
                  {key} land program{hasPreSelectedLandPackages ? '' : 's'}
                </p>
                {packages.map(landPackage => {
                  const landPackageType = landPackage.prePostTagName

                  // we need to check if any other cruises have the same land program combination
                  // we'll compare the other cruises with our current selections if we select this program.
                  const otherSelectedCruiseHasSameLandProgramCombination = compareCombinations(
                    landPackage
                  )

                  const isSelected =
                    activeLandPrograms &&
                    activeLandPrograms[landPackageType] &&
                    activeLandPrograms[landPackageType].packageCode ===
                      landPackage.packageCode

                  return (
                    <div
                      key={landPackage.packageCode}
                      className={`equote__cabins-land-packages-available-package ${
                        otherSelectedCruiseHasSameLandProgramCombination
                          ? 'disabled'
                          : ''
                      }`}
                    >
                      <div className="equote__cabins-land-packages-available-package-select-button-container">
                        <button
                          className={`btn btn-radio ${
                            isSelected ? 'active' : ''
                          }`}
                          aria-label={`select ${
                            landPackage.cruiseTourType
                          } package ${landPackage.title}`}
                          onClick={() =>
                            !isSelected
                              ? props.addLandPackage(
                                  identifier,
                                  landPackageType,
                                  landPackage
                                )
                              : props.removeLandPackages(
                                  identifier,
                                  landPackageType
                                )
                          }
                        />
                      </div>
                      <div
                        className={`equote__cabins-land-packages-available-package-details-container ${
                          otherSelectedCruiseHasSameLandProgramCombination
                            ? 'has-warning'
                            : ''
                        } ${columnWidth}`}
                      >
                        <div className="equote__cabins-land-packages-available-package-details">
                          <div className="equote__cabins-land-packages-available-package-details-column">
                            <p>{landPackage.title}</p>
                            <p>{landPackage.tourNights} nights</p>
                          </div>
                          <div className="equote__cabins-land-packages-available-package-details-column">
                            <p>${landPackage.perGuestPrice}</p>
                          </div>
                        </div>
                        {otherSelectedCruiseHasSameLandProgramCombination ? (
                          <div
                            className={`equote__cabins-land-packages-available-package-details-warning ${columnWidth}`}
                          >
                            <p>THIS COMBINATION HAS ALREADY BEEN APPLIED</p>
                          </div>
                        ) : null}
                        <div className="equote__cabins-land-packages-available-package-details-button-container">
                          <button
                            className="btn"
                            onClick={() => {
                              setLandProgramForModal(landPackage)
                              toggleLandProgramDetailsModalIsOpen(true)
                            }}
                          >
                            Details
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      ) : null}
      {landProgramDetailsModalIsOpen && (
        <EQuoteLandProgramDetailsModal
          isOpen={landProgramDetailsModalIsOpen}
          activeLandPackage={landProgramForModal}
          destinationsData={props.destinationsData}
          togglePackageDetailsModal={() =>
            toggleLandProgramDetailsModalIsOpen(false)
          }
        />
      )}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const identifier =
    ownProps && ownProps.activePackage && ownProps.activePackage.isLandPackage
      ? ownProps.activePackage.landPackageIdentifier
      : ownProps.activePackage.identifier

  const activeLandPrograms =
    state.activeLandPackages && state.activeLandPackages[identifier]

  const { allActivePackages } = ownProps

  const allMatchingActiveCruisesLandPrograms = Object.entries(
    allActivePackages
  ).reduce((arr, [cruiseKey, cruise]) => {
    if (
      cruiseKey !== identifier &&
      cruise.id === ownProps.activePackage.id &&
      (cruise.isLandPackage || cruise.hasLandPackages)
    ) {
      arr.push(cruise.allSelectedLandPrograms)
    }
    return arr
  }, [])

  return {
    activeLandPrograms,
    identifier,
    allMatchingActiveCruisesLandPrograms,
    destinationsData: getDestinationsData()
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addLandPackage(identifier, prePostKey, landPackage) {
      dispatch(setActiveLandPackages(identifier, prePostKey, landPackage))
    },
    removeLandPackages(identifier, prePostKey) {
      dispatch(removeActiveLandPackages(identifier, prePostKey))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteCabinsLandProgramsSelection)
