import React from 'react'

const CustomSelect = props => {
  return (
    <div className="equote-primary-select-button-container">
      <select
        onChange={props.onChange}
        className="equote-primary-select-button"
        name={props.name ? props.name : ''}
      >
        {props.defaultOption && (
          <option value={props.defaultOption.value}>
            {props.defaultOption.text}
          </option>
        )}
        {props.options.map((option, i) => (
          <option
            key={`custom-select-${option.value}-${i}`}
            value={option.value}
          >
            {option.text}
          </option>
        ))}
      </select>
    </div>
  )
}

export default CustomSelect
