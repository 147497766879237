import React from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

import '../../../styles/scss/components/equote-search-disclaimer.scss'
import { getTermsByCurrency } from '../../../reducers/fetchConfigurationReducers'

const EQuoteSearchDisclaimer = props => {
  const copy = get(props, 'terms.terms_content')
  return copy ? (
    <div className="equote__search-disclaimer ">
      <h5>Terms and Conditions</h5>
      <p
        className="u-remove-margin u-txt-italic u-font-small"
        dangerouslySetInnerHTML={{ __html: copy }}
      />
    </div>
  ) : null
}

const mapStateToProps = state => {
  const terms = getTermsByCurrency(state)
  return {
    terms
  }
}

export default connect(mapStateToProps)(EQuoteSearchDisclaimer)
