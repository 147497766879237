import React from 'react'
import { connect } from 'react-redux'
import '../../../../styles/scss/components/equote-search-filter-buttons.scss'
import { getBrandsData } from '../../../../sessionStorage/getBrandsData'
import CustomSelect from '../../../elements/CustomSelect'

function renderCruiseTourTypeOptions(cruiseTours) {
  if (!cruiseTours || !cruiseTours.length) return []
  const options = new Set()
  cruiseTours.forEach(cruiseTour => {
    options.add(cruiseTour.tourTypeLabel)
  })
  return [...options.values()]
}

const EQuoteSailingsFullSearchFilterButtons = props => {
  const filterDuplicatesValues = (array, key) => {
    return array.reduce((filtered, item) => {
      if (!filtered.includes(item[key])) {
        filtered.push(item[key])
      }
      return filtered
    }, [])
  }
  const sortNumber = (a, b) => {
    return a - b
  }

  //Switch array of sailings based on options "Cruises" / "Tours"
  const sailingOption =
    props.activeSailingsTab === 0
      ? props.packages
      : props.activeSailingsTab === 1
      ? props.cruiseTours
      : props.landPrograms

  const ships = filterDuplicatesValues(sailingOption, 'shipName')
  const brands = filterDuplicatesValues(sailingOption, 'brandName')
  const nights = filterDuplicatesValues(sailingOption, 'totalNights').sort(
    sortNumber
  )
  const destinations = filterDuplicatesValues(
    sailingOption,
    'destinationName'
  ).sort()

  const landProgramTypes =
    props.activeSailingsTab === 2 &&
    props.landPrograms &&
    props.landPrograms.length
      ? filterDuplicatesValues(props.landPrograms, 'landProgramType')
      : []

  const cruiseTourTypes =
    props.activeSailingsTab === 1 &&
    props.cruiseTours &&
    props.cruiseTours.length
      ? renderCruiseTourTypeOptions(props.cruiseTours)
      : []

  return (
    <div
      className="equote__sailings-full-search-filter"
      key={props.activeSailingsTab}
    >
      <h6 className="equote__sailings-full-search-filter-title">
        Narrow Your Results
      </h6>

      <div className="equote__sailings-full-search-filter-buttons">
        {brands.length > 1 && (
          <CustomSelect
            onChange={props.handleFilterChangeTerm}
            name="brand"
            defaultOption={{
              value: '',
              text: 'All Brands'
            }}
            options={brands.sort().map(
              brand =>
                brand && {
                  value: brand,
                  text: brand
                }
            )}
          />
        )}
        <CustomSelect
          onChange={props.handleFilterChangeTerm}
          name="ship"
          defaultOption={{
            value: '',
            text: 'All Ships'
          }}
          options={ships.sort().map(
            ship =>
              ship && {
                value: ship,
                text: ship
              }
          )}
        />
        <CustomSelect
          name="destination"
          onChange={props.handleFilterChangeTerm}
          defaultOption={{
            value: '',
            text: 'All Destinations'
          }}
          options={destinations.sort().map(
            destination =>
              destination && {
                value: destination,
                text: destination
              }
          )}
        />
        <CustomSelect
          name="nights"
          onChange={props.handleFilterChangeTerm}
          defaultOption={{ value: '', text: 'All Nights' }}
          options={nights
            .filter(night => !!night)
            .sort((a, b) => +a - +b)
            .map(night => ({
              value: night,
              text: `${night} night${night > 1 ? 's' : ''}`
            }))}
        />
        {Boolean(landProgramTypes && landProgramTypes.length) && (
          <CustomSelect
            name="landProgramTypes"
            onChange={props.handleFilterChangeTerm}
            defaultOption={{ value: '', text: 'Pre and Post Land Programs' }}
            options={landProgramTypes.map(tourType => ({
              value: tourType,
              text: `${tourType.charAt(0).toUpperCase() +
                tourType.slice(1)} Land Program`
            }))}
          />
        )}
        {Boolean(cruiseTourTypes && cruiseTourTypes.length) && (
          <CustomSelect
            name="tourTypeLabel"
            onChange={props.handleFilterChangeTerm}
            defaultOption={{ value: '', text: 'All Tour Types' }}
            options={cruiseTourTypes.map(tourType => ({
              value: tourType,
              text: `${tourType === 'BOTH' ? 'PRE/POST' : tourType} Tour`
            }))}
          />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  activeSearchFilterData: state.activeSearchFilterData,
  brandsData: getBrandsData()
})

export default connect(mapStateToProps)(EQuoteSailingsFullSearchFilterButtons)
