import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import { PrevArrow, NextArrow } from '../elements/CarouselArrows'

import '../../styles/scss/components/equote-package-details-state-rooms-slider.scss'

const renderNumberOfSlidesToShow = () => {
  if (window.innerWidth > 1440) {
    return 3
  }
  if (window.innerWidth > 1000) {
    return 2
  }
  if (window.innerWidth > 768) {
    return 2
  }
  return 1
}

export default function EQuotePackageDetailsStateRoomsSlider(props) {
  const [numberOfSlidesToShow, setSlideCount] = useState(() =>
    renderNumberOfSlidesToShow()
  )

  useEffect(() => {
    const handleResize = () => {
      setSlideCount(() => renderNumberOfSlidesToShow())
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const settings = {
    lazyLoad: 'progressive',
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: numberOfSlidesToShow,
    swipeToSlide: true,
    prevArrow: (
      <PrevArrow {...props} numberOfSlidesToShow={numberOfSlidesToShow} large />
    ),
    nextArrow: (
      <NextArrow {...props} numberOfSlidesToShow={numberOfSlidesToShow} large />
    )
  }

  return (
    <div className="equote__package-details-state-rooms-slider">
      <Slider {...settings}>{props.stateRooms}</Slider>
    </div>
  )
}
