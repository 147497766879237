import {
  REQUEST_RELATED_SAILINGS,
  RELATED_SAILINGS_ERROR,
  RECEIVE_RELATED_SAILINGS,
  FINISHED_RELATED_SAILINGS_REQUESTS
} from '../actions/fetchRelatedSailings'

import { CLEAR_PACKAGE_DATA } from '../actions/fetchPackages'

const initialState = {
  isLoading: false,
  hasErrors: false,
  items: {}
}

const fetchRelatedSailings = (state = initialState, action) => {
  switch (action.type) {
    case RELATED_SAILINGS_ERROR:
      return Object.assign({}, state, {
        hasErrors: true,
        isLoading: false
      })
    case RECEIVE_RELATED_SAILINGS:
      return Object.assign({}, state, {
        items: {
          ...state.items,
          [action.payload.key]: action.payload.info
        },
        isLoading: false,
        hasErrors: false
      })
    case FINISHED_RELATED_SAILINGS_REQUESTS:
      return Object.assign({}, state, {
        isLoading: false
      })
    case REQUEST_RELATED_SAILINGS:
      return Object.assign({}, state, {
        isLoading: true
      })
    case CLEAR_PACKAGE_DATA:
      // CLEAR related sailings on new search.
      return Object.assign({}, state, {
        isLoading: false,
        hasErrors: false,
        items: {}
      })
    default:
      return state
  }
}

export default fetchRelatedSailings
