import React, { useState } from 'react'

const specs = {
  tonnage: 'Tonnage',
  length: 'Length',
  beam: 'Beam',
  draft: 'Draft',
  // power: 'Power',
  // propulsion: 'Propulsion',
  speed: 'Speed',
  capacity: 'Capacity',
  crew: 'Crew'
}

const ShipSpecs = ({ shipDetails, useToggle }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => setIsOpen(!isOpen)
  return (
    <>
      {useToggle && (
        <button
          onClick={toggleOpen}
          className={`btn mobile-tech-toggle ${isOpen ? 'active' : ''}`}
        >
          View Ship Technical Specs{' '}
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none">
            <path
              d="M8.72363 2.0127L17.3936 10.7127L8.69149 19.3847"
              stroke="#3F6BAB"
              strokeWidth="3"
            />
          </svg>
        </button>
      )}

      <div
        className={`ship-details-table ${useToggle ? 'mobile' : 'desktop'} ${
          isOpen ? 'open' : ''
        }`}
      >
        <div className="row">
          <p className="lable">Class and Type:</p>

          <p className="value">{shipDetails ? shipDetails.class : ''}</p>
        </div>

        {Object.entries(specs).map(([key, label]) => {
          if (!shipDetails || !shipDetails[key]) {
            return null
          }

          return (
            <div className="row" key={key}>
              <p>{label}:</p>
              <p>{shipDetails[key]}</p>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default ShipSpecs
