import axios from 'axios'
import 'url-search-params-polyfill'
import { get } from 'lodash'
import fetchLoyalty from './fetchLoyalty'
import setActiveStep from './setActiveStepActions'
import fetchPackages from './fetchPackages'

import triggerCustomGaEvent from '../utilities/triggerCustomGaEvent'
import { setActiveCurrency } from './setActiveSearchFilters'

export const FETCH_SSO_BEGIN = 'FETCH_SSO_BEGIN'
export const FETCH_SSO_SUCCESS = 'FETCH_SSO_SUCCESS'
export const FETCH_SSO_FAILURE = 'FETCH_SSO_FAILURE'

export const fetchSsoBegin = () => ({
  type: FETCH_SSO_BEGIN
})

export const fetchSsoSuccess = packages => ({
  type: FETCH_SSO_SUCCESS,
  payload: packages
})

export const fetchSsoError = error => ({
  type: FETCH_SSO_FAILURE,
  payload: { error }
})

// Handle Availability Fetch & Functionality
export default function fetchSso() {
  return (dispatch, getState) => {
    // Import Query string & Parse URL
    const activeSearchFilterData = getState().activeSearchFilterData

    // Base API path
    const ssoBase = process.env.REACT_APP_FLOW_ENDPOINT_URL + 'agent/'

    // Formatted API path including base and params
    const ssoApi =
      ssoBase +
      activeSearchFilterData.sso +
      '?renewData=' +
      new Date().getTime()

    dispatch(fetchSsoBegin())
    return axios
      .get(ssoApi)
      .then(response => {
        const ssoAgent = response.data.data

        if (ssoAgent) {
          // Dispatch GA event for SSO agent
          triggerCustomGaEvent('equoteTracking-agentRequest', ssoAgent)

          let isArrivalSet = false

          // Flag to enable search based on end date OR count
          if (activeSearchFilterData.endDate || activeSearchFilterData.count) {
            isArrivalSet = true
          }
          if (
            ssoAgent &&
            ssoAgent.agency &&
            ssoAgent.agency.agency_id &&
            activeSearchFilterData.loyaltyId &&
            activeSearchFilterData.brand &&
            activeSearchFilterData.bookingType &&
            activeSearchFilterData.cruiseType &&
            activeSearchFilterData.startDate &&
            isArrivalSet
          ) {
            dispatch(
              fetchLoyalty(
                activeSearchFilterData.loyaltyId,
                ssoAgent.agency.agency_id
              )
            )
          } else if (
            activeSearchFilterData.brand &&
            activeSearchFilterData.bookingType &&
            activeSearchFilterData.cruiseType &&
            activeSearchFilterData.startDate &&
            isArrivalSet
          ) {
            dispatch(fetchPackages(ssoAgent))
            dispatch(setActiveStep(1))
          }
          const currencies = get(
            ssoAgent,
            'transactionData.paymentLevels' || []
          )

          const hasUSD =
            currencies &&
            currencies.length &&
            currencies.some(currency => currency.paymentCurrencyCode === 'USD')
          const firstCurrencyCode = get(
            currencies,
            '0.paymentCurrencyCode',
            null
          )
          if (!hasUSD && firstCurrencyCode) {
            dispatch(setActiveCurrency(firstCurrencyCode))
          }
          dispatch(fetchSsoSuccess(ssoAgent))
        } else {
          return Promise.reject('Please enter a valid agent ID.')
        }
      })
      .catch(error => {
        dispatch(fetchSsoError(error))
      })
  }
}
