import React, { Component } from 'react'
import { connect } from 'react-redux'

import moment from 'moment'
import _, { get } from 'lodash'

import EQuoteSearchFiltersFullFormBrand from './EQuoteSearchFiltersFullFormBrand'
import EQuoteSearchFiltersFullFormShip from './EQuoteSearchFiltersFullFormShip'
import EQuoteSearchFiltersFullFormDestination from './EQuoteSearchFiltersFullFormDestination'
import EQuoteSearchFiltersFullFormDateRange from './EQuoteSearchFiltersFullFormDateRange'
import EQuoteSearchFiltersFullFormNights from './EQuoteSearchFiltersFullFormNights'
import EQuoteSearchFiltersFullFormPromo from './EQuoteSearchFiltersFullFormPromo'
import EQuoteSearchFiltersFullFormResident from './EQuoteSearchFiltersFullFormResident'
import EQuoteSearchFiltersFullFormLoyalty from './EQuoteSearchFiltersFullFormLoyalty'
import EQuoteSearchFiltersFullFormSubmit from './EQuoteSearchFiltersFullFormSubmit'
import EQuoteSearchFiltersFullFormCurrency from './EQuoteSearchFiltersFullFormCurrency'
import EQuoteSearchFiltersFullFormGuestCount from './EQuoteSearchFiltersFullFormGuestCount'
import {
  setActiveBrand,
  setActiveCurrency,
  setActiveShip,
  setActiveRegion,
  setActiveStartDate,
  setActiveEndDate,
  setActiveSailingNights,
  setActiveSeniorCitizenDiscount,
  setActiveServiceMemberDiscount,
  setActivePoliceDiscount,
  setActiveFireFighterDiscount,
  setActiveResidentOf,
  setEndDateVisibility,
  setActiveGuestCount
} from '../../../actions/setActiveSearchFilters'

import fetchLoyalty from '../../../actions/fetchLoyalty'
import removeLoyalty from '../../../actions/removeActiveLoyalty'

import fullSearchIcon from '../../../assets/icons/full-search.svg'
import triggerCustomGaEvent from '../../../utilities/triggerCustomGaEvent'
import { getBrandsData } from '../../../sessionStorage/getBrandsData'
import { getDestinationsData } from '../../../sessionStorage/getDestinationsData'
import { getLabelsData } from '../../../sessionStorage/getLabelsData'
import { getShipsData } from '../../../sessionStorage/getShipsData'
import { getShipDestinationsData } from '../../../sessionStorage/getShipDestinationsData'
import {
  getCanUseQualifiers,
  getDisabledBrandsFromSearchCriteria
} from '../../../reducers/fetchSsoReducers'

class EQuoteSearchFiltersFullForm extends Component {
  constructor(props) {
    super(props)

    // Initialize local state form values
    this.state = {
      startDateModalVisible: false,
      endDateModalVisible: false,
      endDateFieldVisible: false,
      currentBrand: 'C,R',
      currentShip: 0,
      currentDestination: 0,
      currentEmbark: null,
      currentDebark: null,
      currentCount: null,
      duration: '',
      isSeniorCitizen: false,
      isServiceMember: false,
      isPolice: false,
      isFireFighter: false,
      currentResidency: 0,
      currentCurrency: get(props, 'activeSearchFilterData.currency', 'USD'),
      guestCount: 2
    }
  }

  componentDidMount() {
    const { activeSearchFilterData } = this.props

    // Update initial form values if set via parameters and activeSearchFilterData
    if (activeSearchFilterData.brand) {
      this.setState({
        currentBrand: activeSearchFilterData.brand
      })
    } else {
      this.handleSelectBrand('C,R')
    }

    if (activeSearchFilterData.currency) {
      this.setState({
        currentCurrency: activeSearchFilterData.currency
      })
    }

    if (activeSearchFilterData.ship) {
      this.setState({
        currentShip: activeSearchFilterData.ship
      })
    }

    if (activeSearchFilterData.region) {
      this.setState({
        currentDestination: activeSearchFilterData.region
      })
    }

    if (activeSearchFilterData.startDate) {
      this.setState({
        currentEmbark: moment(activeSearchFilterData.startDate)
      })
    } else {
      const { handleActiveStartDate } = this.props

      this.setState({
        currentEmbark: moment()
      })

      handleActiveStartDate(moment().format('YYYY-MM-DD'))
    }

    if (activeSearchFilterData.endDate) {
      this.setState({
        currentDebark: moment(activeSearchFilterData.endDate)
      })
    }

    if (activeSearchFilterData.count) {
      this.setState({
        count: activeSearchFilterData.count
      })
    }

    if (activeSearchFilterData.isSeniorCitizen) {
      this.setState({
        isSeniorCitizen: activeSearchFilterData.isSeniorCitizen
      })
    }

    if (activeSearchFilterData.isServiceMember) {
      this.setState({
        isServiceMember: activeSearchFilterData.isServiceMember
      })
    }

    if (activeSearchFilterData.isPolice) {
      this.setState({
        isPolice: activeSearchFilterData.isPolice
      })
    }

    if (activeSearchFilterData.isFireFighter) {
      this.setState({
        isFireFighter: activeSearchFilterData.isFireFighter
      })
    }

    if (activeSearchFilterData.residentOf) {
      this.setState({
        currentResidency: activeSearchFilterData.residentOf
      })
    }
  }

  // Update brand in local state and redux activeSearchFilter Data
  handleSelectBrand = brand => {
    const {
      handleActiveBrand,
      handleActiveShip,
      handleActiveDestination,
      activeSearchFilterData,
      shipDestinations
    } = this.props
    triggerCustomGaEvent('equoteSearch-filterButton-brand', { brand })
    if (
      shipDestinations &&
      shipDestinations[brand] &&
      activeSearchFilterData &&
      activeSearchFilterData.region
    ) {
      const activeBrandDestinations = shipDestinations[brand]
      let brandContainsDestination = false

      for (const shipKey in activeBrandDestinations) {
        if (
          _.includes(
            activeBrandDestinations[shipKey],
            activeSearchFilterData.region
          )
        ) {
          brandContainsDestination = true
        }
      }

      if (brandContainsDestination) {
        // No region set - reset values for ship
        this.setState({
          currentBrand: brand,
          currentShip: 0
        })

        // Dispatch current brand and reset ship - region to redux
        handleActiveBrand(brand)
        handleActiveShip()
      } else {
        // No region set - reset values for ship and destination
        this.setState({
          currentBrand: brand,
          currentShip: 0,
          currentDestination: 0
        })

        // Dispatch current brand and reset ship - region to redux
        handleActiveBrand(brand)
        handleActiveShip()
        handleActiveDestination()
      }
    } else {
      // No region set - reset values for ship and destination
      this.setState({
        currentBrand: brand,
        currentShip: 0,
        currentDestination: 0
      })

      // Dispatch current brand and reset ship - region to redux
      handleActiveBrand(brand)
      handleActiveShip()
      handleActiveDestination()
    }
  }

  // Update currency in local state and redux activeSearchFilter Data
  handleSelectCurrency = currency => {
    const {
      handleActiveCurrency,
      getUnsupportedBrands,
      activeSearchFilterData
    } = this.props

    const activeBrandCode = activeSearchFilterData.brand
    const matchingUnsupportedBrands = getUnsupportedBrands({ currency })
    let brand = activeSearchFilterData.brand
    if (
      matchingUnsupportedBrands &&
      matchingUnsupportedBrands.length &&
      matchingUnsupportedBrands.some(brandCode => brandCode === activeBrandCode)
    ) {
      brand = 'C,R'
    }

    triggerCustomGaEvent('equoteSearch-filterButton-currency', { currency })
    this.setState({
      currentCurrency: currency
    })
    if (activeBrandCode !== brand) {
      this.handleSelectBrand(brand)
    }
    handleActiveCurrency(currency)
  }

  // Update ship in local state and redux activeSearchFilter Data
  handleSelectShip = ship => {
    const { handleActiveShip } = this.props
    triggerCustomGaEvent('equoteSearch-filterButton-ship', { ship })

    this.setState({
      currentShip: ship
    })

    // Dispatch ship to redux
    handleActiveShip(ship)
  }

  // Update destination in local state and redux activeSearchFilter Data
  handleSelectDestination = destination => {
    const { handleActiveDestination } = this.props
    triggerCustomGaEvent('equoteSearch-filterButton-destination', {
      destination
    })

    this.setState({
      currentDestination: destination
    })

    // Dispatch destination to redux
    handleActiveDestination(destination)
  }

  // Trigger custom date fields
  handleOpenStartDateModal = () => {
    this.setState({ startDateModalVisible: true })
  }
  handleCloseStartDateModal = () => {
    this.setState({ startDateModalVisible: false })
  }

  // Trigger custom date fields
  handleOpenEndDateModal = () => {
    this.setState({ endDateModalVisible: true })
  }

  handleCloseEndDateModal = () => {
    this.setState({ endDateModalVisible: false })
  }

  // Update startDate in local state and redux activeSearchFilter Data
  onStartDateChange = startDate => {
    const { handleActiveStartDate, handleActiveEndDate } = this.props
    triggerCustomGaEvent('equoteSearch-filterButton-startDate', { startDate })

    this.setState({
      currentEmbark: startDate
    })

    // Dispatch startDate to redux when set
    if (startDate) {
      handleActiveStartDate(moment(startDate).format('YYYY-MM-DD'))
      this.handleCloseStartDateModal()
    }

    // Push endDate beyond start date when the same or greater
    if (this.state.currentDebark && startDate >= this.state.currentDebark) {
      this.setState({
        currentDebark: moment(startDate).add(1, 'days')
      })

      handleActiveEndDate(
        moment(startDate)
          .add(1, 'days')
          .format('YYYY-MM-DD')
      )
    }
  }

  // Update endDate in local state and redux activeSearchFilter Data
  onEndDateChange = endDate => {
    const { handleActiveEndDate } = this.props
    triggerCustomGaEvent('equoteSearch-filterButton-endDate', { endDate })

    this.setState({
      currentDebark: endDate
    })

    // Dispatch endDate to redux when set
    if (endDate) {
      handleActiveEndDate(moment(endDate).format('YYYY-MM-DD'))
      this.handleCloseEndDateModal()
    }
  }

  // Render end date field
  toggleEndDateField = () => {
    const { handleEndDataVisibility } = this.props

    this.setState({
      endDateFieldVisible: !this.state.endDateFieldVisible
    })

    handleEndDataVisibility(!this.state.endDateFieldVisible)
  }

  // Update isSeniorCitizen in local state and redux
  handleSelectSeniorCitizen = () => {
    const { handleActiveSeniorCitizenDiscount } = this.props
    triggerCustomGaEvent('equoteSearch-filterButton-SeniorCitizen', {
      isSeniorCitizen: !this.state.isSeniorCitizen
    })

    this.setState({
      isSeniorCitizen: !this.state.isSeniorCitizen
    })

    // Dispatch isSeniorCitizen to redux
    handleActiveSeniorCitizenDiscount(!this.state.isSeniorCitizen)
  }

  // Update isServiceMember in local state and redux
  handleSelectServiceMember = () => {
    const { handleActiveServiceMemberDiscount } = this.props
    triggerCustomGaEvent('equoteSearch-filterButton-ServiceMemberDiscount', {
      isServiceMember: !this.state.isServiceMember
    })

    this.setState({
      isServiceMember: !this.state.isServiceMember
    })

    // Dispatch isServiceMember to redux
    handleActiveServiceMemberDiscount(!this.state.isServiceMember)
  }

  // Update isPolice in local state and redux
  handleSelectPolice = () => {
    const { handleActivePoliceDiscount } = this.props
    triggerCustomGaEvent('equoteSearch-filterButton-PoliceDiscount', {
      isPolice: !this.state.isPolice
    })

    this.setState({
      isPolice: !this.state.isPolice
    })

    // Dispatch isPolice to redux
    handleActivePoliceDiscount(!this.state.isPolice)
  }

  // Update isFireFighter in local state and redux
  handleSelectFireFighter = () => {
    const { handleActiveFireFighterDiscount } = this.props
    triggerCustomGaEvent('equoteSearch-filterButton-FireFighterDiscount', {
      isFireFighter: !this.state.isFireFighter
    })

    this.setState({
      isFireFighter: !this.state.isFireFighter
    })

    // Dispatch isFireFighter to redux
    handleActiveFireFighterDiscount(!this.state.isFireFighter)
  }

  // Update residentOf in local state and redux activeSearchFilter Data
  handleSelectResidentOf = residentOf => {
    const { handleActiveResidentOf } = this.props
    triggerCustomGaEvent('equoteSearch-filterButton-residentOf', {
      currentResidency: residentOf
    })

    this.setState({
      currentResidency: residentOf
    })

    // Dispatch residentOf to redux
    handleActiveResidentOf(residentOf)
  }

  handleSelectGuestCount = guestCount => {
    this.setState({ guestCount })
    this.props.handleGuestCountSelections(guestCount)
  }

  render() {
    const {
      activeSearchFilterData,
      brands,
      destinations,
      ships,
      shipDestinations,
      labelsData,
      loyaltyData,
      ssoData,
      handleFetchLoyalty,
      handleRemoveLoyalty,
      handleActiveSailingNights,
      allowSpecialQualifiers
    } = this.props
    let searchLabels = null,
      isArrivalSet = false,
      isSearchEnabled = false

    if (labelsData && labelsData.search) {
      searchLabels = labelsData.search
    }

    // Flag to enable search based on end date OR count
    if (activeSearchFilterData.endDate || activeSearchFilterData.count) {
      isArrivalSet = true
    }

    if (
      activeSearchFilterData.bookingType &&
      activeSearchFilterData.cruiseType &&
      activeSearchFilterData.brand &&
      activeSearchFilterData.brand !== '0' &&
      activeSearchFilterData.startDate &&
      isArrivalSet
    ) {
      isSearchEnabled = true
    }

    return (
      <div className="equote__search-filters-form u-content-container">
        <h6 className="u-small-title">
          <img src={fullSearchIcon} alt="Ship icon" />{' '}
          {searchLabels && searchLabels.full_search_title
            ? searchLabels.full_search_title
            : 'Full Search'}
        </h6>

        <div className="equote__search-filters-form-field">
          <label className="equote__search-filters-form-field-title u-font-bold u-remove-margin">
            {searchLabels && searchLabels.full_search_modal_brand_title
              ? searchLabels.full_search_modal_brand_title
              : 'Brand'}
          </label>
          <EQuoteSearchFiltersFullFormBrand
            brands={brands}
            currentBrand={this.state.currentBrand}
            handleSelectBrand={this.handleSelectBrand}
            disabledBrands={this.props.getUnsupportedBrands({
              currency: this.state.currentCurrency
            })}
          />

          <EQuoteSearchFiltersFullFormShip
            currentBrand={this.state.currentBrand}
            currentShip={this.state.currentShip}
            handleSelectShip={this.handleSelectShip}
            ships={ships}
          />
        </div>

        <div className="equote__search-filters-form-field">
          <label className="equote__search-filters-form-field-title u-font-bold u-remove-margin">
            {searchLabels && searchLabels.full_search_modal_destination_title
              ? searchLabels.full_search_modal_destination_title
              : 'Destination'}
          </label>

          <EQuoteSearchFiltersFullFormDestination
            currentBrand={this.state.currentBrand}
            currentShip={this.state.currentShip}
            currentDestination={this.state.currentDestination}
            destinations={destinations}
            handleSelectDestination={this.handleSelectDestination}
            shipDestinations={shipDestinations}
          />
        </div>
        <div className="equote__search-filters-form-field">
          <label className="equote__search-filters-form-field-title u-font-bold u-remove-margin">
            Guests
          </label>
          <EQuoteSearchFiltersFullFormGuestCount
            guestCount={this.state.guestCount}
            handleSelectGuestCount={this.handleSelectGuestCount}
            activeBrand={
              activeSearchFilterData ? activeSearchFilterData.brand : ''
            }
          />
        </div>
        <div className="equote__search-filters-form-field">
          <label className="equote__search-filters-form-field-title u-font-bold u-remove-margin">
            {searchLabels && searchLabels.full_search_modal_dates_title
              ? searchLabels.full_search_modal_dates_title
              : 'Sailing Dates'}
          </label>

          <div className="equote__search-filters-form-field-group">
            <div
              className="equote__search-filters-form-custom-date"
              onClick={() => this.handleOpenStartDateModal()}
              tabIndex={0}
            >
              <label className="u-custom-select__label">Depart</label>
              <div className="u-custom-select u-custom-select--inc-label">
                {this.state.currentEmbark ? (
                  <span className="u-custom-select__field">
                    {moment(this.state.currentEmbark).format('DDMMMYYYY')}
                  </span>
                ) : (
                  <span className="u-custom-select__field">
                    Choose Departure Date
                  </span>
                )}

                <span className="u-custom-select__arrow" />
              </div>
            </div>

            {activeSearchFilterData.endDateVisible ? null : (
              <div
                className="equote__search-filters-toggle-date u-font-xs"
                onClick={() => this.toggleEndDateField()}
              >
                Show Date Range
              </div>
            )}
          </div>

          {this.state.startDateModalVisible ? (
            <EQuoteSearchFiltersFullFormDateRange
              handleCloseDateRangeModal={this.handleCloseStartDateModal}
              dateRangeModalVisible={true}
              currentDate={this.state.currentEmbark}
              onDateChange={this.onStartDateChange}
              activeSearchFilterData={activeSearchFilterData}
            />
          ) : null}

          {activeSearchFilterData.endDateVisible ? (
            <div
              className="equote__search-filters-form-custom-date"
              onClick={() => this.handleOpenEndDateModal()}
              tabIndex={0}
            >
              <label className="u-custom-select__label">Return</label>
              <div className="u-custom-select u-custom-select--inc-label">
                {this.state.currentDebark ? (
                  <span className="u-custom-select__field">
                    {moment(this.state.currentDebark).format('DDMMMYYYY')}
                  </span>
                ) : (
                  <span className="u-custom-select__field u-font-initial">
                    Choose Arrival Date
                  </span>
                )}

                <span className="u-custom-select__arrow" />
              </div>
            </div>
          ) : null}

          {this.state.endDateModalVisible ? (
            <EQuoteSearchFiltersFullFormDateRange
              handleCloseDateRangeModal={this.handleCloseEndDateModal}
              dateRangeModalVisible={true}
              currentDate={this.state.currentDebark}
              onDateChange={this.onEndDateChange}
              activeSearchFilterData={activeSearchFilterData}
              minStartDate={
                this.state.currentEmbark
                  ? moment(this.state.currentEmbark).add(1, 'days')
                  : null
              }
            />
          ) : null}

          <EQuoteSearchFiltersFullFormNights
            activeSearchFilterData={activeSearchFilterData}
            handleActiveSailingNights={duration =>
              handleActiveSailingNights(duration)
            }
          />
        </div>

        <div className="equote__search-filters-form-field">
          <label className="equote__search-filters-form-field-title u-font-bold u-remove-margin">
            {searchLabels && searchLabels.full_search_modal_fares_title
              ? searchLabels.full_search_modal_fares_title
              : 'Sailing Dates'}
          </label>
          <EQuoteSearchFiltersFullFormPromo
            isSeniorCitizen={this.state.isSeniorCitizen}
            handleSelectSeniorCitizen={this.handleSelectSeniorCitizen}
            isServiceMember={this.state.isServiceMember}
            handleSelectServiceMember={this.handleSelectServiceMember}
            isPolice={this.state.isPolice}
            handleSelectPolice={this.handleSelectPolice}
            isFireFighter={this.state.isFireFighter}
            handleSelectFireFighter={this.handleSelectFireFighter}
            activeSearchFilterData={activeSearchFilterData}
            guestCount={get(activeSearchFilterData, 'guestCount')}
            allowSpecialQualifiers={allowSpecialQualifiers}
          />
          {allowSpecialQualifiers && (
            <EQuoteSearchFiltersFullFormResident
              residentOf={this.state.currentResidency}
              handleSelectResidentOf={this.handleSelectResidentOf}
            />
          )}

          {ssoData &&
          ssoData.transactionData &&
          !_.isEmpty(ssoData.transactionData.paymentLevels) &&
          ssoData.transactionData.paymentLevels.length > 1 ? (
            <EQuoteSearchFiltersFullFormCurrency
              currentCurrency={this.state.currentCurrency}
              handleSelectCurrency={this.handleSelectCurrency}
              currencies={ssoData.transactionData.paymentLevels}
            />
          ) : null}
        </div>

        {ssoData && ssoData.agency ? (
          <div className="equote__search-filters-form-field">
            <label className="equote__search-filters-form-field-title u-font-bold u-remove-margin">
              Loyalty Number
            </label>

            <EQuoteSearchFiltersFullFormLoyalty
              activeSearchFilterData={activeSearchFilterData}
              handleFetchLoyalty={(loyaltyId, agencyId) =>
                handleFetchLoyalty(loyaltyId, agencyId)
              }
              handleRemoveLoyalty={activeLoyaltyId =>
                handleRemoveLoyalty(activeLoyaltyId)
              }
              loyaltyData={loyaltyData}
              ssoData={ssoData}
            />
          </div>
        ) : null}

        <EQuoteSearchFiltersFullFormSubmit
          isSearchEnabled={isSearchEnabled}
          activeSearchFilterData={activeSearchFilterData}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  const getUnsupportedBrands = getDisabledBrandsFromSearchCriteria(state)
  return {
    activeSearchFilterData: state.activeSearchFilterData,
    brands: getBrandsData(),
    calendarData: state.calendarData.availableDates,
    destinations: getDestinationsData(),
    labelsData: getLabelsData(),
    loyaltyData: state.loyaltyData,
    ships: getShipsData(),
    shipDestinations: getShipDestinationsData(),
    ssoData: state.ssoData,
    allowSpecialQualifiers: getCanUseQualifiers(state),
    getUnsupportedBrands
  }
}

const mapDispatchToProps = dispatch => ({
  handleActiveBrand(brand) {
    return dispatch(setActiveBrand(brand))
  },
  handleActiveCurrency(currency) {
    return dispatch(setActiveCurrency(currency))
  },
  handleActiveShip(ship) {
    return dispatch(setActiveShip(ship))
  },
  handleActiveDestination(destination) {
    return dispatch(setActiveRegion(destination))
  },
  handleActiveStartDate(startDate) {
    return dispatch(setActiveStartDate(startDate))
  },
  handleActiveEndDate(endDate) {
    return dispatch(setActiveEndDate(endDate))
  },
  handleActiveSailingNights(duration) {
    return dispatch(setActiveSailingNights(duration))
  },
  handleActiveSeniorCitizenDiscount(isSeniorCitizen) {
    return dispatch(setActiveSeniorCitizenDiscount(isSeniorCitizen))
  },
  handleActiveServiceMemberDiscount(isServiceMember) {
    return dispatch(setActiveServiceMemberDiscount(isServiceMember))
  },
  handleActivePoliceDiscount(isPolice) {
    return dispatch(setActivePoliceDiscount(isPolice))
  },
  handleActiveFireFighterDiscount(isFireFighter) {
    return dispatch(setActiveFireFighterDiscount(isFireFighter))
  },
  handleActiveResidentOf(residentOf) {
    return dispatch(setActiveResidentOf(residentOf))
  },
  handleFetchLoyalty(loyaltyId, agencyId) {
    return dispatch(fetchLoyalty(loyaltyId, agencyId))
  },
  handleRemoveLoyalty(activeLoyaltyId) {
    return dispatch(removeLoyalty(activeLoyaltyId))
  },
  handleEndDataVisibility(endDateVisible) {
    return dispatch(setEndDateVisibility(endDateVisible))
  },
  handleGuestCountSelections(count) {
    triggerCustomGaEvent('guestsTotalSelected', count)
    dispatch(setActiveGuestCount(count))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteSearchFiltersFullForm)
