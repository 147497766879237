import storageKeys from '../configuration/storageKeys'

export const getDestinationsData = () =>
  JSON.parse(sessionStorage.getItem(storageKeys.destinations))

export const getDestination = destinationCode => {
  const destinations = getDestinationsData()
  return destinations
    ? destinations.find(
        destination => destination.destination_code === destinationCode
      )
    : null
}
