import React, { Component } from 'react'

import Checkbox from '../../elements/Checkbox'

import EQuoteSearchFiltersFullFormLoyalty from './EQuoteSearchFiltersFullFormLoyalty'

class EQuoteSearchFiltersFullFormPromo extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      isSeniorCitizen,
      activeSearchFilterData,
      handleSelectSeniorCitizen,
      isServiceMember,
      handleSelectServiceMember,
      isPolice,
      handleSelectPolice,
      isFireFighter,
      handleSelectFireFighter,
      loyaltyData,
      ssoData,
      handleFetchLoyalty,
      handleRemoveLoyalty,
      guestCount,
      allowSpecialQualifiers
    } = this.props
    const totalCheckPromos = [isFireFighter, isPolice, isServiceMember].filter(
      promo => !!promo
    ).length
    const maxPromoReached = totalCheckPromos >= +guestCount
    return (
      <div className="equote__search-filters-promotional-fare">
        <div className="equote__search-filters-form-promo">
          <div className="equote__search-filters-form-promo-checkboxes">
            <div className="equote__search-filters-form-promo-checkboxes-group">
              <div className="equote__search-filters-form-promo-checkbox">
                <Checkbox
                  className="btn btn-checkbox"
                  checked={isSeniorCitizen}
                  onClick={handleSelectSeniorCitizen}
                  id="seniorDiscount"
                />
                <label
                  id="seniorDiscountLabel"
                  htmlFor="seniorDiscount"
                  className="u-remove-margin"
                >
                  Age 55+{' '}
                </label>
              </div>
              {allowSpecialQualifiers && (
                <>
                  <div
                    className={`equote__search-filters-form-promo-checkbox ${
                      !isPolice && maxPromoReached ? 'disabled' : ''
                    }`}
                  >
                    <Checkbox
                      className="btn btn-checkbox"
                      checked={isPolice}
                      onClick={handleSelectPolice}
                      id="serviceDiscount"
                      disabled={!isPolice && maxPromoReached}
                    />
                    <label
                      id="serviceDiscountLabel"
                      htmlFor="serviceDiscount"
                      className="u-remove-margin"
                    >
                      U.S. or Canadian Law Enforcement
                    </label>
                  </div>
                  <div
                    className={`equote__search-filters-form-promo-checkbox ${
                      !isFireFighter && maxPromoReached ? 'disabled' : ''
                    }`}
                  >
                    <Checkbox
                      className="btn btn-checkbox"
                      checked={isFireFighter}
                      onClick={handleSelectFireFighter}
                      id="firstResponderDiscount"
                      disabled={!isFireFighter && maxPromoReached}
                    />
                    <label
                      id="firstResponderDiscountLabel"
                      htmlFor="firstResponderDiscount"
                      className="u-remove-margin"
                    >
                      Fire Department or EMT
                    </label>
                  </div>
                  <div
                    className={`equote__search-filters-form-promo-checkbox ${
                      !isServiceMember && maxPromoReached ? 'disabled' : ''
                    }`}
                  >
                    <Checkbox
                      className="btn btn-checkbox"
                      checked={isServiceMember}
                      onClick={handleSelectServiceMember}
                      id="firstResponderPoliceDiscount"
                      disabled={!isServiceMember && maxPromoReached}
                    />
                    <label
                      id="firstResponderDiscountLabel"
                      htmlFor="firstResponderPoliceDiscount"
                      className="u-remove-margin"
                    >
                      U.S. Military or Canadian Forces
                    </label>
                  </div>
                </>
              )}
            </div>
          </div>

          {ssoData && ssoData.agency && loyaltyData ? (
            <div className="equote__search-filters-form-promo-loyalty">
              <label className="u-font-bold u-remove-margin">
                Loyalty Number
              </label>

              <EQuoteSearchFiltersFullFormLoyalty
                handleFetchLoyalty={(loyaltyId, agencyId) =>
                  handleFetchLoyalty(loyaltyId, agencyId)
                }
                handleRemoveLoyalty={activeLoyaltyId =>
                  handleRemoveLoyalty(activeLoyaltyId)
                }
                activeSearchFilterData={activeSearchFilterData}
                loyaltyData={loyaltyData}
                ssoData={ssoData}
              />
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

export default EQuoteSearchFiltersFullFormPromo
