import React from 'react'
import RoyalTourIcon from '../../assets/icons/360-arrow-royal.svg'
import CelebTourIcon from '../../assets/icons/celeb-360-double-arrow.svg'

const VirtualTourArrowButton = ({ text, onClick, brandClassName = '' }) => (
  <button
    className={`u-virtual-tour-trigger ${brandClassName}`}
    onClick={onClick}
  >
    <div className="u-virtual-tour-trigger-content">
      <div className="u-virtual-tour-image-wrapper">
        <img
          src={brandClassName === 'royal' ? RoyalTourIcon : CelebTourIcon}
          alt={text}
        />
      </div>
      <div className="u-virtual-tour-trigger-text-container">
        <p className="u-remove-margin">{text}</p>
      </div>
    </div>
  </button>
)

export default VirtualTourArrowButton
