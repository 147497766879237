import React, { useEffect } from 'react'

const EQuoteSearchFiltersFullFormGuestCount = ({
  handleSelectGuestCount,
  guestCount,
  activeBrand
}) => {
  useEffect(() => {
    if (activeBrand === 'Z' && Number(guestCount) === 4) {
      handleSelectGuestCount(3)
    }
  }, [activeBrand, guestCount])

  return (
    <div className="equote__search-filters-form-field-input u-custom-select">
      <select
        name="guestCount"
        onChange={e => handleSelectGuestCount(e.target.value)}
        value={guestCount}
        tabIndex={0}
        aria-labelledby="guestCountToggleLabel"
      >
        <option value="1">Number of Guests: 1</option>
        <option value="2">Number of Guests: 2</option>
        <option value="3">Number of Guests: 3</option>
        {activeBrand !== 'Z' && <option value="4">Number of Guests: 4</option>}
      </select>
      <span className="u-custom-select__arrow" />
    </div>
  )
}

export default EQuoteSearchFiltersFullFormGuestCount
