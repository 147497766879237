import React, { Component } from 'react'
import { connect } from 'react-redux'

import setActiveCabinStep from '../../actions/setActiveCabinStepActions'
import setActiveStep from '../../actions/setActiveStepActions'
import setActiveCabin from '../../actions/setActiveCabin'

import triggerCustomGaEvent from '../../utilities/triggerCustomGaEvent'

import EQuoteCabinsTableContainer from '../../components/cabins/EQuoteCabinsTableContainer'
import PageHeader from '../../components/elements/PageHeader'

import '../../styles/scss/components/equote-cabins.scss'
import '../../styles/scss/components/equote-nested-cabins-table.scss'
import { getAllActivePackagesDetails } from '../../reducers/setActivePackageReducers'
import { getAllActiveCabinData } from '../../reducers/setActiveCabinReducers'
import { getLabelsData } from '../../sessionStorage/getLabelsData'
import { currencyNames } from '../../configuration/currencies'

class EQuoteCabinsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      detailsModalVisible: false
    }
  }

  handleClosePackageDetailsModal = () => {
    triggerCustomGaEvent('equote-dismissSailDetails', {
      source: 'Edit Cabin Page'
    })
    this.setState({
      detailsModalVisible: false
    })
  }
  handleOpenPackageDetailsModal = () => {
    this.setState({
      detailsModalVisible: true
    })
  }

  componentDidMount() {
    const { activePackageData, handleSetActiveCabinStep } = this.props
    const initialCabinSteps = Object.keys(activePackageData).length
    for (const activePackage of Object.values(activePackageData)) {
      const key = activePackage.isLandPackage
        ? activePackage.landPackageIdentifier
        : activePackage.identifier

      const packageHasActiveCabinsSet = Boolean(
        this.props.activeCabinData && this.props.activeCabinData[key]
      )
      if (!packageHasActiveCabinsSet) {
        // we need to set all the superCats
        for (const superCat of Object.values(activePackage.tabularPricing)) {
          if (
            !superCat ||
            !superCat.superCategoryData ||
            !superCat.superCategoryData.bestGuestCountRate
          ) {
            continue
          }

          const activeCabinData = {
            superCategoryCode: superCat.superCategoryData.stateroomType,
            superCategoryDescription:
              superCat.superCategoryData.stateroomDescription,
            packageId: activePackage.id,
            sailDate: activePackage.sailDate,
            ship: activePackage.shipCode,
            stateKey: activePackage.stateKey,
            packageIdentifier: activePackage.identifier,
            subStateKey: activePackage.subStateKey,
            stateroomType: superCat.superCategoryData.stateroomType,
            isSuperCategory: true
          }
          this.props.setActiveCabin(
            key,
            superCat.superCategoryData.stateroomType,
            activeCabinData
          )
        }
      }
    }

    handleSetActiveCabinStep(
      initialCabinSteps,
      0,
      Object.keys(activePackageData)[0],
      true
    )

    // Dispatch GA event for search component
    const trackingComponentData = {
      currentStep: 3,
      component: 'Confirm Categories and Pricing'
    }

    triggerCustomGaEvent(
      'equoteTracking-currentActiveStep',
      trackingComponentData
    )
  }

  renderCabinComponents = () => {
    const { cabinStepData, activePackageData } = this.props
    const activePackage =
      cabinStepData &&
      cabinStepData.activeCabinId &&
      activePackageData &&
      activePackageData[cabinStepData.activeCabinId]
        ? activePackageData[cabinStepData.activeCabinId]
        : null
    if (activePackage) {
      return (
        <EQuoteCabinsTableContainer
          key={activePackage.id + activePackage.sailDate + '-cabin-table'}
          activePackage={activePackage}
          allActivePackages={activePackageData}
          handleOpenPackageDetailsModal={this.handleOpenPackageDetailsModal}
          handleClosePackageDetailsModal={this.handleClosePackageDetailsModal}
          confirmationLabelsData={this.props.confirmationLabelsData}
          detailsModalVisible={this.state.detailsModalVisible}
          showingLandPackages={cabinStepData.showingLandPackages}
        />
      )
    }

    return null
  }

  render() {
    const {
      cabinStepData,
      activeStep,
      handleBack,
      handleSetActiveCabinStep,
      activePackageData,
      confirmationLabelsData
    } = this.props
    return (
      <>
        <div className="equote__cabins u-content-container">
          <PageHeader
            buttonText={
              cabinStepData.activeCabin === 0
                ? 'Back to Search Results'
                : 'Edit Previous Sailing'
            }
            clickHandler={
              cabinStepData.activeCabin === 0
                ? () => handleBack(activeStep)
                : () =>
                    handleSetActiveCabinStep(
                      cabinStepData.cabinTotal,
                      cabinStepData.activeCabin - 1,
                      Object.keys(activePackageData)[
                        cabinStepData.activeCabin - 1
                      ]
                    )
            }
            title={confirmationLabelsData.title}
          />
          <div className="equote__cabins-content">
            {this.renderCabinComponents()}
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  const labelsData = getLabelsData()
  const currencyName = currencyNames[state.activeSearchFilterData.currency]
  const confirmationLabelsData =
    labelsData && labelsData.confirm
      ? {
          super_cat_section_body: `Include up to eight selections to show your client. Your selections may be a combination of different stateroom categories, as long as the number of boxes checked does not exceed eight. Prices are in ${currencyName ||
            state.activeSearchFilterData.currency}.`,
          super_cat_section_title: 'Category Information',
          title: 'Category Details'
        }
      : null

  return {
    activeStep: state.activeStepData.activeStep,
    activePackageData: getAllActivePackagesDetails(state),
    cabinStepData: state.activeCabinStepData,
    activeCabinData: getAllActiveCabinData(state),
    confirmationLabelsData
  }
}

const mapDispatchToProps = dispatch => ({
  handleBack(activeStep) {
    // Dispatch GA events
    triggerCustomGaEvent('backToSearchResults', true)

    return dispatch(setActiveStep(activeStep - 1))
  },
  handleSetActiveCabinStep(
    totalCabinSteps,
    activeCabinStep,
    activeCabinId,
    suppressGAEvent
  ) {
    // Dispatch GA events
    if (!suppressGAEvent) {
      triggerCustomGaEvent('buildYourQuote-editPreviousSailing', true)
    }
    return dispatch(
      setActiveCabinStep(totalCabinSteps, activeCabinStep, activeCabinId)
    )
  },
  setActiveCabin(packageKey, cabinKey, cabin) {
    dispatch(setActiveCabin(packageKey, cabinKey, cabin))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteCabinsContainer)
