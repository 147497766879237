import React from 'react'
import { get } from 'lodash'
import BackArrow from '../../assets/icons/back-button-arrow.svg'

import '../../styles/scss/components/equote-error.scss'

const EQuoteError = props => {
  const { buttonText, clickEvent, title } = props
  const message = get(
    props,
    'subTitle.response.data.[hydra:description]',
    get(props, 'subTitle.message', '')
  )
  return (
    <div className="equote__error">
      {buttonText && (
        <button className="btn btn-transparent" onClick={() => clickEvent()}>
          <span className="equote__error-back-content">
            <span className="equote__error-back-icon">
              <img src={BackArrow} alt="Begin a New Search" />
            </span>
            <span>{buttonText}</span>
          </span>
        </button>
      )}
      <div className="equote__error-title">
        <h2>{title}</h2>
        {message && <p className="u-remove-margin">{message}</p>}
      </div>
    </div>
  )
}

export default EQuoteError
