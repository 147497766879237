import {
  RECEIVE_UNSUPPORTED_QUALIFIER_PRICE,
  RECEIVE_UNSUPPORTED_QUALIFIER_PRICE_ERROR
} from '../actions/fetchUnsupportedQualifierPrices'
import { CLEAR_UNSUPPORTED_QUALIFIER_PRICES } from '../actions/clearUnsupportedQualifierPrices'
const initialState = {
  loading: false,
  error: null,
  packagesData: {},
  cruiseToursData: {}
}

const unsupportedQualifierPrices = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_UNSUPPORTED_QUALIFIER_PRICE:
      return Object.assign({}, state, {
        [action.payload.stateKey]: {
          ...state[action.payload.stateKey],
          [action.payload.sailDate]: {
            ...state[action.payload.stateKey][action.payload.sailDate],
            [action.payload.id]: {
              pricing: action.payload.pricing
            }
          }
        }
      })
    case RECEIVE_UNSUPPORTED_QUALIFIER_PRICE_ERROR:
      return Object.assign({}, state, {
        [action.payload.stateKey]: {
          ...state[action.payload.stateKey],
          [action.payload.sailDate]: {
            ...state[action.payload.stateKey][action.payload.sailDate],
            [action.payload.id]: {
              hasError: true
            }
          }
        }
      })
    case CLEAR_UNSUPPORTED_QUALIFIER_PRICES:
      return Object.assign({}, state, {
        packagesData: {},
        cruiseToursData: {}
      })
    default:
      return state
  }
}

export default unsupportedQualifierPrices

export const getPackageUnsupportedQualifierPrices = (
  state,
  stateKey = 'packagesData',
  date,
  id
) => {
  const packageToCheck = state.unsupportedQualifierPricesData[stateKey]

  if (packageToCheck && packageToCheck[date] && packageToCheck[date][id]) {
    return packageToCheck[date][id]

    // return Object.keys(packageToCheck[date][id].pricing).length
    //   ? packageToCheck[date][id].pricing
    //   : null
  }
  return null
}
