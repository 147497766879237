import axios from 'axios'
import moment from 'moment'
import 'url-search-params-polyfill'

export const FETCH_DESTINATION_MONTHS_BEGIN = 'FETCH_DESTINATION_MONTHS_BEGIN'
export const FETCH_DESTINATION_MONTHS_SUCCESS =
  'FETCH_DESTINATION_MONTHS_SUCCESS'
export const FETCH_DESTINATION_MONTHS_FAILURE =
  'FETCH_DESTINATION_MONTHS_FAILURE'

export const fetchDestinationMonthsBegin = () => ({
  type: FETCH_DESTINATION_MONTHS_BEGIN
})

export const fetchDestinationMonthsSuccess = labels => ({
  type: FETCH_DESTINATION_MONTHS_SUCCESS,
  payload: labels
})

export const fetchDestinationMonthsError = error => ({
  type: FETCH_DESTINATION_MONTHS_FAILURE,
  payload: { error }
})

// Handle Availability Fetch & Functionality
export default function fetchDestinationMonths() {
  return (dispatch, getState) => {
    dispatch(fetchDestinationMonthsBegin())
    const state = getState()
    const { activeSearchFilterData } = state
    const { guestCount } = activeSearchFilterData
    let currentCountryCode = 'USA'

    // Set country code based on current agent currency value
    if (
      activeSearchFilterData &&
      activeSearchFilterData.currency &&
      activeSearchFilterData.currency === 'USD'
    ) {
      currentCountryCode = 'USA'
    } else if (
      activeSearchFilterData &&
      activeSearchFilterData.currency &&
      activeSearchFilterData.currency === 'CAD'
    ) {
      currentCountryCode = 'CAN'
    }
    // Formatted API path
    const labelsApi =
      process.env.REACT_APP_FLOW_ENDPOINT_URL +
      'destination-months?' +
      currentCountryCode

    dispatch(fetchDestinationMonthsBegin())
    return axios
      .get(labelsApi)
      .then(response => {
        const destinationPrices = response.data
        if (destinationPrices) {
          const formattedDestinationMonths = formatDestinationMonths(
            response.data[currentCountryCode],
            guestCount
          )
          dispatch(fetchDestinationMonthsSuccess(formattedDestinationMonths))
        }
      })
      .catch(error => {
        dispatch(fetchDestinationMonthsError(error))
      })
  }
}

const formatDestinationMonths = (data, activeGuestCount) => {
  const guestCount = Number(activeGuestCount)

  let months = []
  const sortedDestinationData = Object.entries(data).reduce(
    (obj, destination) => {
      const destinationKey = destination[0]
      const destinationData = destination[1]
      if (!obj[destinationKey]) {
        obj[destinationKey] = {}
      }

      for (const brand of Object.entries(destinationData)) {
        const brandKey = brand[0]
        const brandData = brand[1]

        for (const month of Object.entries(brandData)) {
          const dateKey = month[0]
          if (!obj[destinationKey][dateKey]) {
            obj[destinationKey][dateKey] = {}
          }

          if (!months.find(month => month === dateKey)) months.push(dateKey)

          if (
            destinationData[brandKey] &&
            destinationData[brandKey][dateKey] &&
            destinationData[brandKey][dateKey][guestCount]
          ) {
            if (!obj[destinationKey][dateKey][brandKey]) {
              obj[destinationKey][dateKey][brandKey] = {
                ...destinationData[brandKey][dateKey][guestCount]
              }
            }
          }
        }
      }

      return obj
    },
    {}
  )

  const destinationDataWithBestPricing = Object.entries(
    sortedDestinationData
  ).reduce((obj, destination) => {
    const destinationKey = destination[0]
    const destinationData = destination[1]
    for (const month of Object.entries(destinationData)) {
      const monthKey = month[0]
      let bestUnfilteredRate
      let bestRateByCategory = {
        I: undefined,
        O: undefined,
        B: undefined,
        D: undefined
      }

      for (const brand of Object.entries(month[1])) {
        const brandKey = brand[0]
        for (const stateRoom of Object.entries(brand[1])) {
          const stateRoomKey = stateRoom[0]
          const stateRoomData = stateRoom[1]
          if (
            !bestUnfilteredRate ||
            bestUnfilteredRate.netPrice > stateRoomData.netPrice
          ) {
            bestUnfilteredRate = {
              ...stateRoomData,
              brand: brandKey,
              stateroomType: stateRoomKey,
              month: monthKey,
              destination: destinationKey
            }
          }
          if (
            !bestRateByCategory[stateRoomKey] ||
            bestRateByCategory[stateRoomKey].netPrice > stateRoomData.netPrice
          ) {
            bestRateByCategory[stateRoomKey] = {
              ...stateRoomData,
              brand: brandKey,
              stateroomType: stateRoomKey,
              month: monthKey,
              destination: destinationKey
            }
          }
        }
      }
      obj[destinationKey] = {
        ...sortedDestinationData[destinationKey],
        ...obj[destinationKey],
        [monthKey]: {
          ...sortedDestinationData[destinationKey][monthKey],
          bestRateByCategory,
          bestUnfilteredRate
        }
      }
    }
    return obj
  }, {})

  const sortedMonths = months.sort((a, b) => {
    if (moment(a, 'YYYY-MM').isBefore(moment(b, 'YYYY-MM'), 'month')) {
      return -1
    }
    return 1
  })

  return {
    destinations: destinationDataWithBestPricing,
    months: sortedMonths
  }
}
