import axios from 'axios'
import 'url-search-params-polyfill'
import moment from 'moment/moment'

export const FETCH_CALENDAR_BEGIN = 'FETCH_CALENDAR_BEGIN'
export const FETCH_CALENDAR_SUCCESS = 'FETCH_CALENDAR_SUCCESS'
export const FETCH_CALENDAR_FAILURE = 'FETCH_CALENDAR_FAILURE'

export const fetchCalendarBegin = () => ({
  type: FETCH_CALENDAR_BEGIN
})

export const fetchCalendarSuccess = availableDates => ({
  type: FETCH_CALENDAR_SUCCESS,
  payload: availableDates
})

export const fetchCalendarError = error => ({
  type: FETCH_CALENDAR_FAILURE,
  payload: { error }
})

var _ = require('lodash')

// Handle Calendar Availability Fetch & Functionality
export default function fetchCalendar(activeSearchFilterData) {
  return dispatch => {
    const sailDate = moment().format('YYYY-MM-DD')

    // URL Params
    let urlParams = {
      brand: activeSearchFilterData.brand,
      ship: activeSearchFilterData.ship,
      region: activeSearchFilterData.region
    }

    // Fetch Query string method
    const fetchQuery = new URLSearchParams()

    // Loop urlParams for undefined values & remove
    for (let key in urlParams) {
      if (typeof urlParams[key] === 'undefined') continue
      fetchQuery.append(key, urlParams[key])
    }

    // Base API path
    const calendarBase =
      process.env.REACT_APP_FLOW_ENDPOINT_URL + 'calendar/' + sailDate + '?'

    // Formatted API path including base and params
    const calendarApi = calendarBase + fetchQuery.toString()

    dispatch(fetchCalendarBegin())
    return axios
      .get(calendarApi)
      .then(response => {
        const calendarDates = response.data.data

        // Sort by dates and filer out unique values by date
        const sortedAvailability = _.sortBy(calendarDates, 'date'),
          uniqueAvailability = _.uniqBy(sortedAvailability, 'date')

        // Initialize date structures
        let availableCalenderDates = [],
          firstAvail = null,
          lastAvail = null,
          fullDateRange = [],
          blackoutDates = null

        // Push unique dates only into an array
        for (const dateKey in uniqueAvailability) {
          availableCalenderDates.push(uniqueAvailability[dateKey].date)
        }

        if (availableCalenderDates) {
          // Find first and last dates from full list of availability
          firstAvail = moment(sailDate)
          lastAvail = moment(
            availableCalenderDates[availableCalenderDates.length - 1]
          )

          // Generate full list of dates between first and last dates of availability
          while (firstAvail.isSameOrBefore(lastAvail)) {
            fullDateRange.push(moment(firstAvail).format('YYYY-MM-DD'))
            firstAvail = moment(firstAvail).add(1, 'days')
          }

          // Compare full list of dates against available dates to calculate blackout dates
          blackoutDates = _.difference(fullDateRange, availableCalenderDates)

          if (blackoutDates) {
            dispatch(
              fetchCalendarSuccess({ blackoutDates, availableCalenderDates })
            )
          }
        }
      })
      .catch(error => {
        dispatch(fetchCalendarError(error))
      })
  }
}
