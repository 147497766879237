export const REMOVE_ACTIVE_PACKAGE = 'REMOVE_ACTIVE_PACKAGE'

export const removePackage = activePackage => ({
	type: REMOVE_ACTIVE_PACKAGE,
	payload: activePackage
})

export default function removeActivePackage(activePackage) {
	return dispatch => {
		dispatch(removePackage(activePackage))
	}
}
